import React, { useState } from "react";
import { Form, Input, Col, Select, Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import NoCapManifest from "../../../../nocap.json";
let religionIndex = 0;

const Religion = (props) => {
  const [religion, setReligion] = useState("");
  const [religionList, setReligionList] = useState(
    NoCapManifest.candidateInfoForm.religions
  );

  const religionSelectOptions = religionList.map((religion, i) => {
    return (
      <Select.Option key={"religion" + i} value={religion}>
        {religion}
      </Select.Option>
    );
  });

  const addReligion = (e) => {
    e.preventDefault();
    setReligion("");
    setReligionList([
      ...religionList,
      religion || `New Religion ${religionIndex++}`,
    ]);
  };

  return (
    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
      <Form.Item label="Select religion" name="religion">
        <Select
          mode="tags"
          allowClear
          showSearch
          maxTagCount={2}
          placeholder="select religion"
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                <Input
                  style={{ flex: "auto" }}
                  value={religion}
                  onChange={(e) => setReligion(e.target.value)}
                />
                <a
                  style={{
                    flex: "none",
                    padding: "8px",
                    display: "block",
                    cursor: "pointer",
                    color: "#8c8c8c",
                  }}
                  href="/"
                  onClick={addReligion}
                >
                  <PlusOutlined /> Add
                </a>
              </div>
            </div>
          )}
        >
          {religionSelectOptions}
        </Select>
      </Form.Item>
    </Col>
  );
};

export default Religion;
