// Libraries
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import axios from "axios";

// Components
import AdHelper from "../shared/ad/AdHelper";
import config from "../../config";

// Ant Design Components
import {
  Button,
  Row,
  Col,
  Affix,
  BackTop,
  Input,
  message,
  Avatar,
  Spin,
  Popover,
} from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  SendOutlined,
  CheckOutlined,
  CloseOutlined,
  UserOutlined,
} from "@ant-design/icons";

const NewsPost = (props) => {
  const [comment, setComment] = useState();
  const [candidatesData, setCandidatesData] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const backToAccountTypesOptions = () => {
    history.goBack();
  };
  // Role-based render
  const user = JSON.parse(localStorage.getItem("user"));
  const canCommentVote = Boolean(user);
  const userUpVoted = user
    ? (props?.data?.upvotes || []).includes(user._id)
    : true;
  const userDownVoted = user
    ? (props?.data?.downvotes || []).includes(user._id)
    : true;

  const upVoteCount = (props?.data?.upvotes?.length || 0).toLocaleString(
    "en-US"
  );
  const downVoteCount = (props?.data?.downvotes?.length || 0).toLocaleString(
    "en-US"
  );

  const canVote = userUpVoted || userDownVoted;

  useEffect(() => {
    async function getCandidatesData() {
      let headers = {
        ids: JSON.stringify(props.data.candidates),
        token: localStorage.getItem("access_token"),
      };
      const { data: candidateResultData } = await axios.get(
        `${config.CANDIDATES}/candidates/find-by-ids`,
        { headers }
      );
      setCandidatesData(candidateResultData);
    }

    if (props?.data?.candidates) {
      getCandidatesData();
    }
  }, [props]);

  async function postUpdatePost(id, data, successMessage) {
    try {
      await axios.patch(`${config.BLOGS}/blogs/update/${id}`, data, {
        headers: {
          token: localStorage.getItem("access_token"),
        },
      });
      message.success(successMessage);
      props.getData();
    } catch (err) {
      message.warning("Something went wrong‼️");
    }
  }

  // Function to add post
  async function addCommentToPost(event) {
    event.preventDefault();
    setLoading(true);

    const post = props.data;
    const newComment = {
      comment,
      _id: user._id,
      nickname: user.nickname,
      picture: user.picture,
    };

    const newsPost = {
      ...post,
      comments: [newComment, ...(post.comments || [])],
    };

    await postUpdatePost(post._id, newsPost, "Comment posted!");
    setComment("");
    setLoading(false);
  }

  // Function to upvote or downvote a post
  async function voteInPost(event, type) {
    event.preventDefault();
    const post = props.data;
    // userId will be added instead of number to prevent multiple vote
    const newVoteData = {
      upvotes:
        type === "upvote" ? [user?._id, ...(post.upvotes || [])] : post.upvote,
      downvotes:
        type === "downvote"
          ? [user._id, ...(post.downvotes || [])]
          : post.downvote,
    };

    const voteData = {
      ...post,
      ...newVoteData,
    };

    await postUpdatePost(post._id, voteData, "Voted Successfully!");
  }

  const backButton = (
    <Button
      className="btn-bordered"
      onClick={backToAccountTypesOptions}
      style={{ width: "96px" }}
      icon={<ArrowLeftOutlined />}
      size="large"
    >
      Back
    </Button>
  );

  const popoverContent = !canCommentVote
    ? "Please signup in order to vote"
    : canVote
    ? "You have voted!"
    : "";
  const PopoverWrapper = ({ children }) => (
    <Popover
      content={popoverContent}
      trigger={!canCommentVote || canVote ? "hover" : undefined}
    >
      {children}
    </Popover>
  );

  return (
    <div className="BlogPost">
      <div className="constrain">
        <header>
          <div className="header-buttons">
            {backButton}
            {/* Admin condition: only show btn if admin */}
            {user?.role === "admin" && (
              <Link to={`${props.data._id}/edit`} className="link">
                <Button
                  className="btn-solid"
                  size="large"
                  icon={<EditOutlined />}
                >
                  Edit Article
                </Button>
              </Link>
            )}
            {/* Admin condition ends */}
          </div>
          <h2>{props.data.title}</h2>
        </header>
        <Row>
          <Col xs={0} xl={3}>
            <Affix offsetTop={10}>
              <AdHelper page="news-post" position="left" />
            </Affix>
          </Col>
          <Col xs={24} xl={18}>
            {props.data.coverImage ? (
              <div className="news-cover-image">
                <img src={props.data.coverImage} alt="news-cover" />
              </div>
            ) : null}
            {props.data.videoUrl ? (
              <div className="videoContainer">
                <div className="videoWrapper">
                  <iframe
                    src={props.data.videoUrl}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="video"
                  />
                </div>
              </div>
            ) : null}
            <div className="meta">
              <h3>
                Published by{" "}
                <span>
                  <span className="nickname">{props.data.author}</span>
                </span>
                <br />
                <span className="date">
                  {moment(props.data.created_at).format("MMMM DD, YYYY")}
                </span>
                <br />
                <span className="date">{props.data.city}</span>
              </h3>
            </div>
            {props.data.editorial && (
              <div id="post-editorial">
                <h4>Editorial / NoCap Commentary</h4>
                {ReactHtmlParser(props.data.editorial)}
              </div>
            )}
            <div id="post-body">
              {ReactHtmlParser(props.data.body)}

              <div
                className="BlogPostVoteCommentsSection"
                style={{ marginTop: 8 }}
              >
                <div className="title">
                  <h3>No Cap!</h3>
                </div>

                <div>
                  <div className="title"> <h4>Fact Check</h4> </div>
                    <div className="candidateAvatars" style={{marginBotton: 16}}>
                      {(props.data.factCheck !== undefined)?
                        ( props.data.factCheck.map((article) => {
                          return (
                          <a href={article.link} target="_blank" rel= "noreferrer">
                          <Avatar
                            size="small"
                            src={article.link}
                            icon={article.imageLink}
                          />
                          </a>
                        )
                        })

                        ):null
                      }
                    </div>
                </div>
                <br/>
                <div>
                <div className="title"> <h4>Candidates in this news</h4> </div>
                <div className="candidateAvatars" style={{ marginBottom: 16 }}>
                  {candidatesData.map((candidate) => {
                    return (
                      <Avatar
                        key={candidate._id}
                        size="small"
                        src={candidate?.bio?.profileImage}
                        icon={!candidate?.bio?.profileImage && <UserOutlined />}
                      />
                    );
                  })}
                </div>
                </div>

                {/*  Vote Actions*/}
                <div style={{ display: "flex", marginBottom: 16 }}>
                  <PopoverWrapper>
                    <Button
                      className="actionButton flex-row align-center"
                      onClick={(e) => voteInPost(e, "upvote")}
                      size="large"
                      icon={<CheckOutlined />}
                      disabled={canVote}
                    >
                      <div className="actionButtonText">
                        <span className="title">Upvotes</span>
                        <span className="body">{upVoteCount}</span>
                      </div>
                    </Button>
                  </PopoverWrapper>
                  <PopoverWrapper>
                    <Button
                      className="actionButton flex-row align-center"
                      onClick={(e) => voteInPost(e, "downvote")}
                      size="large"
                      icon={<CloseOutlined />}
                      disabled={canVote}
                    >
                      <div className="actionButtonText">
                        <div className="title">Downvotes</div>
                        <div className="body">{downVoteCount}</div>
                      </div>
                    </Button>
                  </PopoverWrapper>
                </div>

                {/* Comment Input */}
                <div className="commentsBody">
                  <h3>Comments</h3>
                  {(props?.data?.comments || []).map((data, idx) => {
                    return (
                      <div className="body" key={idx}>
                        <strong>{data.nickname}</strong> {data.comment}
                      </div>
                    );
                  })}
                </div>
                {canCommentVote && (
                  <Input.Group
                    className="commentsInputContainer"
                    compact
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Input
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      placeholder="Type to add comment"
                      disabled={loading}
                      bordered={false}
                    />
                    <Button
                      size="large"
                      icon={loading ? <Spin /> : <SendOutlined />}
                      disabled={loading}
                      onClick={(e) => addCommentToPost(e)}
                    />
                  </Input.Group>
                )}
              </div>
            </div>

          </Col>

          <Col xs={0} xl={3}>
            <Affix offsetTop={10}>
              <AdHelper page="news-post" position="right" />
            </Affix>
          </Col>
          <Col xs={12} xl={0}>
            <AdHelper page="news-post" position="left" />
          </Col>
          <Col xs={12} xl={0}>
            <AdHelper page="news-post" position="right" />
          </Col>
        </Row>
      </div>
      <div className="footer" style={{ justifyContent: "center" }}>
        {backButton}
      </div>
      <BackTop />
    </div>
  );
};

export default NewsPost;
