import React from "react";
import { LeftSquareOutlined, RightSquareOutlined } from "@ant-design/icons";

const NextArrow = ({ onClick }) => (
  <button onClick={onClick} className="slick-custom-next">
    <RightSquareOutlined />
  </button>
);

const PrevArrow = ({ onClick }) => (
  <button onClick={onClick} className="slick-custom-prev">
    <LeftSquareOutlined />
  </button>
);

export const sliderSettings = {
  infinite: true,
  arrows: true,
  centerMode: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  initialSlide: 1,
  prevArrow: <PrevArrow />,
};
