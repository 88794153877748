import moment from "moment";

const geolib = require("geolib");

export const isNumberKey = (val) => {
  var isValid = false;

  var regex = /^[0-9\s]*$/;
  isValid = regex.test(val);
  return isValid;
};

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const capitalizeFirstLetter = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeFirstLetterOfEachWord = (str) => {
  let splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

export const objectIsEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

const DEFAULT_COORDS = { lng: -118.243683, lat: 34.052235 };

export const findNearestLocation = (array) => {
  // console.log("ARRRAY", typeof array)
  // console.log(array);
  let locationArray = array ? array : [];

  let userPoint =
    sessionStorage.getItem("coordinates") !== null
      ? JSON.parse(sessionStorage.getItem("coordinates"))
      : DEFAULT_COORDS;
  // console.log("USER POINTS", userPoint);
  let locationArrayParsed = locationArray.map((key) => ({
    latitude: key.geo.coordinates[1],
    longitude: key.geo.coordinates[0],
  }));
  // console.log("PARSED", locationArrayParsed);
  // console.log(geolib);
  let nearestLocation = geolib.findNearest(
    { latitude: userPoint.lat, longitude: userPoint.lng },
    locationArrayParsed
  );
  // console.log("Results NEREST LOCATIN", nearestLocation);
  return nearestLocation;
};

export const getQueryVariable = (variable, _this) => {
  var query = _this.props.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  // console.log("Query variable %s not found", variable);
  return false;
};

/*
 * Evaluate the business hours and check if current time is in between open hours.
 * Converting the time zones for each business as it is entered by the advertiser
 * is beyond the current scope. Good luck
 */
export const isOpenNow = (hours, todayAsMoment) => {
  // console.log(hours, todayAsMoment, "bring in the vars")
  const todayAsWeekday = todayAsMoment.format("dddd");
  // console.log(todayAsWeekday, "vars keep goign", )
  if (!hours || !Object.keys(hours).includes(todayAsWeekday)) return false;
  // console.log(hours[todayAsWeekday].open, hours[todayAsWeekday].close, "to moment",
  //   moment(hours[todayAsWeekday].open, "HH:mm"),
  //   moment(hours[todayAsWeekday].close, "HH:mm")
  // , "open hours return?")
  return todayAsMoment.isBetween(
    moment(hours[todayAsWeekday].open),
    moment(hours[todayAsWeekday].close)
  );
};

export const getPrimaryCategoryName = (categories) => {
  if (!categories || categories.length < 1) return "Multi";
  // if (categories.length > 1) return "Multi";
  return categories[0];
};

// Service Categories

export const servicesCategories = [
  "Coalition - A-D",
  "Coalition - E-H",
  "Coalition - I-L",
  "Coalition - M-P",
  "Coalition - Q-T",
  "Coalition - U-X",
  "Coalition - Y-Z",

  "Organization - Not For Profit",
  "Organization - NGO",
  "Organization - Other",

  "Service - Other",
  "Service - Promotional Materials",
  "Service - Lobbyists",

  "Movement - Racial Justice",
  "Movement - Women's Rights",
  "Movement - Environmental",
];

// Map of optimal image geometry indexed by image type. Aspect ratio is computed by w/h.
export const imageSizes = {
  logo: { w: 300, h: 300 },
  product: { w: 700, h: 700 },
  profile: { w: 1300, h: 300 },

  // AD - There are currently multiple ad formats referenced in the App by some acronoym.
  // For now, lets use these as the keys to determine image characteristics.
  HTL: { w: 850, h: 400 },
  HHR: { w: 850, h: 800 },
  HBL: { w: 425, h: 400 },
  HBR: { w: 425, h: 400 },
  BHL: { w: 800, h: 350 },
  BHR: { w: 800, h: 350 },
  EHL: { w: 800, h: 350 },
  EHR: { w: 800, h: 350 },
  CHL: { w: 800, h: 350 },
  CHR: { w: 800, h: 350 },

  SHT: { w: 295, h: 590 },
  SHB: { w: 295, h: 590 },

  "blogs-cover": { w: 315, h: 200 }, // NOTE: For blogs-cover only, these are maximum constraints... image is free to be smaller
  "blog-body": { w: 700, h: 700 },
};

export const pages = [
  { name: "Home", weight: 5 },
  { name: "Explore", weight: 4 },
  { name: "Calendar", weight: 4 },
  { name: "Then - Now", weight: 4 },
  { name: "Search", weight: 5 },
];

export const top200LocationsId = [
  //new york
  {
    placeId: "ChIJOwg_06VPwokRYv534QaPC8g",
    USstate: "NY",
    areaCoords: [
      { latitude: 40.49, longitude: -74.26 },
      { latitude: 40.53, longitude: -73.69 },
      { latitude: 40.91, longitude: -73.69 },
      { latitude: 40.91, longitude: -73.69 },
    ],
    weight: 10,
  },
  //Los Angelos
  {
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
    USstate: "CA",
    areaCoords: [
      { latitude: 33.68, longitude: -118.1 },
      { latitude: 33.69, longitude: -118.61 },
      { latitude: 34.34, longitude: -118.72 },
      { latitude: 34.36, longitude: -118.17 },
    ],
    weight: 10,
  },
  //Chicago
  {
    placeId: "ChIJ7cv00DwsDogRAMDACa2m4K8",
    USstate: "IL",
    areaCoords: [
      { latitude: 41.64, longitude: -87.51 },
      { latitude: 41.63, longitude: -87.95 },
      { latitude: 42.02, longitude: -87.97 },
      { latitude: 42.03, longitude: -87.53 },
    ],
    weight: 10,
  },
  //Miami
  {
    placeId: "ChIJEcHIDqKw2YgRZU-t3XHylv8",
    USstate: "FL",
    areaCoords: [
      { latitude: 25.7, longitude: -80.12 },
      { latitude: 25.69, longitude: -80.32 },
      { latitude: 25.86, longitude: -80.32 },
      { latitude: 25.86, longitude: -80.1 },
    ],
    weight: 10,
  },
  //Philidelphia
  {
    placeId: "ChIJ60u11Ni3xokRwVg-jNgU9Yk",
    USstate: "PA",
    areaCoords: [
      { latitude: 40.14, longitude: -74.94 },
      { latitude: 39.87, longitude: -74.97 },
      { latitude: 39.84, longitude: -75.28 },
      { latitude: 40.16, longitude: -75.3 },
    ],
    weight: 10,
  },
  //Toronto
  {
    placeId: "ChIJpTvG15DL1IkRd8S0KlBVNTI",
    USstate: "ON",
    areaCoords: [
      { latitude: 43.59, longitude: -79.01 },
      { latitude: 43.54, longitude: -79.62 },
      { latitude: 43.85, longitude: -79.69 },
      { latitude: 43.88, longitude: -79.1 },
    ],
    weight: 10,
  },
  //Dallas
  {
    placeId: "ChIJS5dFe_cZTIYRj2dH9qSb7Lk",
    USstate: "TX",
    areaCoords: [
      { latitude: 32.61, longitude: -96.52 },
      { latitude: 33.04, longitude: -96.59 },
      { latitude: 33.01, longitude: -97.02 },
      { latitude: 32.6, longitude: -97.02 },
    ],
    weight: 10,
  },
  //Houston
  {
    placeId: "ChIJAYWNSLS4QIYROwVl894CDco",
    USstate: "TX",
    areaCoords: [
      { latitude: 29.53, longitude: -95.02 },
      { latitude: 29.49, longitude: -95.77 },
      { latitude: 30.11, longitude: -95.81 },
      { latitude: 30.14, longitude: -95.06 },
    ],
    weight: 10,
  },
  //Washington
  {
    placeId: "ChIJW-T2Wt7Gt4kRKl2I1CJFUsI",
    USstate: "DC",
    areaCoords: [
      { latitude: 38.79, longitude: -77.02 },
      { latitude: 38.93, longitude: -77.13 },
      { latitude: 39.0, longitude: -77.03 },
      { latitude: 38.89, longitude: -76.89 },
    ],
    weight: 10,
  },
  //Atlanta
  {
    placeId: "ChIJjQmTaV0E9YgRC2MLmS_e_mY",
    USstate: "GA",
    areaCoords: [
      { latitude: 33.64, longitude: -84.28 },
      { latitude: 33.64, longitude: -84.56 },
      { latitude: 33.9, longitude: -84.54 },
      { latitude: 33.89, longitude: -84.27 },
    ],
    weight: 1,
  },
  //Boston
  {
    placeId: "ChIJGzE9DS1l44kRoOhiASS_fHg",
    USstate: "MA",
    areaCoords: [
      { latitude: 42.32, longitude: -70.86 },
      { latitude: 42.2, longitude: -71.12 },
      { latitude: 42.31, longitude: -71.28 },
      { latitude: 42.46, longitude: -71.08 },
    ],
    weight: 10,
  },

  //Detroit
  {
    placeId: "ChIJdR3LEAHKJIgR0sS5NU6Gdlc",
    USstate: "MI",
    areaCoords: [
      { latitude: 42.47, longitude: -82.89 },
      { latitude: 42.33, longitude: -82.89 },
      { latitude: 42.2, longitude: -83.22 },
      { latitude: 42.47, longitude: -83.36 },
    ],
    weight: 10,
  },

  //phoenix
  {
    placeId: "ChIJy3mhUO0SK4cRrBtKNfjHaYw",
    USstate: "AZ",
    areaCoords: [
      { latitude: 33.94, longitude: -111.85 },
      { latitude: 33.26, longitude: -111.93 },
      { latitude: 33.27, longitude: -112.3 },
      { latitude: 33.94, longitude: -112.38 },
    ],
    weight: 10,
  },

  //Montreal
  {
    placeId: "ChIJDbdkHFQayUwR7-8fITgxTmU",
    USstate: "QC",
    areaCoords: [
      { latitude: 45.77, longitude: -73.51 },
      { latitude: 45.7, longitude: -73.43 },
      { latitude: 45.4, longitude: -73.51 },
      { latitude: 45.42, longitude: -73.96 },
    ],
    weight: 10,
  },
  //San Francisco
  {
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
    USstate: "CA",
    areaCoords: [
      { latitude: 37.84, longitude: -122.34 },
      { latitude: 37.7, longitude: -122.35 },
      { latitude: 37.69, longitude: -122.51 },
      { latitude: 37.81, longitude: -122.52 },
    ],
    weight: 10,
  },

  //Seattle
  {
    placeId: "ChIJVTPokywQkFQRmtVEaUZlJRA",
    USstate: "WA",
    areaCoords: [
      { latitude: 47.74, longitude: -122.24 },
      { latitude: 47.48, longitude: -122.22 },
      { latitude: 47.47, longitude: -122.42 },
      { latitude: 47.74, longitude: -122.45 },
    ],
    weight: 10,
  },

  //San Diego
  {
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
    USstate: "CA",
    areaCoords: [
      { latitude: 33.11, longitude: -116.85 },
      { latitude: 32.52, longitude: -116.91 },
      { latitude: 32.48, longitude: -117.29 },
      { latitude: 33.12, longitude: -17.38 },
    ],
    weight: 10,
  },

  //Minneapolis
  {
    placeId: "ChIJvbt3k5Azs1IRB-56L4TJn5M",
    USstate: "MN",
    areaCoords: [
      { latitude: 45.05, longitude: -93.19 },
      { latitude: 44.88, longitude: -93.18 },
      { latitude: 44.87, longitude: -93.33 },
      { latitude: 45.05, longitude: -93.34 },
    ],
    weight: 10,
  },

  //Tampa
  {
    placeId: "ChIJ4dG5s4K3wogRY7SWr4kTX6c",
    USstate: "FL",
    areaCoords: [
      { latitude: 28.18, longitude: -82.22 },
      { latitude: 27.8, longitude: -82.46 },
      { latitude: 27.81, longitude: -82.6 },
      { latitude: 28.18, longitude: -82.53 },
    ],
    weight: 10,
  },

  //Denver
  {
    placeId: " ChIJzxcfI6qAa4cR1jaKJ_j0jhE",
    USstate: "CO",
    areaCoords: [
      { latitude: 39.86, longitude: -104.51 },
      { latitude: 39.52, longitude: -105.04 },
      { latitude: 39.72, longitude: -105.22 },
      { latitude: 39.96, longitude: -104.67 },
    ],
    weight: 10,
  },
  //Vancouver
  {
    placeId: "ChIJs0-pQ_FzhlQRi_OBm-qWkbs",
    USstate: "BC",
    areaCoords: [
      { latitude: 49.01, longitude: -122.24 },
      { latitude: 49.01, longitude: -123.22 },
      { latitude: 49.32, longitude: -123.24 },
      { latitude: 49.2, longitude: -122.31 },
    ],
    weight: 9,
  },
  //Baltimore
  {
    placeId: "ChIJt4P01q4DyIkRWOcjQqiWSAQ",
    USstate: "MD",
    areaCoords: [
      { latitude: 39.37, longitude: -76.51 },
      { latitude: 39.19, longitude: -76.52 },
      { latitude: 39.18, longitude: -76.72 },
      { latitude: 39.38, longitude: -76.72 },
    ],
    weight: 9,
  },

  //S Louis
  {
    placeId: "ChIJ-Y7t-qm02IcRW-C7IsrqOb4",
    USstate: "MO",
    areaCoords: [
      { latitude: 38.76, longitude: -90.11 },
      { latitude: 38.5, longitude: -90.23 },
      { latitude: 38.57, longitude: -90.36 },
      { latitude: 38.78, longitude: -90.22 },
    ],
    weight: 9,
  },

  //San Juan
  {
    placeId: "ChIJbxlo4m9oA4wR3FqTXA9_a60",
    USstate: "PR",
    areaCoords: [
      { latitude: 18.42, longitude: -65.96 },
      { latitude: 18.28, longitude: -66.03 },
      { latitude: 18.33, longitude: -66.11 },
      { latitude: 18.48, longitude: -66.12 },
    ],
    weight: 9,
  },

  //Riverside
  {
    placeId: "ChIJu330f9-m3IAR6ApeEwXXofc",
    USstate: "CA",
    areaCoords: [
      { latitude: 34.06, longitude: -117.28 },
      { latitude: 33.87, longitude: -117.27 },
      { latitude: 33.87, longitude: -117.51 },
      { latitude: 33.95, longitude: -117.54 },
    ],
    weight: 9,
  },

  //Las Vegas
  {
    placeId: "ChIJ0X31pIK3voARo3mz1ebVzDo",
    USstate: "NV",
    areaCoords: [
      { latitude: 36.39, longitude: -115.03 },
      { latitude: 36.12, longitude: -115.04 },
      { latitude: 36.11, longitude: -115.43 },
      { latitude: 36.39, longitude: -115.39 },
    ],
    weight: 9,
  },

  //Portland
  {
    placeId: "ChIJJ3SpfQsLlVQRkYXR9ua5Nhw",
    USstate: "OR",
    areaCoords: [
      { latitude: 45.65, longitude: -122.44 },
      { latitude: 45.42, longitude: -122.45 },
      { latitude: 45.41, longitude: -122.84 },
      { latitude: 45.67, longitude: -122.88 },
    ],
    weight: 9,
  },

  //Cleveland
  {
    placeId: "ChIJLWto4y7vMIgRQhhi91XLBO0",
    USstate: "OH",
    areaCoords: [
      { latitude: 41.63, longitude: -81.52 },
      { latitude: 41.4, longitude: -81.51 },
      { latitude: 41.36, longitude: -81.91 },
      { latitude: 41.47, longitude: -81.88 },
    ],
    weight: 9,
  },

  //San Antonio
  {
    placeId: "ChIJrw7QBK9YXIYRvBagEDvhVgg",
    USstate: "TX",
    areaCoords: [
      { latitude: 29.77, longitude: -98.2 },
      { latitude: 29.18, longitude: -98.23 },
      { latitude: 29.17, longitude: -98.8 },
      { latitude: 29.77, longitude: -98.82 },
    ],
    weight: 9,
  },

  //Pittsburgh
  {
    placeId: "ChIJA4UGSG_xNIgRNBuiWqEV-Y0",
    USstate: "PA",
    areaCoords: [
      { latitude: 40.51, longitude: -79.84 },
      { latitude: 40.35, longitude: -79.87 },
      { latitude: 40.38, longitude: -80.11 },
      { latitude: 40.51, longitude: -80.1 },
    ],
    weight: 9,
  },

  //Sacramento
  {
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
    USstate: "CA",
    areaCoords: [
      { latitude: 38.69, longitude: -121.3 },
      { latitude: 38.41, longitude: -121.31 },
      { latitude: 38.42, longitude: -121.56 },
      { latitude: 38.7, longitude: -121.6 },
    ],
    weight: 9,
  },

  //San Jose
  {
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
    USstate: "CA",
    areaCoords: [
      { latitude: 37.49, longitude: -121.73 },
      { latitude: 37.12, longitude: -121.51 },
      { latitude: 37.12, longitude: -122.02 },
      { latitude: 37.5, longitude: -122.15 },
    ],
    weight: 9,
  },

  //Cincinnati
  {
    placeId: "ChIJ-SE43rFRQIgRF5PA5It--2k",
    USstate: "OH",
    areaCoords: [
      { latitude: 39.21, longitude: -84.32 },
      { latitude: 39.02, longitude: -84.36 },
      { latitude: 39.05, longitude: -84.73 },
      { latitude: 39.24, longitude: -84.7 },
    ],
    weight: 9,
  },

  //Kansas City
  {
    placeId: "ChIJl5npr173wIcRolGqauYlhVU",
    USstate: "MO",
    areaCoords: [
      { latitude: 39.38, longitude: -94.28 },
      { latitude: 38.79, longitude: -94.31 },
      { latitude: 38.81, longitude: -94.91 },
      { latitude: 39.39, longitude: -95.01 },
    ],
    weight: 9,
  },

  //Orlando
  {
    placeId: "ChIJd7zN_thz54gRnr-lPAaywwo",
    USstate: "FL",
    areaCoords: [
      { latitude: 28.63, longitude: -81.22 },
      { latitude: 28.32, longitude: -81.2 },
      { latitude: 28.33, longitude: -81.5 },
      { latitude: 28.61, longitude: -81.52 },
    ],
    weight: 9,
  },
];
