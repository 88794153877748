import React, { useEffect, useState } from "react";
import { BackTop } from "antd";
import Joyride, { STATUS } from "react-joyride";
// import { Link } from "react-router-dom";

import AdHelper from "../shared/ad/AdHelper";
import HomeNavigation from "../navigation/HomeNavigation";
// import VimeoVideo from "../video/VimeoVideo";
// import VimeoChat from "../video/VimeoChat";
// import CandidatePreview from "../candidate/CandidatePreview";
import { useLoading, useGuideData } from "../../hooks";

const HomePage = (props) => {
  const [inProgress, setProgress] = useState(false);
  const { isLoading } = useLoading();

  // hardcoded live streaming candidate example
  // const liveStreamer = {
  //   bio: {
  //     profileImage:
  //       "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Kamala_Harris_official_photo_%28cropped2%29.jpg/220px-Kamala_Harris_official_photo_%28cropped2%29.jpg",
  //     name: "Kamala Devi Harris",
  //     rating: {
  //       total: 1,
  //       stars: 5,
  //     },
  //     office: {
  //       incumbent: {
  //         state: "Florida",
  //         city: "Miami Beach",
  //         title: "Senator",
  //       },
  //       ballot: {
  //         title: "Vice President of United States",
  //       },
  //       party: "Democratic Party",
  //     },
  //     contact: {
  //       address: "112 Hart Senate Office Building, Washington, DC, 20510",
  //       phone: "202-224-3553",
  //     },
  //     social: {
  //       facebook: "https://www.facebook.com/SenatorKamalaHarris/",
  //       youtube: "https://www.youtube.com/channel/UCe1ciA1TDa5F9K6Ufr_6Fsw",
  //       twitter: "https://twitter.com/SenKamalaHarris",
  //       instagram: "https://www.instagram.com/kamalaharris/?hl=en",
  //       website: "https://www.harris.senate.gov/",
  //     },
  //     general: {
  //       comments: [],
  //     },
  //     political: {
  //       experience: "Senator, United States Senate, California, 2017-present",
  //       comments: [
  //         {
  //           comment: "yes, she is a senator",
  //           userId: "testUser",
  //           status: "pending",
  //           date: "26/08/2020",
  //           candidateId: "5f502c781eed5bbe2409f790",
  //           section: "political",
  //           up: 0,
  //           down: 0,
  //         },
  //       ],
  //     },
  //     personal: {
  //       comments: [],
  //       gender: "female",
  //       family: "Husband: Doug; 2 Step-Children: Ella, Cole",
  //       birthdate: "10/20/1964",
  //       education:
  //         "JD, University of California, Hastings College of the Law, 1990",
  //       religion: "Baptist",
  //       homeCity: "San Francisco, CA",
  //     },
  //     professional: {
  //       experience: "Deputy District Attorney, Alameda County, 1990-1998",
  //       comments: [],
  //     },
  //     other: {
  //       experience: "Vice President, National District Attorneys Association",
  //       comments: [],
  //     },
  //     committee: {
  //       experience:
  //         "Former Member, Subcommittee on Surface Transportation and Merchant Marine Infrastructure, Safety, and Security, United States Senate",
  //       comments: [],
  //     },
  //   },
  //   positions: [
  //     {
  //       topic: "Abortion & Reproductive",
  //       position:
  //         "Kamala Harris has been a champion for a woman's right to choose and access to comprehensive reproductive health care throughout her entire career.",
  //       comments: [
  //         {
  //           comment: "test comment #1",
  //           userId: "testUser",
  //           status: "pending",
  //           date: "26/08/2020",
  //           candidateId: "5f502c781eed5bbe2409f790",
  //           section: "Abortion & Reproductive",
  //           up: 0,
  //           down: 0,
  //         },
  //         {
  //           comment: "test comment #2",
  //           userId: "testUser",
  //           status: "pending",
  //           date: "26/08/2020",
  //           candidateId: "5f502c781eed5bbe2409f790",
  //           section: "Abortion & Reproductive",
  //           up: 0,
  //           down: 0,
  //         },
  //         {
  //           comment: "test comment #6",
  //           userId: "testUser",
  //           status: "pending",
  //           date: "26/08/2020",
  //           candidateId: "5f502c781eed5bbe2409f790",
  //           section: "Abortion & Reproductive",
  //           up: 0,
  //           down: 0,
  //         },
  //       ],
  //     },
  //     {
  //       topic: "Budget, Spending & Taxes",
  //       position:
  //         "Instead of giving America's most fortunate more tax breaks, let's ask them to make a contribution a little bit more to help invest in infrastructure, job training, and making college affordable.",
  //       comments: [
  //         {
  //           comment: "test comment #3",
  //           userId: "testUser",
  //           status: "pending",
  //           date: "26/08/2020",
  //           candidateId: "5f502c781eed5bbe2409f790",
  //           section: "Budget, Spending & Taxes",
  //           up: 0,
  //           down: 0,
  //         },
  //         {
  //           comment: "test comment #4",
  //           userId: "testUser",
  //           status: "pending",
  //           date: "26/08/2020",
  //           candidateId: "5f502c781eed5bbe2409f790",
  //           section: "Budget, Spending & Taxes",
  //           up: 0,
  //           down: 0,
  //         },
  //         {
  //           comment: "test comment #5",
  //           userId: "testUser",
  //           status: "pending",
  //           date: "26/08/2020",
  //           candidateId: "5f502c781eed5bbe2409f790",
  //           section: "Budget, Spending & Taxes",
  //           up: 0,
  //           down: 0,
  //         },
  //         {
  //           comment: "",
  //           userId: "testUser",
  //           status: "pending",
  //           date: "26/08/2020",
  //           candidateId: "5f502c781eed5bbe2409f790",
  //           section: "Budget, Spending & Taxes",
  //           up: 0,
  //           down: 0,
  //         },
  //         {
  //           comment: "",
  //           userId: "testUser",
  //           status: "pending",
  //           date: "26/08/2020",
  //           candidateId: "5f502c781eed5bbe2409f790",
  //           section: "Budget, Spending & Taxes",
  //           up: 0,
  //           down: 0,
  //         },
  //       ],
  //     },
  //   ],
  //   questions: [
  //     {
  //       question: "new test question 3 for Kamala",
  //       user: "testUser",
  //       status: "pending",
  //       answers: [
  //         {
  //           user: "testabcd",
  //           candidate_id: {
  //             $oid: "5f502c781eed5bbe2409f790",
  //           },
  //           questionIndex: 0,
  //           answer: "reply to question 3 for Kamala",
  //           date: "11/08/2020",
  //         },
  //       ],
  //       date: "20/08/2020",
  //       candidateId: "5f502c781eed5bbe2409f790",
  //     },
  //     {
  //       question: "new test question 4 for Kamala",
  //       user: "testUser",
  //       status: "pending",
  //       answers: [
  //         {
  //           user: "testabcd",
  //           candidate_id: {
  //             $oid: "5f502c781eed5bbe2409f790",
  //           },
  //           questionIndex: 1,
  //           answer: "reply to test question 4 for kamala",
  //           date: "11/08/2020",
  //         },
  //       ],
  //       date: "20/08/2020",
  //       candidateId: "5f502c781eed5bbe2409f790",
  //     },
  //     {
  //       question: "New Test Question for Kamala",
  //       user: "testUser",
  //       status: "pending",
  //       answers: [
  //         {
  //           user: "testabcd",
  //           candidate_id: {
  //             $oid: "5f502c781eed5bbe2409f790",
  //           },
  //           questionIndex: 2,
  //           answer: "reply to test question for kamala",
  //           date: "11/08/2020",
  //         },
  //       ],
  //       date: "20/08/2020",
  //       candidateId: "5f502c781eed5bbe2409f790",
  //     },
  //     {
  //       question: "happy",
  //       user: "testUser",
  //       status: "pending",
  //       answers: [],
  //       date: "20/08/2020",
  //       candidateId: "5f502c781eed5bbe2409f790",
  //     },
  //     {
  //       question: "fdfdfs\n",
  //       user: "testUser",
  //       status: "pending",
  //       answers: [],
  //       date: "20/08/2020",
  //       candidateId: "5f502c781eed5bbe2409f790",
  //     },
  //     {
  //       question: "",
  //       user: "testUser",
  //       status: "pending",
  //       answers: [],
  //       date: "20/08/2020",
  //       candidateId: "5f502c781eed5bbe2409f790",
  //     },
  //   ],
  //   updates: [
  //     {
  //       date: "10/09/2020",
  //       description:
  //         "Harris, Leahy, Booker Press Chairman Graham Not To Conduct Supreme Court Hearings Without COVID-19 Testing Procedures In Place",
  //     },
  //     {
  //       description:
  //         "Harris, Brown Introduce Heat Stress Legislation to Protect Workers",
  //       date: "10/01/2020",
  //     },
  //   ],
  //   legislation: [
  //     {
  //       bill: "S4",
  //       vote: "Other",
  //       name_description:
  //         "Coronavirus Aid, Relief, and Economic Security Act (CARES Act)",
  //       key: "1",
  //       summary:
  //         "This bill is to protect the healthcare of hundreds of millions of people of the United States and prevent efforts of the Department of Justice to advocate courts to strike down the Patient Protection and Affordable Care Act.",
  //     },
  //     {
  //       key: "2",
  //       bill: "H.R.1957",
  //       vote: "Yea",
  //       name_description: "Taxpayer First Act of 2019",
  //       summary:
  //         "This bill establishes the National Parks and Public Land Legacy Restoration Fund to support deferred maintenance projects on federal land.",
  //     },
  //     {
  //       key: "3",
  //       bill: "H.R.748",
  //       vote: "Nay",
  //       name_description: "Middle Class Health Benefits Tax Repeal Act of 2019",
  //       summary:
  //         "Amendment SA 1577, under the order of 3/25/20, not having achieved 60 votes in the affirmative, was S.Amdt.1577 RC# 79",
  //     },
  //     {
  //       key: "4",
  //       bill: "S.4",
  //       vote: "Sponsored",
  //       name_description: "(no name for now)",
  //       summary:
  //         "This bill is to direct Occupational Safety and Health Administration (OSHA) to issue an occupational safety and health standard to protect workers from heat-related injuries and illnesses.",
  //     },
  //   ],
  //   archived: true,
  // };

  const tutorialSteps = [
    {
      target: ".tutorial-navigation",
      content: "Navigation for homepage, scrollable in mobile",
      title: "Navigation",
      disableBeacon: true,
    },
    {
      target: ".tutorial-nav-elections",
      content: "Elections list with candidates involved",
      title: "Elections",
    },
    {
      target: ".tutorial-nav-candidates",
      content: "Candidates list with their profiles and details",
      title: "Navigation",
    },
    {
      target: ".tutorial-nav-coalitions",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tincidunt erat id quam pulvinar, in convallis neque auctor. ",
      title: "Navigation",
    },
    {
      target: ".tutorial-nav-organizations",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tincidunt erat id quam pulvinar, in convallis neque auctor. ",
      title: "Navigation",
    },
    {
      target: ".tutorial-nav-movements",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tincidunt erat id quam pulvinar, in convallis neque auctor. ",
      title: "Navigation",
    },
    {
      target: ".tutorial-nav-services",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tincidunt erat id quam pulvinar, in convallis neque auctor. ",
      title: "Navigation",
    },
    {
      target: ".tutorial-body",
      content:
        "This is where you will see all elections and candidates details.",
    },
  ];

  const { updateUserGuide, runJoyRideStep } = useGuideData();

  function handleJoyrideCallback(data) {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      updateUserGuide("home-nav");
    }
  }

  useEffect(() => {
    if (isLoading) {
      // False since it is still loading.
      // This is to ensure joyride will not run
      setProgress(false);
    } else {
      setTimeout(() => {
        setProgress(!runJoyRideStep("home-nav"));
      }, 5000);
    }
  }, [isLoading]); // eslint-disable-line

  return (
    <>
      <Joyride
        steps={tutorialSteps}
        showProgress={true}
        showSkipButton={true}
        showStepsProgress
        continuous
        run={inProgress}
        callback={handleJoyrideCallback}
      />
      {/* <Row justify="end" gutter={[16, 8]}>
        <Col>
          <Switch
            checkedChildren="live"
            onChange={() => setLiveView(!liveView)}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row> */}
      {/* {liveView ? (
        <Row gutter={[16, 8]}>
          <Col xs={24} md={4}>
            <Affix offsetTop={10}>
              <AdHelper page="news-post" position="left" />
            </Affix>
          </Col>
          <Col xs={24} md={20}>
            <Row gutter={[16, 8]}>
              <Col xs={24} md={16}>
                <VimeoVideo />
              </Col>
              <Col xs={24} md={8}>
                <Space
                  direction="vertical"
                  align="center"
                  justify="center"
                  style={{ width: "100%" }}
                >
                  <Link
                    to={{
                      pathname: `/candidate/${liveStreamer["_id"]}`,
                      state: {
                        data: liveStreamer,
                      },
                    }}
                  >
                    <CandidatePreview
                      details={liveStreamer}
                      buttonVisibility={false}
                    />
                  </Link>
                </Space>
                <VimeoChat />
              </Col>
            </Row>
            <HomeNavigation
              coordinates={props.coordinates}
              userLocation={props.userLocation}
              divisions={props.divisions}
            />
          </Col>
        </Row>
      ) : (
        <> */}
      <AdHelper page="home-top" />
      <HomeNavigation
        coordinates={props.coordinates}
        userLocation={props.userLocation}
        divisions={props.divisions}
      />
      {/* </>
      )} */}
      <div className="inner lead" style={{ textAlign: "center" }}>
        <h2>ABOUT US</h2>
        <p style={{ display: "inline-block", textAlign: "center" }}>
          NO CAP = NO LIES. <br />
          It is not about our opinions and not about our personal experience.
          <br />
          It is about one’s words and one’s actions. <br />
          It is about your analysis and your actions that impact your future.
          <br />
          No Cap Vote.
        </p>
      </div>

      <BackTop />
    </>
  );
};

export default HomePage;
