import React, { Component } from "react";
import Loading from "../../Loading";

class Callback extends Component {
  componentDidMount() {
    this.props.handleAuthentication();
  }

  render() {
    const style = {
      display: "flex",
      justifyContent: "center",
      height: "100vh",
      width: "100vw",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "white",
    };

    return (
      <div style={style}>
        <Loading />
      </div>
    );
  }
}

export default Callback;
