import React, { Component } from "react";
import moment from "moment";
import { Row, Col, Form, Button, Dropdown, BackTop } from "antd";

import { DownOutlined } from "@ant-design/icons";
import DirectoryGrid from "../map/DirectoryGrid";
import DirectoryMap from "../map/DirectoryMap";
import { getQueryVariable, isOpenNow } from "../../helpers";
import CheckboxDropdown from "../map/dropdowns/CheckboxDropdown";
import NoCapManifest from "../../nocap.json";

export class DirectoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locating: false,
      activeFiltersCategories: [],
      activeFiltersAmeneties: [],
      defaultAges: ["21+", "18+"],
      defaultAmenities: [
        "Accessible",
        "ATM",
        "Credit Cards",
        "Lab Tested",
        "Veteran Discount",
      ],
      clearAll: false,
      services: [],
      freeServices: [],
      paidServices: [],
      showMap: true,
      width: 0,
      height: 0,
      keywords: "",
      isOpen: false,
      moreFilters: false,
      today: moment(),
      time: moment().format("HH:mm"),
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.updateWindowDimensions);
    this.updateWindowDimensions();
    this.props.fetchServices(
      this.props.coordinates.lat,
      this.props.coordinates.lng
    );
    if (this.props.keywords) this.setState({ keywords: this.props.keywords });
    this.loadQuery();
  }

  loadQuery = () => {
    const query = getQueryVariable("category", this);
    if (query) this.setState({ activeFilters: [query] });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  // Filter methods . . . . . . . . . . . . .
  clearFilters = () => {
    this.setState({ activeFilters: [], isOpen: false });
  };

  toggleFilter = (e, type) => {
    const { name, checked } = e.target;
    const selectAll = name.includes("selectll");
    const allServices = NoCapManifest.services[name.replace(" - selectll", "")];
    if (checked) {
      const activeFilters = this.state[type];
      selectAll
        ? allServices.forEach((name) => activeFilters.push(name))
        : activeFilters.push(name);
      this.setState({ [type]: activeFilters });
      this.props.fetchServices(
        this.props.coordinates.lat,
        this.props.coordinates.lng,
        `${
          this.state.activeFiltersCategories.length > 0
            ? `&categories=${JSON.stringify(
                this.state.activeFiltersCategories
              )}`
            : ""
        }`
      );
    }
    if (!checked) {
      const activeFilters = this.state[type].filter((filterName) => {
        return selectAll
          ? !allServices.includes(filterName)
          : filterName !== name;
      });

      this.setState({ [type]: activeFilters }, () => {
        this.props.fetchServices(
          this.props.coordinates.lat,
          this.props.coordinates.lng,
          `${
            this.state.activeFiltersCategories.length > 0
              ? `&search-text=' '&categories=${JSON.stringify(
                  this.state.activeFiltersCategories
                )}`
              : ""
          }`
        );
      });
    }
  };

  toggleAmenities = (e) => {
    this.toggleFilter(e, "activeFiltersCategories");
  };

  toggleCategories = (e) => {
    this.toggleFilter(e, "activeFiltersCategories");
  };

  handleKeywordsChange = (e) => {
    let keywords = e.target.value;
    this.setState({ keywords });
  };

  render() {
    const filteredByOpenNow = this.state.isOpen
      ? this.props.marketServices.filter((service) =>
          isOpenNow(service.location[0].hours, this.state.today)
        )
      : this.props.marketServices;

    let setServices = filteredByOpenNow;
    return (
      <div className="DirectoryPage">
        <div className="DirectoryFilter">
          <Form>
            <Row gutter={12} style={{ margin: "12px 0" }}>
              <Col xs={0} md={6}>
                <Dropdown
                  overlay={
                    <CheckboxDropdown
                      handleCheckboxChange={this.toggleCategories}
                      activeItems={this.state.activeFiltersCategories}
                    />
                  }
                  trigger={["click"]}
                >
                  <Button
                    className="btn-dropdown"
                    size="large"
                    style={{
                      color: "#FFF",
                    }}
                  >
                    <span>Filters</span>{" "}
                    <DownOutlined style={{ display: "float" }} />
                  </Button>
                </Dropdown>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="dmap-dgrid-wrapper">
          {this.state.width < 768 && this.state.showMap ? null : (
            <DirectoryGrid
              services={setServices}
              loading={this.props.locatingMarket}
              activeFilters={this.state.activeFiltersCategories}
            />
          )}
          {this.state.showMap && (
            <DirectoryMap
              coordinates={this.state.coordinates}
              services={this.props.marketServices}
              // services={this.state.services}
              handlePlaceChanged={this.handlePlaceChanged}
              defaultCenter={this.props.coordinates}
              today={this.state.today}
            />
          )}
        </div>
        <BackTop />
      </div>
    );
  }
}

export default DirectoryPage;
