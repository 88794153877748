import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SingleImageUploader from "../shared/imageUploaders/singleImage/SingleImageUploader";
import { Form, Input, Row, Col, Button, message } from "antd";
import axios from "axios";
import config from "../../config";

const AdminInfoForm = (props) => {
  // Profile Picture
  const [logoUrl, setLogoUrl] = useState(
    props.edit ? JSON.parse(localStorage.getItem("user")).picture : ""
  );
  const nickName = props.edit
    ? JSON.parse(localStorage.getItem("user")).nickname
    : "";

  const history = useHistory();

  // Submission
  const onFinish = (values) => {
    const doc = JSON.stringify({
      role: "admin",
      nickname: values.displayName,
      picture: logoUrl,
    });
    return axios
      .patch(
        `${config.USERS}/users`,
        {},
        {
          headers: {
            token: localStorage.getItem("access_token"),
            doc: encodeURIComponent(doc),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (props.edit) {
            message.success("You profile has been updated.");
          } else {
            message.success("You have been successfully registered as a User!");
          }
          let user = JSON.parse(localStorage.getItem("user"));
          user.picture = logoUrl;
          user.nickname = values.displayName;
          user.role = "admin";
          localStorage.setItem("role", "admin");
          localStorage.setItem("user", JSON.stringify(user));
          history.push("/");
        } else {
          message.error(
            "Something went wrong, make sure you are connected to the internet."
          );
        }
      });
  };

  //Navigation - Cancel Button
  const backToAccountTypesOptions = () => {
    let path = `/profile-start`;
    if (props.edit) {
      path = "/";
    }
    history.push(path);
  };
  console.log("logoUrl", logoUrl);
  return (
    <Form
      name="userInfoForm"
      className="accountForm"
      onFinish={onFinish}
      layout="vertical"
      initialValues={{
        displayName: `${nickName}`,
      }}
    >
      <Row gutter={[12]}>
        <Col span={24} style={{ marginBottom: "150px" }}>
          <SingleImageUploader
            handleImageUpload={(url) => setLogoUrl(url)}
            imagePreviewUrl={logoUrl}
            typeKey={"user"}
            title="Photo (optional)"
            layout={"logo"}
          />
        </Col>
        <Col span={24}>
          <Form.Item
            label="Display Name"
            name="displayName"
            rules={[
              {
                required: true,
                message: "Please enter display name",
              },
            ]}
          >
            <Input placeholder="Enter display name" />
          </Form.Item>
        </Col>
        <Col
          span={24}
          style={{
            textAlign: "right",
          }}
        >
          <Form.Item>
            <Button
              className="btn-bordered"
              onClick={backToAccountTypesOptions}
              style={{ margin: "0px 5px", width: "85px" }}
              type="text"
            >
              Cancel
            </Button>
            <Button
              className="btn-solid"
              htmlType="submit"
              style={{ margin: "0px 5px", width: "85px" }}
              type="text"
            >
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AdminInfoForm;
