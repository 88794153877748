import React from "react";
import { Button } from "antd";

const BannerLayout = (props) => {
  return (
    <>
      <div className="image-wrapper">{props.$imagePreview}</div>
      {props.imagePreviewUrl ? (
        <>
          <Button
            onClick={props.toggleVisibility}
            type="text"
            style={{ zIndex: 15 }}
            className={"file-upload file-change-banner light"}
          >
            <span style={{ textDecoration: "underline" }}>Change Banner</span>
          </Button>
          <Button
            onClick={props.handleRemoveImage}
            type="text"
            style={{ zIndex: 50, marginRight: "-80px" }}
            className={"file-upload file-remove-banner light"}
          >
            <span style={{ textDecoration: "underline" }}>Remove</span>
          </Button>
        </>
      ) : (
        <Button
          onClick={props.toggleVisibility}
          type="text"
          style={{ zIndex: 15 }}
          className={"file-upload dark"}
        >
          {" "}
          <span style={{ textDecoration: "underline" }}>Upload Banner</span>
        </Button>
      )}
    </>
  );
};

export default BannerLayout;
