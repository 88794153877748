import React from "react";
const brandIMG = "https://s3.amazonaws.com/dev.nocap.com/uploads/logo.png";

class FreeSubscriptionPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ textAlign: "center", padding: "3em", fontSize: "16px" }}>
        <img
          style={{ display: "block", margin: "0 auto" }}
          src={brandIMG}
          width={200}
          alt="brand"
        />
        You have chose a free subscriptions, no payment is required!
        <br />
        Please click done to complete the onboarding process!
      </div>
    );
  }
}

export default FreeSubscriptionPayment;
