import React from "react";
import { Table, Empty } from "antd";

const LegislationContent = (props) => {
  const legislations = props.details.legislations;
  const columns = [
    { title: "Bill Nbr", dataIndex: "bill", key: "bill", width: 100 },
    { title: "Bill Name", dataIndex: "billName", key: "billName", width: 100 },
  ];
  return (
    <div className="voting-history">
      {legislations !== null && legislations !== undefined ? (
        <div>
          {legislations.length !== 0 ? (
            <Table
              columns={columns}
              dataSource={legislations}
              pagination={{ pageSize: 8 }}
              expandable={{
                expandedRowRender: (record) => (
                  <div>
                  <p style={{ margin: 0 }}>Overview: {record.summary}</p>
                  <br/>
                  <p style={{ margin: 0}}> Vote: {record.vote} </p>
                  </div>
                  
                ),
                rowExpandable: (record) => record.summary !== "",
              }}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={"Candidate has not yet provided information."}
            />
          )}{" "}
        </div>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"Candidate has not yet provided information."}
        />
      )}
    </div>
  );
};
export default LegislationContent;
