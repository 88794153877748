import React, { useEffect, useState } from "react";
import CandidateForm from "../forms/candidateForm/CandidateInfoForm";
import UserForm from "../forms/UserInfoForm";
import OnBoarding from "../shared/onboarding/Onboarding";
import OldOnBoarding from "../shared/onboarding/oldOnboarding";
import { message } from "antd";
import axios from "axios";
import config from "../../config";
import useCheckIfLoggedIn from "../../hooks/useCheckIfLoggedIn";

const UserSignupFormServe = (props) => {
  useCheckIfLoggedIn();
  const [isAdvertiser, setIsAdvertiser] = useState(true);
  const [type, setType] = useState("");

  useEffect(() => {
    const typeProp = props.match.params.type;
    if (localStorage.getItem("role") === "advertiser") {
      setIsAdvertiser(true);
    } else if (
      localStorage.getItem("role") === "first-auth" &&
      typeProp === "advertiser"
    ) {
      handleAdvertiser();
    }
    setType(typeProp);
  }, [props.match.params.type]); // eslint-disable-line

  const updateUserWithDefaultPlan = async () => {
    await axios.patch(
      `${config.USERS}/users/plans`,
      {},
      {
        cors: true,
        timeout: 10000,
        headers: {
          token: localStorage.getItem("access_token"),
          plan: "free",
        },
      }
    );
  };

  const handleAdvertiser = async () => {
    try {
      await updateUserWithDefaultPlan();
      await updateUserRole("advertiser");
      localStorage.setItem("role", "advertiser");
      setIsAdvertiser(true);
    } catch (err) {
      console.error(err);
      message.error("There was a problem connecting to the network.");
    }
    setIsAdvertiser(true);
    // props.history.push("/onboard");
  };

  const updateUserRole = async (role) => {
    //ONBOARD CHANGE HERE
    const doc = JSON.stringify({
      role: role,
    });
    await axios.patch(
      `${config.USERS}/users`,
      {},
      {
        headers: {
          token: localStorage.getItem("access_token"),
          doc: encodeURIComponent(doc),
        },
      }
    );
  };

  return (
    <div>
      {type === "candidate" ? <CandidateForm /> : null}
      {type === "user" ? <UserForm /> : null}
      {type === "old" ? <OldOnBoarding /> : null}
      {type === "advertiser" && isAdvertiser ? (
        <OnBoarding props={props} history={props.history} />
      ) : null}
    </div>
  );
};

export default UserSignupFormServe;
