import React, { useEffect, useContext } from "react";
import { Card, Row, Col, Divider } from "antd";
import { StarTwoTone } from "@ant-design/icons";
import Joyride, { STATUS } from "react-joyride";
import useGuideData from "../../../hooks/useGuideData";
import { SignUpContext } from "../../../providers/SignupContext";

const SelectSubscription = (props) => {
  const { profileData, setProfileData, setDisableNext } =
    useContext(SignUpContext);
  const { membership } = profileData;

  const tutorialSteps = [
    // tutorial-banner tutorial-form
    {
      target: ".tutorial-free",
      content: "Want to try out the app? This plan is perfect for you.",
      title: "Plans",
      disableBeacon: true,
    },
    {
      target: ".tutorial-recommended",
      title: "Plans",
      content: "Most recommended plan for businesses.",
    },
    {
      target: ".tutorial-premium",
      title: "Plans",
      content: "Plan to maximize all functionality of the app",
    },
  ];

  useEffect(() => {
    setDisableNext(!membership.name);
  }, [setDisableNext, membership]);

  const onClickMembership = (data) => {
    const cost = data === "Premium" ? 195 : data === "Sponsor" ? 95 : 0;

    setProfileData((prev) => ({
      ...prev,
      membership: {
        name: data,
        cost,
      },
    }));
    // props.addMemebershipCost(name);
  };

  const { updateUserGuide, runJoyRideStep } = useGuideData();
  function handleJoyrideCallback(data) {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      updateUserGuide(2);
    }
  }

  return (
    <div style={{ marginBottom: "2em" }}>
      <Joyride
        run={!runJoyRideStep(2)}
        steps={tutorialSteps}
        showProgress
        showSkipButton
        continuous
        callback={handleJoyrideCallback}
      />

      <div>
        <Row justify="center">
          <Col xd={24} md={8} className="tutorial-free">
            <Card
              onClick={() => onClickMembership("Free")}
              className={
                membership.name === "Free"
                  ? "selectedMembership"
                  : "selectMembership"
              }
              style={{
                background: "#e9e9e9",
                outline: "1px solid lightgray",
                margin: "2em",
              }}
            >
              <div id="free">
                <h1>Bronze</h1>
                <Divider />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h2>$0</h2>/mo
                </div>
              </div>
            </Card>

            <ul>
              <li>Business Listing</li>
              <li>Standard icon</li>
            </ul>
          </Col>
          <Col xd={24} md={8} className="tutorial-recommended">
            <Card
              onClick={() => onClickMembership("Sponsor")}
              className={
                membership.name === "Sponsor"
                  ? "selectedMembership"
                  : "selectMembership"
              }
              style={{ margin: "2em" }}
            >
              <div id="sponsor">
                <h1>Silver</h1>
                <Divider>
                  <StarTwoTone
                    twoToneColor="grey"
                    style={{ fontSize: "30px" }}
                  />
                </Divider>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h2>$95</h2>/mo
                </div>
              </div>
            </Card>
            <ul>
              <li>Priority business listing</li>
              <li>Enhanced icon</li>
            </ul>
          </Col>
          <Col xd={24} md={8} className="tutorial-premium">
            <Card
              // onClick={() => onClickMembership("Premium")}
              className={
                membership.name === "Premium"
                  ? "selectedMembership"
                  : "selectMembership"
              }
              style={{ margin: "2em" }}
            >
              <div id="premium">
                <h1>Platinum</h1>
                <Divider />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h2>$195</h2>/mo
                </div>
              </div>
            </Card>
            <ul>
              <li>Priority business listing</li>
              <li>Enhanced icon</li>
            </ul>
          </Col>
        </Row>
        {/* </Card> */}
      </div>
      <Divider />
    </div>
  );
};

export default SelectSubscription;
