import React from "react";
import { Form, Input, Col, Button, DatePicker } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const ProfessionalExperience = ({ formValues }) => {
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
  return (
      <div>
      <Form.List name="professionalExperiences">
        {(fields, { add, remove }) => {
          const professionalExperiences = formValues?.professionalExperiences || [];
          const enableBtn =
            professionalExperiences.length > 0
              ? professionalExperiences.every(
                (v) =>
                  v?.title && v?.startDate && v?.endDate
              )
              : true;
              return (
                <Form.Item label="Professional Experience (Please enter most recent to oldest)">
                  {fields.map((field, index) => (
                    <div key={"professionalExperience" + index} className="flex-row" style={{ justifyContent: "space-between", gap: 16 }}>
                      <Form.Item
                        label="Title"
                        name={[field.name, "title"]}
                        style={{ width: "100%" }}
                      >
                        <Input placeholder="please enter title here" />
                      </Form.Item>

                      <Form.Item label="Start Date" name= {[field.name, "startDate"]}>
                        <DatePicker
                          style={{ width: "100%" }}
                          format={dateFormatList}
                          popupStyle={{ color: "black" }}
                          placeholder="select a date (dd/mm/yyyy)"
                        />
                      </Form.Item>

                      <Form.Item label="End Date" name= {[field.name, "endDate"]}>
                        <DatePicker
                          style={{ width: "100%" }}
                          format={dateFormatList}
                          popupStyle={{ color: "black" }}
                          placeholder="select a date (dd/mm/yyyy)"
                        />
                      </Form.Item>

                      {fields.length > 1 ? (
                        <Form.Item style={{ marginBottom: 16 }}>
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Form.Item>
                      ) : null}
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      style={{
                        width: "100%",
                      }}
                      disabled={!enableBtn}
                    >
                      <PlusOutlined /> Add field
                    </Button>
                  </Form.Item>
                </Form.Item>
              );
            }}
          </Form.List>
      </div>
  );
};

export default ProfessionalExperience;
