import React from "react";
import { Row, Col, Button, Typography } from "antd";
const { Title } = Typography;

const AdBannerLayout = (props) => {
  return (
    <Row>
      {props.imagePreviewUrl ? (
        <Col flex="200px">
          <div>{props.$imagePreview}</div>
        </Col>
      ) : null}
      <Col flex="auto">
        <Title level={4} style={{ marginTop: "50px", fontWeight: 300 }}>
          {props.title}
        </Title>
        {props.imagePreviewUrl ? (
          <>
            <Button onClick={props.toggleVisibility} type="text">
              <span style={{ textDecoration: "underline" }}>Change</span>
            </Button>
            <Button onClick={props.handleRemoveImage} type="text">
              <span style={{ textDecoration: "underline" }}>Remove</span>
            </Button>
          </>
        ) : (
          <Button onClick={props.toggleVisibility} type="text">
            {" "}
            <span style={{ textDecoration: "underline" }}>Upload</span>
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default AdBannerLayout;
