import React from "react";
import { NavLink, Link } from "react-router-dom";
import moment from "moment";
import { Form, Row, Col, Input, Button } from "antd";
import ContactUsModal from "./ContactUsModal";
import { InstagramOutlined, TwitterOutlined } from "@ant-design/icons";

const Footer = (props) => {
  let menuProps = props.footerNavLinks ? props.footerNavLinks : [];
  let menuLinks = [{ title: "HOME", path: "/" }, ...menuProps].map(
    (item, i) => {
      return (
        <NavLink
          key={i}
          activeClassName="active"
          to={item.path}
          className="nav-link"
          style={{ fontSize: 14, fontWeight: "bold" }}
        >
          {item.title}
        </NavLink>
      );
    }
  );

  const handleSubscribe = () => {};

  return (
    <Row className="Footer">
      <ContactUsModal
        showContact={props.showContact}
        changeShowContact={props.changeShowContact}
      />
      <Col
        xs={24}
        className="inner--footer"
        style={{ display: "flex", justifyContent: "center", marginBottom: 16 }}
      >
        {menuLinks}
      </Col>

      <Col className="flex-col" style={{ gap: 4 }}>
      <div>
        <button
          onClick={props.changeShowContact}
          style={{
            background: "unset",
            border: "unset",
            cursor: "pointer",
            margin: 0,
            padding: 0
          }}
        >
          Contact Us
        </button>
        </div>
        <Link to="/">Advertise</Link>
        <Link to="/terms">Terms and Conditions</Link>
        <Link to="/">Terms of Sale</Link>
        <Link to="/privacy">Privacy Policy</Link>
        <Link to="/">Cookie Settings</Link>
        <Link to="/">Do not sell my personal information</Link>
        <Link to="/">California Notices</Link>
      </Col>

      <Col>
        <div className="blue title">SUBSCRIBE</div>
        <p style={{ paddingRight: "20px" }}>
          Subscribe to updates and newsletters to get the latest news and more.
        </p>
        <Form onSubmit={handleSubscribe}>
          <Row>
            <Col xs={16} sm={18}>
              <Form.Item
                style={{ textAlign: "left" }}
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="enter email address"
                  size="large"
                  onChange={props.handleEmailChange}
                />
              </Form.Item>
            </Col>
            <Col xs={4} sm={4} offset={1}>
              <Button
                htmlType="submit"
                className="btn-bordered"
                style={{ width: 85, height: 40 }}
                type="text"
              >
                GO
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>



      <Col className="flex-row" style={{ gap: 8 }}>
        <Button
          style={{ borderColor: "#bfbfbf" }}
          className="btn-bordered"
          href=""
          icon={<InstagramOutlined />}
        />
        <Button
          style={{ borderColor: "#bfbfbf" }}
          className="btn-bordered"
          href=""
          icon={<TwitterOutlined />}
        />
      </Col>

      <Col
        xs={24}
        md={24}
        style={{
          textAlign: "center",
          borderTop: "1px solid rgb(233, 233, 233)",
          paddingTop: 16,
        }}
      >
        @ {moment().format("YYYY")} No Cap Vote
      </Col>
    </Row>
  );
};

export default Footer;
