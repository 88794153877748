import React, { useState } from "react";
import { Button, Input, message, Spin } from "antd";
import axios from "axios";
import moment from "moment";
import SingleQuestionAnswer from "./SingleQuestionAnswer";
import AnswerComment from "./AnswerComment";
const { TextArea } = Input;

const QuestionAnswer = (props) => {
  const [QA, setQA] = useState("");
  const [loading, setLoading] = useState(false);

  // Sets the state of QA to any user input
  const handleChange = (e) => {
    setQA(e.currentTarget.value);
  };

  //Handles the submission of the form by sending data to backend
  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let user = JSON.parse(localStorage.getItem("user"));

    const data = {
      question: QA,
      user: user.nickname || user.name,
      status: "pending",
      answers: [],
      date: moment().format("DD/MM/YYYY"),
      candidateId: props.candidateId,
      avatar: user.picture,
    };

    // mongodb insert one in specific candidate collection

    return axios
      .post(
        "https://ne9c9azsf1.execute-api.us-east-1.amazonaws.com/dev/candidates/questions/insert",
        data,
        {
          headers: {
            candidate_id: props.candidateId,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          message.success("Saved!");
          setQA("");
          props.refreshFunction(data, "question", null);
        } else {
          message.error(
            "Something went wrong, make sure you are connected to the internet."
          );
        }

        setLoading(false);
      });
  };

  return (
    <div>
      {/*QuestionAnswer List */}
      {/* Root QuestionAnswer Form */}
      <form style={{ display: "flex", height: 50 }} onSubmit={onSubmit}>
        <TextArea
          style={{ width: "80", borderRadius: "5px" }}
          onChange={handleChange}
          value={QA}
          placeholder="ask a question"
          autoSize
          disabled={loading}
        />

        <Button
          style={{
            marginLeft: "5px",
          }}
          className="btn-solid"
          disabled={loading}
          onClick={onSubmit}
          type="text"
        >
          {loading ? <Spin /> : "Submit"}
        </Button>
      </form>
      <div
        style={{
          maxHeight: 250,
          overflow: "auto",
          border: "1px solid #d9d9d9",
          paddingLeft: "10px",
        }}
      >
        {props.QAlist &&
          props.QAlist.map(
            (QA, index) =>
              // put a condition to render the questions after identifying it as a question for example if QAlist.question
              !QA.responseTo && (
                <React.Fragment key={props.QAlist.indexOf(QA)}>
                  <SingleQuestionAnswer
                    QA={QA}
                    questionId={props.QAlist.indexOf(QA)}
                    type="question"
                    content={QA.question}
                    candidateId={props.candidateId}
                    refreshFunction={props.refreshFunction}
                  />
                  <AnswerComment
                    QAlist={props.QAlist}
                    candidateId={props.candidateId}
                    parentQuestionId={props.QAlist.indexOf(QA)}
                    refreshFunction={props.refreshFunction}
                  />
                </React.Fragment>
              )
          )}
      </div>
    </div>
  );
};

export default QuestionAnswer;
