import React from "react";
import config from "../../../../config";
import axios from "axios";
import { Upload, Modal, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const MultiImageUploader = (props) => {
  const handleCancel = () => {
    props.setPreviewVisible(false);
    props.setTouched(true);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    props.setPreviewImage(file.url || file.preview);
    props.setPreviewVisible(true);
    props.setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList }) => {
    props.setFileList(fileList);
    props.setTouched(true);
  };

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    let w = 300;
    let h = 300;

    const postConfig = {
      headers: {
        token: localStorage.getItem("access_token"),
        width: w,
        height: h,
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const body = JSON.stringify({ image: e.target.result });

      return axios
        .post(
          `${config.IMAGES_UPLOAD}/images/${props.endpoint}`,
          body,
          postConfig
        )
        .then((response) => {
          props.setActiveFileList([
            ...props.activeFileList,
            {
              file: file,
              uploadedUrl: `${config.IMAGE_HOST}/${response.data.key}`,
            },
          ]);
          onSuccess("Ok");
        })
        .catch((error) => {
          console.log("Eroor: ", error);
          onError({ error });
        });
    };
  };

  const onRemove = (file) => {
    const index = props.fileList.indexOf(file);
    const newFileList = props.fileList.slice();
    const newActiveFileList = props.activeFileList.slice();
    newFileList.splice(index, 1);
    newActiveFileList.splice(index, 1);
    props.setFileList(newFileList);
    props.setActiveFileList(newActiveFileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <>
      <Upload
        accept="image/*"
        listType="picture-card"
        fileList={props.fileList}
        onPreview={handlePreview}
        customRequest={uploadImage}
        onChange={handleChange}
        onRemove={onRemove}
      >
        {props.fileList.length >= 8 ? null : (
          <Tooltip
            placement="topLeft"
            title="300px x 300px wide for best results"
          >
            {uploadButton}
          </Tooltip>
        )}
      </Upload>
      <Modal
        visible={props.previewVisible}
        title={props.previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={props.previewImage} />
      </Modal>
    </>
  );
};

export default MultiImageUploader;
