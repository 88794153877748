import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Table, Button, Input, message, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import config from "../../config";

const CandidateConsolePage = (props) => {
  const history = useHistory();
  const [keywords, setKeywords] = useState("");

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  async function get() {
    try {
      setLoading(true);
      const result = await axios.get(`${config.CANDIDATES}/candidates/all`);
      setData(result.data);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    get();
  }, []); // eslint-disable-line

  if (error)
    return message.error(
      "Oops ... Something went wrong, we could not load the candidates information"
    );

  const handleArchive = (event, record) => {
    event.stopPropagation();
    console.log(record._id)
    console.log(record)
    let index = data.findIndex((candidate) => candidate._id === record._id);
    let status = record.archived ? false : true;
    let candidateDoc = JSON.stringify({
      archived: status,
    });
    let candidatesData = [...data];
    let modifyCandidate = { ...candidatesData[index] };
    modifyCandidate.archived = status;
    candidatesData[index] = modifyCandidate;
    const patchData = async () => {
      try {
        await axios.patch(
          `${config.CANDIDATES}/candidates/update`,
          {},
          {
            headers: {
              candidate_id: record._id,
              token: localStorage.getItem("access_token"),
              doc: encodeURIComponent(candidateDoc),
            },
          }
        );
        setData(candidatesData);
      } catch (err) {
        message.error("Unable to update the candidate");
        console.error(err);
      }
    };
    return patchData();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      defaultSortOrder: "ascend",
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: "Incumbent",
      dataIndex: ["office", "incumbent", "title"],
      key: "incumbent_title",
      width: 150,
      sorter: (a, b) => {
        return a.office.incumbent.title.localeCompare(b.office.incumbent.title);
      },
    },
    {
      title: "State",
      dataIndex: ["office", "incumbent", "state"],
      key: "incumbent_state",
      width: 150,
      sorter: (a, b) => {
        if (a.office.incumbent.state === undefined)
          a.office.incumbent.state = "";
        if (b.office.incumbent.state === undefined)
          b.office.incumbent.state = "";
        return a.office.incumbent.state.localeCompare(b.office.incumbent.state);
      },
      responsive: ["md"],
    },
    {
      title: "City",
      dataIndex: ["office", "incumbent", "city"],
      key: "incumbent_city",
      width: 150,
      sorter: (a, b) => {
        if (a.office.incumbent.city === undefined) a.office.incumbent.city = "";
        if (b.office.incumbent.city === undefined) b.office.incumbent.city = "";
        return a.office.incumbent.city.localeCompare(b.office.incumbent.city);
      },
      responsive: ["md"],
    },
    {
      title: "Ballot",
      dataIndex: ["office", "ballot", "title"],
      key: "ballot_title",
      width: 150,
      sorter: (a, b) => {
        if (a.office.ballot === undefined) a.office.ballot = { title: "" };
        if (b.office.ballot === undefined) b.office.ballot = { title: "" };
        return a.office.ballot.title.localeCompare(b.office.ballot.title);
      },
      responsive: ["lg"],
    },
    {
      title: "Party",
      dataIndex: ["office", "party"],
      key: "party",
      width: 150,
      sorter: (a, b) => {
        if (a.office.party === null) a.office = { party: "" };
        if (b.office.party === null) b.office = { party: "" };
        return a.office.party.localeCompare(b.office.party);
      },
      responsive: ["lg"],
    },
    {
      title: "Status",
      dataIndex: "",
      key: "status",
      width: 150,
      render: (text, record) => {
        let btn = record.archived ? "Unarchive" : "Archive";
        let btnClass = record.archived ? "btn-solid" : "btn-bordered";
        return (
          <Button
            className={btnClass}
            onClick={(event) => handleArchive(event, record)}
          >
            {btn}
          </Button>
        );
      },
      filters: [
        {
          text: "Current",
          value: false,
        },
        {
          text: "Archived",
          value: true,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => Boolean(record.archived) === value,
    },
  ];

  const re = new RegExp(keywords, "gi");
  const filteredCandidates = keywords.length
    ? data.filter((event) => event.name.match(re))
    : data;

  return (
    <Fragment>
      <Typography.Title>Candidates Console</Typography.Title>
      <Input
        size="large"
        placeholder="Filter by Name"
        suffix={<SearchOutlined />}
        allowClear
        onChange={(e) => setKeywords(e.target.value)}
        style={{ zIndex: 1, width: "100%", margin: "10px 0" }}
      />
      <Table
        loading={loading}
        className="events-table"
        onRow={(record) => ({
          onClick: () => {
            history.push(`/candidate/${record._id}`);
          },
        })}
        rowKey={(record) => record._id}
        columns={columns}
        dataSource={filteredCandidates || null}
        pagination={{ defaultPageSize: 25 }}
      />
    </Fragment>
  );
};

export default CandidateConsolePage;
