import React, { useContext, useEffect } from "react";
import SingleImageUploader from "../imageUploaders/singleImage/SingleImageUploader";
import { servicesCategories } from "../../../helpers";
import { Form, Input, Select, Row, Col, message } from "antd";
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  YoutubeOutlined,
  GoogleOutlined,
} from "@ant-design/icons";
import CustomInput from "./CustomInput";
import LocationInput from "../../forms/LocationInput";
import { SignUpContext } from "../../../providers/SignupContext";
import { LoadingContext } from "../../../providers/LoadingContext";

const DescribeService = () => {
  const { isLoading } = useContext(LoadingContext);

  const { profileData, setProfileData, setDisableNext } =
    useContext(SignUpContext);

  const onProfileDataChange = (field, value) => {
    setProfileData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    const valid =
      profileData.organization_name &&
      profileData.formatted_address &&
      profileData.primaryEmail &&
      profileData.categories.length > 0;
    if (valid) {
      setDisableNext(false);
    } else {
      setDisableNext(true);
    }
  }, [profileData]); // eslint-disable-line

  const handleInputChange = (event, field) => {
    const value = event.target.value;
    onProfileDataChange(field, value);
  };

  const handleCategoriesChanged = (categories) => {
    if (profileData.categories.length <= 1) {
      onProfileDataChange("categories", categories);
    } else {
      message.warning(
        "If there are more service categories, please contact No Cap Vote"
      );
    }
  };

  const handleReferralChange = (event, field) => {
    const value = event.target.value;
    onProfileDataChange("referral", {
      ...profileData.referral,
      [field]: value,
    });
  };

  const handleSocialChange = (event, field) => {
    const host = event.target.name;
    const value = event.target.value;
    setProfileData((prev) => ({
      ...prev,
      social: {
        ...prev.social,
        [host]: value,
      },
    }));
  };

  return (
    !isLoading && (
      <div>
        <div className="EditStoreInfo">
          <SingleImageUploader
            handleImageUpload={(url) =>
              onProfileDataChange("bannerImageUrl", url)
            }
            imagePreviewUrl={profileData?.bannerImageUrl || ""}
            typeKey={"serviceBanner"}
          />
          <div style={{ marginLeft: "20px", marginTop: "-60px" }}>
            <SingleImageUploader
              handleImageUpload={(url) =>
                onProfileDataChange("logoImageUrl", url)
              }
              imagePreviewUrl={profileData?.logoImageUrl || ""}
              typeKey={"serviceLogo"}
              title=""
              layout={"logo"}
            />
          </div>
          <div style={{ marginTop: "134px" }}>
            <Form
              className="inner"
              layout="vertical"
              initialValues={{
                referral: profileData.referral,
                organization_name: profileData.organization_name,
                formatted_address: profileData.formatted_address,
                categories: profileData.categories,
                primaryEmail: profileData.primaryEmail,
                alternativeEmail: profileData.alternativeEmail,
                primaryPhone: profileData.primaryPhone,
                phone: profileData.phone,
                website: profileData.website,
                description: profileData.description,
                ...profileData.social,
              }}
            >
              <Row gutter={12}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Referral Name"
                    name="referral"
                    rules={[{ required: false }]}
                  >
                    <div className="flex-row" style={{ gap: 8 }}>
                      <Input
                        size="large"
                        type="text"
                        id="referral"
                        name="referral.firstname"
                        placeholder="first name"
                        onChange={(e) => handleReferralChange(e, "firstname")}
                        value={profileData.referral.firstname}
                      />
                      <Input
                        size="large"
                        type="text"
                        id="referral"
                        name="referral.middlename"
                        placeholder="middle name"
                        onChange={(e) => handleReferralChange(e, "middlename")}
                        value={profileData.referral.middlename}
                      />
                      <Input
                        size="large"
                        type="text"
                        id="referral"
                        name="referral.lastname"
                        placeholder="last name"
                        onChange={(e) => handleReferralChange(e, "lastname")}
                        value={profileData.referral.lastname}
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Company Name"
                    name="organization_name"
                    rules={[
                      {
                        min: 3,
                        message: "Company name must be more that 3 Letters.",
                      },
                      {
                        max: 60,
                        message: "Company name must be less that 60 Letters.",
                      },
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      size="large"
                      name="organization_name"
                      placeholder="enter your business name"
                      onChange={(e) =>
                        handleInputChange(e, "organization_name")
                      }
                      value={profileData.organization_name}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Company Address"
                    name="formatted_address"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <LocationInput
                      id="state"
                      stateOnly
                      value={profileData.formatted_address}
                      height={40}
                      onChange={(v) =>
                        onProfileDataChange("formatted_address", v)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Service Category"
                    name="categories"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      name="service category"
                      placeholder="select least one category"
                      size="large"
                      onChange={handleCategoriesChanged}
                      value={profileData.categories}
                      allowClear
                      onClear={() => onProfileDataChange("categories", [])}
                    >
                      {servicesCategories.map((category, idx) => (
                        <Select.Option
                          key={category + idx}
                          value={category}
                          disabled={profileData.categories.length >= 2}
                        >
                          {category}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={
                      <div>
                        Primary Email{" "}
                        <span className="form-sub-label">
                          (not available to the public)
                        </span>
                      </div>
                    }
                    name="primaryEmail"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "The input is empty or invalid",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      name="primaryEmail"
                      placeholder="enter email"
                      onChange={(v) => handleInputChange(v, "primaryEmail")}
                      value={profileData.primaryEmail}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Alternative Email"
                    name="alternativeEmail"
                    rules={[
                      {
                        type: "email",
                        message: "The input is empty or invalid",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      name="alternativeEmail"
                      placeholder="other email"
                      onChange={(v) => handleInputChange(v, "alternativeEmail")}
                      value={profileData.alternativeEmail}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={
                      <div>
                        Primary Contact Number{" "}
                        <span className="form-sub-label">
                          (not available to the public)
                        </span>
                      </div>
                    }
                    name="primaryPhone"
                    rules={[
                      {
                        min: 7,
                        message: "Check your phone number is correct",
                      },
                    ]}
                  >
                    <CustomInput
                      // form={this.props.form}
                      type="tel"
                      placeholder="enter phone number"
                      name="primaryPhone"
                      onChange={(e) => handleInputChange(e, "primaryPhone")}
                      value={profileData.primaryPhone}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Phone"
                    name="phone"
                    rules={[
                      {
                        min: 7,
                        message: "Check your phone number is correct",
                      },
                    ]}
                  >
                    <CustomInput
                      // form={this.props.form}
                      type="tel"
                      placeholder="enter phone number"
                      name="phone"
                      onChange={(e) => handleInputChange(e, "phone")}
                      value={profileData.phone}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="Website" name="website">
                    <CustomInput
                      // form={this.props.form}
                      type="url"
                      size="large"
                      name="website"
                      onChange={(e) => handleInputChange(e, "website")}
                      placeholder="http://your-website.com"
                      value={profileData.website}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ max: 3000 }]}
                  >
                    <Input.TextArea
                      style={{ height: "167px" }}
                      rows={4}
                      onChange={(e) => handleInputChange(e, "description")}
                      name="description"
                      placeholder="describe your business to customers..."
                      value={profileData.description}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <div className="ant-col ant-form-item-label">
                    <label>Social Media Links</label>
                  </div>
                </Col>
                {[
                  { name: "facebook", icon: <FacebookOutlined /> },
                  { name: "instagram", icon: <InstagramOutlined /> },
                  { name: "twitter", icon: <TwitterOutlined /> },
                  { name: "youtube", icon: <YoutubeOutlined /> },
                  { name: "google", icon: <GoogleOutlined /> },
                  {
                    name: "yelp",
                    icon: (
                      <img
                        alt="yelp-icon"
                        src="https://api.iconify.design/mdi-yelp.svg"
                      />
                    ),
                  },
                ].map((host) => {
                  return (
                    <Col sm={12} xs={24} key={host.name}>
                      <Form.Item
                        label={host.name}
                        name={host.name}
                        rules={[
                          {
                            type: "url",
                            message: "Enter Valid link.",
                          },
                        ]}
                      >
                        <Input
                          suffix={host.icon}
                          size="large"
                          name={host.name}
                          placeholder={host.name}
                          value={profileData.social[host.name]}
                          type="url"
                          onChange={(e) => handleSocialChange(e, "website")}
                        />
                      </Form.Item>
                    </Col>
                  );
                })}
              </Row>
            </Form>
          </div>
        </div>
      </div>
    )
  );
};

export default DescribeService;
