import React, { useContext } from "react";
import { message, Card } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";

import config from "../../config";
import { LoadingContext } from "../../providers/LoadingContext";
import { capitalizeFirstLetter } from "../../helpers";

const AccountChange = () => {
  const { setGlobalLoading } = useContext(LoadingContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();

  const onCardClick = (role) => {
    if (role === "candidate") onRequestCandidate();
    if (role === "advertiser") onRequestAdvertiser();
  };

  const onRequestCandidate = async () => {
    setGlobalLoading(true);
    const queueData = JSON.stringify({
      userId: localStorage.getItem("userid"),
      role: user.role,
      category: "Change Account Type",
      task_name: `${
        user?.nickname || user?.name
      } has request to change account type from User to Candidate (${
        user.email
      })`,
      user: user,
      status: "in queue",
      userToken: localStorage.getItem("access_token"),
    });
    await Promise.all([
      axios.post(
        `${config.QUEUES}/queues`,
        {},
        {
          headers: {
            token: localStorage.getItem("access_token"),
            doc: encodeURIComponent(queueData),
          },
        }
      ),
    ]);

    message.success("User Account Change has been requested!");
    history.push("/");
  };

  const onRequestAdvertiser = () => {
    setGlobalLoading(true);

    const doc = JSON.stringify({
      role: "advertiser",
    });
    axios
      .patch(
        `${config.USERS}/users`,
        {},
        {
          headers: {
            token: localStorage.getItem("access_token"),
            doc: encodeURIComponent(doc),
          },
        }
      )
      .then(({ data }) => {
        localStorage.setItem("user", JSON.stringify(data.value));
        localStorage.setItem("role", "advertiser");
        message.success(`Account Type changed to advertiser`);
        setGlobalLoading(false);
        history.push("/signup/advertiser");
      });
  };

  const accountTypes = [
    {
      title: "advertiser",
      type: "advertiser",
      description:
        "Reach influential communities talking about today’s most relevant political topics. ",
      icon: "https://s3.amazonaws.com/test.satingroves.com/nocap-test/megaphone.png",
    },
    {
      title: "candidate",
      type: "candidate",
      description:
        "Create a profile to help voters to understand who you are and why you are running for office.",
      icon: "https://s3.amazonaws.com/test.satingroves.com/nocap-test/ambassador.png",
    },
  ];

  return (
    <div style={{ textAlign: "center" }}>
      <h2>Request Account Type to Change</h2>
      <div style={{ display: "flex", gap: 16, justifyContent: "center" }}>
        {accountTypes.map((item, i) => (
          <Card
            style={{ width: "230px", height: "315px", cursor: "pointer" }}
            className="black-border-hover"
            onClick={() => onCardClick(item.title)}
          >
            <img
              src={item.icon}
              alt={item.title}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "50%",
                paddingBottom: "1em",
              }}
            />
            <h2 style={{ textAlign: "center" }}>
              {capitalizeFirstLetter(item.title)}
            </h2>
            <div>{item.description}</div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default AccountChange;
