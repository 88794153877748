import React from "react";
import { Collapse, List, Button, Empty } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
const { Panel } = Collapse;

const styles = {
  scrollContainer: {
    overflow: "auto",
    padding: "4px 16px",
    maxHeight: " 180px",
  },
};

const PlatformContent = (props) => {
  const positions = props.details.positions;
  const handleExpandPanel = (key) => {
    props.setCurrentAdvocacyKey(key);
  };
  return (
    <>
      {positions.length !== 0 ? (
        <Collapse
          onChange={(key) => handleExpandPanel(key)}
          accordion
          style={{ maxHeight: "400px" }}
        >
          {positions.map((position, key) => {
            return (
              <Panel header={position.topic} key={key}>
                <div style={styles.scrollContainer}>
                  <List>
                    <List.Item style={{ whiteSpace: "pre-line" }}>
                      {position.position}
                    </List.Item>
                  </List>
                  {/* <Button
                    className="btn-bordered"
                    // onClick={}
                    style={{ margin: "0px 5px", width: "50px" }}
                    type="text"
                    icon={<CheckOutlined />}
                  />
                  <Button
                    className="btn-solid"
                    // onClick={}
                    style={{ margin: "0px 5px", width: "50px" }}
                    type="text"
                    icon={<CloseOutlined />}
                  /> */}
                </div>
              </Panel>
            );
          })}
        </Collapse>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"Candidate has not yet provided information."}
        />
      )}
    </>
  );
};
export default PlatformContent;
