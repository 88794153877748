import React from "react";
import { Card, Divider, Button, List } from "antd";
import {
  EnvironmentOutlined,
  DollarOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
const CareerItem = (props) => {
  return (
    <div>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 2,
          xxl: 2,
        }}
        pagination={{
          onChange: (page) => {
            console.log(page);
          },
          pageSize: 10,
        }}
        dataSource={props.careerItems}
        renderItem={(p, i) => (
          <List.Item style={{ minWidth: "390px" }} key={p._id}>
            <Card key={i}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "50%" }}>
                  <h4 style={{ margin: 0, fontSize: "18px", fontWeight: 700 }}>
                    {p.title}{" "}
                  </h4>
                  <h4 style={{ margin: 0 }}>{p.company}</h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "50%",
                  }}
                >
                  <div>
                    <h5 style={{ margin: 0 }}>
                      <EnvironmentOutlined /> {p.location.formatted_address}
                    </h5>
                    <h5 style={{ margin: 0 }}>
                      <DollarOutlined /> {p.rate}
                    </h5>
                    <h5 style={{ margin: 0 }}>
                      <ScheduleOutlined /> {p.contract_type}
                    </h5>
                  </div>
                </div>
              </div>
              <Divider />
              <p>{p.summary}</p>
              <Button
                className="btn-solid"
                style={{ margin: "0px 5px", width: "120px" }}
                type="text"
                onClick={() => {
                  props.showItemModal(p);
                }}
              >
                {" "}
                More Info
              </Button>
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

export default CareerItem;
