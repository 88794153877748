import React from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
// import { findNearestLocation } from "../../helpers";

const DirectoryMap = (props) => {
  // console.log(props, "default center??")

  //Find Service that is within location of user

  // console.log(props.services);
  // console.log(props.services.services);
  // console.log(props.services["services"]);

  let mapMarkers = props.services.map((service) => {
    // console.log("LOCATION SENT1", service.location)
    // console.log("SERVICE LOCATION ARRAY", service.location)
    // let nearestLocation = findNearestLocation(service.location);
    // console.log("RESULTSENT1", nearestLocation)
    // console.log(service.location)
    // if (!service.location[nearestLocation].geo.coordinates) return null;
    // console.log(JSON.parse(sessionStorage.getItem("coordinates")).lng, "coors")
    return (
      <Marker
        type={service.type}
        contact={service.contact}
        location={service.location[0]}
        categories={service.descriptors.categories}
        coordinates={props.defaultCenter}
        key={service._id}
        id={service._id}
        logo={service.images.logo}
        organizationName={service.organization_name}
        lat={service.location[0].geo.coordinates[1]}
        lng={service.location[0].geo.coordinates[0]}
        service={service}
        // openNow={isOpenNow(service.location[nearestLocation].hours, props.today)}
      />
    );
  });

  // const defaultCenter =
  //   props.defaultCenter !== null
  //     ? props.defaultCenter
  //     : { lng: -119.417931, lat: 36.778259 };

  const coords = { lng: -118.243683, lat: 34.052235 };
  const silverMapStyles = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];
  return (
    // Important! Always set the container height explicitly
    <div className="DirectoryMap">
      <div className="dmap-inner">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCQOQNlLCsR4NzTciZgLeFPGmLG6smYUEk" }}
          defaultCenter={coords}
          center={coords}
          zoom={4}
          options={{
            styles: silverMapStyles,
          }}
        >
          {mapMarkers}
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default DirectoryMap;
