// Libraries
import React from "react";

// Ant Design Components
import { Row, Col, Tag } from "antd";

const CategoryTags = (props) => {
  return (
    <Row className="news-cta--tags-container" style={{ width: props.width }}>
      {props.categories.map((c) => (
        <Col xs={12} xl={3} key={c}>
          <Tag
            key={c}
            value={c}
            onClick={() => props.onChange(c)}
            className={
              c === props.value ? "home-tab-tag-selected" : "home-tab-tag"
            }
            style={{
              width: "120px",
              textAlign: "center",
            }}
          >
            {c}
          </Tag>
        </Col>
      ))}
    </Row>
  );
};

export default CategoryTags;
