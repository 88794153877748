import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import {
  CheckOutlined,
  CloseOutlined,
  CommentOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Button, Input, message, Avatar, Popover } from "antd";
import { UserOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../config";

const NewsCard = (props) => {
  const [comment, setComment] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [candidatesData, setCandidatesData] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const canCommentVote = Boolean(user);
  const isLoggedIn = user;
  const userUpVoted = user
    ? (props?.post?.upvotes || []).includes(user._id)
    : true;
  const userDownVoted = user
    ? (props?.post?.downvotes || []).includes(user._id)
    : true;

  const postPreview = props.post.body
    ? `${props.post.body.substring(0, 150)} ...`
    : "";
  const parsedPost = ReactHtmlParser(postPreview);
  const upVoteCount = (props?.post?.upvotes?.length || 0).toLocaleString(
    "en-US"
  );
  const downVoteCount = (props?.post?.downvotes?.length || 0).toLocaleString(
    "en-US"
  );

  const canVote = userUpVoted || userDownVoted;

  useEffect(() => {
    console.log(props.post)
    async function getCandidatesData() {
      let headers = {
        ids: JSON.stringify(props.post.candidates),
        token: localStorage.getItem("access_token"),
      };
      const { data: candidateResultData } = await axios.get(
        `${config.CANDIDATES}/candidates/find-by-ids`,
        { headers }
      );
      setCandidatesData(candidateResultData);
    }

    if (props?.post?.candidates) {
      getCandidatesData();
    }
  }, [props]);

  async function postUpdatePost(id, data, successMessage) {
    try {
      setIsLoading(true);
      await axios.patch(`${config.BLOGS}/blogs/update/${id}`, data, {
        headers: {
          token: localStorage.getItem("access_token"),
        },
      });
      props.getData();
      message.success(successMessage);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      message.warning("Something went wrong‼️");
    }
  }

  // Function to add post
  async function addCommentToPost(event) {
    event.preventDefault();

    const post = props.post;
    const newComment = {
      comment,
      _id: user._id,
      nickname: user.nickname,
      picture: user.picture,
    };

    const newsPost = {
      ...post,
      comments: [newComment, ...(post.comments || [])],
    };

    await postUpdatePost(post._id, newsPost, "Comment posted!");
    setComment("");
  }

  // Function to upvote or downvote a post
  async function voteInPost(event, type) {
    event.preventDefault();
    if (type === "upvote" && userUpVoted) {
      message.error("You have up voted for this news.");
    } else if (type === "downvote" && userDownVoted) {
      message.error("You have down voted for this news.");
    } else {
      const post = props.post;
      // userId will be added instead of number to prevent multiple vote
      const newVoteData = {
        upvotes:
          type === "upvote"
            ? [user?._id, ...(post.upvotes || [])]
            : post.upvote,
        downvotes:
          type === "downvote"
            ? [user._id, ...(post.downvotes || [])]
            : post.downvote,
      };

      const voteData = {
        ...post,
        ...newVoteData,
      };

      await postUpdatePost(post._id, voteData, "Voted Successfully!");
    }
  }

  function onButtonClick(event) {
    // Prevent from directing to specific article
    event.preventDefault();
  }

  function notLoggedIn(event) {
    event.preventDefault();
    message.info("Please login to use this feature.");
  }
  const defaultBannerLink =
    "https://s3.amazonaws.com/dev.nocap.com/uploads/logo.png";

  const popoverContent = !canCommentVote
    ? "Please signup in order to vote"
    : canVote
      ? "You have voted!"
      : "";

  const PopoverWrapper = ({ children }) => (
    <Popover
      content={popoverContent}
      trigger={!canCommentVote || canVote ? "hover" : undefined}
    >
      {children}
    </Popover>
  );

  return (
    <div className="BlogGridItem" key={props.post["_id"]}>
      <Link
        to={{
          pathname: props.pathname,
          state: {
            data: props.post,
          },
        }}
        className="link"
      >
        <img
          style={{ width: "100%", height: 200, margin: "0 auto" }}
          src={props.post.coverImage || defaultBannerLink}
          alt={props.post.title}
        />
        {props.post.videoUrl ? (
          <iframe
            src={props.post.videoUrl}
            frameBorder="0"
            style={{ width: "100%", height: "100%" }}
            allow="encrypted-media"
            allowFullScreen
            title="video"
          />
        ) : null}
        <div className="actionContainer">
          <div style={{ display: "flex" }}>
            <PopoverWrapper>
              <Button
                className="actionButton flex-row align-center"
                onClick={(e) =>
                  isLoggedIn ? voteInPost(e, "upvote") : notLoggedIn(e)
                }
                size="large"
                icon={<CheckOutlined />}
                disabled={isLoggedIn ? canVote : true}
              >
                <div className="actionButtonText">
                  <span className="title">Upvotes</span>
                  <span className="body">{upVoteCount}</span>
                </div>
              </Button>
            </PopoverWrapper>
            <PopoverWrapper>
              <Button
                className="actionButton flex-row align-center"
                onClick={(e) =>
                  isLoggedIn ? voteInPost(e, "downvote") : notLoggedIn(e)
                }
                size="large"
                icon={<CloseOutlined />}
                disabled={isLoggedIn ? canVote : true}
              >
                <div className="actionButtonText">
                  <div className="title">Downvotes</div>
                  <div className="body">{downVoteCount}</div>
                </div>
              </Button>
            </PopoverWrapper>
          </div>
          <Button
            size="large"
            icon={<CommentOutlined />}
            onClick={onButtonClick}
            disabled={!canCommentVote}
          />
        </div>
        <div className="title">
          <h3>{props.post.title}</h3>
        </div>
        <div className="date">
          {moment(props.post.created_at).format("MMMM DD, YYYY")}
        </div>
        <div className="origin">No Cap Vote, Author: {props.post.author}, Paid Post</div>
        <div className="body">{parsedPost}</div>


        <div className="noCapSection">
          {/* <div className="title"><b>Candidates in this post</b></div>
          <div className="candidateAvatars">
            {candidatesData.map((candidate) => {
              return (
                <Avatar
                  key={candidate._id}
                  size="small"
                  src={candidate?.bio?.profileImage}
                  icon={!candidate?.bio?.profileImage && <UserOutlined />}
                />
              );
            })}
          </div> */}
          <div className="noCapBody">
            <div className="body">{props.post.editorial}</div>
            {/* <div className="origin">Author: {props.post.author} </div> */}
          </div>
        </div>
      </Link>

      <div>
      <div className="title"><b>Fact Check</b></div>
      <div className="candidateAvatars">
        {(props.post.factCheck !== undefined)?
          (
            props.post.factCheck.map((article) => {
              return (
              <Avatar
                size="small"
                src={article.link}
                icon={article.imageLink}
              />)
            })

          ):null
        }
      </div>
      </div>

      <div className="commentsContainer">
        <div className="title">
          <h3>Comments</h3>
        </div>
        {/* Replace below when integrating API */}

        <div className="commentsBody">
          {(props.post.comments || []).slice(0, 4).map((data, idx) => {
            return (
              <div className="body" key={idx}>
                <strong>{data.nickname}</strong> {data.comment}
              </div>
            );
          })}
          {(props.post.comments || []).length > 4 && (
            <Link
              to={{
                pathname: props.pathname,
                state: {
                  data: props.post,
                },
              }}
              className="link"
              style={{ display: "block", textAlign: "center" }}
            >
              View all ({(props.post.comments || []).length}) comments
            </Link>
          )}
        </div>
        {/* Comment Input */}
        {canCommentVote && (
          <Input.Group
            className="commentsInputContainer"
            compact
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Input
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              onClick={onButtonClick}
              placeholder="Type to add comment"
              on
              bordered={false}
            />
            <Button
              size="large"
              icon={<SendOutlined />}
              disabled={isLoading || !Boolean(comment)}
              onClick={(e) => addCommentToPost(e)}
            />
          </Input.Group>
        )}
      </div>
    </div>
  );
};

export default NewsCard;
