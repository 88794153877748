import React, { useContext } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from "./StripeCheckoutOnly";
import { SignUpContext } from "../../../providers/SignupContext";

const brandIMG = "https://s3.amazonaws.com/dev.nocap.com/uploads/logo.png";

const InjectOnboardingCheckout = (props) => {
  const { profileData, setProfileData } = useContext(SignUpContext);

  const removeBannerData = () => {
    setProfileData((prev) => ({
      ...prev,
      bannerData: {},
    }));
  };

  const handleStandardInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setProfileData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <StripeProvider apiKey="pk_test_C603xCp8pcAXYqvSp6olTTo5">
      <div className="stripe-checkout" style={{ alignItems: "center" }}>
        <img
          style={{ display: "block", margin: "0 auto" }}
          src={brandIMG}
          width={200}
          alt="brand"
        />
        {/* <h3>Complete Purchase</h3> */}
        <Elements>
          <CheckoutForm
            removeBannerData={removeBannerData}
            banner={props.banners}
            stepStatus={{
              page: profileData?.bannerData?.page,
              typeName: profileData?.bannerData?.typeName,
              image: profileData?.bannerData?.image,
              bannerData: profileData?.bannerData,
            }}
            bannerCost={profileData.bannerData.adCost}
            handleStandardInputChange={handleStandardInputChange}
            totalCost={0}
            saveAds={props.saveAds}
            newAds={props.newAds}
          />
        </Elements>
      </div>
    </StripeProvider>
  );
};

export default InjectOnboardingCheckout;
