import React, { useState } from "react";
import axios from "axios";
import config from "../../../../../config";
import Add from "./Add";
import { capitalizeFirstLetterOfEachWord } from "../../../../../helpers";
import NoCapManifest from "../../../../../nocap.json";
import { Form, Input, Col, Button, Select, message } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

let stateIndex = 1;
let divisionIndex = 1;
let officeIndex = 1;
let politicalDivisionIndex = 1;
let titleIndex = 1;
let districtIndex = 1;


const OfficeSelectionReformed = (props, formValues) => {
  const [inputState, setInputState] = useState({
    state: "",
    division: "",
    office: "",
    politicalDivision:"",
    title: "",
    district: ""
  });
  const [states, setStates] = useState(
    NoCapManifest.candidateInfoForm.stateData
  );
  const [offices, setOffices] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [divisionPhrase, setDivisionPhrase] = useState("select city");
  const [officePhrase, setOfficePhrase] = useState("select office");

  const [stateSelections, setStateSelections] = useState(null);
  const [divisionSelections, setDivisionSelections] = useState(null)



  //PoliticalDivsion and Title Dropdown

  const [politicalDivisions, setPoliticalDivisions] = useState(NoCapManifest.candidateInfoForm.politicalDivisions);
  const [titles, setTitles] = useState([]);
  const [titlePhrase, setTitlePhrase] = useState("select a title");
  const [localLegislativeTitles, setLocalLegislativeTitles] = useState(NoCapManifest.candidateInfoForm.localLegislativeTitles);
  const [localExecutiveTitles, setLocalExecutiveTitles] = useState(NoCapManifest.candidateInfoForm.localExecutiveTitles);
  const [stateJudicialTitles, setStateJudicialTitles] = useState(NoCapManifest.candidateInfoForm.stateJudicialTitles);
  const [statewideTitles, setStatewideTitles] = useState(NoCapManifest.candidateInfoForm.statewideTitles);
  const [federalJudicialTitles, setFederalJudicialTitles] = useState(NoCapManifest.candidateInfoForm.federalJudicialTitles);
  const [stateLegislativeTitles, setStateLegislativeTitles] = useState(NoCapManifest.candidateInfoForm.stateLegislativeTitles);
  const [gubernatorialTitles, setGubernatorialTitles] = useState(NoCapManifest.candidateInfoForm.gubernatorialTitles);
  const [congressionalTitles, setCongressionalTitles] = useState(NoCapManifest.candidateInfoForm.congressionalTitles);
  const [presidentialOfficialsTitles, setPresidentialOfficialsTitles] = useState(NoCapManifest.candidateInfoForm.presidentialOfficialsTitles);

  const [politicalDivisionSelections,setPoliticalDivisionsSelections] = useState(null);
  const [titleSelections, setTitleSelections] = useState(null);
  const [districts, setDistricts] = useState(NoCapManifest.candidateInfoForm.districts);
  const [districtSelections, setDistrictSelections] = useState(null);





  const handleStateChange = async (value, index) => {
    // reset all the divisions and offices related states
    setDivisions([]);
    setOffices([]);
    divisionIndex = 1;
    officeIndex = 1;
    // console.log(props.form)
    // console.log(props.form.getFieldValue(`TestState`))
    // // console.log(props.form.getFieldsValue(true).officeSelections[0].state);
    // console.log(props.form.getFieldsValue(true));
    // console.log((props.form.getFieldValue(`officeSelections`)).state)
    // console.log(value);

    // reset selected option
    props.form.setFieldsValue({
      [`division`]: undefined,
      [`office`]: undefined,
    });

    console.log(props.form.getFieldsValue(true))
    console.log(props.form.getFieldsValue(true).officeSelections[index].state)
    console.log(props.form.getFieldValue("officeSelections"))
    setStateSelections(props.form.getFieldValue("officeSelections")[index].state)

    props.setDivisionIds({
      ...props.divisionIds
    });

    // disabled office and division selection and show 'loading ...' when state is fetching data
    setLoading(true);
    setDivisionPhrase("Loading ...");

    // fetch the division and office list if the state is not manually entered
    if (!states[value].startsWith("other")) {
      const divisions = await axios.get(
        `${config.CANDIDATES}/candidates/get-dvisions-list`,
        {
          cors: true,
          responseType: "json",
          headers: {
            state: NoCapManifest.candidateInfoForm.stateData[value],
          },
        }
      );
      console.log(divisions)
      if (divisions.data.length > 0) {
        setDivisions(divisions.data);
        setDivisionPhrase("select city");
      } else {
        setDivisionPhrase("add a division");
        setOfficePhrase("add an office");
      }
    } else {
      setDivisionPhrase("add a city");
      setOfficePhrase("add an office");
    }
    setLoading(false);
  };

  const handleDivisionChange = async (value, e, index) => {
    setLoading(true);
    setOffices([]);
    props.form.setFieldsValue({
      [`office`]: undefined,
    });

    setDivisionSelections(props.form.getFieldValue("officeSelections")[index].division)

    props.setDivisionIds({
      ...props.divisionIds,
    });
    let result = await divisions.filter((division) => {
      return division.name === value;
    });
    if (result[0].offices !== null) {
      let sortedResults = result[0].offices.sort();
      setOffices(sortedResults);
      setOfficePhrase("Select office");
    } else {
      setOfficePhrase("Add a office");
    }
    setLoading(false);
  };

  const addState = (e) => {
    e.preventDefault();
    let i = stateIndex++;
    let addedState = inputState.state
      ? capitalizeFirstLetterOfEachWord(inputState.state)
      : `New State ${i}`;
    setInputState({ ...inputState, state: "" });
    setStates({ ...states, [`${addedState}`]: `other ${i}` });
  };

  const addDivision = (e) => {
    e.preventDefault();
    let i = divisionIndex++;
    let addedDivision = inputState.division
      ? capitalizeFirstLetterOfEachWord(inputState.division)
      : `New Division ${i}`;
    setInputState({ ...inputState, division: "" });
    setDivisions([
      ...divisions,
      {
        divisonId: `other ${i}`,
        name: addedDivision,
        offices: null,
        state: props.form.getFieldValue(`${props.type}State`),
        _id: `other ${i}`,
      },
    ]);
  };

  const addOffice = (e) => {
    e.preventDefault();
    let i = officeIndex++;
    let addedOffice = inputState.office
      ? capitalizeFirstLetterOfEachWord(inputState.office)
      : `New Office ${i}`;
    setInputState({ ...inputState, office: "" });
    setOffices([...offices, addedOffice]);
  };


  const handleChangePoliticalDivision = (value, index) => {
    // reset all the divisions and offices related states
    setTitles([]);
    titleIndex = 1;
    // console.log(props.form)
    // console.log(props.form.getFieldValue(`TestState`))
    // // console.log(props.form.getFieldsValue(true).officeSelections[0].state);
    // console.log(props.form.getFieldsValue(true));
    // console.log((props.form.getFieldValue(`officeSelections`)).state)
    // console.log(value);

    console.log(value)
    console.log(politicalDivisions[value])


    // reset selected option
    props.form.setFieldsValue({
      [`title`]: undefined
    });

    console.log(props.form.getFieldsValue(true))
    console.log(props.form.getFieldsValue(true).officeSelections[index].politicalDivision)
    console.log(props.form.getFieldValue("officeSelections"))
    setPoliticalDivisionsSelections(props.form.getFieldValue("officeSelections")[index].politicalDivision)



    // disabled office and division selection and show 'loading ...' when state is fetching data
    setLoading(true);
    setTitlePhrase("Loading ...");




    if(value === "Local Legislative") {
      setTitles(localLegislativeTitles);
      setTitlePhrase("select title");
      console.log(localLegislativeTitles);
      console.log(titles)
    } else if (value === "Local Executive") {
      setTitles(localExecutiveTitles);
      setTitlePhrase("select title");
      console.log(localExecutiveTitles);
      console.log(titles)
    } else if (value === "State Judicial") {
      setTitles(stateJudicialTitles);
      setTitlePhrase("select title");
      console.log(stateJudicialTitles);
      console.log(titles);
    } else if (value === "Statewide") {
      setTitles(statewideTitles);
      setTitlePhrase("select title");
      console.log(statewideTitles);
      console.log(titles);
    } else if (value === "Federal Judicial") {
      setTitles(federalJudicialTitles);
      setTitlePhrase("select title");
      console.log(federalJudicialTitles);
      console.log(titles);
    } else if (value === "State Legislative") {
      setTitles(stateLegislativeTitles);
      setTitlePhrase("select title");
      console.log(stateLegislativeTitles);
      console.log(titles);
    } else if (value === "Gubernatorial") {
      setTitles(gubernatorialTitles);
      setTitlePhrase("select title");
      console.log(gubernatorialTitles);
      console.log(titles);
    } else if (value === "Congressional") {
      setTitles(congressionalTitles);
      setTitlePhrase("select title");
      console.log(congressionalTitles);
      console.log(titles);
    } else if (value === "Presidential Officials") {
      setTitles(presidentialOfficialsTitles);
      setTitlePhrase("select title");
      console.log(presidentialOfficialsTitles);
      console.log(titles);
    }


    setLoading(false);

  }

  const addPoliticalDivision = (e) => {
    e.preventDefault();
    let i = politicalDivisionIndex++;
    let addedPoliticalDivision = inputState.politicalDivision
      ? capitalizeFirstLetterOfEachWord(inputState.politicalDivision)
      : `New Political Division ${i}`;

    if(politicalDivisions.includes(addedPoliticalDivision)) {
      console.log("Political Division already exists. Please select from the list")
      message.error("Political Division already exists. Please select from the list")
      setInputState({...inputState, politicalDivision: ""})
    } else {
    setInputState({ ...inputState, politicalDivision: "" });
    setPoliticalDivisions([ ...politicalDivisions, addedPoliticalDivision]);
    }

  }

  const addTitle = (e) => {
    e.preventDefault();
    let i = titleIndex++;
    let addedTitle = inputState.title
      ? capitalizeFirstLetterOfEachWord(inputState.title)
      : `New Title ${i}`;

    if(titles.includes(addedTitle)) {
      console.log("Title already exists. Please select from the list")
      message.error("Title already exists. Please select from the list")
      setInputState({...inputState, title: ""})
    } else {
    setInputState({ ...inputState, title: "" });
    setTitles([ ...titles, addedTitle]);
    }

  }



  const handleChangeTitle = async(value, index) => {
    setLoading(true);

    setTitleSelections(props.form.getFieldValue("officeSelections")[index].title)

    try {
      let result = await titles.filter((title) => {
        return title === value;
      });

    } catch {

      console.log("Some error")

    } finally {
      setLoading(false);
    }


  }

  const handleChangeDistrict = async(value, index) => {


    setDistrictSelections(props.form.getFieldValue("officeSelections")[index].district)


    console.log(districtSelections)

  }

  const addDistrict = (e) => {
    e.preventDefault();
    let i = districtIndex++;
    let addedDistrict = inputState.district
      ? capitalizeFirstLetterOfEachWord(inputState.district)
      : `New Area ${i}`;

    if(districts.includes(addedDistrict)) {
      console.log("District already exists")
      message.error("District already exists")
      setInputState({...inputState, district: ""})
    } else {
    setInputState({ ...inputState, district: "" });
    setDistricts([ ...districts, addedDistrict]);
    }

  }

  return (
    <Col span={24}>
      <Form.List name="officeSelections">
        {(fields, { add, remove }) => {
          const officeSelections = formValues?.officeSelections || [];
          const enableBtn =
            officeSelections.length > 0
              ? officeSelections.every(
                (v) =>
                  v?.politicalDivision && v?.title && v?.position && v?.district && v?.state && v?.division && v?.office && v?.term
              )
              : true;

      return (

      //State
      <Form.Item label="Office Selections (Please enter most recent to oldest)" >
      {fields.map((field, index) => (
      <div key={"officeSelection" + index}>

        <Form.Item
          label="Political Division"
          labelCol={{ span: 12 }}
          name={[field.name, "politicalDivision"]}
          fieldKey={[field.fieldKey, "politicalDivision"]}
          rules={[
            {
              required:true,
              message: `Please select your political division description`,
            },
          ]}
        >
          <Select
            showSearch
            placeholder="select political division"
            onChange={(value) => handleChangePoliticalDivision(value, index)}
            dropdownRender={(menu) => (
              <Add
                menu={menu}
                placeholder = "please enter political division"
                setInputState={setInputState}
                inputState={inputState}
                type="politicalDivision"
                add={addPoliticalDivision}
              />
            )}
          >
            {politicalDivisions.map((politicalDivision, i) => (
              <Option key={"politicalDivision" + i} value={politicalDivision}>
                {politicalDivision}
              </Option>
            ))}
          </Select>
        </Form.Item>



        <Form.Item
          label="Title"
          labelCol={{ span: 12 }}
          name={[field.name, "title"]}
          fieldKey={[field.fieldKey, "title"]}
          rules={[
            {
              required: true,
              message: `Please select your title`,
            },
          ]}
        >
        <Select
          showSearch
          placeholder={titlePhrase}
          loading={loading}
          onChange={(value) => handleChangeTitle(value, index)}
          disabled={
            !(politicalDivisionSelections) || loading
          }
          dropdownRender={(menu) => (
            <Add
              menu={menu}
              placeholder="please enter title"
              setInputState={setInputState}
              inputState={inputState}
              type="title"
              add={addTitle}
            />
          )}
        >
          {titles.map((title, i) => (
            <Option key={"title" + i} value={title}>
              {title}
            </Option>
          ))}
        </Select>
        </Form.Item>



        <Form.Item
          label="Position"
          labelCol={{ span: 12 }}
          name={[field.name, "position"]}
          fieldKey={[field.fieldKey, "position"]}
          rules={[
            {
              required: true,
              message: `Please enter position`,
            },
          ]}
        >
          <Input placeholder="for example: committee on finance" />
        </Form.Item>



        <Form.Item
          label="District"
          labelCol={{ span: 12 }}
          name={[field.name, "district"]}
          fieldKey={[field.fieldKey, "district"]}
          rules={[
            {
              required: true,
              message: `Please select your district`,
            },
          ]}
        >
        <Select
          showSearch
          placeholder="Please select your district"
          onChange={(value) => handleChangeDistrict(value, index)}
          dropdownRender={(menu) => (
            <Add
              menu={menu}
              setInputState={setInputState}
              inputState={inputState}
              placeholder = "please enter district"
              type="district"
              add={addDistrict}
            />
          )}
        >
          {districts.map((district, i) => (
            <Option key={"district" + i} value={district}>
              {district}
            </Option>
          ))}
        </Select>
        </Form.Item>





        <Form.Item
          label="State"
          labelCol={{ span: 12 }}
          name= {[field.name, "state"]}
          fieldKey={[field.fieldKey, "state"]}
          rules={[
            {
              required: true,
              message: `please select your state`,
            },
          ]}
        >
          <Select
            showSearch
            placeholder="select state"
            onChange={(value) => handleStateChange(value, index)}
            dropdownRender={(menu) => (
              <Add
                menu={menu}
                setInputState={setInputState}
                inputState={inputState}
                type="state"
                placeholder = "please enter state"
                add={addState}
              />
            )}
          >
            {Object.keys(states).map((state) => (
              <Option key={states[state]} value={state}>
                {state}
              </Option>
            ))}
          </Select>
        </Form.Item>




        <Form.Item
          label="City"
          name={[field.name, "division"]}
          fieldKey={[field.fieldKey, "division"]}
          
        >
          <Select
            showSearch
            placeholder={divisionPhrase}
            loading={loading}
            onChange={(value, e) => handleDivisionChange(value, e, index)}
            disabled={
              !(stateSelections) || loading
            }
            dropdownRender={(menu) => (
              <Add
                menu={menu}
                setInputState={setInputState}
                inputState={inputState}
                type="division"
                placeholder = "please enter city"
                add={addDivision}
              />
            )}
          >
            {divisions.map((division) => (
              <Option key={division.divisonId} value={division.name}>
                {division.name}
              </Option>
            ))}
          </Select>
        </Form.Item>




        <Form.Item
          label= "Office"
          name= {[field.name, "office"]}
          fieldKey={[field.fieldKey, "office"]}
          
        >
          <Select
            showSearch
            placeholder={officePhrase}
            loading={loading}
            disabled={
              !(divisionSelections) ||
              !(stateSelections) ||
              loading
            }
            dropdownRender={(menu) => (
              <Add
                menu={menu}
                setInputState={setInputState}
                inputState={inputState}
                type="office"
                placeholder = "please enter office"
                add={addOffice}
              />
            )}
          >
            {offices.map((office, index) => (
              <Option key={office + index} value={office}>
                {office}
              </Option>
            ))}
          </Select>
        </Form.Item>




        <Form.Item
          label={
            <span>
              Term{" "}
              <span
                style={{
                  fontSize: 10,
                  fontStyle: "italic",
                }}
              >
                (yyyy - yyyy)
              </span>
            </span>
          }
          name={[field.name, "term"]}
          field = "term"
          rules={[
            {
              required: true,
              message: `Please indicate years in office`,
            },
          ]}
        >
          <Input.TextArea
            autoSize
            allowClear
            placeholder="enter term in office"
          />
        </Form.Item>


      {fields.length > 1 ? (
        <Form.Item style={{ marginBottom: "0px" }}>
          <MinusCircleOutlined
            className="dynamic-delete-button"
            onClick={() => {
              remove(field.name);
            }}
          />
        </Form.Item>
      ) : null}

      </div>
    ))}
      <Form.Item>
        <Button
          type="dashed"
          onClick={() => {
            add();
          }}
          style={{
            width: "100%",
          }}
          disabled={!enableBtn}
        >
          <PlusOutlined /> Add New Office
        </Button>
      </Form.Item>
    </Form.Item>
  );
}}
    </Form.List>
    </Col>
);
};

export default OfficeSelectionReformed;
