import React from "react";
import { BackTop } from "antd";

class TermsConditionsPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="constrain">
        <div className="inner">
          <h2>ACCEPTANCE OF TERMS AND CONDITIONS OF USE</h2>
          <p>
            Welcome to www.nocapvote.com (“Site”), a Site owned and operated by
            No Cap Vote, LLC, (“our,” “us,” and “we”). The Terms and Conditions
            of Use (“Terms”) govern the use of the Site and is controlled by us
            and/or our subsidiary and affiliated entities and may be hosted by
            one or more web servers (however accessed and/or used, whether via
            personal computers, mobile devices or otherwise and other
            interactive features, applications or downloads that are operated by
            us and that are available through, or interact with, this Site where
            these Terms are posted.{" "}
          </p>
          <h2>DESCRIPTION</h2>
          <p>
            No Cap Vote is a multimedia source that incudes, but is not limited
            to, news, current events, sports, weather, government, elections,
            campaigns, political issues, commentary, education, entertainment,
            travel, leisure, current news, directories, and/or product/service
            overviews and promotions.{" "}
          </p>
          <p>
            We are committed to protecting the privacy of Internet users and
            fostering user confidence in the Internet, Internet advertising, and
            direct marketing. We continually evaluate innovative ways to deliver
            relevant advertising, news, information, sales information, and
            custom online experiences on behalf of our licensors/advertisers. No
            Cap Vote provides services for its licensors/advertisers, such as
            media planning and buying, marketing communications planning,
            campaign analytics and research, paid search placement and search
            engine optimization, branded entertainment as well as sports
            sponsorships ("Service").{" "}
          </p>
          <h2>LIMITATIONS ON USE</h2>
          <p>
            You must be at least 18 years to access this Site. If you are not at
            least 18 years old, you are not permitted to access or use the site.{" "}
          </p>
          <p>
            Please read these terms of use carefully before you start to use the
            site as they affect your legal rights and obligations. By proceeding
            to use this Site, you agree to be bound by these Terms and
            Conditions described herein, in addition to our Privacy Policy
            describing information we may collect from you and how we will use
            it and the Cookies Policy (
            <a href="http://nocapvote.com/privacy">
              <u>http://nocapvote.com/privacy</u>
            </a>
            ) describing the cookies that we place on your device and their
            purpose at any time. We respect the privacy of visitors and users of
            this Site, and will only collect personally identifiable data, such
            as your name or email address, when it is voluntarily submitted to
            us in this Site.{" "}
          </p>
          <p>
            <b>
              IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, PLEASE REFRAIN
              FROM USING THS SITE.
            </b>{" "}
          </p>
          <h2>SITE ACCESS AND USE</h2>
          <p>
            The Service may provide you with an opportunity to interact with
            others and share your thoughts, information, products, and
            materials. You are solely responsible for the content, information
            and other materials that you post on the Service or transmit to
            other users and agree that you will not hold No Cap Vote responsible
            or liable for any Content from other users that you access on the
            Site.{" "}
          </p>
          <p>
            The information contained in this Site is intended for general
            information purposes only and is not meant to be relied upon nor
            intended as financial, professional nor legal advice, nor an offer
            or solicitation or giving any recommendation in relation to any
            products or services.{" "}
          </p>
          <p>
            We have made all reasonable precautions and care in compiling the
            information in this Site. We do not make any representations,
            warranties, nor guarantees of any kind, whether express or implied,
            as to the accuracy, completeness, nor any up-to-date information of
            any content throughout this Site, including any hypertext links to
            any Site or other items used either directly or indirectly from this
            Site.{" "}
          </p>
          <h2>INTELLECTUAL PROPERTY</h2>
          <p>
            You acknowledge, understand, and agree that, unless otherwise
            explicitly specified, all copyright, trademarks, service marks,
            logos, patents and all other intellectual property rights in the
            design, content, and arrangement of this Site, including, but not
            limited to, its look and feel, text, typographical arrangement,
            derivative works and enhancements, illustrations, interviews,
            instructions, files, images, artwork, photographs, blogs, articles,
            content, materials, products, services, URLs, documentation,
            advertising copy, the compilation, video footage, assembly and
            arrangement of materials, sound, graphics, video, webinars, software
            and underlying coding (“Content”), shall remain, at all times,
            controlled/owned by us or our licensors/advertisers/account users.
            Nothing in these terms operates to transfer, assign, nor grant any
            of those rights to you. You agree that the content is made available
            for your non-exclusive, personal, non-commercial use, and you shall
            not copy, distribute, reproduce, modify, download, re-sell, or make
            derivative works of any materials in the Site or any part of it
            without prior written consent of the owner of such materials. You
            must not use any part of the content on our Site for commercial
            purposes without obtaining a license/written permission to do so
            from us or our advertisers. Downloading material from the Site is
            done at your own risk.{" "}
          </p>
          <p>
            Any information on this Site is up-to-date at the time it is posted
            but is subject to subsequent variation, without notice. We reserve
            the right at any time, and without notice, to enhance, modify,
            alter, suspend or permanently discontinue any or all parts of this
            Site and/or to restrict or prohibit access to it. Please note that
            any of the content on the Site may be out of date at any given time,
            and we are under no obligation to update it. We do not guarantee
            that the Site, or any content on it, will be free from errors or
            omissions.{" "}
          </p>
          <p>
            These terms may be amended by us from time to time at any time and
            without prior notice and the changes will become effective
            immediately after posting the revised terms. You can review the most
            current version of the terms at any time at{" "}
            <a href="www.nocapvote.com">
              <u>www.nocapvote.com</u>
            </a>
            . You are responsible for checking periodically for changes and your
            continued use of this Site indicates your acceptance of the new
            terms. You agree not to send any unsolicited promotional or
            advertising material, spam or similar materials or any volume
            messages that may interfere with the operation of this Site or with
            the enjoyment of this Site by other visitors.{" "}
          </p>
          <h2>THIRD PARTIES</h2>
          <p>
            You understand that when using the Site, you will be exposed to
            content from a variety of sources, and that we are not responsible
            for the accuracy, usefulness, safety, or intellectual property
            rights of or relating to such content.{" "}
          </p>
          <p>
            We may include third party logos or links to third-party sites or
            services on our Site. We accept no responsibility nor liability
            arising with respect to any content of such third-party Sites, nor
            shall the use of third-party logos, sites, or services be deemed to
            be a recommendation or endorsement of such third party or their
            products or services by us. We are not responsible for the practices
            or the content of such other Sites or services.{" "}
          </p>
          <p>
            In consideration of No Cap Vote granting you access to the service
            described, you hereby grant No Cap Vote a perpetual license to use,
            redact, republish, copy, perform and distribute your content and
            screen name, including any intellectual property contained therein,
            in any medium now known or hereinafter developed without payment or
            compensation to you and without seeking any further approval from
            you.{" "}
          </p>
          <p>
            We reserve the right to display advertisements in connection with
            your User Content and to use your User Content for advertising and
            promotional purposes. You acknowledge and agree that your User
            Content may be included on the Sites and advertising networks of our
            distribution partners and third-party service providers, including
            their downstream users.{" "}
          </p>
          <h2>USER CONTENT</h2>
          <p>
            We may now or in the future permit users to post, upload, display,
            publish, distribute, transmit through, broadcast or otherwise make
            available on the Sites (collectively, "submit") messages, text,
            illustrations, files, images, graphics, photos, comments, sounds,
            music, videos, information, content, and/or other materials ("User
            Content"). Except for any Site content included in your User Content
            we have given you permission to use, and subject to the rights and
            license you grant herein, you retain all rights, titles and
            interests in your User Content. It is solely your responsibility to
            monitor and protect any intellectual property rights that you may
            have in your User Content, and we do not accept any responsibility
            for same. You agree that you either:{" "}
          </p>
          <ol>
            <li>
              own the rights to the User Content you submit and the right to
              grant all the rights and licenses in these Terms; or
            </li>
            <li>
              have all necessary rights and licenses from the owner(s) of these
              rights to enter into these Terms. Upon our request, you will
              furnish us with any documentation, substantiation or releases
              necessary to verify your compliance with these Terms. You also
              acknowledge that the Internet may be subject to breaches of
              security and should be aware that submissions of User Content or
              other information may not be secure, and you should consider this
              before submitting any information to us. Subject to these Terms,
              we grant you the limited, revocable, non-exclusive permission to
              use Site content in your User Content as may from time to time be
              made available on this Site specifically for that purpose, but
              only for such purposes as may be explicitly stated at the time
              such Site content is so made available on this Site.
            </li>
          </ol>
          <p>
            Whenever you make use of a feature that allows you to send, submit
            or upload material to our Site, or to initiate contact with other
            users of our Site, you must comply with the content standards set
            out in our Site. You warrant that any such contribution does comply
            with those standards, and you indemnify us for any breach of that
            warranty. We will not be responsible, or liable to any third party,
            for the content or accuracy of any materials posted by you or any
            other user of our Site.{" "}
          </p>
          <p>
            Any material you upload to our Site will be considered
            non-confidential and you grant us the right to display, use, copy,
            distribute, store and disclose to third parties any such material
            for any purpose. We also have the right to disclose your identity to
            any third party who is claiming that any material posted or uploaded
            by you to our Site constitutes a violation of their intellectual
            property rights, or of their right to privacy.{" "}
          </p>
          <p>
            At our sole discretion, we have the right, but not the obligation,
            to refuse, delete or remove any material or posting you make on our
            Site if, in our opinion, such material does not comply with the
            content standards set out in our acceptable or for any other reason.
            We, in no way, guarantee that your content, or any part of it, will
            be displayed on the Site.{" "}
          </p>
          <p>
            You warrant and undertake that all content submitted by you to our
            Site, including, but not limited to, your name, biographical
            information and all other names, postings, messages, text, files,
            images, photographs, video, sounds likenesses, graphics, logos,
            marks, and all other information shall not:{" "}
          </p>
          <ol>
            <li>
              infringe copyright, trademarks, rights of privacy, publicity or
              other intellectual property rights of any other person or entity;
            </li>
            <li>
              contain any personal information relating to any persons under 18;
            </li>
            <li>
              be inaccurate where it states facts or disingenuous where it
              states opinions;
            </li>
            <li>
              contain any content that is libelous, defamatory, indecent,
              vulgar, obscene, pornographic, sexually explicit, harmful,
              harassing, intimidating, threatening, hateful, objectionable,
              fraudulent, discriminatory, abusive or racially, culturally, or
              ethnically offensive or which may or may appear to, impersonate
              any third party; or
            </li>
            <li>
              violate any applicable law, statute, ordinance, rule or
              regulation.
            </li>
          </ol>
          <p>
            You acknowledge, understand and agree that you are solely liable for
            any content that you post, email or otherwise make available via the
            Site, and shall indemnify, compensate and reimburse us against any
            claim received, or damages suffered, by us resulting from content
            you submit that:{" "}
          </p>
          <ol>
            <li>
              does not infringe any other person's rights (for example, you
              should own all the rights in your content such that it should be
              original and may not include any music soundtrack or any copyright
              protected work (the rights in which are owned by a third party) as
              part of your content, which you submit, without the express
              written permission of the owner of the respective right),
            </li>
            <li>
              does not contain any information which is subject to any data
              protection or confidentiality obligations other than where
              permitted by law,
            </li>
            <li>
              does not contain any viruses or other contaminants that can
              modify, delete, or damage our computer programs and hardware;
            </li>
            <li>
              does not contain any materials that are libelous, defamatory,
              indecent, vulgar, obscene, pornographic, sexually explicit,
              harmful, harassing, intimidating, threatening, hateful,
              objectionable, fraudulent, discriminatory, abusive or racially,
              culturally, or ethnically offensive,
            </li>
            <li>
              does not violate any law, rule or regulation in any way, and
            </li>
            <li>
              does not comply with our content standards that you send,
              knowingly receive, upload, download, use or re-use
            </li>
          </ol>
          <h2>COPYRIGHT INFRINGEMENT</h2>
          <p>
            If you believe any materials on the Service infringe a copyright,
            you should provide us with written notice that at a minimum
            contains:{" "}
          </p>
          <ol>
            <li>
              a physical or electronic signature of a person authorized to act
              on behalf of the owner of an exclusive right that is allegedly
              infringed;
            </li>
            <li>
              identification of the copyrighted work claimed to have been
              infringed, or, if multiple copyrighted works at a single online
              site are covered by a single notification, a representative list
              of such works at that site;
            </li>
            <li>
              identification of the material that is claimed to be infringing or
              to be the subject of infringing activity and that is to be removed
              or access to which is to be disabled, and information reasonably
              sufficient to permit us to locate the material;
            </li>
            <li>
              information reasonably sufficient to permit us to contact the
              complaining party, such as an address, telephone number, and, if
              available, an electronic mail address at which the complaining
              party may be contacted;
            </li>
            <li>
              a statement that the complaining party has a good faith belief
              that use of the material in the manner complained of is not
              authorized by the copyright owner, its agent, or the law; and
            </li>
            <li>
              a statement that the information in the notification is accurate,
              and that the complaining party is authorized to act on behalf of
              the owner of an exclusive right that is allegedly infringed.
            </li>
          </ol>
          <p>
            It is our policy to terminate relationships regarding content with
            third parties who repeatedly infringe the copyrights of others.{" "}
          </p>
          <h2>TRADEMARKS</h2>
          <p>
            We expressly reserve all rights in and to the domain name
            www.nocapvote.com and all other related service marks, trading names
            or other trademarks relating to our products or services. Other
            trademarks, products and company names mentioned on the Site may be
            trademarks of their respective owners or licensors/advertisers and
            the rights in such marks are expressly reserved to the respective
            owners or licensors/advertisers.{" "}
          </p>
          <h2>EDIT OR REMOVAL OF MATERIAL</h2>
          <p>
            We are under no obligation to screen or monitor content, but may
            review content from time to time at its sole discretion to review
            compliance with these Terms. We have no obligation to post,
            maintain, accept, display, exploit or otherwise make use of User
            Content, do not guarantee distribution of User Content and User
            Content will not be returned and you will not have the right, once
            posted, to access, archive, maintain or otherwise use such User
            Content on the Site. We may discontinue operation of the Site, or
            your use of the Site, in either case in whole or in part, in our
            sole discretion. You have no right to maintain or access your User
            Content on the Sites and we have no obligation to return your User
            Content or otherwise make it available to you. We may, in our sole
            discretion, delete, move, re-format, edit, alter, distort, remove or
            refuse to exploit User Content without notice or liability;
            provided, however, that we reserve the right to treat User Content
            on the Site, or on certain portions of the Site, as content stored
            at the direction of users for which we will not exercise editorial
            control except to block or remove content that is obscene, lewd,
            lascivious, filthy, violent, harassing or otherwise objectionable or
            to enforce the rights of third parties.{" "}
          </p>
          <p>
            We will make all determinations as to what content is appropriate at
            our sole discretion.{" "}
          </p>
          <p>
            We reserve the right to edit and/or remove, without notice, any
            comments at our discretion which;{" "}
          </p>
          <ol>
            <li>
              are defamatory, abusive, obscene, profane, insulting, or
              offensive;{" "}
            </li>
            <li>
              is threatening, harassing or that promotes racism, bigotry, hatred
              or physical harm of any kind against any group or individual, as
              well as any “masked” profanities;{" "}
            </li>
            <li>contain swear words or other language likely to offend;</li>
            <li>
              break the law, condone or encourage unlawful activity. This
              includes breach of copyright, defamation and contempt of court;
            </li>
            <li>are seen to impersonate someone else;</li>
            <li>
              are being repeatedly posted, such as "junk mail," "spam," "chain
              letters," "pyramid schemes," or any other form of solicitation we
              feel are inappropriate;
            </li>
            <li>
              any incorrect information and contact details, such as phone
              numbers, postal or e-mail addresses;
            </li>
            <li>
              infringes or violates another party's intellectual property rights
              (such as music, videos, photos or other materials for which you do
              not have written authority from the owner of such materials to
              post on the Site;
            </li>
            <li>
              violates any party’s right of publicity or right of privacy, is
              inaccurate, false or misleading in any way; and
            </li>
            <li>
              promotes illegal or unauthorized copying of another person's
              copyrighted work or links to them or providing information to
              circumvent security measures. Comments may be used in the print
              edition at editorial discretion and may be restricted to 500 words
              or less.
            </li>
          </ol>
          <h2>CONTEST, SWEEPSTAKES, INTERACTIVE SERVICES</h2>
          <p>
            From time to time, we may run certain contests or sweepstakes. Each
            such contest or sweepstakes will have its own rules and conditions,
            which shall be in addition to the terms in these Terms.
            Participation in any such contest or sweepstakes requires your
            acceptance of such rules and conditions.{" "}
          </p>
          <p>
            From time to time, we may provide interactive services, including,
            but not limited to, chat rooms and bulletin boards. Where we do
            provide any interactive services, we will provide clear information
            to you about the kind of services offered, if it is moderated, and
            what form of moderation is included (human or technical).{" "}
          </p>
          <h2>CONDITIONS FOR LINKING TO SITE</h2>
          <p>
            We provide the Site, including, without limitation, Site content for
            entertainment, educational, and promotional purposes only. You may
            not rely on any information and opinions expressed on the Site for
            any other purpose. In all instances, it is your responsibility to
            evaluate the accuracy, timeliness, completeness, or usefulness of
            the Site content. Under no circumstances will we be liable for any
            loss or damage caused by your reliance on any Site content.{" "}
          </p>
          <p>
            In many instances, Site content will include content posted by a
            third-party or will represent the opinions and judgments of a
            third-party. We do not endorse, warrant and are not responsible for
            the accuracy, timeliness, completeness, or reliability of any
            opinion, advice, or statement made on the Site by anyone, including,
            but not limited to, advertisers, authorized employees or
            spokespersons while acting in their official capacities.{" "}
          </p>
          <p>
            If there is a dispute between persons accessing the Site or between
            persons accessing the Sites and any third party, you acknowledge,
            understand, and agree that we are under no obligation to become
            involved. If there is such a dispute, you hereby release No Cap Vote
            and its officers, directors, employees, parents, partners,
            successors, agents, affiliates, subsidiaries, and their related
            companies from claims, demands, and damages of every kind or nature
            arising out of, relating to, or in any way connected with such
            dispute.{" "}
          </p>
          <p>
            The Site may contain links to other Sites maintained by third
            parties. We do not operate nor control, in any way, or necessarily
            endorse the content found on these third-party Sites. You assume
            sole responsibility for your use of third-party links. We are not
            responsible for any content posted on third-party Sites or liable to
            you for any loss or damage of any sort incurred as a result of your
            dealings with any third-party or their Site.{" "}
          </p>
          <p>
            We hereby grant you a non-exclusive, limited license, revocable at
            our discretion, for you to link to the Site home page from any site
            you own or control that is not commercially competitive with the
            Sites and does not criticize or otherwise injure us, so long as the
            site where the link resides, and all other locations to which such
            site links, comply with all applicable laws and do not in any way
            abuse, defame, stalk, threaten, or violate the rights of privacy,
            publicity, intellectual property or other legal rights of others or,
            in any way, post, publish, distribute, disseminate or facilitate any
            inappropriate, infringing, defamatory, profane, indecent, obscene or
            illegal/unlawful information, topic, name or other material or that
            violates the spirit of our mission. Such a link is not an
            endorsement of such other site(s) by us. All of our rights and
            remedies are expressly reserved. Notwithstanding anything to the
            contrary contained in these Terms, we reserve the right to prohibit
            linking to the Site for any reason, without notice, in our sole and
            absolute discretion, even if the linking complies with the
            requirements described above.{" "}
          </p>
          <p>
            You must not establish a link in such a way as to suggest any form
            of association, approval, or endorsement on our part should none
            exist. You may not frame nor use framing techniques of this Site to
            enclose any part of the Site, nor any content accessible on it, nor
            publish a hypertext link to any part of it, without our express
            written consent. You must not establish a link from any Site that is
            not owned by you.{" "}
          </p>
          <h2>USER REGISTRATION</h2>
          <p>
            To access or use some or, potentially, all of the features of the
            Site, you may have to become a registered user. If you are under the
            age of twenty-one (21), then you are not permitted to register as a
            user or otherwise submit personal information to No Cap Vote.{" "}
          </p>
          <p>
            If you become a registered user, you will provide true, accurate,
            current and complete registration information about you as may be
            prompted by any registration forms and, if such information changes,
            you will promptly update the relevant registration information.
            During registration, you may be required to create a username and
            password (a "Membership"), which may permit you access to certain
            areas of the Sites not available to non-registered users. You are
            responsible for safeguarding and maintaining the confidentiality of
            your membership and for restricting access to your computer so that
            others may not access any password protected portion of the Site
            using your username in whole or in part. If you register with us,
            you agree to accept sole responsibility for all activities that
            occur under your membership, whether or not you have authorized the
            activity. You agree you will not sell, transfer or assign your
            membership or any membership rights. You agree to notify us
            immediately at{" "}
            <a href="mailto:info@nocapvote.com">
              <u>info@nocapvote.com</u>
            </a>{" "}
            of any breach of security or unauthorized use of your membership. We
            reserve the right to terminate your account or otherwise deny you
            access to the Site in our sole discretion for any or no reason
            without notice and without liability.
          </p>
          <h2>MOBILE</h2>
          <p>
            The Site may include certain features and services that may be
            available via your mobile phone, including, without limitation:{" "}
          </p>
          <ol>
            <li>
              the ability to upload to the Site via your mobile phone (Mobile
              Uploads);
            </li>
            <li>
              The ability to receive and reply to messages and to send content
              and messages using text messaging (Mobile Texts); and
            </li>
            <li>
              the ability to access the Site from your mobile phone (Mobile Web)
              (collectively, the "Mobile Services"). Typically, we do not charge
              for Mobile Services. Your carrier’s normal messaging, data and
              other rates and fees will, however, still apply. Your carrier may
              prohibit or restrict certain Mobile Services and certain Mobile
              Services may be incompatible with your carrier or mobile device.
              You should check with your carrier to find out what plans are
              available and how much they cost. By using the Mobile Services,
              you agree that we may communicate with you regarding the Site and
              our partners via SMS, MMS, text message or other electronic means
              to your mobile device and that certain information about your
              usage of the Mobile Services will be communicated to us.
            </li>
          </ol>
          <p>
            You agree that in connection with the Mobile Services for which you
            are registered for, we may send communications to your mobile device
            regarding us or other parties. Further, we may collect information
            related to your use of the Mobile Services. If you have registered
            for Mobile Services, you agree to notify us of any changes to your
            mobile number and update your account(s) on the Sites to reflect
            this change.{" "}
          </p>
          <h2>YOUR CONDUCT</h2>
          <p>
            You may use the Site only for lawful purposes. You may not use the
            Site in any way that breaches any applicable local, national or
            international law or regulation or in any way that is unlawful or
            fraudulent, or has any unlawful or fraudulent purpose or effect.{" "}
          </p>
          <p>
            You also agree not to access, without our authority, interfere with,
            damage or disrupt any part of the Site or any equipment or network
            on which the Site is hosted or any software used in the provision of
            the Site or any equipment, network or software owned or used by any
            third party. You are responsible for making the necessary
            arrangements for you to have access to the Site and ensuring that
            all persons who access the Site through your Internet connection are
            aware of these Terms.{" "}
          </p>
          <h2>MISUSE OF SITE </h2>
          <p>
            You must not misuse the Site by knowingly introducing viruses,
            trojans, worms, logic bombs or other material which is malicious or
            technologically harmful. You must not attempt to gain unauthorized
            access to the Site, the server on which the Site is stored, nor any
            server, computer or database connected to our Site. You must not
            attack our Site via a denial-of-service attack or a distributed
            denial-of service attack. By breaching this provision, you would
            commit a criminal offense under the Computer Misuse Act 1990. We
            will report any such breach to the relevant law enforcement
            authorities and will co-operate with those authorities by disclosing
            your identity to them. In the event of such a breach, your right to
            use the Site will cease immediately.{" "}
          </p>
          <p>
            You will not use spiders, robots, data mining techniques nor other
            automated devices or programs to catalog, download or otherwise
            reproduce, store or distribute content available on the Site. You
            will not use any such automated means to manipulate the Site nor
            attempt to exceed the limited authorization and access granted to
            you under these Terms. You will not resell use of, or access to, the
            Site to any third party.{" "}
          </p>
          <h2>INDEMNIFICATION</h2>
          <p>
            You acknowledge, understand, and agree to defend, indemnify and hold
            harmless No Cap Vote, LLC, and its officers, directors, employees,
            parents, partners, successors, agents, distribution partners,
            affiliates, subsidiaries, and their related companies from and
            against any and all claims, liabilities, losses, damages,
            obligations, costs and expenses (including reasonable attorneys’
            fees and costs) arising out of, related to, or that may arise in
            connection with:{" "}
          </p>
          <ol>
            <li>your access to or use of the Site;</li>
            <li>
              any actual or alleged violation or breach by you of any of these
              Terms;
            </li>
            <li>
              user content provided by you and through your use of your
              membership;
            </li>
            <li>
              your use or misuse of Site and/or materials or features available
              on the Site (except to the extent a claim is based upon
              infringement of a third party right by materials created by No Cap
              Vote;
            </li>
            <li>
              any actual or alleged breach of any misrepresentation, warranty,
              or covenant that you made to us; or
            </li>
            <li>
              violation by you of applicable law or any agreement or terms with
              a third party to which you are subject.
            </li>
          </ol>
          <h2>EXCLUSIONS, DISCLAIMERS AND WARRANTIES</h2>
          <p>
            You expressly agree that use of the Site is at your sole risk. The
            content in this Site are meant for information purposes only and is
            not meant to be relied upon or intended as financial, professional
            or legal advice, an offer or solicitation or giving any
            recommendation in relation to any service. While we have taken every
            reasonable precaution and care in compiling the information in this
            Site, to the fullest extent permissible by law, we disclaim any
            express or implied warranties or representations of any kind as to
            the accuracy or completeness of any content.{" "}
          </p>
          <p>
            We accept no liability for any inaccuracies, omissions, nor
            interruptions in your use of this Site. The Site is provided on an
            "as is" and "as available" basis, “with all faults” without any
            representation, endorsement, or warranty of any kind from us, either
            express or implied, and we reserve the right to suspend access to
            the Site without notice. Without limiting the foregoing and to the
            fullest extent permitted by law, No Cap Vote and its officers,
            directors, employees, parents, partners, successors, agents,
            distribution partners, affiliates, subsidiaries and the related
            companies disclaim any and all warranties including any:{" "}
          </p>
          <ol>
            <li>warranties that the Site will meet your requirements;</li>
            <li>
              warranties concerning the availability, accuracy, security,
              usefulness, timeliness, or information content of the Site or Site
              content;
            </li>
            <li>
              warranties of title, noninfringement, title, or misappropriation
              of intellectual property rights of third parties, custom, trade,
              quiet enjoyment, merchantability, or fitness for any particular
              purpose;
            </li>
            <li>
              warranties for services and good received through or advertised on
              our Site or accessed through the Site; and
            </li>
            <li>
              warranties concerning the accuracy or reliability of the results
              that may be obtained from the use of the Site.
            </li>
          </ol>
          <p>
            We do not promise that your use of this Site will be uninterrupted,
            timely, secure or error-free, that defects will be corrected or that
            the Site or the server, which makes it available, are free of
            viruses or bugs or are fully functional, accurate, or reliable. We
            do not accept any liability to you if the Site is delayed due to the
            non-availability or failure of any telecommunications or computer
            services, systems, equipment or software operated by you or any
            third party or any other event not reasonably within our control. We
            will not be liable for any loss or damage caused by a virus,
            distributed denial-of-service attack, or other technologically
            harmful material that may infect your computer equipment, computer
            programs, data or other proprietary material due to your use of our
            Site or to your downloading of any content on it, or on any Site
            linked to it. You are responsible for configuring your information
            technology, computer programs and platforms to access the Site. You
            should use your own virus protection software.{" "}
          </p>
          <h2>LIMITATION OF OUR LIABILITY</h2>
          <p>
            Our limits on liability are based on the fact that this Site is
            provided to you to view.{" "}
          </p>
          <p>
            Neither we nor any of our subsidiaries nor affiliates will be liable
            to any user for any loss or damage, whether in contract, tort
            (including negligence), in law or in equity, breach of statutory
            duty, or otherwise, even if foreseeable, arising out of, under, or
            in connection with:{" "}
          </p>
          <ol>
            <li>use of, or inability to use, our Site;</li>
            <li>
              use of or reliance on any content displayed on our Site; nor
            </li>
            <li>
              DIRECT, INDIRECT, SPECIAL, INCIDENTAL, OR OTHER CONSEQUENTIAL
              EXEMPLARY AND/OR PUNITIVE DAMAGES, LOST DATA, COSTS OF COVER,
              PERSONAL INJURY/WRONGFUL DEATH, EACH OF WHICH IS HEREBY EXCLUDED
              BY AGREEMENT OF THE PARTIES REGARDLESS OF WHETHER OR NOT WE HAVE
              BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. HOWEVER, NOTHING
              IN THESE TERMS SHALL LIMIT OR EXCLUDE EITHER PARTY’S LIABILITY FOR
              PERSONAL INJURY/WRONGFUL DEATH CAUSED BY NEGLIGENCE OR ANY
              LIABILITY WHICH MAY BE EXCLUDED OR LIMITED AS A MATTER OF LAW.
            </li>
          </ol>
          <p>
            If you are accessing this Site for personal or business use, we will
            not be liable for lost profits, sales, business, or revenue;
            business/personal interruption; loss of anticipated savings; lost
            data; lost business opportunities, goodwill nor reputation; wasted
            management or office time. We will not be liable for any direct,
            indirect, special, incidental, goodwill, use, intangible, or
            consequential loss or any other damages however arising from the use
            of this Site or any information obtained either directly or
            indirectly from this Site resulting from:{" "}
          </p>
          <ol>
            <li>the use or the inability to use the site;</li>
            <li>
              unauthorized access to or alteration of your transmissions or
              data; or
            </li>
            <li>
              any other matter relating to the site. Your sole remedy is to
              discontinue using this Site.
            </li>
          </ol>
          <h2>RIGHT TO SUSPEND OR TERMINATE</h2>
          <p>
            No Cap Vote reserves the right in its sole discretion to suspend,
            terminate, restrict, or block your access of the Site at any time,
            with or without notice, for any or no reason whatsoever. Without
            limitation of the foregoing, we may, in any event, suspend or
            terminate your use of the Site in the event you breach any provision
            of these terms.{" "}
          </p>
          <h2>SEVERABILITY</h2>
          <p>
            If any part of the Terms is deemed by any competent authority to be
            invalid, unlawful, void or for any reason unenforceable to any
            extent, then that provision shall be deemed to be severable from
            these Terms and shall not affect the validity and enforceability to
            the fullest extent permitted by law of any of the remaining
            provisions of the Terms. If we are otherwise found to be liable to
            you in any manner, then our aggregate liability for all claims under
            such circumstances for liabilities, shall not exceed the lesser of
            the amount paid by you for your use of the Site during the prior two
            (2) months.{" "}
          </p>
          <h2>GOVERNING LAW</h2>
          <p>
            You agree that these terms and any claim, dispute or controversy
            (whether in contract, tort or otherwise, whether preexisting,
            present or future, and including statutory, common law and equitable
            claims) between you and No Cap Vote arising from or relating to
            these terms, its application, interpretation or breach, termination
            or validity, the site, or any related transaction, shall, to the
            maximum extent permitted by applicable law, waive any right to trial
            by jury in any lawsuit, arbitration, or other proceeding. To the
            extent allowed by law, each of you and No Cap Vote waive any right
            to pursue disputes on a class-wide basis; that is, to either join a
            claim with a claim of any other person or entity or assert a claim
            in a representative capacity on behalf of anyone else in any
            lawsuit, arbitration or other proceeding. These terms require you to
            agree to arbitrate disputes rather than going to court, grant us
            certain rights and remedies, and limit our liability and obligations
            to you. Any claims or cause of action asserted by you, in connection
            with the Service, must be asserted in writing to No Cap Vote within
            one (1) year of the date such claim first arose, regardless of any
            statute or law to the contrary. In the event any such claim or cause
            of action is not filed within such one (1) year period, such claim
            or cause of action shall be forever barred. Each claim shall be
            adjudicated individually, and you agree not to combine your claim
            with the claim of any third party.{" "}
          </p>
          <p>
            The failure by us to partially or fully exercise any rights or the
            waiver of any breach of these Terms by you shall not prevent a
            subsequent exercise of such right by us or be deemed a waiver by us
            of any subsequent breach by you of the same or any other term or
            provisions of these Terms. Our rights and remedies under these Terms
            shall be cumulative, and the exercise of any such right or remedy
            shall not limit our right to exercise any other right or remedy.{" "}
          </p>
          <p>
            These terms set forth the entire understanding and agreement between
            you and No Cap Vote with respect to the subject matter hereof.{" "}
          </p>
          <h2>QUESTIONS OR COMMENTS </h2>
          <p>
            Should you have any questions regarding this Site, please contact us
            via email at{" "}
            <a href="mailto:info@nocapvote.com">
              <u>info@nocapvote.com</u>
            </a>
            . We will respond to you as soon as reasonably possible.{" "}
          </p>
        </div>
        <BackTop />
      </div>
    );
  }
}

export default TermsConditionsPage;
