import { useState, useContext } from "react";
import moment from "moment";
import config from "../../../config";
import { SignUpContext } from "../../../providers/SignupContext";
import axios from "axios";
import { message } from "antd";

const useBannerCalculation = () => {
  const { setProfileData, setLoading } = useContext(SignUpContext);

  const [state, setState] = useState({
    loading: false,
    ads: {},
    ad: [],
    selectedLocation: { formatted_address: "" },
    startValue: "",
    endValue: "",
    rateCard: [],
    rateCardMulti: [],
    typeKey: "",
    placementType: "",
    value: "",
    allLocationSelection: [],
    multiLocationCart: [],
    adsType: { type: "Select Page", weight: 1, key: "HHR" },
    showImageUploader: false,
    enableBannerDropdown: true,
    showNextDate: true,
    pageValue: 1,
    RATE: config.ADRATE,
    locationValue: 5,
    imageSize: "Please Select Page and Type",
  });

  // const setImageGuide = (type) => {
  //   let imageValue;
  //   if (type in imageSizes) {
  //     imageValue = `Recommended image size ${imageSizes[type]["w"]} x ${imageSizes[type]["h"]}`;
  //   } else {
  //     imageValue = "Please Select Page and Type";
  //   }

  //   setState((prev) => ({
  //     ...prev,
  //     imageSize: imageValue,
  //   }));
  // };

  const getValueFromForm = (e) => {
    let data = JSON.parse(e.target.value);
    let typeKey = data.acro;
    let weight = data.weight;
    let label = data.type;
    let stateValue = label + "Value";
    let valueName = data.type;
    let runTime = data.runTime;
    let cost = data.cost;
    let page = data.page;
    let typeName = data.type;
    let availableDate = moment(new Date()).format("dddd, MMMM Do YYYY");
    // await this.checkDateOverlap(typeKey, runTime);
    let bannerValues = {
      typeKey,
      weight,
      label,
      page,
      typeName,
      stateValue,
      valueName,
      runTime,
      cost,
      availableDate,
    };

    const startDate = new Date().setHours(0, 0, 0, 0);
    setState((prev) => ({
      ...prev,
      adsType: {
        weight,
        type: label,
        key: typeKey,
      },
    }));

    setProfileData((prev) => ({
      ...prev,
      bannerData: {
        adType: bannerValues.typeKey,
        typeName: bannerValues.typeName,
        adCost: bannerValues.cost,
        page: state.pageValue,
        type: bannerValues.label,
        start_date: startDate,
        runTime: bannerValues.runTime,
      },
    }));

    // await setImageGuide(typeKey);
  };

  const saveAds = async (ads) => {
    try {
      setLoading(true);
      const json = JSON.stringify(ads);
      await axios.post(
        `${config.ADS}/ads`,
        {},
        {
          headers: {
            doc: encodeURI(json),
            token: localStorage.getItem("access_token"),
          },
        }
      );
    } catch (error) {
      message.error("Something went wrong please try again");
      setLoading(false);
    }
  };

  return {
    state,
    setState,
    getValueFromForm,
    saveAds,
  };
};

export default useBannerCalculation;
