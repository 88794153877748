import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Table, Row, Col, Space, message, Tag, Card, Avatar,Input } from "antd";
import { UpOutlined, DownOutlined, PlusOutlined } from "@ant-design/icons";
import FeatureServiceCards from "../services/FeatureServiceCard";

import axios from "axios";
import moment from "moment";

import LocationInput from "../forms/LocationInput";
import { useBreakpoint } from "../../hooks/useWindowSize";
import config from "../../config";
import CandidatePreview from "../candidate/CandidatePreview";
import NoCapManifest from "../../nocap.json";


const Elections = () => {
  const [electionsData, setElectionsData] = useState([]);
  const [activeFilter, setActiveFilter] = useState("all");
  const [isLoading, setIsLoading] = useState(true);
  const [localState, setLocalState] = useState("");
  const [state, setState] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const { mobileView } = useBreakpoint();
  const [currentServiceCategory, setCurrentServiceCategory] = useState("");
  // MOCK DATA
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const isAdmin = user.role === "admin";

  useEffect(() => {
    // getCurrentLocation();
  }, []); // eslint-disable-line

  // async function getStateFromLatLng(lat, lng) {
  //   const { data: res } = await axios.get(
  //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
  //   );
  //   const address = res.results[0].address_components.filter((v) =>
  //     v.types.includes("administrative_area_level_1")
  //   );

  //   const addressState = address[0].long_name;
  //   return addressState;
  // }

  // function getCurrentLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       async (position) => {
  //         setIsLoading(true);
  //         const pos = {
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude,
  //         };

  //         const addressState = await getStateFromLatLng(pos.lat, pos.lng);

  //         setLocalState(addressState);
  //         setState(addressState);
  //         await getElectionsData();
  //         setIsLoading(false);
  //       },
  //       () => {
  //         // handleLocationError(true, infoWindow, map.getCenter());
  //       }
  //     );
  //   }
  // }

  let testService ={
    "_id":"6010d62e1259452fd239b08a",
    "organization_name": "General Technology",
    "location": [
      {
        "places_component": {
          "id": "c1dfab0a751bb47f0833f66e4bb784053cbb677b",
          "components": [
            {
              "long_name": "West Georgia Street",
              "short_name": "W Georgia St",
              "types": [
                "route"
              ]
            },
            {
              "long_name": "Los Angeles",
              "short_name": "Los Angeles",
              "types": [
                "locality",
                "political"
              ]
            },
            {
              "long_name": "Los Angeles",
              "short_name": "Los Angeles",
              "types": [
                "administrative_area_level_2",
                "political"
              ]
            },
            {
              "long_name": "Test State",
              "short_name": "CA",
              "types": [
                "administrative_area_level_1",
                "political"
              ]
            },
            {
              "long_name": "United States",
              "short_name": "TT",
              "types": [
                "country",
                "political"
              ]
            }
          ]
        },
        "geo": {
          "type": "Point",
          "coordinates": [
            -119,
            34
          ]
        },
        "hours": {},
        "formatted_address": "Test address",
        "location_id": {
          "$oid": "5da75f2a3a7b122ba67d6ad8"
        },
        "tags": []
      }
    ],
    "contact": {
      "emails": {
        "primary": "joe314@mailinator.com"
      },
      "phone": "4445011212"
    },
    "images": {
      "logo": "https://s3.amazonaws.com/dev.nocap.com/uploads/logo.png",
      "profile": "https://s3.amazonaws.com/dev.nocap.com/uploads/admanager/3.jpg"
    },
    "descriptors": {
      "website": "www.example.com",
      "categories": [
        "Service - Technology ∙ Software Solutions"
      ],
      "social": {
        "facebook": "",
        "instagram": "",
        "twitter": "",
        "youtube": ""
      }
    },
    "user_id": {
      "$oid": "5d4b01b2453dfd16fbe3a8d4"
    },
    "placement": "19",
    "rating": {
      "total": 0,
      "stars": 0
    },
    "voted_users": [
      {
        "user": {
          "$oid": "5df7fd548e4342642e31541d"
        },
        "rate": 5
      },
      {
        "user": {
          "$oid": "5df7fd548e4342642e31541e"
        },
        "rate": 3
      },
      {
        "user": {
          "$oid": "5df7fd548e4342642e31541f"
        },
        "rate": 4
      },
      {
        "user": {
          "$oid": "5df7fd548e4342642e31541c"
        },
        "rate": 5
      }
    ],
    "source": "mock-services"
  }

  async function getElectionsData() {
    try {
      let { data } = await axios.get(`${config.ELECTIONS}/all`);
      let elections = await Promise.all(
        data.map(async (v) => {
          let startDate = moment(v.startDate).format("MMM DD, YYYY");
          let endDate = moment(v.endDate).format("MMM DD, YYYY");
          let formattedDate = `${startDate} - ${endDate}`;

          let candidatesWithData =
            v?.candidates?.length > 0
              ? await getCandidatesData(v.candidates)
              : [];

          return {
            ...v,
            key: v._id,
            candidates: candidatesWithData,
            date: formattedDate,
          };
        })
      );

      setElectionsData(elections);
      setIsLoading(false);
    } catch (err) {
      message.error("Something is wrong!");
      console.log(err);
    }
  }

  async function getCandidatesData(ids) {
    if (ids?.length === 0) return [];
    let headers = {
      ids: JSON.stringify(ids),
      token: localStorage.getItem("access_token"),
    };
    const { data: candidateResultData } = await axios.get(
      `${config.CANDIDATES}/candidates/find-by-ids`,
      { headers }
    );

    return candidateResultData;
  }

  const changeCategory = (e, value) => {
    setCurrentServiceCategory(value);
  };

  useEffect(() => {
    getElectionsData();
  }, []); // eslint-disable-line

  const tableColumns = [
    {
      title: "Date",
      dataIndex: "date",
      width: 300,
    },
    {
      title: "State",
      dataIndex: "state",
      width: 200,
    },
    {
      title: "Description",
      dataIndex: "name",
    },
  ];

  if (isAdmin) {
    tableColumns.push({
      title: "",
      dataIndex: "operation",
      fixed: mobileView ? "right" : null,
      width: mobileView ? 60 : null,
      render: (_, b) => (
        <Space size="middle">
          <Link to={`/elections/edit/${b._id}`} className="election-action">
            <span>Edit</span>
          </Link>
          {/* <Popconfirm title="Are you sure?" onConfirm={() => {}}>
            <span
              className="election-action"
              style={{
                cursor: "pointer",
              }}
            >
              Delete
            </span>
          </Popconfirm> */}
        </Space>
      ),
    });
  }

  function isRowExpanded({ expanded, onExpand, record }) {
    const showIcon = record?.candidates.length > 0;
    if (!showIcon) return;
    return showIcon && expanded ? (
      <Button
        size="small"
        icon={<UpOutlined />}
        onClick={(e) => onExpand(record, e)}
      />
    ) : (
      <Button
        size="small"
        icon={<DownOutlined />}
        onClick={(e) => onExpand(record, e)}
      />
    );
  }

  async function findCandidatesByLocation(lat, lng) {
    setIsLoading(true);
    // const addressState = await getStateFromLatLng(lat, lng);
    //setState(addressState);
    setIsLoading(false);
  }

  let filteredData = electionsData;
  if (activeFilter === "close") {
    filteredData = electionsData.filter((v) => v.state.includes(localState));
  } else if (locationValue) {
    filteredData = electionsData.filter((v) => v.state.includes(state));
  }



  return (
    <div className="elections-container">
      {isAdmin && (
        <Link to="/elections/new" className="news-cta--btn-container">
          <Button
            block
            className="btn-bordered"
            icon={<PlusOutlined />}
            size="large"
          >
            Add Catalog
          </Button>
        </Link>
      )}
      <div className="election-action-btns" style={{ marginBottom: 16 }}>
        <Button
          onClick={() => {
            setLocationValue("");
            setActiveFilter("close");
          }}
          type={activeFilter === "close" && "primary"}
          style={{ marginRight: 8 }}
        >
          Live
        </Button>
        <Button
          onClick={() => {
            setActiveFilter("all");
            setLocationValue("");
          }}
          type={activeFilter === "all" && "primary"}
        >
          Catalog
        </Button>
      </div>
      {activeFilter === "close" && (
        <div>
        <div>
          <h3>Click to enjoy live contents</h3>
        </div>
        <Card hoverable bordered={false} className="DirectoryGridItem">
          <Link
            
          >
            <div className="wrapper">
              <Avatar size={400} src="https://s3.amazonaws.com/www.angelshabitats.org/uploads/angelsHabitatLogoTemp.png" />
            </div>

            <div className="card-body">
              <div style={{ textAlign: "center" }}>
                <h4 style={{ margin: "0", height: "18px" }}>Kniz.fm</h4>
              </div>
            </div>
          </Link>

        </Card>

        


        </div>
      )}

      {activeFilter === "all" && (
        <div>
        <div className="find-candidates-title-container">
          <h3>Search Catalog for contents</h3>
          <Input placeholder="Search catalog"/>
        </div>
        {NoCapManifest.services.coalitionCategories.map((c, i) => (
          <Tag
            key={c}
            value={c}
            onClick={(e) => changeCategory(e, c)}
            className={
              c === currentServiceCategory
                ? "home-tab-tag-selected"
                : "home-tab-tag"
            }
          >
            {c.substring("Coalition -".length)}
          </Tag>
           
        ))}
         <FeatureServiceCards service={testService} key={testService._id} />
        </div>

      )}
      {/* <Table
        className="elections-table"
        size="small"
        columns={tableColumns}
        loading={isLoading}
        dataSource={filteredData}
        scroll={{ x: 768 }}
        pagination={{
          defaultPageSize: 5,
        }}
        expandable={{
          rowExpandable: (record) => record?.candidates.length > 0,
          expandIcon: isRowExpanded,
          expandedRowRender: (record) => (
            <Row>
              {record.candidates.map((candidate, idx) => (
                <Col
                  key={candidate + idx}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  xl={6}
                  className="ant-card-grid-hoverable"
                >
                  <Link
                    key={`candidate ${idx}`}
                    to={{
                      pathname: `/candidate/${candidate["_id"]}`,
                      state: {
                        data: candidate,
                      },
                    }}
                  >
                    <CandidatePreview
                      details={candidate}
                      buttonVisibility={false}
                    />
                  </Link>
                </Col>
              ))}
            </Row>
          ),
        }}
      /> */}

      
    </div>
  );
};

export default Elections;
