import React, { Component } from "react";
import {
  Layout,
  DatePicker,
  Input,
  Button,
  Form,
  Row,
  Col,
  Table,
  message,
  BackTop,
  Divider,
  Tag
} from "antd";
import { Link } from "react-router-dom";
import config from "../../config";
import { GlobalOutlined, SearchOutlined } from "@ant-design/icons";
import json2csv from "json2csv";
import moment from "moment";
import axios from "axios";
import AdHelper from "../shared/ad/AdHelper";

export class EventsPage extends Component {
  state = {
    events: [],
    filteredEvents: {},
    loading: false,
    dateRange: [],
    coordinates: [],
    keywords: "",
    isAdmin: false,
    venueAutocomplete: null,
    venuePlace: "",
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    try {
      this.fetchEvents();
      this.venueAutocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById("places-autocomplete"),
        {}
      );
      this.venueAutocomplete.addListener(
        "place_changed",
        this.handlePlaceChanged
      );

      const usersResponse = await axios.get(`${config.USERS}/users`, {
        headers: { token: localStorage.getItem("access_token") },
      });
      // console.log(usersResponse.data, "user data");
      // check for administrator role for whether to show 'CREATE NEW POST'
      if (usersResponse.data && usersResponse.data.role === "admin") {
        this.setState({ isAdmin: true }, () => console.log("Admin is true"));
      }
    } catch (err) {
      console.log(
        err,
        `There was a problem connecting to the geolocation service.`
      );
    }
  }

  clearQueries = () => {
    document.getElementById("event-query-form").reset();
  };

  fetchEvents = async () => {
    this.setState({ loading: true }); // show the spinner
    const queryParams = [];
    // console.log("this.state.coordinates", this.state.coordinates);
    if (this.state.coordinates.length > 0)
      queryParams.push(
        `lat=${this.state.coordinates[0]}&lng=${this.state.coordinates[1]}`
      );
    if (this.state.dateRange.length > 0)
      queryParams.push(
        `start_date=${this.state.dateRange[0]}&end_date=${this.state.dateRange[1]}`
      );
    // Format the query parameters into a string and send make the request.
    const queryString = queryParams.join("&");
    try {
      const events = await axios.get(`${config.EVENTS}/events?${queryString}`);
      if (events.data.length) {
        this.setState({ events: events.data });
        message.info(`Found ${events.data.length} events.`);
      } else {
        message.info("None found.");
      }
      this.setState({ loading: false });
    } catch (err) {
      // console.log("events page err", err);
      message.error("There was a problem connecting to the network!");
    }
  };

  handlePlaceChanged = () => {
    const place = this.venueAutocomplete.getPlace();
    this.setState({
      venuePlace: place.formatted_address,
      coordinates: [
        place.geometry.location.lat(),
        place.geometry.location.lng(),
      ],
    });
  };

  handlePlaceInput = (event) => {
    this.setState({ venuePlace: event.target.value });
  };

  // - @Params(<moment>, <moment>])
  handleRangeChange = (range) => {
    if (range) {
      const dateRange = range.map((a) => a.format());
      this.setState({ dateRange });
    } else {
      this.setState({ dateRange: [] });
    }
  };

  handleKeywordsChange = (e) => {
    let keywords = e.target.value;
    this.setState({ keywords });
  };

  downloadEventsAsCSV = async () => {
    try {
      const res = await json2csv.parse(this.state.events, {});
      const data = new Blob([res], { type: "text/csv" });
      const csvURL = window.URL.createObjectURL(data);
      const tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute(
        "download",
        `nocap-eventlist-${moment().format("MM-DD-YYYY")}`
      );
      tempLink.setAttribute("target", "_blank");
      tempLink.click();
    } catch (err) {
      console.error(err);
    }
  };

  handleSubmit = () => {
    this.fetchEvents();
    this.clearQueries();
    this.setState({
      coordinates: [],
    });
  };

  render() {
    // filter remaining events by keywords
    const events = this.state.events;

    const re = new RegExp(this.state.keywords, "gi");
    const filteredEvents = this.state.keywords.length
      ? events.filter((event) => event.name.match(re))
      : events;

    return (
      <div className="EventsPage">
        <AdHelper page="events" />
        <div className="event-filters">
          <Form id="event-query-form">
            <Row gutter={24} style={{ margin: "12px auto" }}>
              <Col xs={24} md={5}>
                <div className="filter-row">
                  <Input
                    size="large"
                    style={{ zIndex: 1 }}
                    placeholder="enter event"
                    suffix={<SearchOutlined />}
                    onChange={this.handleKeywordsChange}
                  />
                </div>
              </Col>
              <Col xs={0} md={1} style={{ height: "30px", marginTop: "5px" }}>
                <Divider
                  type="vertical"
                  style={{ height: "100%", backgroundColor: "black" }}
                />
              </Col>
              <Col xs={24} md={0}>
                <Divider />
              </Col>
              <Col xs={24} md={{ span: 6 }}>
                <div className="filter-row">
                  <DatePicker.RangePicker
                    style={{ width: "100%", zIndex: 1 }}
                    onChange={this.handleRangeChange}
                    size="large"
                  />
                </div>
              </Col>
              <Col xs={24} md={6}>
                <div className="filter-row">
                  <Input
                    size="large"
                    style={{ zIndex: 1 }}
                    id="places-autocomplete"
                    onChange={(event) => this.handlePlaceInput(event)}
                    value={this.state.venuePlace}
                    onPressEnter={(e) =>
                      e.keyCode === 13 ? e.preventDefault() : ""
                    }
                    placeholder="Search locations"
                    suffix={<GlobalOutlined />}
                  />
                </div>
              </Col>
              <Col sm={24} md={6}>
                <div className="filter-row">
                  <Button
                    size="large"
                    style={{ width: "100%", zIndex: 1, margin: "inherit" }}
                    className="btn-bordered"
                    onClick={this.handleSubmit}
                    type="text"
                  >
                    Search
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
        <Layout>
          <Row>
            {/* Tablet and bigger screens */}
            <Col xs={0} sm={0} md={0} lg={3}>
              <AdHelper page="news-post" position="left" />
            </Col>
            <Col xs={24} md={24} lg={21}>
              <Layout.Content style={{ backgroundColor: "#ffffff" }}>
                <div className="events-wrapper">
                  <div style={{ marginBottom: "1em" }}>
                    {this.state.isAdmin && (
                      <Link className="link" to="/calendar/new">
                        CREATE NEW EVENT
                      </Link>
                    )}
                  </div>
                  <Table
                    loading={this.state.loading}
                    className="events-table"
                    onRow={(record) => ({
                      onClick: () => {
                        this.props.history.push(`/calendar/${record._id}`);
                      },
                    })}
                    rowKey={(record) => record._id}
                    columns={eventColumns}
                    dataSource={filteredEvents || null}
                    pagination={{ pageSize: 25 }}
                  />
                  <div style={{ textAlign: "center", margin: "30px auto" }}>
                    <Button
                      onClick={this.downloadEventsAsCSV}
                      className="btn-bordered"
                      type="text"
                    >
                      Download as .CSV
                    </Button>
                  </div>
                </div>
              </Layout.Content>
            </Col>
            {/* Mobile screens */}
            <Col xs={24} sm={24} md={0}>
              <AdHelper page="news-post" position="left" />
            </Col>
          </Row>
        </Layout>
        <BackTop />
      </div>
    );
  }
}

export default EventsPage;

const eventColumns = [
  {
    title: "Event",
    dataIndex: "name",
    key: "name",
    width: 150,
    sorter: (a, b) => {
      return a.name.localeCompare(b.name);
    },
  },
  {
    title: "Location",
    dataIndex: ["location", "formatted_address"],
    key: "location.formatted_address",
    width: 150,
    sorter: (a, b) => {
      return a.location.formatted_address.localeCompare(
        b.location.formatted_address
      );
    },
  },
  {
    title: "Start Date",
    dataIndex: ["dates", "start"],
    key: "start",
    width: 150,
    render: (date) => moment(date).format("MMMM DD, YYYY"),
    sorter: (a, b) => {
      return moment(a.dates.start).valueOf() - moment(b.dates.start).valueOf();
    },
  },
  {
    title: "End Date",
    dataIndex: ["dates", "end"],
    key: "end",
    width: 150,
    render: (date) => moment(date).format("MMMM DD, YYYY"),
    sorter: (a, b) => {
      return moment(a.dates.end).valueOf() - moment(b.dates.end).valueOf();
    },
  },
  {
    title: "Description",
    dataIndex: ["descriptors", "tags"],
    key: "tags",
    render: (tags) => (
      <span>
        {tags && tags.map((tag, index) => <Tag key={`${index}`}>{tag}</Tag>)}
      </span>
    ),
    width: 150,
  },
];
