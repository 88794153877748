import React, { useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  EnvironmentFilled,
  PhoneFilled,
  MailFilled,
  ClockCircleFilled,
  CheckCircleFilled,
} from "@ant-design/icons";
import {
  message,
  Space,
  Rate,
  Typography,
  Row,
  Col,
  Menu,
  Divider,
  BackTop,
  Empty,
} from "antd";
import axios from "axios";
import config from "../../../config";
import ProductGridItem from "../ProductGridItem";
import AdHelper from "../../shared/ad/AdHelper";
import VimeoVideo from "../../video/VimeoVideo";

const ServiceLayout = (props) => {
  const { id } = useParams();

  // Rating
  let ratingList = JSON.parse(localStorage.getItem("ratingList"))
    ? JSON.parse(localStorage.getItem("ratingList"))
    : { candidates: {}, services: {} };

  let initialRating = 0;
  let rated = false;

  if (ratingList.services && ratingList.services[id]) {
    initialRating = ratingList.services[id];
    rated = true;
  }

  const [rating, setRating] = useState(initialRating);
  const [average, setAverage] = useState(
    parseInt(props?.details?.rating?.stars || 0)
  );
  const [total, setTotal] = useState(
    parseInt(props?.details?.rating?.total || 0)
  );
  const [rateStatus, setRateStatus] = useState(rated);
  const [userRatingList, setUserRatingList] = useState(ratingList);

  const handleRating = async (value) => {
    if (!localStorage.getItem("userid")) {
      message.info(`Log in is required in order to rate the service.`);
    } else {
      message.loading("Action in progress..", 1);
      let newAverage = 0;
      let newTotal = total;
      let newRatingList = userRatingList;

      // First time rate the service
      if (!rateStatus) {
        newTotal = total + 1;
        newAverage = (average + value) / newTotal;
        setRateStatus(true);
        setTotal(newTotal);
        newRatingList.services[id] = value;
      } else {
        // Re-rate the service
        newAverage = (average * total - rating + value) / total;
        newRatingList.services[id] = value;
      }
      setUserRatingList(newRatingList);
      setRating(value);
      setAverage(newAverage);
      // Update user rating list array
      let userDoc = JSON.stringify({
        ratingList: userRatingList,
      });
      // Update service rating list array
      let serviceDoc = JSON.stringify({
        rating: {
          total: newTotal,
          stars: newAverage,
        },
      });
      await Promise.all([
        axios.patch(
          `${config.USERS}/users`,
          {},
          {
            headers: {
              token: localStorage.getItem("access_token"),
              doc: encodeURIComponent(userDoc),
            },
          }
        ),
        axios.patch(
          `${config.SERVICES}/services`,
          {},
          {
            headers: {
              service_id: id,
              isReview: true,
              token: localStorage.getItem("access_token"),
              doc: encodeURIComponent(serviceDoc),
            },
          }
        ),
      ]);
      localStorage.setItem("ratingList", JSON.stringify(newRatingList));
      message.success(
        `You gave ${props.details.organization_name} a rating of ${value}`
      );
    }
  };

  const [currentMenu, setCurrentMenu] = useState("about");

  let mapURL =
    props.details.contact && props.details.location[0].geo.coordinates
      ? `https://maps.googleapis.com/maps/api/staticmap?key=${config.GOOGLE_API_KEY}
      &size=300x222&zoom=12&format=png&maptype=roadmap&style=element:geometry%7Ccolor:0x212121&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x757575&style=element:labels.text.stroke%7Ccolor:0x212121&style=feature:administrative%7Celement:geometry%7Ccolor:0x757575&style=feature:administrative.country%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:administrative.land_parcel%7Cvisibility:off&style=feature:administrative.locality%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0x181818&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:poi.park%7Celement:labels.text.stroke%7Ccolor:0x1b1b1b&style=feature:road%7Celement:geometry.fill%7Ccolor:0x2c2c2c&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x8a8a8a&style=feature:road.arterial%7Celement:geometry%7Ccolor:0x373737&style=feature:road.highway%7Celement:geometry%7Ccolor:0x3c3c3c&style=feature:road.highway.controlled_access%7Celement:geometry%7Ccolor:0x4e4e4e&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:transit%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:water%7Celement:geometry%7Ccolor:0x000000&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x3d3d3d&size=480x360&markers=color%3awhite|${props.details.location[0].geo.coordinates[1]},${props.details.location[0].geo.coordinates[0]}`
      : null;
  const logoUrl = "https://s3.amazonaws.com/dev.nocap.com/uploads/logo.png";

  const hasAddress = props.details.location[0].formatted_address;
  const hasPhone = props.details.contact.phone;
  const hasEmail = props.details.contact.emails.primary;
  const hasWebsite = props.details.descriptors.website;
  return (
    <div className="ServiceDetailsPage big-bottom-padding">
      <div>
        <div className="service">
          <Row className="bg-uploader">
            <Col span={24}>
              <div
                className="details-bg"
                style={{
                  backgroundImage: `url(${props.details.images.profile})`,
                }}
              />
              <div className="brand-img-wrapper">
                <img
                  className="profile-img"
                  src={props.details.images.logo}
                  alt="brand"
                />
              </div>
            </Col>
          </Row>

          <Row className="details-menu" gutter={24}>
            <Col xs={24} md={{ span: 10, offset: 6 }}>
              {props.details.descriptors.categories[0].split(" ")[0] !==
              "Service" ? (
                <Menu
                  mode="horizontal"
                  onClick={(e) => setCurrentMenu(e.key)}
                  activeKey={currentMenu}
                >
                  <Menu.Item key="videos">Videos</Menu.Item>
                  <Menu.Item key="about">About</Menu.Item>
                  <Menu.Item key="products">Mission</Menu.Item>
                  <Menu.Item key="testimonials">Work</Menu.Item>
                </Menu>
              ) : (
                <Menu mode="horizontal" onClick={(e) => setCurrentMenu(e.key)}>
                  <Menu.Item key="videos">Videos</Menu.Item>
                  <Menu.Item key="about">About</Menu.Item>
                  <Menu.Item key="products">Products</Menu.Item>
                  <Menu.Item key="testimonials">Testimonials</Menu.Item>
                </Menu>
              )}
            </Col>
            <Col xs={24} md={8}></Col>
          </Row>

          <Row>
            <Col xs={24} md={6}>
              <div className="inner">
                <h2 className="organization">
                  {props.details.organization_name}
                </h2>
                <Row>
                  <Col xs={24} className="rating-container">
                    <Rate
                      style={{ color: "black" }}
                      value={average}
                      character={<CheckCircleFilled />}
                      onChange={(value) => handleRating(value)}
                    />
                    <Typography.Text
                      type="secondary"
                      style={{
                        marginLeft: 16,
                      }}
                    >
                      ({`${total}`})
                    </Typography.Text>
                  </Col>
                </Row>
                <Row>
                  {rateStatus ? (
                    <Space direction="vertical">
                      <Typography.Text type="secondary">{`- You rated: ${rating} -`}</Typography.Text>
                    </Space>
                  ) : null}
                </Row>

                {hasWebsite && (
                  <Col span={24} className="flex-col" style={{ marginTop: 8 }}>
                    <Typography.Text strong underline>
                      Website
                    </Typography.Text>
                    <a
                      style={{ wordBreak: "break-all" }}
                      href={
                        props.details.descriptors.website.includes("http")
                          ? props.details.descriptors.website
                          : `https://${props.details.descriptors.website}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {props.details.descriptors.website}
                    </a>
                  </Col>
                )}
                <div className="category-list">
                  {props.details.descriptors.amenities &&
                    props.details.descriptors.amenities.map((amenity) => (
                      <div key={amenity} className="amenity-list-item">
                        <span className="icon">
                          <img src={logoUrl} alt={amenity} />
                        </span>
                        <span className="capitalized name" key={amenity}>
                          &nbsp;{amenity}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <div className="service-ads" style={{ width: "200px" }}>
                <AdHelper page="news-post" position="left" />
              </div>
            </Col>
            <Col xs={24} md={18} lg={12} className="description">
              <div className="card-container">
                {currentMenu === "videos" && <VimeoVideo />}

                {currentMenu === "about" && (
                  <div className="inner service-description">
                    {props.details.descriptors.description ? (
                      props.details.descriptors.description
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </div>
                )}

                {currentMenu === "products" && (
                  <div className="inner">
                    {props.details.products &&
                    props.details.products.length !== 0 ? (
                      props.details.products.map((product, index) => (
                        <div className="products-grid">
                          <ProductGridItem key={index} product={product} />
                        </div>
                      ))
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </div>
                )}
                {currentMenu === "testimonials" && (
                  <div className="inner">
                    {props.details.testimonies &&
                    props.details.testimonies.length !== 0 ? (
                      props.details.testimonies.map((r) => (
                        <div
                          key={r.id}
                          style={{ textAlign: "center", marginBottom: "2em" }}
                        >
                          <div style={{ paddingBottom: ".7em" }}>
                            {r.description}
                          </div>
                          <div
                            style={{ fontStyle: "italic", fontSize: "12px" }}
                          >
                            --{r.author}
                          </div>
                          <div
                            style={{ marginTop: "1em" }}
                            className="divider div-transparent"
                          ></div>
                        </div>
                      ))
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </div>
                )}
              </div>
            </Col>
            <Col sm={24} lg={6}>
              <div className="service-details-list">
                <div className="inner">
                  <div className="service-details-list-wrapper">
                    <Row gutter={8}>
                      <Col xs={24} sm={24} md={12} lg={24}>
                        <img src={mapURL} alt="map" id="static-map" />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={24}>
                        {hasAddress && (
                          <div>
                            <EnvironmentFilled />
                            <span>
                              {props.details.location[0].formatted_address}
                            </span>
                          </div>
                        )}

                        {hasPhone && (
                          <div>
                            <PhoneFilled />
                            <a href={`tel:${props.details.contact.phone}`}>
                              {props.details.contact.phone}
                            </a>
                          </div>
                        )}

                        {hasEmail && (
                          <div>
                            <MailFilled />
                            <a
                              href={`mailto:${
                                props.details.contact.emails[
                                  Object.keys(props.details.contact.emails)[0]
                                ]
                              }`}
                            >
                              {
                                props.details.contact.emails[
                                  Object.keys(props.details.contact.emails)[0]
                                ]
                              }
                            </a>
                          </div>
                        )}

                        <Divider>
                          <ClockCircleFilled />
                        </Divider>
                        <div className="hours-list">
                          {props.details.location &&
                            props.details.location.hours &&
                            Object.keys(props.details.location.hours).map(
                              (key) => (
                                <Row key={key}>
                                  <Col xs={10} md={8}>
                                    <label>
                                      <strong>
                                        {
                                          props.details.location.hours[key]
                                            .weekday
                                        }{" "}
                                        :
                                      </strong>
                                    </label>
                                  </Col>
                                  <Col xs={14} md={16}>
                                    <span>
                                      {moment(
                                        props.details.location.hours[key].open
                                      ).format("h:mm a")}
                                    </span>
                                    &nbsp;<small>to</small>&nbsp;
                                    <span>
                                      {moment(
                                        props.details.location.hours[key].close
                                      ).format("h:mm a")}
                                    </span>
                                  </Col>
                                </Row>
                              )
                            )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <BackTop />
      </div>
    </div>
  );
};

export default ServiceLayout;
