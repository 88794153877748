import React, { useState } from "react";
import { Form, Input, Col, Select, Divider, Button } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import NoCapManifest from "../../../../nocap.json";
let categoryIndex = 0;

const Position = (props) => {
  const [category, setCategory] = useState("");
  const [categoryList, setCategoryList] = useState(
    NoCapManifest.candidateInfoForm.positionCategories
  );

  const positionCategorySelectOptions = categoryList.map((category, i) => {
    return (
      <Select.Option key={"category" + i} value={category}>
        {category}
      </Select.Option>
    );
  });

  const addCategory = (e) => {
    e.preventDefault();
    setCategory("");
    setCategoryList([
      ...categoryList,
      category || `New Category ${categoryIndex++}`,
    ]);
  };

  return (
    <Col span={24}>
      <Form.List name="positions">
        {(fields, { add, remove }) => {
          return (
            <Form.Item label="Advocacy">
              {fields.map((field, index) => (
                <div key={"position" + index}>
                  <Form.Item
                    required={false}
                    name={[field.name, "category"]}
                    fieldKey={[field.fieldKey, "category"]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Select
                      showSearch
                      placeholder="select category(ies)"
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              padding: 8,
                            }}
                          >
                            <Input
                              style={{ flex: "auto" }}
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                            />
                            <a
                              style={{
                                flex: "none",
                                padding: "8px",
                                display: "block",
                                cursor: "pointer",
                                color: "#8c8c8c",
                              }}
                              onClick={addCategory}
                              href="/"
                            >
                              <PlusOutlined /> Add
                            </a>
                          </div>
                        </div>
                      )}
                    >
                      {positionCategorySelectOptions}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={[field.name, "detail"]}
                    fieldKey={[field.fieldKey, "detail"]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input.TextArea autoSize={{ minRows: 3 }} allowClear placeholder="description"/>
                  </Form.Item>
                  {fields.length > 1 ? (
                    <Form.Item style={{ marginBottom: "0px" }}>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Form.Item>
                  ) : null}
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  <PlusOutlined /> Add field
                </Button>
              </Form.Item>
            </Form.Item>
          );
        }}
      </Form.List>
    </Col>
  );
};

export default Position;
