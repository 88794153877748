import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import Loading from "../Loading";
import ServiceLayout from "./serviceDetails/serviceLayout";
import config from "../../config";

const ServiceDetailsPage = () => {
  const { id } = useParams();

  function FetchInfo(url) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    async function get() {
      try {
        setLoading(true);
        const result = await axios(url);
        setData(result.data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }
    useEffect(() => {
      get();
    }, []); // eslint-disable-line
    return [data, error, loading];
  }
  const [data, error, loading] = FetchInfo(`${config.SERVICES}/services/${id}`);

  if (error)
    return message.error(
      "Oops ... Something went wrong, we could not load the information for this service"
    );
  let result = null;

  if (loading) {
    result = <Loading />;
  } else if (data && data !== null) {
    result = <ServiceLayout details={data} />;
  }
  return <>{result}</>;
};

export default ServiceDetailsPage;
