import React from "react";
import { Steps, Button, message, Row, Col, Tabs } from "antd";
import DescribeService from "./DescribeService";
import SelectSubscription from "./SelectSubscription";
import StripeCheckout from "../stripe/StripeCheckoutOnlyInject";
import ConfirmSubscription from "./ConfirmSubscription";
import InvoicePreview from "./InvoicePreview";
import Banners from "./Banners";
import Axios from "axios";
import Spinner from "../../Loading";
import config from "../../../config";
import FreeSubscriptionPayment from "./FreeSubscriptionPayment";
import CompletedModal from "./CompletedModal";
import { LoadingOutlined } from "@ant-design/icons";
const { Step } = Steps;
const { TabPane } = Tabs;

class OldOnBoarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      skipBanner: false,
      cartTotal: 0,
      touched: false,
      loading: false,
      pageLoad: false,
      // Service
      location: { formatted_address: "" },
      currentStep: 0,
      user_id: "",
      organization_name: "",
      coordinates: [],
      places_component: {},
      licenses: {},
      phone: "",
      referral: "",
      website: "",
      categories: [],
      amenities: [],
      description: "",
      storeHours: {},
      primaryEmail: "",
      emails: {},
      products: [],
      typeName: "",
      social: {
        facebook: "",
        instagram: "",
        twitter: "",
        youtube: "",
      },
      // Form helpers
      storeHour: {},
      license: {},
      email: "",
      logoImageUrl: "",
      bannerImageUrl: "",
      formatted_address: "",
      // bannerLocation: {formatted_address: ""},
      stepStatus: {
        membership: {},
        one: "",
        two: "",
        three: "",
        four: "",
        five: "",
        current: 0,
        completed: false,
        bannerData: { banner: false },
      },
      page: "",
      banner: false,
      termAgreement: false,
      term2Agreement: false,
      adImage: "",
      showCompletedModal: false,
      runTime: 30,
      adCost: 0,
      profileSubmited: {
        status: false,

        // id: service._id
      },
      paymentComplete: false,
      showUploader: false,
      stripe_name: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).nickname
        : "",
      stripe_email: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).email
        : "",
      bannerStartDate: "",
    };
  }
  formRef = React.createRef();
  bannerFormRef = React.createRef();
  async componentDidMount() {
    let status = JSON.parse(localStorage.getItem("onboard"));

    this.setState({ pageLoad: true });
    //check current step, bring in details from database for step

    // let page = values.page_choice
    // let type = this.state.adType
    // let cost = this.state.adCost
    // let image = this.state.adImage
    // let runTime = this.state.runTime

    await this.loadService();
    if (status) {
      this.setState({
        banner: status.banner ? status.banner : false,
        adCost:
          status.bannerData && status.bannerData.cost
            ? status.bannerData.cost
            : 0,
        adImage:
          status.bannerData && status.bannerData.image
            ? status.bannerData.image
            : "",
        runTime:
          status.bannerData && status.bannerData.runTime
            ? status.bannerData.runTime
            : "",
        paymentComplete: status.paymentComplete ? true : false,
        // page: status.bannerData && status.bannerData.page ? status.bannerData.page : "",
        // bannerLocation: status.bannerData && status.bannerData.location ? status.bannerData.location : "",
        stepStatus: status,
        current: this.props.profile ? 0 : status.current,
        skipBanner: false,
      });
    }
  }

  removeBannerData = () => {
    let status = { ...this.state.stepStatus };
    status.banner = false;
    status.bannerData = {
      banner: false,
    };

    this.setState(
      {
        banner: false,
        stepStatus: status,
      },
      () => {
        this.saveCompletedProfile("remove");
      }
    );
  };

  loadService = async () => {
    try {
      const response = await Axios.get(`${config.SERVICES}/service`, {
        headers: {
          token: localStorage.getItem("access_token"),
        },
      });
      if (response.status === 200 && response.data[0]) {
        const service = response.data[0];
        this.setState({
          profileSubmited: { status: true, service_id: service._id },
          referral: service.referral || "",
          bannerImageUrl: service.images.profile,
          logoImageUrl: service.images.logo,
          user_id: service.user_id,
          organization_name: service.organization_name,
          places_component: service.location[0].places_component,
          formatted_address: service.location[0].formatted_address,
          // coordinates: service.location.geo.coordinates,
          coordinates: service.location[0].geo.coordinates,
          primaryEmail: service.contact.emails.primary,
          location: service.location[0],
          licenses: service.licenses || {},
          phone: service.contact.phone,
          website: service.descriptors.website,
          locations: service.locations,
          categories: service.descriptors.categories,
          amenities: service.descriptors.amenities,
          description: service.descriptors.description,
          storeHours: service.location.hours || {},
          emails: service.contact.emails || {},
          social: service.descriptors.social || this.state.social,
          products: service.products || [],

          // memebership: service.onBoarding.membership || {name: "Sponsor", cost: 95},
          // stepStatus: service.onBoarding || {
          //   membership: {name: "Sponsor", cost: 95},
          //   one: '',
          //   two: '',
          //   three: '',
          //   four: '',
          //   five: '',
          //   current: 0
          // }
        });
      }
      this.setState({ pageLoad: false });
    } catch (err) {
      console.log(err);
    }
  };

  setCheckbox = (e) => {
    // e.preventDefault()
    // e.stopPropagation()
    // const state = { ...this.state.stepStatus };
    const name = e.target.name;

    this.setState({ [name]: !this.state[name] });
  };

  handleStandardInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({ [name]: value });
  };

  addMemebershipCost = (membership) => {
    let checkMembership =
      membership === "Premium" ? 195 : membership === "Sponsor" ? 95 : 0;

    const onBoarding = { ...this.state.stepStatus };
    onBoarding.membership = {
      name: membership,
      cost: checkMembership,
    };
    this.setState({
      stepStatus: onBoarding,
    });
  };

  saveCurrentStep = async () => {
    const stepStatus = { ...this.state.stepStatus };
    // this.setState({ loading: true });
    if (this.state.current === 0) {
      //  validate all fields
      this.formRef.current
        .validateFields()
        .then((values) => {
          this.saveService();
        })
        .catch((error) =>
          message.warning("Please fill in all required fields")
        );
      this.setState({ current: 1 });
    }

    if (this.state.current === 1) {
      stepStatus.two = "finish";
      if (this.state.stepStatus.membership.name === "Free") {
        stepStatus.current = 3;
        this.setState(
          {
            stepStatus,
          },
          () => {
            this.saveStepData(3);
          }
        );
      } else {
        stepStatus.current = 2;
        this.setState(
          {
            stepStatus,
          },
          () => {
            this.saveStepData();
          }
        );
      }
    }

    if (this.state.current === 2) {
      if (this.state.skipBanner) {
        this.next();
      }
      this.setState({ loading: false });

      //IF FREE MEMBERSHIP IS SELECT DO NOT ALLOW SET UP
      //if checked changed banners to true
      // if false and subscription free skip payment
    }

    if (this.state.current === 3) {
      if (
        this.state.termAgreement === false ||
        this.state.term2Agreement === false
      ) {
        message.error(
          "Please agree to terms and conditions before continuing."
        );
        // this.next()
      } else {
        //AD LOGIC FOR BANNER SUBMIT HERE
        this.next();
      }
      this.setState({ loading: false });
    }
    if (this.state.current === 4) {
      //run trascation at this point if paid membership
      //ADD TYPE PAID/FREE AFTER PAYMENT
    }
  };

  handleSubmitBanner = async (e) => {
    const image = this.state.adImage;
    if (image.length === 0) {
      message.error("Please upload image for ad");
    }
    if (image.length > 0) {
      if (this.bannerFormRef.current) {
        const adFields = ["places-autocomplete", "page_choice"];

        let validation = await this.bannerFormRef.current
          .validateFields(adFields)
          .then((values) => {
            this.setState({ page: values.page_choice }, () => {
              this.saveBannerInReserve();
            });
            message.success("Banner added to account");
            return true;
          })
          .catch((error) => {
            message.error("Please complete banner or choose 'Skip this step'.");
            return false;
          });
        return validation;
      } else {
        this.saveBannerInReserve();
        message.success("Banner added to account");
      }
    }
  };

  setBannerData = (values) => {
    this.setState(
      {
        adType: values.typeKey,
        typeName: values.typeName,
        adCost: values.cost,
        page: this.state.page,
        type: values.label,
        start_date: this.state.bannerStartDate,
        //  typeName: values.typeName,
        //  adImage:

        runTime: values.runTime,
      },
      () => {
        console.log(
          "BANNER DATA STATE",
          this.state.adCost,
          this.state.adType,
          this.state.typeName,
          this.state.runTime
        );
      }
    );

    // typeKey,
    // weight,
    // label,
    // stateValue,
    // valueName,
    // runTime,
    // cost,
  };

  getBannerDate = (value) => {
    this.setState({ bannerStartDate: value });
  };

  saveBannerInReserve = (values) => {
    //ADD BANNER TRUE ON SUBMIT TO ACCOUNT
    let page = this.state.page
      ? this.state.page
      : this.state.stepStatus.bannerData.page;
    //let placeID = this.state.locationSet ? this.state.newLocation.places_component.place_id : this.state.location.places_component.place_id
    let type = this.state.adType;
    let typeName = this.state.typeName
      ? this.state.typeName
      : this.state.stepStatus.bannerData.typeName;
    let cost = this.state.adCost;
    let image = this.state.adImage;
    let runTime = this.state.runTime;
    let location = this.state.locationSet
      ? this.state.newLocation
      : this.state.location;
    let stepData = { ...this.state.stepStatus };

    console.log("LOCATION ONBOARD", location);

    stepData.current = 3;
    stepData.three = "finish";
    stepData.banner = true;

    stepData.bannerData = {
      banner: true,
      page: page,
      type: type,
      cost: cost,
      image: image,
      location: location,
      runTime: runTime,
      typeName: typeName,
      bannerStartDate: this.state.bannerStartDate,
    };
    this.setState(
      {
        bannerType: "bht",
        banner: true,
        stepStatus: stepData,
      },
      () => {
        this.saveStepData();
      }
    );

    //POST TO ACCOUNT AND SAVE BANNER DETAILS
  };

  handleImageUpload = (url) => {
    this.setState({ showImage: true, adImage: url });
  };

  // buildAd = async (values, state) => {
  //   console.log(this.state.location)
  //   let userId = localStorage.getItem("userid")
  //   // console.log(, "SubmittedStart")
  //   console.log(this.props.startDate.setHours(0,0,0), "SubmittedEnd")

  //   let dayStart =  new Date(this.props.startDate.setHours(0,0,0))
  //   let dayEnd = new Date(this.props.endDate.setHours(23,59,59))
  //   console.log(dayStart, "daystart")

  // const ad = {
  //   user_id: userId,
  //   location: { places_component : {
  //               id: this.props.location.places_component.id,
  //               place_id: this.props.location.places_component.place_id,
  //               components: this.props.location.places_component.address_components,
  //             },
  //     formatted_address: this.props.location.formatted_address,
  //     coordinates: {
  //       lng: this.props.location.coordinates.lng,
  //       lat: this.props.location.coordinates.lat},
  //       geo : {
  //         "type" : "Point",
  //         "coordinates" : [
  //           this.props.location.coordinates.lng,
  //           this.props.location.coordinates.lat
  //         ]
  //       }
  //   },
  //   category: "image",
  //   // level: "sponsor",
  //   // plan_id: "sponsor",
  //   type_key : this.props.typeKey,
  //   image: this.props.adImage,
  //   type: values.type,
  //   page: values.page,
  //   start_date: dayStart.toISOString(),
  //   end_date: dayEnd.toISOString(),
  //   cost: this.props.getCostForForm(),

  //   }
  //   console.log(ad, "built ad")
  // return ad
  // }

  setCurrent = (num) => {
    this.setState({ current: num });
  };

  saveStepData = async (stepValue) => {
    let doc = {
      onBoarding: { ...this.state.stepStatus },
    };
    const response = await Axios.patch(
      `${config.USERS}/users`,
      {},
      {
        headers: {
          token: localStorage.getItem("access_token"),
          doc: encodeURIComponent(JSON.stringify(doc)),
        },
      }
    );
    if (response.status === 200) {
      this.setState({ loading: false });
      localStorage.setItem("onboard", JSON.stringify(this.state.stepStatus));
      this.next(stepValue);
    } else {
      message.error("Something went wrong please try again");
    }
  };

  paymentCompleted = () => {
    let steps = { ...this.state.stepStatus };
    steps.paymentComplete = true;
    steps.completed = true;
    steps.four = "finish";
    steps.five = "finish";

    this.setState(
      {
        paymentComplete: true,
        stepStatus: steps,
      },
      () => {
        this.saveCompletedProfile();
      }
    );

    //SAVE IN USER PROFILE
    // RECHECK BANNER BEFORE PAYMENT AND SEND TO DB HERE AFTER PAYMENT
  };

  doneOnboarding = () => {
    if (this.state.stepStatus.membership.name === "Free") {
      this.saveCompletedProfile();
      this.setState({ showCompletedModal: true }, () =>
        console.log("SET TO", this.state.showCompletedModal)
      );
    } else {
      if (this.state.paymentComplete) {
        this.setState({ showCompletedModal: true });
        //save banner here
      } else {
        message.error("Please complete payment to continue");
      }
      //TAKE PAYMENT AND CONFIRM BANNER
    }
  };

  saveCompletedProfile = async (type) => {
    let status = { ...this.state.stepStatus };
    if (!type) {
      status.completed = true;
    }
    let doc = {
      onBoarding: status,
    };
    const response = await Axios.patch(
      `${config.USERS}/users`,
      {},
      {
        headers: {
          token: localStorage.getItem("access_token"),
          doc: encodeURIComponent(JSON.stringify(doc)),
        },
      }
    );
    if (response.status === 200) {
      this.setState({ loading: false });
      localStorage.setItem("onboard", JSON.stringify(status));
    } else {
      message.error("Something went wrong please try again");
    }
  };

  next = (custom) => {
    const current = custom ? custom : this.state.current + 1;

    this.setState({ current });
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  // Change Buisness

  saveService = async () => {
    const service = this.parseServiceFromState();
    let changeStep = { ...this.state.stepStatus };
    changeStep.one = "finish";
    changeStep.current = 1;
    this.setState({
      stepStatus: changeStep,
    });

    const response = this.state.profileSubmited.status
      ? await Axios.patch(
          `${config.SERVICES}/services`,
          { doc: encodeURIComponent(JSON.stringify(service)) },
          {
            headers: {
              token: localStorage.getItem("access_token"),
            },
          }
        )
      : await Axios.post(
          `${config.SERVICES}/services/insert`,
          JSON.stringify(service),
          {
            headers: {
              token: localStorage.getItem("access_token"),
            },
          }
        );
    if (response.status === 200) {
      this.setState({ touched: false, loading: false });
      this.state.profileSubmited.status
        ? message.success("Your profile has been saved!")
        : message.success("Your profile has been created!");
      window.scrollTo(0, 0);
      this.saveStepData();
    } else {
      message.error(
        "Something went wrong, if you continue to get this message please contact support"
      );
      this.setState({ touched: false, loading: false });
    }
  };

  parseServiceFromState = () => {
    let user_id = localStorage.getItem("userid");
    const {
      organization_name,
      coordinates,
      places_component,
      categories,
      amenities,
      description,
      licenses,
      referral,
      phone,
      website,
      storeHours,
      logoImageUrl,
      bannerImageUrl,
      social,
      formatted_address,
      primaryEmail,
    } = this.state;
    let emailAlt = this.state.emails;
    // build the Document
    let emails = {
      primary: primaryEmail,
      ...emailAlt,
    };
    const contact = {
      emails,
      phone,
    };

    const location = [
      {
        contact,
        tags: [],
        places_component,
        geo: {
          type: "Point",
          coordinates,
        },
        hours: storeHours,
        formatted_address,
      },
    ];

    const images = {
      logo: logoImageUrl,
      profile: bannerImageUrl,
    };
    const descriptors = {
      website,
      categories,
      amenities,
      description,
      social,
    };

    const service = {
      referral,
      contact,
      user_id,
      images,
      descriptors,
      organization_name,
      location,
      licenses,
    };
    return service;
  };

  setAdType = (adType, adCost) => {
    this.setState({
      adType: adType,
      adCost: adCost,
    });
  };

  handleBannerUpload = (url) => this.setState({ bannerImageUrl: url });

  removeBanner = () => this.setState({ bannerImageUrl: "" });

  handleCategoriesChanged = (categories) => {
    if (this.state.categories.length <= 1) {
      this.setState({ categories, touched: true });
    } else {
      message.warning(
        "If there are more service categories, please contact No Cap Vote"
      );
    }
  };

  handleAmenitiesChanged = (amenities) =>
    this.setState({ amenities, touched: true });

  handleInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const state = { ...this.state.service };
    state[name] = value;
    this.setState({ ...state, touched: true });
  };

  handleLicenseTypeChange = (value) => {
    const license = { ...this.state.license };
    license.type = value;
    this.setState({ license });
  };

  handleLicenseInputChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    const license = { ...this.state.license };
    license[name] = value;
    this.setState({ license });
  };

  handleLicenseCityChange = (value) => {
    const license = { ...this.state.license };
    license.city = value;
    this.setState({ license });
  };

  handleLicenseNumberChange = (value) => {
    const license = { ...this.state.license };
    license.number = value;
    this.setState({ license });
  };

  handleStoreHourOpenChange = (momentObj) => {
    const storeHour = { ...this.state.storeHour };
    storeHour.open = momentObj;
    this.setState({ storeHour });
  };

  handleStoreHourCloseChange = (momentObj) => {
    const storeHour = { ...this.state.storeHour };
    storeHour.close = momentObj;
    this.setState({ storeHour });
  };

  handleStoreHourWeekdayChange = (day) => {
    const storeHour = { ...this.state.storeHour };
    storeHour.weekday = day;
    this.setState({ storeHour });
  };

  handleSocialChange = (event) => {
    const host = event.target.name;
    const value = event.target.value;
    const change = { [host]: value };
    this.setState({
      social: { ...this.state.social, ...change },
      touched: true,
    });
  };

  handleLicenseChange = (event) => {
    this.setState({
      license: event.target.value,
      touched: true,
    });
  };

  handleEmailChange = (event) => {
    // this.formRef.current.setFieldsValue({ email: event.target.value });
    this.setState({
      email: event.target.value,
      touched: true,
    });
  };

  addLicense = (e) => {
    const licenses = { ...this.state.licenses };
    licenses[`license${Date.now()}`] = this.state.license;
    this.setState({ licenses, touched: true });
  };

  removeLicense = (e) => {
    const licenses = { ...this.state.licenses };
    delete licenses[e.target.id];
    this.setState({ licenses, touched: true });
  };

  addEmail = (e) => {
    const emails = { ...this.state.emails };
    emails[`email${Date.now()}`] = this.state.email;
    this.setState({ emails, email: "", touched: true });
  };

  removeEmail = (e) => {
    const emails = { ...this.state.emails };
    delete emails[e.target.id];
    this.setState({ emails, touched: true });
  };

  addStoreHour = (e) => {
    const storeHours = { ...this.state.storeHours };
    const storeHour = this.state.storeHour;
    if (!storeHour.weekday || !storeHour.open || !storeHour.close) return;
    storeHours[`${storeHour.weekday}`] = storeHour;
    this.setState({ storeHours, touched: true });
  };

  removeStoreHour = (e) => {
    const storeHours = { ...this.state.storeHours };
    delete storeHours[e.target.id];
    this.setState({ storeHours, touched: true });
  };

  handlePlaceChanged = (place) => {
    this.formRef.current.setFieldsValue({
      "company address": place.formatted_address,
    });

    this.setState({
      places_component: {
        id: place.id,
        components: place.address_components,
        place_id: place.place_id,
      },
      formatted_address: place.formatted_address,
      coordinates: [
        place.geometry.location.lng(),
        place.geometry.location.lat(),
      ],
      touched: true,
      locationSet: true,

      newLocation: {
        places_component: {
          id: place.id,
          components: place.address_components,
        },
        formatted_address: place.formatted_address,
        coordinates: [
          place.geometry.location.lng(),
          place.geometry.location.lat(),
        ],
        geo: {
          type: "Point",
          coordinates: [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ],
        },
      },
    });
  };

  handleBannerLocation = (place) => {
    this.bannerFormRef.current.setFieldsValue({
      location: place.formatted_address,
    });

    this.setState({
      locationSet: true,

      newLocation: {
        places_component: place.places_component,
        formatted_address: place.formatted_address,
        coordinates: [place.geo.coordinates.lng, place.geo.coordinates.lat],
        geo: {
          type: "Point",
          coordinates: [place.geo.coordinates.lng, place.geo.coordinates.lat],
        },
      },
    });
  };

  handleLogoUpload = (url) => {
    this.setState({ logoImageUrl: url });
  };

  skipBanners = (e) => {
    this.setState({ skipBanner: e });
  };

  handleTabChange = (key) => {
    let current = 0;
    switch (key) {
      case "Description":
        current = 0;
        break;
      case "Ad Impressions":
        current = 1;
        break;
      case "Testimonial":
        current = 2;
        break;
      case "Plans":
        current = 3;
        break;
      case "Banners":
        current = 4;
        break;
      case "Payment":
        current = 5;
        break;
      default:
        current = 0;
        break;
    }
    this.setState({ current });
  };

  render() {
    const { current } = this.state;
    const parsedService = this.parseServiceFromState();
    const steps = [
      {
        title: "Description",
        subtitle: "This information will be used for your public profile. ",
        content: (
          <DescribeService
            // service={this.state.service}
            // services={this.props.services}
            form={this.formRef}
            handleLogoUpload={this.handleLogoUpload}
            primaryEmail={this.state.primaryEmail}
            history={this.props.history}
            initialSetUp={this.state.initialSetUp}
            logoImageUrl={this.state.logoImageUrl}
            bannerImageUrl={this.state.bannerImageUrl}
            license={this.state.license}
            licenses={this.state.licenses}
            storeHour={this.state.storeHour}
            email={this.state.email}
            emails={this.state.emails}
            service={parsedService}
            handleAmenitiesChanged={this.handleAmenitiesChanged}
            handleCategoriesChanged={this.handleCategoriesChanged}
            handleBannerUpload={this.handleBannerUpload}
            // removeBanner={this.removeBanner}
            handleInputChange={this.handleInputChange}
            handleStoreHourOpenChange={this.handleStoreHourOpenChange}
            handleStoreHourCloseChange={this.handleStoreHourCloseChange}
            handleStoreHourWeekdayChange={this.handleStoreHourWeekdayChange}
            handleSocialChange={this.handleSocialChange}
            handleLicenseTypeChange={this.handleLicenseTypeChange}
            handleLicenseInputChange={this.handleLicenseInputChange}
            handleEmailChange={this.handleEmailChange}
            addLicense={this.addLicense}
            removeLicense={this.removeLicense}
            addEmail={this.addEmail}
            removeEmail={this.removeEmail}
            addStoreHour={this.addStoreHour}
            removeStoreHour={this.removeStoreHour}
            handlePlaceChanged={this.handlePlaceChanged}
            // saveService={this.saveService}
            loadService={this.loadService}
          />
        ),
        status: this.state.stepStatus.one,
        heading: "Describe your Service",
      },
      {
        title: "Plans",
        subtitle:
          "This plan is a monthly fee that will occur on the same day of the month, every month.",
        content: (
          <SelectSubscription
            addMemebershipCost={this.addMemebershipCost}
            currentMembership={this.state.stepStatus.membership}
          />
        ),
        status: this.state.stepStatus.two,
        heading: "select Plan",
      },
      {
        title: "Banners",
        subtitle: `Purchase your banner placement to "promoting" immediateley!`,
        content: (
          <Banners
            setBannerData={this.setBannerData}
            // bannerLocation={this.state.bannerLocation}
            adImage={this.state.adImage}
            handleBannerLocation={this.handleBannerLocation}
            newLocation={this.state.newLocation}
            locationSet={this.state.locationSet}
            getBannerDate={this.getBannerDate}
            setAdType={this.setAdType}
            form={this.bannerFormRef}
            skipBanners={this.skipBanners}
            skipBanner={this.state.skipBanner}
            location={this.state.location}
            currentMembership={this.state.stepStatus.membership}
            typeKey={this.state.typeKey}
            runTime={this.state.runTime}
            handleStandardInputChange={this.handleStandardInputChange}
            handleImageUpload={this.handleImageUpload}
            page={this.state.page}
            banner={this.state.banner}
            stepStatus={this.state.stepStatus}
            removeBannerData={this.removeBannerData}
            adCost={this.state.adCost}

            // type={this.state.typeName}
          />
        ),
        heading: "Banner Advertisements",
        status: this.state.stepStatus.three,
      },
      {
        title: "Agreement",
        subtitle:
          "Please review the terms and conditions. If you agree to the terms and conditions, please check the boxes below.",
        content: (
          <ConfirmSubscription
            setCheckbox={this.setCheckbox}
            termAgreement={this.state.termAgreement}
            term2Agreement={this.state.term2Agreement}
          />
        ),
        heading: "Agree To Terms",
        status: this.state.stepStatus.five,
      },
      {
        title: "Payment",
        subtitle: "Enter your payment details to start your subscription ",
        content:
          this.state.stepStatus.membership.name === "Free" ? (
            <FreeSubscriptionPayment />
          ) : (
            <StripeCheckout
              paymentComplete={this.state.paymentComplete}
              setCurrent={this.setCurrent}
              removeBannerData={this.removeBannerData}
              paymentCompleted={this.paymentCompleted}
              stepStatus={this.state.stepStatus}
              bannerCost={this.state.adCost}
              handleStandardInputChange={this.handleStandardInputChange}
              name={this.state.stripe_name}
              email={this.state.stripe_email}
            />
          ),
        heading: "Enter Payment Details",
        status: this.state.stepStatus.four,
      },
    ];

    if (this.props.profile) {
      steps.splice(5, 1);
    }
    return (
      <div className="OnboardingContainer">
        <CompletedModal
          show={this.state.showCompletedModal}
          accountStatus={this.state.stepStatus.membership.name}
        />
        <Row gutter={8}>
          <Col
            span={!this.props.profile ? 18 : 24}
            xs={24}
            sm={24}
            md={!this.props.profile ? 18 : 24}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {this.props.profile ? (
                <Tabs
                  defaultActiveKey="1"
                  onChange={this.handleTabChange}
                  centered
                >
                  {steps.map((item) => {
                    return (
                      <TabPane tab={item.title} key={item.title}>
                        <div>
                          <h1
                            className="onboard-heading"
                            style={{ textAlign: "center" }}
                          >
                            {steps[current].heading}
                          </h1>
                          <div
                            style={{ textAlign: "center", fontStyle: "italic" }}
                          >
                            {steps[current].subtitle}{" "}
                          </div>

                          <div style={{ marginTop: "2em" }}>
                            {steps[current].content}
                          </div>
                        </div>
                      </TabPane>
                    );
                  })}
                </Tabs>
              ) : (
                <>
                  <Steps current={current}>
                    {steps.map((item) => (
                      <Step
                        key={item.title}
                        title={item.title}
                        status={item.status}
                      />
                    ))}
                  </Steps>
                  <div className="steps-action">
                    {current > 0 && (
                      <Button
                        className="btn-bordered"
                        style={{ marginLeft: 8 }}
                        onClick={() => this.prev()}
                      >
                        Previous
                      </Button>
                    )}
                    {current < steps.length - 1 && (
                      <Button
                        className="btn-solid"
                        style={{ float: "right", width: "7em" }}
                        onClick={this.saveCurrentStep}
                      >
                        {this.state.loading ? (
                          <LoadingOutlined style={{ fontSize: `12px` }} />
                        ) : (
                          "Next"
                        )}
                      </Button>
                    )}
                    {current === steps.length - 1 && (
                      <Button
                        className="btn-solid"
                        style={{ float: "right" }}
                        onClick={this.doneOnboarding}
                      >
                        Done
                      </Button>
                    )}
                  </div>
                  {this.state.pageLoad ? (
                    <Spinner size={59} />
                  ) : (
                    <div>
                      <h1
                        className="onboard-heading"
                        style={{ textAlign: "center" }}
                      >
                        {steps[current].heading}
                      </h1>
                      <div style={{ textAlign: "center", fontStyle: "italic" }}>
                        {steps[current].subtitle}{" "}
                      </div>

                      <div style={{ marginTop: "2em" }}>
                        {steps[current].content}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            {!this.props.profile ? (
              <div className="steps-action" style={{ height: "4em" }}>
                {current > 0 && (
                  <Button
                    className="btn-bordered"
                    style={{ marginLeft: 8 }}
                    onClick={() => this.prev()}
                  >
                    Previous
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button
                    className="btn-solid"
                    style={{ float: "right", width: "7em" }}
                    onClick={this.saveCurrentStep}
                  >
                    {this.state.loading ? (
                      <LoadingOutlined style={{ fontSize: `12px` }} />
                    ) : (
                      "Next"
                    )}
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button
                    className="btn-solid"
                    style={{ float: "right" }}
                    onClick={this.doneOnboarding}
                  >
                    Done
                  </Button>
                )}
              </div>
            ) : null}
          </Col>
          {!this.props.profile ? (
            <Col span={6} xs={24} sm={24} md={6}>
              <InvoicePreview
                cartTotal={this.state.cartTotal}
                membership={this.state.stepStatus.membership}
                banner={this.state.banner}
                bannerCost={this.state.adCost}
                runTime={this.state.runTime}
              />
            </Col>
          ) : null}
        </Row>
      </div>
    );
  }
}

export default OldOnBoarding;
