import React from "react";
import { Form, Input, Col, Button } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const Family = ({ formValues }) => {
  return (
    <Form.Item label="Family">
      <div

      >
      <Form.List name="spouses">
        {(fields, { add, remove }) => {
          const spouses = formValues?.spouses || [];
          const enableBtn =
            spouses.length > 0
              ? spouses.every(
                (v) =>
                  v?.spouse && v?.noOfChildren
              )
              : true;
              return (
                <Form.Item >
                  {fields.map((field, index) => (
                    <div key={"spouse" + index} className="flex-row" style={{ justifyContent: "space-between", gap: 16 }}>
                      <Form.Item
                        label="Spouse"
                        name={[field.name, "spouse"]}
                        style={{ width: "100%" }}
                      >
                        <Input placeholder="Please enter name" />
                      </Form.Item>

                      <Form.Item
                        label="Number of Children"
                        name={[field.name, "noOfChildren"]}
                        style={{ width: "100%" }}
                      >
                        <Input min={0} autoSize allowClear type="number" />
                      </Form.Item>

                      {fields.length > 1 ? (
                        <Form.Item style={{ marginBottom: 16 }}>
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Form.Item>
                      ) : null}
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      style={{
                        width: "100%",
                      }}
                      disabled={!enableBtn}
                    >
                      <PlusOutlined /> Add field
                    </Button>
                  </Form.Item>
                </Form.Item>
              );
            }}
          </Form.List>
      </div>

      <div
        className="flex-row"
        style={{ justifyContent: "space-between", gap: 16 }}
      >
        <Form.Item
          label="Father's Name"
          name="father"
          style={{ width: "100%" }}
        >
          <Input.TextArea autoSize allowClear placeholder="enter name" />
        </Form.Item>
        <Form.Item
          label="Mother's Name"
          name="mother"
          style={{ width: "100%" }}
        >
          <Input.TextArea autoSize allowClear placeholder="enter name" />
        </Form.Item>
      </div>
    </Form.Item>
  );
};

export default Family;
