import React from "react";
import { Modal, Divider } from "antd";
import {
  EnvironmentOutlined,
  DollarOutlined,
  ScheduleOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import ReactHtmlParser from "react-html-parser";

class CareerItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    console.log("modalDescription", this.props.modalDescription);

    let {
      title,
      company,
      rate,
      email,
      phone,
      contract_type,
      location,
      description,
    } = this.props.modalDescription;
    return (
      <div>
        <Modal
          visible={this.props.show}
          title={[
            <div style={{ width: "50%" }}>
              <h2 style={{ fontWeight: 700 }}>{title} </h2>
              <h3 style={{ margin: 0 }}>{company}</h3>
            </div>,
          ]}
          closable={true}
          width="75%"
          onCancel={this.props.showItemModal}
          footer={[
            <div style={{ display: "flex", marginBottom: "2em" }}>
              <h5 style={{ margin: "auto" }}>
                <MailOutlined /> {email}
              </h5>
              <h5 style={{ margin: "auto" }}>
                <PhoneOutlined /> {phone}
              </h5>
            </div>,
          ]}
        >
          <div>
            <div style={{ display: "flex", marginBottom: "2em" }}>
              <h5 style={{ margin: "auto" }}>
                <EnvironmentOutlined />{" "}
                {location && location.formatted_address
                  ? location.formatted_address
                  : ""}
              </h5>
              <h5 style={{ margin: "auto" }}>
                <DollarOutlined /> {rate}
              </h5>
              <h5 style={{ margin: "auto" }}>
                <ScheduleOutlined /> {contract_type}
              </h5>
            </div>
            <Divider />
            <h2>Job Description</h2>
            <div>{ReactHtmlParser(description)}</div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CareerItemModal;
