// Libraries
import React, { useContext } from "react";

// Component
import CandidateTag from "./CandidateTag";

// Helpers & Config
import { CandidateContext } from "../../providers/CandidateContext";

const CandidateTagList = (props) => {
  const { candidateTags } = useContext(CandidateContext);

  return (
    <div className="candidate-tags-container">
      {candidateTags.map((c) => {
        return <CandidateTag key={c + c._id} details={c} />;
      })}
    </div>
  );
};

export default CandidateTagList;
