import React, { createContext, useState } from "react";

export const NewsContext = createContext({});

export const NewsProvider = (props) => {
  const [factCheckTags, setFactCheckTags] = useState([]);

  const data = { factCheckTags, setFactCheckTags };

  return (
    <NewsContext.Provider value={data}>
      {props.children}
    </NewsContext.Provider>
  );
};

export default NewsProvider;
