import React, { useContext } from "react";
import { Divider, Col, Row } from "antd";
import { SignUpContext } from "../../../providers/SignupContext";

export default function InvoicePreview() {
  const { profileData } = useContext(SignUpContext);
  return (
    <div className="invoicePreview">
      <h3>Invoice Preview</h3>
      <Divider style={{ backgroundColor: "darkgrey" }} />
      <strong>Monthly Fees</strong>

      <Row style={{ display: "flex", marginTop: "1em" }}>
        <Col span={18}>
          Subscription -{" "}
          {profileData.membership.name === "Premium"
            ? "Platinum"
            : profileData.membership.name === "Sponsor"
            ? "Silver"
            : "Free"}
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          ${profileData.membership.cost}
        </Col>
      </Row>
      {profileData.bannerData.runTime > 0 && (
        <Row style={{ display: "flex", marginTop: "1em" }}>
          <Col span={18}>
            Banner - {`${profileData.bannerData.runTime} days`}
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            ${profileData.bannerData.adCost}
          </Col>
        </Row>
      )}

      <Divider style={{ backgroundColor: "darkgrey" }} />
      <Row style={{ display: "flex" }}>
        <Col span={18}>Total:</Col>
        <Col span={6} style={{ textAlign: "right" }}>
          ${profileData.bannerData.adCost + profileData.membership.cost}
        </Col>
      </Row>
    </div>
  );
}
