import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";

function LocationInput({
  onChange: onInputChange,
  stateOnly,
  cityOnly,
  value,
  getLangLng,
  height,
}) {
  // For Address Input
  const [, setLat] = useState("");
  const [, setLng] = useState("");
  const [, setAddress] = useState("");
  const [input, setInput] = useState("");
  let addressAutoComplete;

  let mapType = stateOnly ? "-state" : cityOnly ? "-city" : "";
  let elementId = "place-autocomplete" + mapType;

  // eslint-disable-next-line
  useEffect(() => {
    if (value) {
      setInput(value);
    }
  });

  useEffect(() => {
    handleScriptLoad(
      setInput,
      setLat,
      setLng,
      setAddress,
      document.getElementById(elementId)
    );
  }, []); // eslint-disable-line

  function handleScriptLoad(setInput, setLat, setLng, setAddress, addressId) {
    let optionTypes = cityOnly
      ? "(cities)"
      : stateOnly
      ? "(regions)"
      : "address";
    addressAutoComplete = new window.google.maps.places.Autocomplete(
      addressId,
      {
        types: [optionTypes],
        componentRestrictions: { country: "us" },
      }
    );
    addressAutoComplete.addListener("place_changed", () =>
      handleAddressPlaceSelect(setInput, setLat, setLng, setAddress)
    );
  }

  async function handleAddressPlaceSelect(
    setInput,
    setLat,
    setLng,
    setAddress
  ) {
    const addressObject = addressAutoComplete.getPlace();
    setInput(addressObject.formatted_address);
    setAddress(addressObject.formatted_address);
    setLat(addressObject.geometry.location.lat());
    setLng(addressObject.geometry.location.lng());
    onInputChange && onInputChange(addressObject.formatted_address);
    getLangLng &&
      getLangLng(
        addressObject.geometry.location.lat(),
        addressObject.geometry.location.lng()
      );
  }

  return (
    <Input
      suffix={<EnvironmentOutlined />}
      id={elementId}
      type="text"
      placeholder="enter location"
      onChange={(e) => {
        setInput(e.target.value);
        onInputChange && onInputChange(e.target.value);
      }}
      value={input}
      onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
      style={{
        height,
      }}
    />
  );
}

export default LocationInput;
