import React from "react";

const VimeoVideo = () => {
  return (
    <div
      style={{
        padding: "56.25% 0 0 0",
        position: "relative",
      }}
    >
      <iframe
        src="https://vimeo.com/event/541082/embed"
        frameBorder="0"
        allow="autoplay"
        allowFullScreen
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
        }}
        title="vimeo_video"
      />
    </div>
  );
};

export default VimeoVideo;
