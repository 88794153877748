import React from "react";
import {
  Menu,
  Row,
  Col,
  message,
  Empty,
  Button,
  Card,
  BackTop,
  Popconfirm,
} from "antd";
import { Link } from "react-router-dom";
import config from "../../config";
import {
  EnvironmentFilled,
  PhoneFilled,
  MailFilled,
  LinkOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import axios from "axios";
import Loading from "../Loading";
import moment from "moment";
import AdHelper from "../shared/ad/AdHelper";

const gridStyle = {
  width: 250,
  height: 250,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
// function removeGoogleMapScript() {
//   let keywords = ["maps.googleapis"];

//   //Remove google from BOM (window object)
//   window.google = undefined;

//   //Remove google map scripts from DOM
//   let scripts = document.head.getElementsByTagName("script");
//   for (let i = scripts.length - 1; i >= 0; i--) {
//     let scriptSource = scripts[i].getAttribute("src");
//     if (scriptSource != null) {
//       if (keywords.filter((item) => scriptSource.includes(item)).length) {
//         scripts[i].remove();
//       }
//     }
//   }
// }

class EventDetailsPage extends React.Component {
  state = {
    loading: true,
    event: {},
    currentMenu: "about",
    isAdmin: false,
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    const eventId = this.props.match.params.id;
    try {
      const response = await axios.get(`${config.EVENTS}/events/${eventId}`);
      this.setState({ event: response.data, loading: false });
      const usersResponse = await axios.get(`${config.USERS}/users`, {
        headers: { token: localStorage.getItem("access_token") },
      });
      // check for administrator role
      if (usersResponse.data && usersResponse.data.role === "admin") {
        this.setState({ isAdmin: true });
      }
    } catch (err) {
      console.log(err);
      message.error("There was a problem connecting to the network.");
    }
  }

  // confirmed delete event
  confirm = async () => {
    try {
      const id = this.props.match.params.id;
      await axios.patch(
        `${config.EVENTS}/events/${id}/delete`,
        {},
        {
          headers: {
            token: localStorage.getItem("access_token"),
          },
        }
      );
      message.success("Your event post was deleted.");
      this.props.history.push("/calendar");
    } catch (err) {
      message.warning("Your event post wasn't deleted.");
    }
  };

  render() {
    const { name, location, descriptors, contact, images, dates } =
      this.state.event;

    let mapURL;
    if (location && location.geo !== undefined) {
      // removeGoogleMapScript();
      let coords = {
        lng: location.geo.coordinates[0],
        lat: location.geo.coordinates[1],
      };
      mapURL =
        coords &&
        `https://maps.googleapis.com/maps/api/staticmap?size=300x222&zoom=14&&maptype=roadmap&key=${config.GOOGLE_API_KEY}&markers=${coords.lat},${coords.lng}`;
    }

    const galleryCards =
      images && images.gallery ? (
        images.gallery.map((image, index) => {
          return (
            <Card.Grid style={gridStyle}>
              <img
                style={{ width: 200 }}
                alt={`event ${index}`}
                src={image.uploadedUrl}
              />
            </Card.Grid>
          );
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      );

    return (
      <div className="EventDetailsPage big-bottom-padding">
        {this.state.loading ? (
          <Loading />
        ) : (
          <div>
            <Row className="bg-uploader">
              <Col span={24}>
                <div
                  className="details-bg"
                  style={{
                    height: 300,
                    backgroundImage: `url(${
                      images.profile
                        ? images.profile
                        : "https://s3.amazonaws.com/dev.nocap.com/uploads/admanager/3.jpg"
                    })`,
                    backgroundPosition: "center center",
                  }}
                />
              </Col>
            </Row>
            <div style={{ margin: "1em" }}>
              {this.state.isAdmin && (
                <>
                  <Link
                    className="link"
                    to={`/calendar/${this.props.match.params.id}/edit`}
                  >
                    <Button>EDIT</Button>
                  </Link>
                  {"   "}
                  <Popconfirm
                    title="Are you sure delete this event?"
                    onConfirm={this.confirm}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Link className="link" to="#">
                      <Button>DELETE</Button>
                    </Link>
                  </Popconfirm>
                </>
              )}
            </div>
            <div>
              <Row className="details-menu" gutter={24}>
                <Col sm={24} md={{ span: 10, offset: 6 }}>
                  <Menu
                    mode="horizontal"
                    onClick={(e) => this.setState({ currentMenu: e.key })}
                  >
                    <Menu.Item key="about">Details</Menu.Item>
                    <Menu.Item key="gallery">Gallery</Menu.Item>
                  </Menu>
                </Col>
                {descriptors && descriptors.social ? (
                  <Col xs={24} md={8}>
                    {descriptors.social.facebook ? (
                      <Button
                        style={{ borderColor: "#bfbfbf" }}
                        className="btn-bordered"
                        target="_blank"
                        href={descriptors.social.facebook}
                        icon={<FacebookOutlined />}
                      />
                    ) : null}
                    {descriptors.social.instagram ? (
                      <Button
                        style={{ borderColor: "#bfbfbf" }}
                        className="btn-bordered"
                        target="_blank"
                        href={descriptors.social.instagram}
                        icon={<InstagramOutlined />}
                      />
                    ) : null}
                    {descriptors.social.twitter ? (
                      <Button
                        style={{ borderColor: "#bfbfbf" }}
                        className="btn-bordered"
                        target="_blank"
                        href={descriptors.social.twitter}
                        icon={<TwitterOutlined />}
                      />
                    ) : null}
                    {descriptors.social.youtube ? (
                      <Button
                        style={{ borderColor: "#bfbfbf" }}
                        className="btn-bordered"
                        target="_blank"
                        href={descriptors.social.youtube}
                        icon={<YoutubeOutlined />}
                      />
                    ) : null}
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col xs={24} md={6}>
                  <div className="details-header">
                    <h2>{name}</h2>
                    <div className="details-actions">
                      <div style={{ textAlign: "center" }}>
                        {moment(dates.start).format("LLL")}
                        <br />
                        — <br /> {moment(dates.end).format("LLL")}
                      </div>
                    </div>
                    <div className="category-list">
                      {descriptors &&
                        descriptors.tags &&
                        descriptors.tags.map((tag) => (
                          <div key={tag}>
                            {/* <Marker /> */}
                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: "600",
                                marginLeft: "10px",
                                color: "#aaa",
                              }}
                            >
                              {" · "}
                              {tag}
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div style={{ width: "200px", marginLeft: "100px" }}>
                    <AdHelper page="news-post" position="left" />
                  </div>
                </Col>
                <Col xs={24} md={12} className="description">
                  <div className="card-container">
                    {this.state.currentMenu === "about" && (
                      <div className="inner service-description">
                        {descriptors.description ||
                          `Visit their websites for more details.`}
                      </div>
                    )}

                    {this.state.currentMenu === "gallery" && (
                      <div className="inner gallery">{galleryCards}</div>
                    )}
                  </div>
                </Col>
                <Col sm={24} md={5}>
                  {location && location.geo !== undefined ? (
                    <div style={{ textAlign: "center" }}>
                      <img src={mapURL} alt="map" id="static-map" />
                    </div>
                  ) : null}
                  <div className="service-details-list">
                    {location && location.geo !== undefined && (
                      <div>
                        <EnvironmentFilled />
                        <span>{location.formatted_address}</span>
                      </div>
                    )}

                    {contact && contact.phone && (
                      <div>
                        <PhoneFilled />
                        <span>{contact.phone}</span>
                      </div>
                    )}

                    {contact && contact.email && (
                      <div>
                        <MailFilled />
                        <span>{contact.email}</span>
                      </div>
                    )}

                    {descriptors && descriptors.website && (
                      <div>
                        <LinkOutlined />
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ wordBreak: "break-all" }}
                          href={descriptors.website}
                        >
                          {descriptors.website}
                        </a>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
            <BackTop />
          </div>
        )}
      </div>
    );
  }
}

export default EventDetailsPage;
