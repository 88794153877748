import React from "react";
import { Card, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
const eventsGridStyle = {
  width: 300,
  height: 300,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const EventsSection = (props) => {
  return (
    props.data.events &&
    props.data.events.map((event, index) => (
      <Link
        to={{
          pathname: `/calendar/${event["_id"]}`,
        }}
      >
        <Card.Grid style={eventsGridStyle}>
          <Space direction="vertical" align="center">
            {event.images && event.images.profile ? (
              <img
                style={{ width: 250 }}
                alt={event.name}
                src={event.images.profile}
              />
            ) : null}

            <Typography.Text strong>{event.name}</Typography.Text>
            <Typography.Text>
              {moment(event.dates.start).format("MMM D, YYYY")} —{" "}
              {moment(event.dates.end).format("MMM D, YYYY")}
            </Typography.Text>
            <Typography.Text type="secondary">
              {event.location.formatted_address}
            </Typography.Text>
          </Space>
        </Card.Grid>
      </Link>
    ))
  );
};

export default EventsSection;
