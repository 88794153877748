import React, { useEffect, useState } from "react";
import axios from "axios";
import UserForm from "../forms/UserInfoForm";
import EditCandidateInfoForm from "../forms/candidateForm/EditCandidateInfoForm";
import { useParams } from "react-router-dom";
import { message } from "antd";
import config from "../../config";
import Loading from "../Loading";
import DraftMessage from "../forms/candidateForm/components/edit/DraftMessage";
import OnBoarding from "../shared/onboarding/Onboarding";
import AdminInfoForm from "../forms/AdminInfoForm";

const ProfilePage = (props) => {
  //type
  const { type } = useParams();

  let results;

  // fetch candidate initial data
  const [editInitialData, setEditInitialData] = useState(null);
  const [editInitialError, setEditInitialError] = useState(null);
  const [editInitialLoading, setEditInitialLoading] = useState(false);

  async function get(url) {
    try {
      setEditInitialLoading(true);
      const result = await axios(url);
      setEditInitialData(result.data);
    } catch (e) {
      setEditInitialError(e);
    } finally {
      setEditInitialLoading(false);
    }
  }

  useEffect(() => {
    if (type === "candidate") {
      let id = JSON.parse(localStorage.getItem("user")).candidateId;
      let url = `${config.CANDIDATES}/candidates/findOne/${id}`;
      get(url);
    }
  }, []); // eslint-disable-line

  if (editInitialError) {
    results = message.error(
      "Oops ... Something went wrong, we could not load the information for this profile"
    );
  }

  if (editInitialLoading) {
    results = <Loading />;
  } else {
    switch (type) {
      case "admin":
        results = <AdminInfoForm edit={true} />;
        break;
      case "user":
        results = <UserForm edit={true} />;
        break;
      case "candidate":
        if (editInitialData && editInitialData.status === "draft") {
          results = <DraftMessage />;
        } else {
          results = <EditCandidateInfoForm initialData={editInitialData} />;
        }
        break;
      case "advertiser":
        console.log("here?");
        results = <OnBoarding props={props} history={props.history} profile />;
        break;
      default:
        break;
    }
  }
  return <>{results}</>;
};

export default ProfilePage;
