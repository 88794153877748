import React from "react";
import { Link } from "react-router-dom";
import { Popover } from "antd";
import { getDistance } from "geolib";

const KmAway = ({ location, coordinates }) => {
  let distance = getDistance(
    { latitude: coordinates.lat, longitude: coordinates.lng },
    {
      latitude: location.geo.coordinates[1],
      longitude: location.geo.coordinates[0],
    },
    100
  );
  console.log(distance, "units");
  let kmaway = distance / 1000;
  console.log(kmaway, "km");
  return Math.round(kmaway);
};

const Content = ({
  id,
  organizationName,
  logo,
  location,
  contact,
  coordinates,
  service,
}) => (
  <div className="marker-details">
    <div>
      <Link to={`/services/${id}`} className="normalize">
        <img alt="logo" src={logo} width="60" />
      </Link>
    </div>
    <div>
      <Link
        to={{
          pathname: `/services/${id}`,
          state: {
            data: service,
          },
        }}
        className="normalize"
      >
        <div className="organization-name">
          <span>{organizationName}</span>
        </div>
      </Link>
      <div>
        <ul style={{ listStyle: "none", margin: "0px", padding: "0px" }}>
          <li style={{ fontSize: "12px", fontStyle: "italic" }}>
            {contact.phone}
          </li>
          <li style={{ fontSize: "13px" }}>
            {location.places_component.components[0].long_name}{" "}
            {location.places_component.components[1].short_name}
          </li>
          <li style={{ fontSize: "10px", textAlign: "right" }}>
            <KmAway coordinates={coordinates} location={location} />
            km
          </li>
        </ul>
      </div>
    </div>
  </div>
);

const Marker = ({
  type,
  categories,
  id,
  organizationName,
  logo,
  location,
  contact,
  coordinates,
  service,
}) => {
  return (
    <Popover
      content={
        <Content
          id={id}
          organizationName={organizationName}
          logo={logo}
          location={location}
          contact={contact}
          coordinates={coordinates}
          service={service}
        />
      }
      trigger="click"
    >
      <div className="marker">
        <img
          alt={categories}
          src={logo}
          style={{
            height: "56px",
            display: "inline-block",
          }}
        />
      </div>
    </Popover>
  );
};

export default Marker;
