import React, { useState } from "react";
import { Form, Input, Select, Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const Education = () => {
  const [college, setCollege] = useState("");
  const [collegeList, setCollegeList] = useState([]);

  const addCollege = (e) => {
    e.preventDefault();
    setCollegeList((prev) => [...prev, college]);
    setCollege("");
  };

  return (
    <Form.Item label="Education">
      <Form.Item label="College/University" name="college">
        <Select
          mode="tags"
          allowClear
          maxTagCount={2}
          showSearch
          placeholder="enter college/university "
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                <Input
                  style={{ flex: "auto" }}
                  value={college}
                  onChange={(e) => setCollege(e.target.value)}
                  placeholder="provide college/university"
                />
                <a
                  style={{
                    flex: "none",
                    padding: "8px",
                    display: "block",
                    cursor: "pointer",
                    color: "#8c8c8c",
                  }}
                  href="/"
                  onClick={addCollege}
                >
                  <PlusOutlined /> Add
                </a>
              </div>
            </div>
          )}
        >
          {collegeList.map((college, i) => (
            <Select.Option key={"college" + i} value={college}>
              {college}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="High School" name="highschool">
        <Input.TextArea
          autoSize
          allowClear
          placeholder="enter high school "
          place
        />
      </Form.Item>
    </Form.Item>
  );
};

export default Education;
