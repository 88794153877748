import { useState } from "react";

const useBannerPreview = () => {
  const [showPreview, setShowPreview] = useState(false);

  const handleShowPreview = () => {
    setShowPreview(true);

    setTimeout(() => {
      setShowPreview(false);
    }, 5000);
  };

  return {
    showPreview,
    handleShowPreview,
  };
};

export default useBannerPreview;
