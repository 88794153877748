import React, { useState } from "react";
import { Form, Col, Select, Input } from "antd";
import axios from "axios";
import config from "../../../../../config";
import Add from "./Add";
import { capitalizeFirstLetterOfEachWord } from "../../../../../helpers";
import NoCapManifest from "../../../../../nocap.json";

const { Option } = Select;

let stateIndex = 1;
let divisionIndex = 1;
let officeIndex = 1;

const OfficeSelections = (props) => {
  const [inputState, setInputState] = useState({
    state: "",
    division: "",
    office: "",
  });
  const [states, setStates] = useState(
    NoCapManifest.candidateInfoForm.stateData
  );
  const [offices, setOffices] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [divisionPhrase, setDivisionPhrase] = useState("select city");
  const [officePhrase, setOfficePhrase] = useState("select office");

  const handleStateChange = async (value) => {
    // reset all the divisions and offices related states
    setDivisions([]);
    setOffices([]);
    divisionIndex = 1;
    officeIndex = 1;

    console.log(props.form.getFieldsValue(true))

    // reset selected option
    props.form.setFieldsValue({
      [`${props.type}Division`]: undefined,
      [`${props.type}Office`]: undefined,
    });

    props.setDivisionIds({
      ...props.divisionIds,
      [`${props.type}`]: undefined,
    });

    // disabled office and division selection and show 'loading ...' when state is fetching data
    setLoading(true);
    setDivisionPhrase("Loading ...");

    // fetch the division and office list if the state is not manually entered
    if (!states[value].startsWith("other")) {
      const divisions = await axios.get(
        `${config.CANDIDATES}/candidates/get-dvisions-list`,
        {
          cors: true,
          responseType: "json",
          headers: {
            state: NoCapManifest.candidateInfoForm.stateData[value],
          },
        }
      );
      if (divisions.data.length > 0) {
        setDivisions(divisions.data);
        setDivisionPhrase("select divion");
      } else {
        setDivisionPhrase("Add a division");
        setOfficePhrase("Add a office");
      }
    } else {
      setDivisionPhrase("Add a division");
      setOfficePhrase("Add a office");
    }
    setLoading(false);
  };

  const handleDivisionChange = async (value, e) => {
    setLoading(true);
    setOffices([]);
    props.form.setFieldsValue({
      [`${props.type}Office`]: undefined,
    });

    props.setDivisionIds({
      ...props.divisionIds,
      [`${props.type}`]: e.key,
    });
    let result = await divisions.filter((division) => {
      return division.name === value;
    });
    if (result[0].offices !== null) {
      let sortedResults = result[0].offices.sort();
      setOffices(sortedResults);
      setOfficePhrase("Select office");
    } else {
      setOfficePhrase("Add a office");
    }
    setLoading(false);
  };

  const addState = (e) => {
    e.preventDefault();
    let i = stateIndex++;
    let addedState = inputState.state
      ? capitalizeFirstLetterOfEachWord(inputState.state)
      : `New State ${i}`;
    setInputState({ ...inputState, state: "" });
    setStates({ ...states, [`${addedState}`]: `other ${i}` });
  };

  const addDivision = (e) => {
    e.preventDefault();
    let i = divisionIndex++;
    let addedDivision = inputState.division
      ? capitalizeFirstLetterOfEachWord(inputState.division)
      : `New Division ${i}`;
    setInputState({ ...inputState, division: "" });
    setDivisions([
      ...divisions,
      {
        divisonId: `other ${i}`,
        name: addedDivision,
        offices: null,
        state: props.form.getFieldValue(`${props.type}State`),
        _id: `other ${i}`,
      },
    ]);
  };

  const addOffice = (e) => {
    e.preventDefault();
    let i = officeIndex++;
    let addedOffice = inputState.office
      ? capitalizeFirstLetterOfEachWord(inputState.office)
      : `New Office ${i}`;
    setInputState({ ...inputState, office: "" });
    setOffices([...offices, addedOffice]);
  };

  return (
    <>
      <Col xs={{ span: 24 }} lg={{ span: 24 }}>
        <label
          style={{
            color: "lightgray",
            fontStyle: "italic",
          }}
        >
          Office:{" "}
        </label>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item
          label="State"
          labelCol={{ span: 12 }}
          name={`${props.type}State`}
          rules={[
            {
              required: props.type === "ballot" ? true : false,
              message: `Please select your ${props.type} state`,
            },
          ]}
        >
          <Select
            showSearch
            placeholder="select state"
            onChange={(value) => handleStateChange(value)}
            dropdownRender={(menu) => (
              <Add
                menu={menu}
                setInputState={setInputState}
                inputState={inputState}
                type="state"
                add={addState}
              />
            )}
          >
            {Object.keys(states).map((state) => (
              <Option key={states[state]} value={state}>
                {state}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item
          label="City"
          name={`${props.type}Division`}
          rules={[
            {
              required: props.type === "ballot" ? true : false,
              message: `Please select your ${props.type} city`,
            },
          ]}
        >
          <Select
            showSearch
            placeholder={divisionPhrase}
            loading={loading}
            onChange={(value, e) => handleDivisionChange(value, e)}
            disabled={
              !props.form.getFieldValue(`${props.type}State`) || loading
            }
            dropdownRender={(menu) => (
              <Add
                menu={menu}
                setInputState={setInputState}
                inputState={inputState}
                type="division"
                add={addDivision}
              />
            )}
          >
            {divisions.map((division) => (
              <Option key={division.divisonId} value={division.name}>
                {division.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item
          label="Office"
          name={`${props.type}Office`}
          rules={[
            {
              required: props.type === "ballot" ? true : false,
              message: `Please select your ${props.type} office`,
            },
          ]}
        >
          <Select
            showSearch
            placeholder={officePhrase}
            loading={loading}
            disabled={
              !props.form.getFieldValue(`${props.type}State`) ||
              !props.form.getFieldValue(`${props.type}Division`) ||
              loading
            }
            dropdownRender={(menu) => (
              <Add
                menu={menu}
                setInputState={setInputState}
                inputState={inputState}
                type="office"
                add={addOffice}
              />
            )}
          >
            {offices.map((office, index) => (
              <Option key={office + index} value={office}>
                {office}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item
          label={
            <span>
              Term{" "}
              <span
                style={{
                  fontSize: 10,
                  fontStyle: "italic",
                }}
              >
                (yyyy - yyyy)
              </span>
            </span>
          }
          name={`${props.type}Term`}
          rules={[
            {
              required: props.type === "ballot" ? true : false,
              message: `Please indicate years in office`,
            },
          ]}
        >
          <Input.TextArea
            autoSize
            allowClear
            placeholder="enter term in office"
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default OfficeSelections;
