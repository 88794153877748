// Libraries
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

// Components
import Loading from "../Loading";
import NewsPost from "../news/NewsPost";

// Ant Design Components
import { message } from "antd";

// Helpers & Config
import config from "../../config";
import useNewsData from "../../hooks/useNewsData";

const NewsPostPage = (props) => {
  const { id } = useParams();

  // Retrieve news post data and states with custom hook
  const { data, error, loading, get } = useNewsData(
    `${config.BLOGS}/blogs/findOne/${id}`
  );

  useEffect(() => {
    get();
    // eslint-disable-next-line
  }, []);

  // Scroll to top of page when viewed
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // eslint-disable-next-line
  }, [data]);

  if (loading) {
    return <Loading />;
  }

  if (data && data !== null) {
    return <NewsPost data={data} getData={get} />;
  }

  if (error)
    return message.error(
      "Oops ... Something went wrong, we could not load the information for this article"
    );
};

export default NewsPostPage;
