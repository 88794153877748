import auth0 from "auth0-js";
import config from "../../../config";

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: config.AUTH_CONFIG.domain,
    clientID: config.AUTH_CONFIG.clientId,
    redirectUri: config.AUTH_CONFIG.callbackUrl,
    audience: `https://${config.AUTH_CONFIG.originalDomain}/userinfo`,
    responseType: "token id_token",
    overrides: {
  	__tenant: config.auth0Tenant,
  	__token_issuer: "https://login.nocapvote.com/"
  },

  });

  FBauth0 = new auth0.WebAuth({
    domain: config.AUTH_CONFIG.originalDomain,
    clientID: config.AUTH_CONFIG.clientId,
    redirectUri: config.AUTH_CONFIG.fbCallbackUrl,
    audience: `https://${config.AUTH_CONFIG.originalDomain}/userinfo`,
    responseType: "token id_token",
  });

  login = (username, password) => {
    return new Promise((resolve, reject) => {
      this.auth0.login(
        {
          realm: config.AUTH_CONFIG.dbConnectionName,
          username,
          password,
        },
        (err, authResult) => {
          if (err) reject(err);
          resolve(authResult);
        }
      );
    });
  };

  signup = (email, password) => {
    return new Promise((resolve, reject) => {
      this.auth0.signup(
        {
          connection: config.AUTH_CONFIG.dbConnectionName,
          email,
          password,
        },
        (err, authResult) => {
          if (err) reject(err);
          resolve(authResult);
        }
      );
    });
  };

  loginWithFacebook = () => {
    return new Promise((resolve, reject) => {
      this.FBauth0.authorize({ connection: "facebook" }, (err, authResult) => {
        if (err) reject(err);
        resolve(authResult);
      });
    });
  };

  isVerifiedByEmail = (authResult) => {
    return authResult.idTokenPayload["email_verified"];
  };

  changePassword = (email) => {
    return new Promise((resolve, reject) => {
      this.auth0.changePassword(
        {
          connection: config.AUTH_CONFIG.dbConnectionName,
          email,
        },
        (err, res) => {
          if (err) reject(err);
          resolve(res);
        }
      );
    });
  };

  FBhandleAuthentication = () => {
    return new Promise((resolve, reject) => {
      this.FBauth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          // store token and user info in localStorage
          this.setSession(authResult);
          resolve(authResult);
          return;
        } else {
          if (err) reject(err);
          console.log(err)
          console.log("Errored Out:(")
          return;
        }
      });
    });
  };

  handleAuthentication = () => {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          // store token and user info in localStorage
          this.setSession(authResult);
          resolve(authResult);
          return;
        } else {
          if (err) reject(err);
          return;
        }
      });
    });
  };

  setSession = (authResult) => {
    // Set the time that the access token will expire at

    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem("plan", authResult.plan);
    localStorage.setItem("expires_at", expiresAt);
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
  };

  // pass in the app state, and update the verified flag, ( or just check for a user )
  getUserInfo = (accessToken) => {
    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(accessToken, (err, user) => {
        if (err) return reject(err);
        if (!user) return reject({ msg: "We can't find you ! 😱" });
        alert(user);
        return resolve(user);
      });
    });
  };

  logout = () => {
    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("user");
    localStorage.removeItem("plan");
    localStorage.removeItem("userid");
    localStorage.removeItem("role");
    localStorage.removeItem("useremail");
    localStorage.removeItem("onboard");
    localStorage.removeItem("votingList");
    localStorage.removeItem("ratingList");
  };

  isAuthenticated = () => {
    // Check whether the current time is past the access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  };

  isSessionValid = () => {
    // remove session if expired
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    let sessionSet = expiresAt === null ? false : true;
    let expired = new Date().getTime() > expiresAt ? true : false;
    if (expired === true && sessionSet) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("id_token");
      localStorage.removeItem("expires_at");
      localStorage.removeItem("user");
      localStorage.removeItem("plan");
      localStorage.removeItem("userid");
      localStorage.removeItem("votingList");
      localStorage.removeItem("ratingList");
      localStorage.removeItem("role");
      localStorage.removeItem("onboard");
      return true;
    } else {
      return false;
    }
  };
} //End of export
