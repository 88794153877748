import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { message, Spin, Empty, Card, BackTop } from "antd";
import NewsSection from "./NewsSection";
import ServicesSection from "./ServicesSection";
import EventsSection from "./EventsSection";
import CandidatesSection from "./CandidatesSection";
import config from "../../config";
import AdHelper from "../shared/ad/AdHelper";

const SearchPage = (props) => {
  const location = useLocation();

  function FetchInfo(url) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    async function get() {
      try {
        setLoading(true);
        const result = await axios(url);
        setData(result.data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }
    useEffect(() => {
      get();
    }, [location]); // eslint-disable-line
    return [data, error, loading];
  }
  const [data, error, loading] = FetchInfo(
    `${config.CANDIDATES}/candidates/search?search-text=${location.state.detail}&limit=100`
  );
  console.log("data=", data);
  if (error)
    return message.error(
      "Oops ... Something went wrong, we could not load the information for this search"
    );

  let result = null;

  if (loading) {
    result = (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <small>Loading ... </small>
        <Spin />
      </div>
    );
  } else if (data) {
    console.log("data", data);
    result = (
      <div>
        {data.candidates.length > 0 ? (
          <Card title="Candidates" bordered={false}>
            <CandidatesSection data={data} />
          </Card>
        ) : null}
        {data.services.length > 0 ? <ServicesSection data={data} /> : null}
        {data.blogs.length > 0 ? (
          <Card title="News" bordered={false}>
            <NewsSection data={data} />
          </Card>
        ) : null}
        {data.events.length > 0 ? (
          <Card title="Calendar" bordered={false}>
            <EventsSection data={data} />
          </Card>
        ) : (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)}
      </div>
    );
  } else {
    result = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  return (
    <div>
      <AdHelper page="news" />
      <br/>
      {result} <BackTop />
      <br/>
      <AdHelper page="news" />
    </div>
  );
};

export default SearchPage;
