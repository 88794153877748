import React from "react";
import { Skeleton } from "antd";

const BannerPreview = ({ bannerLocation }) => {
  const showTop = bannerLocation === "SHT" || bannerLocation === "SHB";
  const showHalfR =
    bannerLocation === "BHR" ||
    bannerLocation === "CHR" ||
    bannerLocation === "EHR" ||
    bannerLocation === "HTL";
  const showHalfL =
    bannerLocation === "EHL" ||
    bannerLocation === "CHL" ||
    bannerLocation === "BHL";

  const showBottomL = bannerLocation === "HBL";
  const showBottomM = bannerLocation === "HBR";
  const showRight = bannerLocation === "HHR";
  return (
    <div
      style={{
        padding: 16,
        width: "100%",
        height: 500,
        border: "1px solid black",
        position: "relative",
      }}
    >
      {showRight && (
        <Skeleton.Button
          active
          size="large"
          block
          style={{
            height: 250,
            position: "absolute",
            top: "20%",
            right: 16,
            width: "20%",
          }}
        />
      )}

      {showTop && (
        <Skeleton.Button
          active
          size="large"
          block
          style={{
            height: 50,
          }}
        />
      )}
      {showHalfL && (
        <Skeleton.Button
          active
          size="large"
          block
          style={{
            height: 50,
            width: "50%",
          }}
        />
      )}
      {showHalfR && (
        <Skeleton.Button
          active
          size="large"
          block
          style={{
            height: 50,
            position: "absolute",
            top: 16,
            right: 16,
            width: "50%",
          }}
        />
      )}
      {(showBottomL || showBottomM) && (
        <Skeleton.Button
          active
          size="large"
          style={{
            height: 50,
            width: "50%",
            position: "absolute",
            bottom: 16,
            left: showBottomM && "25%",
          }}
        />
      )}
    </div>
  );
};

export default BannerPreview;
