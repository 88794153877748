import React from "react";
import { Tabs } from "antd";
// import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
// import { useBreakpoint } from "../../../hooks/useWindowSize";

function CustomTabs({ onChange, activeKey, children, ...rest }) {
  // const hideLeftBtn = activeKey == 1; // eslint-disable-line
  // const hideRightBtn = activeKey == children.length; // eslint-disable-line
  // const OperationsSlot = {
  //   left: hideLeftBtn ? (
  //     <div></div>
  //   ) : (
  //     <Button
  //       onClick={(e) => onArrowClick(e, "left")}
  //       shape="circle"
  //       icon={<ArrowLeftOutlined />}
  //       style={{
  //         width: "50%",
  //       }}
  //     />
  //   ),
  //   right: hideRightBtn ? (
  //     <div></div>
  //   ) : (
  //     <Button
  //       onClick={(e) => onArrowClick(e, "right")}
  //       shape="circle"
  //       icon={<ArrowRightOutlined />}
  //       style={{
  //         width: "50%",
  //       }}
  //     />
  //   ),
  // };

  // function onArrowClick(event, type) {
  //   event.stopPropagation();
  //   const val = Number(activeKey);
  //   if (type === "left") {
  //     onTabChange(String(val - 1));
  //   } else {
  //     onTabChange(String(val + 1));
  //   }
  // }

  function onTabChange(key) {
    onChange(key);
  }

  // const { mobileView } = useBreakpoint();

  // const showArrows = mobileView;

  return (
    <Tabs
      onChange={onTabChange}
      // tabBarExtraContent={showArrows ? OperationsSlot : <div />}
      activeKey={activeKey}
      {...rest}
    >
      {children}
    </Tabs>
  );
}

export default CustomTabs;
