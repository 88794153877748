import React, { Component } from "react";
import { message, Button, Upload } from "antd";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

export class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      src: "",
      crop: {
        aspect: this.props.cropRatio,
      },
    };
  }

  uploadImage = async (data) => {
    try {
      return this.props.handleUpload(this.state.file);
    } catch (err) {
      message.error(
        "Image Didn't Upload, please contact support if error continues"
      );
    }
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () =>
          this.setState({
            src: reader.result,
          }),
        false
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image, url) => {
    this.imageRef = image;
  };

  onCropComplete = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "image_name"
      );
      let file = await this.getBase64(croppedImageUrl);
      this.setState({
        croppedImageUrl: croppedImageUrl,
        preview: URL.createObjectURL(croppedImageUrl),
        file: file,
      });
    }
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        file.name = fileName;
        resolve(file);
      }, "image/jpeg");
    });
  };

  render() {
    const { Dragger } = Upload;
    const dummyRequest = ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    };
    const draggerProps = {
      name: "file",
      multiple: false,
      showUploadList: false,
      onChange(info) {
        const { status } = info.file;
        if (status === "done") {
          message.success(`${info.file.name} file uploaded successfully.`);
          handlePreview(info.file);
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    let handlePreview = async (e) => {
      let file = e.originFileObj;
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file);
      }
      this.setState({
        previewImage: file.url || file.preview,
        previewVisible: true,
        src: file.url || file.preview,
      });
    };

    return (
      <div>
        <div>
          <div style={{ height: "15em", marginBottom: "2em" }}>
            <Dragger {...draggerProps} customRequest={dummyRequest}>
              <p className="ant-upload-drag-icon"></p>
              <p className="ant-upload-text">
                upload or drag image here
              </p>
              <p className="ant-upload-hint">{this.props.recommendedPhrase}</p>
            </Dragger>
          </div>
          <div stle={{ marginBottom: "2em" }}>
            {this.state.src !== "" ? (
              <p>click and drag pointer to crop for profile image</p>
            ) : null}
            <ReactCrop
              src={this.state.src}
              crop={this.state.crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          </div>
          {this.state.src !== "" && this.state.preview ? (
            <div>
              <p>preview of profile image</p>
              <div className="BrandImagePreviewContainer">
                <div
                  className={this.props.imageClass}
                  style={{ marginBottom: 16 }}
                >
                  <img
                    className="image-wrapper"
                    src={this.state.preview}
                    alt="preview"
                  />
                </div>
                <Button
                  key="submit"
                  className="btn-bordered"
                  onClick={this.uploadImage}
                  type="text"
                  loading={this.props.loading}
                >
                  Confirm Image
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ImageUploader;
