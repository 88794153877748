import React, { useState } from "react";
import { Modal, Button, Input, Form, message as antMessage } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import config from "../config";
import { LoadingOutlined } from "@ant-design/icons";

function ContactUsModal(props) {
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [subject, setsubject] = useState("");
  const [message, setmessage] = useState("");
  const [verified, setverified] = useState(false);
  const [loading, setloading] = useState(false);

  const { TextArea } = Input;
  const recaptchaRef = React.createRef();
  const grecaptchaObject = window.grecaptcha;

  const onFinish = (values) => {
    setloading(true);
    if (verified) {
      const doc = {
        email: email,
        name: name,
        subject: subject,
        message: message,
      };
      return axios.post(`${config.EMAIL}/mailer`, doc).then((response) => {
        if (response.status === 200) {
          setloading(false);
          antMessage.success("Message sent to No Cap.");
          antMessage.success(
            "Please allow No Cap Vote to respond to your email within 24-48 hours."
          );
          props.changeShowContact();
        } else {
          antMessage.error(
            "Something went wrong, make sure you are connected to the internet."
          );
        }
      });
    } else {
      setloading(false);
      antMessage.error("Verify yourself before submitting form.");
    }
  };

  const onChange = async (value, ref) => {
    const response = await axios.post(
      `${config.EMAIL}/verifycaptcha`,
      {},
      {
        headers: {
          doc: encodeURIComponent(value),
        },
      }
    );
    if (response.status === 200) {
      setverified(response.data.success);
    } else {
      message.error(
        "Something went wrong, make sure you are connected to the internet."
      );
    }
  };

  return (
    <Modal
      visible={props.showContact}
      title="Contact NoCap"
      closable={true}
      width="650px"
      onCancel={props.changeShowContact}
      footer={null}
    >
      <img
        style={{ display: "block", margin: "0 auto" }}
        src="https://s3.amazonaws.com/dev.nocap.com/uploads/logo.png"
        width={236}
        alt="brand"
      />

      <Form onFinish={onFinish} layout="vertical">
        <Form.Item label="Email" name="email" rules={[{ required: true }]}>
          <Input
            size="large"
            name="email"
            onChange={(e) => setemail(e.target.value)}
            placeholder="Contact Email"
            disabled={loading}
          />
        </Form.Item>

        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input
            size="large"
            name="name"
            onChange={(e) => setname(e.target.value)}
            placeholder="Your Name"
            disabled={loading}
          />
        </Form.Item>

        <Form.Item label="Subject" name="subject" rules={[{ required: true }]}>
          <Input
            size="large"
            name="subject"
            onChange={(e) => setsubject(e.target.value)}
            placeholder="Subject"
            disabled={loading}
          />
        </Form.Item>

        <Form.Item label="Message" name="message" rules={[{ required: true }]}>
          <TextArea
            size="large"
            name="message"
            onChange={(e) => setmessage(e.target.value)}
            rows={6}
            disabled={loading}
          />
        </Form.Item>

        <Form.Item>
          <ReCAPTCHA
            ref={recaptchaRef}
            //ACTUAL KEY
            sitekey="6Lcicc8UAAAAADXbrTyzr4KhbgP330cMdvoJUA3N"
            //TEST KEY"
            grecaptcha={grecaptchaObject}
            // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
            onChange={(e) => {
              onChange(e, grecaptchaObject);
            }}
          />
        </Form.Item>

        <Form.Item>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              className="btn-solid"
              htmlType="submit"
              type="text"
              style={{ margin: "0px 5px", width: "85px" }}
            >
              {loading ? <LoadingOutlined /> : "Submit"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ContactUsModal;
