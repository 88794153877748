import React from "react";
import { Row, Col, Button, Typography, Avatar } from "antd";
import DefaultImage from "../../../../../css/images/default_image.jpg";

const { Title } = Typography;

const LogoLayout = (props) => {
  return (
    <Row>
      <Col xs={24} sm={24} md={10} lg={6} className="uploader-avatar">
        {props.$imagePreview ? (
          <div className="image-wrapper">{props.$imagePreview}</div>
        ) : (
          <Avatar size={150} src={DefaultImage} />
        )}
      </Col>
      <Col xs={24} sm={24} md={14} lg={18} className="uploader-upload-btn">
        <Title level={4} style={{ marginTop: "50px", fontWeight: 300 }}>
          {props.title}
        </Title>
        {props.imagePreviewUrl ? (
          <>
            <Button onClick={props.toggleVisibility} type="text">
              <span style={{ textDecoration: "underline" }}>Change</span>
            </Button>
            <Button onClick={props.handleRemoveImage} type="text">
              <span style={{ textDecoration: "underline" }}>Remove</span>
            </Button>
          </>
        ) : (
          <Button onClick={props.toggleVisibility} type="text">
            {" "}
            <span style={{ textDecoration: "underline" }}>Upload</span>
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default LogoLayout;
