// Libraries
import React, { useContext } from "react";
import { Link } from "react-router-dom";

// Candidate
import CandidatePreview from "../candidate/CandidatePreview";

// Ant Design Candidate
import { Button, Card, Row, Col } from "antd";

// Helpers & Config
import { CandidateContext } from "../../providers/CandidateContext";

const newsFormStyle = {
  width: 240,
  height: 400,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const CandidatesSection = (props) => {
  const { candidateTags, setCandidateTags } = useContext(CandidateContext);

  console.log("candidate section");
  return (
    props.data.candidates &&
    props.data.candidates.map((state) => {
      return (
        <Card
          key={state._id}
          title={state._id}
          style={{ width: "100%", border: "none" }}
        >
          <Row justify="center">
            {state.data.map((candidate) => {
              if (props.newsForm) {
                return (
                  <Card.Grid style={newsFormStyle} key={candidate._id}>
                    <CandidatePreview
                      details={candidate}
                      buttonVisibility={false}
                      avatarSize={120}
                    />
                    <Button
                      className="btn-bordered"
                      style={{ margin: "1em 0" }}
                      onClick={() =>
                        setCandidateTags([...candidateTags, candidate])
                      }
                      disabled={candidateTags.includes(candidate)}
                    >
                      Add
                    </Button>
                  </Card.Grid>
                );
              }
              return (
                <Col
                  key={candidate["_id"]}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  xl={6}
                  className="ant-card-grid-hoverable"
                  style={{
                    padding: 16,
                    textAlign: "center",
                  }}
                >
                  <Link
                    to={{
                      pathname: `/candidate/${candidate["_id"]}`,
                      state: {
                        data: candidate,
                      },
                    }}
                  >
                    <CandidatePreview
                      details={candidate}
                      buttonVisibility={false}
                    />
                  </Link>
                </Col>
              );
            })}
          </Row>
        </Card>
      );
    })
  );
};

export default CandidatesSection;
