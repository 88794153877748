import { message } from "antd";
import { useHistory } from "react-router-dom";

export function useCheckIfLoggedIn() {
  const user = JSON.parse(localStorage.getItem("user"));

  const history = useHistory();

  if (!user) {
    message.error("Not Authorized. Redirected to login page.");
    history.push("/login");
  }
}

export default useCheckIfLoggedIn;
