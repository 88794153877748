import React from "react";
import { Radio, Button } from "antd";
import "react-table-drag-select/style.css";
import BannerPreview from "./BannerPreview";
import useBannerPreview from "./useBannerPreview";
import useBannerCalculation from "./useBannerCalculation";

const RateCard = (props) => {
  const rateValue = Number(props.RATE * props.locationValue * props.pageValue);
  const { showPreview, handleShowPreview } = useBannerPreview();
  const { getValueFromForm } = useBannerCalculation();
  const [bannerLocation, setBannerLocation] = React.useState("");

  const handleSelectAdType = (e) => {
    if (e.target.value !== "") {
      let data = JSON.parse(e.target.value);
      setBannerLocation(data.acro);
      getValueFromForm(e);
    }
  };

  return (
    <div className="rate-card">
      {props.rates.length === 0 ? null : (
        <div>
          <strong>Please select Rate:</strong>
          <div style={{ margin: "auto" }}>
            <tr
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                minWidth: 500,
              }}
            >
              <td style={{ border: "1px solid lightgrey", width: "30%" }}>
                Ad Type
              </td>
              <td style={{ border: "1px solid lightgrey", width: "17.5%" }}>
                1 month
              </td>
              <td style={{ border: "1px solid lightgrey", width: "17.5%" }}>
                3 months
              </td>
              <td style={{ border: "1px solid lightgrey", width: "17.5%" }}>
                6 months
              </td>
              <td style={{ border: "1px solid lightgrey", width: "17.5%" }}>
                1 year
              </td>
            </tr>
          </div>
          <Radio.Group
            onChange={handleSelectAdType}
            style={{ width: "100%" }}
            buttonStyle="solid"
          >
            {props.rates.map((r, i) => (
              <tr
                key={i}
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  minWidth: 500,
                }}
              >
                <td style={{ border: "1px solid lightgrey", width: "30%" }}>
                  <Button
                    style={{
                      width: "100%",
                      background: "#fff",
                      color: "black",
                      opacity: "0.6",
                    }}
                    disabled
                  >
                    {r.type}
                  </Button>
                </td>
                <td style={{ border: "1px solid lightgrey", width: "17.5%" }}>
                  <Radio.Button
                    style={{ width: "100%" }}
                    value={JSON.stringify({
                      acro: r.acro,
                      type: r.type,
                      weight: r.weight,
                      runTime: 30,
                      cost: Math.trunc(30 * r.weight * rateValue),
                    })}
                  >
                    ${Math.trunc(30 * r.weight * rateValue)}
                  </Radio.Button>
                </td>
                <td style={{ border: "1px solid lightgrey", width: "17.5%" }}>
                  <Radio.Button
                    style={{ width: "100%" }}
                    value={JSON.stringify({
                      acro: r.acro,
                      type: r.type,
                      weight: r.weight,
                      runTime: 90,
                      cost: Math.trunc(90 * r.weight * rateValue),
                    })}
                  >
                    ${Math.trunc(90 * r.weight * rateValue)}
                  </Radio.Button>
                </td>
                <td style={{ border: "1px solid lightgrey", width: "17.5%" }}>
                  <Radio.Button
                    style={{ width: "100%" }}
                    value={JSON.stringify({
                      acro: r.acro,
                      type: r.type,
                      weight: r.weight,
                      runTime: 180,
                      cost: Math.trunc(180 * r.weight * rateValue),
                    })}
                  >
                    ${Math.trunc(180 * r.weight * rateValue)}
                  </Radio.Button>
                </td>
                <td style={{ border: "1px solid lightgrey", width: "17.5%" }}>
                  <Radio.Button
                    style={{ width: "100%" }}
                    value={JSON.stringify({
                      acro: r.acro,
                      type: r.type,
                      weight: r.weight,
                      runTime: 365,
                      cost: Math.trunc(365 * r.weight * rateValue),
                    })}
                  >
                    ${Math.trunc(365 * r.weight * rateValue)}
                  </Radio.Button>
                </td>
              </tr>
            ))}
          </Radio.Group>
        </div>
      )}

      {props.enablePreview && (
        <Button
          onClick={handleShowPreview}
          style={{
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          Banner Preview
        </Button>
      )}

      {showPreview && <BannerPreview bannerLocation={bannerLocation} />}
    </div>
  );
};

export default RateCard;
