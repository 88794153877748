import React from "react";
import { Divider, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const Add = (props) => {
  return (
    <div>
      {props.menu}
      <Divider className="office-add-input-divider" />
      <div className="office-add-input-container">
        <Input
          className="office-add-input"
          value={props.inputState[props.type]}
          placeholder = {props.placeholder}
          onChange={(e) =>
            props.setInputState({
              ...props.inputState,
              [`${props.type}`]: e.target.value,
            })
          }
        />
        <a className="office-add-input-plus" href="/" onClick={props.add}>
          <PlusOutlined /> Add
        </a>
      </div>
    </div>
  );
};

export default Add;
