import React from "react";
import { withRouter } from "react-router-dom";
import { Modal, Button } from "antd";

const brandIMG = "https://s3.amazonaws.com/dev.nocap.com/uploads/logo.png";

class OnBoardCompleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentLoading: false,
    };
  }

  render() {
    return (
      <div>
        <Modal
          visible={this.props.show}
          title="Welcome to No Cap"
          closable={false}
          width="650px"
          footer={[
            <Button
              key="submit"
              className="btn-bordered"
              style={{ borderRadius: ".5em" }}
              onClick={() => {
                this.props.history.push("/profile/advertiser");
              }}
            >
              Continue to Account
            </Button>,
          ]}
        >
          <div>
            <img
              style={{ display: "block", margin: "0 auto" }}
              src={brandIMG}
              width={236}
              alt="brand"
            />
            <p style={{ textAlign: "center" }}>
              Your profile set up is now complete. You can view all your
              information under the account section. Click a quick redirect
              button to start managing what matters most to your service.
            </p>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {/* IF FREE ACCOUNT EXCLUDE THIS OPTION*/}

              {/* {this.props.accountStatus === "Free" ? null :
            <Button key="Advertisments"
            className="btn-orange"
            style={{borderRadius: ".5em", }}
            onClick={() => { window.location = `${config.WINDOW_URL}account/Advertisements` } }>
              Add More Banners
            </Button>} */}
            </div>
            {/* <div style={{textAlign: "right", marginBottom: "1em"}}>
            <h4 style={{fontWeight: "900", fontSize: "15px"}}>Need Help?</h4>
            Call 1-949-701-2175
            </div> */}
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(OnBoardCompleteModal);
