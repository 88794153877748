import React, { useContext } from "react";
import { Steps, Button, Row, Col, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import { SignUpContext } from "../../../providers/SignupContext";
import useBannerCalculation from "./useBannerCalculation";

export const StepButtons = () => {
  const history = useHistory();
  const {
    saveCompletedProfile,
    setShowCompletedModal,
    setDisableNext,
    saveService,
    disableNext,
    isLoading,
    step,
    setStep,
    profileData,
  } = useContext(SignUpContext);

  const { saveAds } = useBannerCalculation();

  const handleNextStep = async () => {
    try {
      if (step === 1) {
        await saveService();
      } else if (step === 3 && !profileData.skipBanner) {
        await saveAds();
      } else {
        setStep(step + 1);
        localStorage.setItem("step", Number(step) + 1);
      }
    } catch (err) {
      console.log("Error - ", err);
    }
    if (step < 4) {
      setDisableNext(true);
    }
  };

  const doneOnboarding = async () => {
    // if (this.state.stepStatus.membership.name === "Free") {
    await saveCompletedProfile();
    await setShowCompletedModal(true);
    message.success("Your profile has been saved!");
    message.loading("Redirecting...");
    setTimeout(() => {
      history.push("/");
    }, 1000);
    // } else {
    //   if (this.state.paymentComplete) {
    //     this.setState({ showCompletedModal: true });
    //     //save banner here
    //   } else {
    //     message.error("Please complete payment to continue");
    //   }
    //   //TAKE PAYMENT AND CONFIRM BANNER
    // }
  };

  return (
    <div
      className="steps-action"
      style={{
        display: "flex",
        justifyContent: step === 1 ? "flex-end" : "space-between",
        marginBottom: 16,
      }}
    >
      {step > 1 && (
        <Button className="btn-bordered" onClick={() => setStep(step - 1)}>
          Previous
        </Button>
      )}
      <Button
        className="btn-solid"
        style={{ float: "right", width: "7em" }}
        onClick={step < 5 ? handleNextStep : doneOnboarding}
        disabled={disableNext}
      >
        {isLoading ? (
          <LoadingOutlined style={{ fontSize: `12px` }} />
        ) : step < 5 ? (
          "Next"
        ) : (
          "Done"
        )}
      </Button>
    </div>
  );
};

const OnboardingSteps = () => {
  const { Step } = Steps;
  const { step } = useContext(SignUpContext);

  const stepStatus = (val) => {
    if (val === step) {
      return "process";
    } else if (val < step) {
      return "finish";
    } else {
      return "wait";
    }
  };
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={18}>
        <Steps className="onboarding-steps" responsive>
          <Step status={stepStatus(1)} title="Description" />
          <Step status={stepStatus(2)} title="Plans" />
          <Step status={stepStatus(3)} title="Banners" />
          <Step status={stepStatus(4)} title="Agreement" />
          <Step status={stepStatus(5)} title="Payment" />
        </Steps>
      </Col>

      <Col xs={24} sm={24} md={24} lg={18}>
        <StepButtons />
      </Col>
    </Row>
  );
};

export default OnboardingSteps;
