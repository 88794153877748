// Libraries
import React, { useContext, useEffect } from "react";
import { Tag } from "antd";
import axios from "axios";

// Components
import CandidatesSection from "../search/CandidatesSection";
import { CandidateContext } from "../../providers/CandidateContext";
import CandidateTagList from "./CandidateTagList";
import config from "../../config";

// Ant Design Components
import { Button, Form, Input } from "antd";

const CandidateSearchAndTags = ({
  label,
  onSearch,
  candidateData,
  onClick,
  loading,
}) => {
  const { candidateTags, setCandidateTags } = useContext(CandidateContext);

  useEffect(() => {
    getElectionsData();
  }, [candidateTags]); // eslint-disable-line

  async function getElectionsData() {
    if (candidateTags?.length > 0) {
      let headers = {
        ids: JSON.stringify(candidateTags),
        token: localStorage.getItem("access_token"),
      };
      const { data: candidateResultData } = await axios.get(
        `${config.CANDIDATES}/candidates/find-by-ids`,
        { headers }
      );

      setCandidateTags(candidateResultData);
    }
  }

  function removeCandidate(id) {
    // const newTags = candidateTags;
    setCandidateTags((prev) => prev.filter((v) => v._id !== id));
  }

  return (
    <>
      <Form.Item name="candidates" label={label ? label : "Candidates"}>
        {candidateData.candidates > 0 ? <CandidateTagList /> : null}

        {candidateTags.length > 0 &&
          candidateTags.map((candidate) => (
            <Tag
              key={candidate._id}
              closable
              onClose={() => removeCandidate(candidate._id)}
              style={{ marginBottom: 8 }}
            >
              {candidate?.bio?.name}
            </Tag>
          ))}

        <Input.Search
          allowClear
          placeholder="Search by name"
          size="large"
          onSearch={(value) => onSearch(value)}
          id="search-candidate"
          loading={loading}
        />
        {candidateData.candidates.length > 0 && (
          <Button className="btn-transparent" onClick={onClick}>
            Clear Result
          </Button>
        )}
      </Form.Item>
      {candidateData.candidates.length > 0 && (
        <CandidatesSection data={candidateData} newsForm />
      )}
    </>
  );
};

export default CandidateSearchAndTags;
