import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SingleImageUploader from "../shared/imageUploaders/singleImage/SingleImageUploader";
import { Form, Input, Row, Col, Button, message, Spin } from "antd";
import axios from "axios";
import config from "../../config";

const UserForm = (props) => {
  // Profile Picture
  const [isLoading, setIsLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState(
    props.edit ? JSON.parse(localStorage.getItem("user")).picture : ""
  );
  const nickName = props.edit
    ? JSON.parse(localStorage.getItem("user")).nickname
    : "";
  const userEmail = props.edit
    ? JSON.parse(localStorage.getItem("user")).email
    : "";

  const history = useHistory();

  // Submission
  const onFinish = (values) => {
    setIsLoading(true);
    const doc = JSON.stringify({
      role: "user",
      nickname: values.displayName,
      email: values.email,
      picture: logoUrl,
      authorized: true,
    });

    return axios
      .patch(
        `${config.USERS}/users`,
        {},
        {
          headers: {
            token: localStorage.getItem("access_token"),
            doc: encodeURIComponent(doc),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (props.edit) {
            message.success("You profile has been updated.");
          } else {
            message.success("You have been successfully registered as a User!");
          }
          let user = JSON.parse(localStorage.getItem("user"));
          user.picture = logoUrl;
          user.nickname = values.displayName;
          user.email = values.email;
          user.role = "user";
          localStorage.setItem("role", "user");
          localStorage.setItem("user", JSON.stringify(user));
          history.push("/");
        } else {
          message.error(
            "Something went wrong, make sure you are connected to the internet."
          );
        }
        setIsLoading(false);
      });
  };

  //Navigation - Cancel Button
  const backToAccountTypesOptions = () => {
    let path = `/profile-start`;
    if (props.edit) {
      path = "/";
    }
    history.push(path);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Form
        name="userInfoForm"
        className="accountForm"
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          displayName: `${nickName}`,
          email: userEmail,
        }}
      >
        <Row gutter={[12]}>
          <Col span={24} style={{ marginBottom: "150px" }}>
            <SingleImageUploader
              handleImageUpload={(url) => setLogoUrl(url)}
              imagePreviewUrl={logoUrl}
              typeKey={"user"}
              title="Photo (optional)"
              layout={"logo"}
            />
          </Col>
          <Col span={24}>
            <Form.Item
              label="Display Name"
              name="displayName"
              rules={[
                {
                  required: true,
                  message: "Please enter display name",
                },
              ]}
            >
              <Input placeholder="Enter display name" disabled={isLoading} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Primary Email Address (not available to the public)"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter valid email",
                },
              ]}
            >
              <Input placeholder="Enter email" disabled={isLoading} />
            </Form.Item>
          </Col>
          <Col
            span={24}
            style={{
              textAlign: "right",
            }}
          >
            <Form.Item>
              <Button
                className="btn-bordered"
                onClick={backToAccountTypesOptions}
                style={{ margin: "0px 5px", width: "85px" }}
                type="text"
              >
                Cancel
              </Button>
              <Button
                className="btn-solid"
                htmlType="submit"
                style={{ margin: "0px 5px", width: "85px" }}
                type="text"
                disabled={isLoading}
              >
                {isLoading ? <Spin /> : "Save"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UserForm;
