import React, { useState } from "react";
import { Button, Empty, List } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const UpdateContent = (props) => {
  const [upVotes, setUpVotes] = useState(0);
  const [downVotes, setDownVotes] = useState(0);

  const onUpVote = (e) => {
    console.log("UpVote");
    setUpVotes((upVotes) => upVotes + 1);
  };

  const onDownVote = (e) => {
    setDownVotes((downVotes) => downVotes - 1);
  };

  return (
    <div>
      {props.blogData.length ? (
        <div>
          <List
            itemLayout="vertical"
            size="large"
            pagination={{
              onChange: (page) => {
                console.log(page);
              },
              pageSize: 3,
            }}
            dataSource={props.blogData}
            renderItem={(item) => (
              <List.Item
                key={item.title}
                extra={<img width={180} alt="logo" src={item.coverImage} />}
                actions={[
                  <Button
                    onClick={onUpVote}
                    type="text"
                    style={{
                      height: "5px",
                      fontSize: "14px",
                    }}
                    icon={<CheckOutlined />}
                  >
                    {""}
                    {upVotes} UpVote
                  </Button>,

                  <Button
                    onClick={onDownVote}
                    type="text"
                    style={{
                      height: "5px",
                      fontSize: "14px",
                    }}
                    icon={<CloseOutlined />}
                  >
                    {" "}
                    {downVotes} DownVote
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={<a href={item.videoUrl}>{item.title}</a>}
                  description={`Author: ${item.author}`}
                />
                {item.editorial}
              </List.Item>
            )}
          />
        </div>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"No information to view."}
        />
      )}
    </div>
  );
};
export default UpdateContent;
