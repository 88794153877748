import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Row, Col, Affix, BackTop } from "antd";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import AdHelper from "../shared/ad/AdHelper";

function CreateForm({ onCancel, loading, children, submitLabel, disabledBtn }) {
  const history = useHistory();
  const createButton = (
    <Button
      className="btn-solid"
      htmlType="submit"
      size="large"
      icon={<SaveOutlined />}
      loading={loading}
      disabled={loading || disabledBtn}
      // onClick={onSubmit}
    >
      {submitLabel ? submitLabel : "Submit"}
    </Button>
  );

  function onClear() {
    if (onCancel) {
      onCancel();
    } else {
      history.goBack();
    }
  }

  const cancelButton = (
    <Button
      className="btn-bordered"
      onClick={onClear}
      icon={<CloseOutlined />}
      size="large"
    >
      Cancel
    </Button>
  );

  const backButton = (
    <Button
      className="btn-bordered"
      onClick={onClear}
      icon={<ArrowLeftOutlined />}
      size="large"
    >
      Back
    </Button>
  );

  return (
    <div className="BlogPost">
      <div className="constrain">
        <header>
          <div className="header-buttons">
            {backButton}
            {createButton}
          </div>
        </header>
        <Row>
          <Col xs={0} xl={3}>
            <Affix offsetTop={10}>
              <AdHelper page="news-post" position="left" />
            </Affix>
          </Col>
          <Col xs={24} xl={18} className="form-body">
            {children}
          </Col>
          <Col xs={0} xl={3}>
            <Affix offsetTop={10}>
              <AdHelper page="news-post" position="right" />
            </Affix>
          </Col>
          <Col xs={12} xl={0}>
            <AdHelper page="news-post" position="left" />
          </Col>
          <Col xs={12} xl={0}>
            <AdHelper page="news-post" position="right" />
          </Col>
        </Row>
        <div className="footer">
          {cancelButton}
          {createButton}
        </div>
      </div>
      <BackTop />
    </div>
  );
}

export default CreateForm;
