import React from "react";
import { Form, Input, InputNumber } from "antd";
import {
  EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

const OfficeContacts = ({ officeAddressQuery, setOfficeAddressQuery }) => {
  return (
    <Form.Item label="Office Contacts">
      <Form.Item
       
      >
        <Input
          suffix={<EnvironmentOutlined />}
          id="address-place-autocomplete"
          type="text"
          placeholder="search office address"
          onChange={(event) => setOfficeAddressQuery(event.target.value)}
          value={officeAddressQuery}
          onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
        />
      </Form.Item>
      <Form.Item label = "Phone" name="phone" 
        rules={[
              {
                required: true,
                message: "Please enter your primary number",
              },
            ]}
      >
        <InputNumber
          type="number"
          suffix={<PhoneOutlined />}
          placeholder="enter primary number (available to the public)"
          onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
          controls={false}
          style={{width: "100%"}}
          addonAfter={<PhoneOutlined />}
        />
      </Form.Item>
      <Form.Item label = "Email Address" name="email" rules={[
            {
              required: true,
              message: "Please enter your email address",
            },
          ]}>
        <Input
          suffix={<MailOutlined />}
          placeholder="enter email address"
          onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
        />
      </Form.Item>
      <Form.Item name="fax">
        <Input
          type="number"
          suffix={<PrinterOutlined />}
          placeholder="enter fax number"
          onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
        />
      </Form.Item>
    </Form.Item>
  );
};

export default OfficeContacts;
