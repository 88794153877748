import React from "react";
import DirectoryGridItem from "./DirectoryGridItem";
import { LoadingOutlined } from "@ant-design/icons";

export default (props) => {
  const spinner = (
    <div className="spinner-container">
      <LoadingOutlined style={{ fontSize: "50px", width: "150px" }} />
    </div>
  );

  //manually pushed mock items to the top
  let mockOrgs = [
    "World Wildlife Fund",
    "Women's March",
    "The Federalist Society",
    "National Federation of Republican Women",
    "Libertarian",
    "Democrats for Life",
    "Blue Dog Coalition",
    "Black Lives Matter",
    "350",
  ];
  if (props.services.length) {
    mockOrgs.forEach((org) => {
      props.services.unshift(
        props.services.splice(
          props.services.findIndex((elt) => elt.organization_name === org),
          1
        )[0]
      );
    });
  }

  const filterServices = props.services.filter((service) => {
    if (props.activeFilters.length > 0) {
      const intersection = service.descriptors.categories.filter((v) =>
        props.activeFilters.some((a) => a === v)
      );

      if (intersection.length > 0) {
        return service;
      }
      return false;
    }
    return service;
  });

  const services =
    filterServices.length &&
    filterServices.map((service) => {
      return <DirectoryGridItem service={service} key={service._id} />;
    });

  return (
    <div className="DirectoryGrid">
      <div className="dgrid-inner">{services || spinner}</div>
    </div>
  );
};
