import React from "react";
import { Card } from "antd";
import Careers from "../shared/careers/CareerTab";
import SearchNewsCard from "./SearchNewsCard";

const newsGridStyle = {
  width: 390,
  height: 550,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const NewsSection = (props) => {
  return (
    props.data.blogs &&
    props.data.blogs.map((subCategory, index) => {
      if (subCategory._id !== "Careers") {
        return (
          <Card
            key={subCategory._id}
            title={subCategory._id}
            style={{ width: "100%", border: "none" }}
          >
            {subCategory.blogs.map((blog) => {
              return (
                <Card.Grid style={newsGridStyle} key={blog["_id"]}>
                  <SearchNewsCard
                    post={blog}
                    pathname={`/news/${blog["_id"]}`}
                  />
                </Card.Grid>
              );
            })}
          </Card>
        );
      } else {
        return (
          <Card title="Careers" style={{ width: "100%", border: "none" }}>
            <Careers careerPosts={subCategory.blogs} />
          </Card>
        );
      }
    })
  );
};

export default NewsSection;
