import React from "react";
import { NavLink } from "react-router-dom";
import { Card, Avatar, Rate, Space, Typography } from "antd";
import { isOpenNow } from "../../helpers";
import { CheckCircleFilled } from "@ant-design/icons";
import moment from "moment";

// For redirecting when use clicks 'advertise here bizcard'
// function mutatePlaceholderLink(id) {
//   if (id.includes("advertise-here")) return "/signup";
//   return `/services/${id}`;
// }

export default (props) => {
  const { _id, organization_name, location, descriptors, rating, images } =
    props.service;

  const logoUrl = "https://s3.amazonaws.com/dev.nocap.com/uploads/logo.png";

  const categories = descriptors.categories;

  const ReturnCategories = () => {
    if (categories.length >= 2) {
      return `{${categories[0]}  ${categories[1]}}`;
    }
    if (categories.length === 1) {
      return categories[0];
    }
    if (categories.length < 1) {
      return <div />;
    }
  };

  const { Meta } = Card;

  const gridStyle = {
    width: "100%",
    textAlign: "left",
    padding: "8px 16px",
    height: "auto",
  };

  return (
    <Card.Grid style={gridStyle}>
      <NavLink
        style={{ width: "100%", height: "90px" }}
        to={{
          pathname: `/services/${_id}`,
          state: {
            data: props.service,
          },
        }}
      >
        <Meta
          avatar={
            <Avatar
              shape="square"
              style={{ height: "65px", width: "65px" }}
              src={images.logo}
            />
          }
          title={
            <div className="cardDescriptor">
              <div className="item-1">
                <div style={{ lineHeight: "18px" }}> {organization_name}</div>
                <Space>
                  <Rate
                    style={{ color: "black" }}
                    value={rating?.stars || 0}
                    defaultValue={0}
                    character={<CheckCircleFilled />}
                    disabled
                  />
                  <Typography.Text type="secondary">
                    ({`${rating?.total || 0}`})
                  </Typography.Text>
                </Space>
              </div>
              <div className="item-2">
                <Avatar
                  shape="square"
                  style={{
                    // position: "absolute",

                    // top: 0,
                    float: "right",
                    // zIndex: 1000,
                    // bottom: "12px"
                  }}
                  src={logoUrl}
                />
              </div>
            </div>
          }
          description={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <ReturnCategories />
              {isOpenNow(location.hours, moment()) ? (
                <div
                  style={{
                    marginTop: 16,
                    color: "green",
                    opacity: 0.75,
                    textAlign: "right",
                    fontStyle: "italic",
                    fontSize: "12px",
                  }}
                >
                  open
                </div>
              ) : (
                <div
                  style={{
                    marginTop: 16,
                    color: "orangered",
                    opacity: 0.75,
                    textAlign: "right",
                    fontStyle: "italic",
                    fontSize: "12px",
                  }}
                >
                  closed
                </div>
              )}
            </div>
          }
        />
      </NavLink>
    </Card.Grid>
  );
};
