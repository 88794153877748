import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Table, Button, Input, message, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import config from "../../config";
import NoCapManifest from "../../nocap.json";
import moment from "moment";

const Queues = (props) => {
  const history = useHistory();
  const [keywords, setKeywords] = useState("");

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  async function get() {
    try {
      setLoading(true);
      const result = await axios.get(`${config.QUEUES}/queues/all`);
      setData(result.data);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    get();
  }, []); // eslint-disable-line

  if (error)
    return message.error(
      "Oops ... Something went wrong, we could not load the candidates information"
    );

  const handleCandidateSignUpFormAction = async (event, record, action) => {
    event.stopPropagation();
    let queue = data.filter((queue) => queue._id === record._id);
    let candidate_id = queue[0].candidate_id;

    // update candidate status from 'draft' to approve / reject
    const candidateDoc = JSON.stringify({
      status: action,
      archived: action === "approve" ? false : true,
    });

    /*
    update queue status from 'in queue' to approve / reject,
    and review_by user information and updated time
    */
    const queueDoc = JSON.stringify({
      status: action,
      review_by: {
        name: JSON.parse(localStorage.getItem("user")).nickname,
        _id: JSON.parse(localStorage.getItem("user"))._id,
      },
      updated_at: new Date(),
    });

    // notify the admin and candidate by email
    const emailDoc = {
      email: queue[0].user.email,
      name: queue[0].user.name,
      candidate_id,
      action,
      review_by: JSON.parse(localStorage.getItem("user")).nickname,
    };

    let promise = [];

    if (record.category === "Change Account Type" && action === "approve") {
      const doc = JSON.stringify({
        role: "candidate",
      });
      promise.push(
        axios.patch(
          `${config.USERS}/users`,
          {},
          {
            headers: {
              token: record.userToken,
              doc: encodeURIComponent(doc),
            },
          }
        )
      );
    } else {
      promise.push(
        axios.patch(
          `${config.CANDIDATES}/candidates/update`,
          {},
          {
            headers: {
              candidate_id,
              token: localStorage.getItem("access_token"),
              doc: encodeURIComponent(candidateDoc),
            },
          }
        )
      );
    }

    await Promise.all([
      ...promise,
      axios.patch(
        `${config.QUEUES}/queues/update`,
        {},
        {
          headers: {
            queue_id: queue[0]._id,
            token: localStorage.getItem("access_token"),
            doc: encodeURIComponent(queueDoc),
          },
        }
      ),
      axios.post(`${config.EMAIL}/mailer`, emailDoc),
    ]);
    let updatedData = data.filter((queue) => queue._id !== record._id);
    setData(updatedData);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "date",
      // width: 200,
      defaultSortOrder: "descend",
      render: (date) => moment(date).format("MMMM DD, YYYY"),
      sorter: (a, b) => {
        return moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf();
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      // width: 200,
      filters: NoCapManifest.queuesCategories,
      filterMultiple: false,
      onFilter: (value, record) => record.category === value,
    },
    {
      title: "Task Name",
      dataIndex: "task_name",
      key: "task_name",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "status",
      // width: 300,
      render: (record) => {
        return (
          <div className="queues-actions">
            <Button
              className="btn-bordered"
              onClick={(event) =>
                handleCandidateSignUpFormAction(event, record, "approve")
              }
            >
              Approve
            </Button>{" "}
            <Button
              className="btn-bordered"
              onClick={(event) =>
                handleCandidateSignUpFormAction(event, record, "reject")
              }
            >
              Reject
            </Button>
          </div>
        );
      },
    },
  ];

  const re = new RegExp(keywords, "gi");
  const filteredQueues = keywords.length
    ? data.filter((event) => event.task_name.match(re))
    : data;

  return (
    <Fragment>
      <Typography.Title>Queues</Typography.Title>
      <Input
        size="large"
        placeholder="Filter by Task Name"
        suffix={<SearchOutlined />}
        allowClear
        onChange={(e) => setKeywords(e.target.value)}
        style={{ zIndex: 1, width: "100%", margin: "10px 0" }}
      />
      <Table
        loading={loading}
        className="events-table"
        onRow={(record) => ({
          onClick: () => {
            if (record.category === "Candidate Sign Up") {
              history.push(`/candidate/${record.candidate_id}`);
            }
          },
        })}
        rowKey={(record) => record._id}
        columns={columns}
        dataSource={filteredQueues || null}
        pagination={{ defaultPageSize: 25 }}
      />
    </Fragment>
  );
};

export default Queues;
