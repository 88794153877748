import React from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
const SearchNewsCard = (props) => {
  let postPreview = `${props.post.body.substring(0, 150)} ...`;
  let parsedPost = ReactHtmlParser(postPreview);

  return (
    <Link
      to={{
        pathname: props.pathname,
        state: {
          data: props.post,
        },
      }}
    >
      {props.post.coverImage ? (
        <img
          style={{ width: "100%", height: "100%" }}
          src={props.post.coverImage}
          alt={props.post.title}
        />
      ) : null}
      {props.post.videoUrl ? (
        <iframe
          src={props.post.videoUrl}
          frameBorder="0"
          style={{ width: "100%", height: "100%" }}
          allow="encrypted-media"
          allowFullScreen
          title="video"
        />
      ) : null}
      <div className="title">
        <h3>{props.post.title}</h3>
      </div>
      <div className="date">
        {moment(props.post.created_at).format("MMMM DD, YYYY")}
      </div>
      <div className="body">{parsedPost}</div>
      <div className="origin">{props.post.author} </div>
    </Link>
  );
};

export default SearchNewsCard;
