import React from "react";
import { useHistory } from "react-router-dom";
import { Input } from "antd";
const { Search } = Input;

const SearchNav = (props) => {
  let history = useHistory();
  const handleSearch = (value) => {
    if (value.length > 0) {
      history.push({
        pathname: "/search",
        search: `?query=${value}`,
        state: { detail: value },
      });
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <Search
        placeholder="Search by keyword"
        id="search-keyword"
        onSearch={(value) => handleSearch(value)}
        size="large"
        allowClear
        className="search-nav-bar"
      />
    </div>
  );
};

export default SearchNav;
