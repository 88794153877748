import React, { useState } from "react";
import { Input } from "antd";
import { isNumberKey, isValidHttpUrl } from "../../../helpers";

const CustomInput = ({ form, type, placeholder, name, onChange, value }) => {
  const [error, setError] = useState("");
  const onInputChange = (e) => {
    if (type === "tel" && isNumberKey(e.target.value)) {
      onChange(e);
    } else if (type === "url") {
      onChange(e);
    } else {
      return;
    }
  };

  const checkForError = () => {
    if (type === "url" && !isValidHttpUrl(value)) {
      setError("ant-input-status-error");
    } else {
      setError("");
    }
  };

  return (
    <Input
      className={`ant-input ant-input-lg ${error}`}
      type={type}
      placeholder={placeholder}
      name={name}
      onChange={onInputChange}
      onBlur={checkForError}
      value={value}
    />
  );
};

export default CustomInput;
