import React from "react";
import axios from "axios";
import JumboGrid from "./JumboGrid";
import DoubleHalfBanner from "./DoubleHalfBanner";
import VerticalBanner from "../../ad/VerticalBanner";
import config from "../../../config";
import { useLoading } from "../../../hooks";

const AdHelper = (props) => {
  const { setIsLoading } = useLoading();

  const getAd = async (type, page) => {
    setIsLoading(true);
    try {
      let lat = 34;
      let lng = -118;
      const response = await axios.get(`${config.ADS}/ads/other`, {
        params: {
          lng: lng,
          lat: lat,
          page: page,
          type: type,
          limit: 1,
        },
      });
      const post = response.data;

      setIsLoading(false);

      if (post.length >= 1) {
        return `/${post[0].image}`;
      }
    } catch (err) {
      console.log(err, "There was an error connecting to the network!");
    }
  };

  let ads = "";
  switch (props.page) {
    case "news":
    case "events":
    case "my-voting-list":
      ads = (
        <div>
          <DoubleHalfBanner getAd={getAd} page={props.page} />
        </div>
      );
      break;
    case "news-post":
      ads = (
        <div>
          <VerticalBanner
            getAd={getAd}
            page={props.page}
            position={props.position}
          />
        </div>
      );
      break;
    case "home-top":
      ads = (
        <div>
          <JumboGrid getAd={getAd} />
        </div>
      );
      break;

    //hard coded for demo purpose
    case "home-side":
      ads = (
        <div>
          <VerticalBanner getAd={getAd} page="news-post" position="left" />
        </div>
      );
      break;
    default:
      ads = (
        <div>
          <JumboGrid getAd={getAd} />
        </div>
      );
  }
  return ads;
};

export default AdHelper;
