import React from "react";
import { Form, Input, Col, Button } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const Contribution = ({ formValues }) => {
  return (
    <Col span={24}>
      <Form.List name="contributions">
        {(fields, { add, remove }) => {
          const contributions = formValues?.contributions || [];
          const enableBtn =
            contributions.length > 0
              ? contributions.every(
                (v) =>
                  v?.amount && v?.name && v?.industry && v?.yearOfContribution
              )
              : true;

          return (
            <Form.Item label="Contributors (Please enter most recent to oldest)">
              {fields.map((field, index) => (
                <div key={"contribution" + index}>
                  <Form.Item
                    name={[field.name, "industry"]}
                    style={{ marginBottom: 16 }}
                  >
                    <Input placeholder="name" />
                  </Form.Item>

                  <Form.Item
                    name={[field.name, "name"]}
                    style={{ marginBottom: 16 }}
                  >
                    <Input placeholder="industry description" />
                  </Form.Item>

                  <Form.Item
                    name={[field.name, "amount"]}
                    style={{ marginBottom: 16 }}
                  >
                    <Input placeholder="amount" type="number" />
                  </Form.Item>

                  {/* <Form.Item name={[field.name, "yearOfContribution"]}>
                    <Input
                      placeholder="year to contribution"
                      type="number"
                      min={1}
                    />
                  </Form.Item> */}

                  {fields.length > 1 ? (
                    <Form.Item style={{ marginBottom: 16 }}>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Form.Item>
                  ) : null}
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  style={{
                    width: "100%",
                  }}
                  disabled={!enableBtn}
                >
                  <PlusOutlined /> Add field
                </Button>
              </Form.Item>
            </Form.Item>
          );
        }}
      </Form.List>
    </Col>
  );
};

export default Contribution;
