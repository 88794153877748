// Libraries
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

// Components
import EditNewsPost from "../news/EditNewsPost";
import Loading from "../Loading";

// Ant Design Components
import { message } from "antd";

// Helpers & Config
import config from "../../config";
import useNewsData from "../../hooks/useNewsData";

const EditNewsPostPage = (props) => {
  const { id } = useParams();

  // Retrieve news post data and states with custom hook
  const { data, error, loading, get } = useNewsData(
    `${config.BLOGS}/blogs/findOne/${id}`
  );

  useEffect(() => {
    get();
    // eslint-disable-next-line
  }, []);

  if (error)
    return message.error(
      "Oops ... Something went wrong, we could not load the information for this article"
    );

  if (loading) {
    return <Loading />;
  }

  if (data && data !== null) {
    return <EditNewsPost data={data} />;
  }
};

export default EditNewsPostPage;
