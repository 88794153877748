import React, { useContext, useEffect } from "react";
import { Checkbox } from "antd";
import { SignUpContext } from "../../../providers/SignupContext";

const brandIMG = "https://s3.amazonaws.com/dev.nocap.com/uploads/logo.png";

export default function ConfirmSubscription(props) {
  // Declare a new state variable, which we'll call "count"
  const { profileData, setProfileData, setDisableNext } =
    useContext(SignUpContext);
  const { termAgreement, term2Agreement } = profileData;
  useEffect(() => {
    if (termAgreement && term2Agreement) {
      setDisableNext(false);
    }
  }, [setDisableNext, termAgreement, term2Agreement]);

  const setCheckbox = (field, val) => {
    setProfileData((prev) => ({
      ...prev,
      [field]: !val,
    }));
  };

  return (
    <div
      style={{ textAlign: "left", padding: "0 3em 3em 3em", fontSize: "16px" }}
    >
      <img
        style={{ display: "block", margin: "0 auto" }}
        src={brandIMG}
        width={236}
        alt="brand"
      />
      <p>
        When you click the “Place Order”button, No Cap will send you an email
        acknowledging receipt of your order. Your contract to purchase ad space
        will not be complete until No Cap notifies you via email. Any additional
        credit card fees (interest/chargebacks) are non-refundable and will be
        applied to your account.
        <br />
        <br />
        Customers may receive a full refund if they cancel within twenty-four
        (24) hours of subscribing. Customers must contact.
        <br />
        <br />
      </p>

      <Checkbox
        name="termAgreement"
        onChange={() => setCheckbox("termAgreement", termAgreement)}
        checked={termAgreement}
      >
        By checking this box, I am providing an electronic signature
        acknowledging and agreeing to the{" "}
        <a href="/terms">Terms and Conditions </a>and understand that
        information will be used as described in our
        <a href="/privacy"> Privacy Policy</a>.
      </Checkbox>
      <br />
      <br />
      <Checkbox
        key="check2"
        style={{ margin: 0 }}
        name="term2Agreement"
        onChange={() => setCheckbox("term2Agreement", term2Agreement)}
        checked={term2Agreement}
      >
        If you are outside of the U.S., you agree to have information sent to
        the U.S., and understand U.S. information collection laws may not be the
        same as those in your country.
      </Checkbox>
    </div>
  );
}
