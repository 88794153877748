import React from "react";
import { BackTop } from "antd";

class PrivacyPolicyPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="constrain">
        <div className="inner">
          <h2>PRIVACY POLICY</h2>
          <p>
            Welcome to nocapvote.com (“Site”), owned and operated by No Cap
            Vote, LLC, its affiliates, subsidiaries, partners, and/or assigns
            (“our,” “we,” “us,” or “No Cap”).{" "}
          </p>
          <p>
            No Cap is an educational and political source that incudes, but is
            not limited to, news, current events, sports, weather, government,
            elections, campaigns, political issues, commentary, education,
            entertainment, travel, leisure, current news, directories, and/or
            product/service overviews and promotions.{" "}
          </p>
          <p>
            We are committed to exercising transparency in how we process,
            utilize, and disclose any/all information provided by you when using
            the Site via mobile and/or software applications or devices,
            portals, channels, sub-domains, and related services (“Services”) to
            improve and maintain your Site experience.{" "}
          </p>
          <p>
            Please read the Privacy Policy (“Policy”) carefully. By providing
            information to us and/or using this Site, you understand, agree, and
            accept the terms and conditions of this Policy. To protect our
            operations or those of any of our affiliates, you, also, understand,
            agree, and accept to comply with all applicable laws inside/outside
            the state/country in which you reside.{" "}
          </p>
          <p>
            No Cap reserves the right to make any changes and/or modifications
            to this Policy at any time. Should there be any material changes,
            all parties will be provided with notices via this Privacy Policy,
            email on file, postal service, Site posting/s, push notification/s,
            and/or the like. Use of the Site following any changes and/or
            modifications constitutes that you agree, understand, and accept
            any/all Policy revisions then in effect.{" "}
          </p>
          <p>
            If you do not agree with the terms and conditions of this Policy,
            please do not provide any information or use this Site.{" "}
          </p>

          <h2>INFORMATION COLLECTED</h2>
          <p>
            No Cap collects, processes, uses, including discloses any publicly
            available data or personally identifiable information that you
            furnish to us, which may be utilized to administer the service you
            have requested. By submitting any/all information through our Site,
            you understand, agree, and accept to the transfer of your personal
            data, via servers, which may be utilized to provide, improve,
            modify, and/or maintain our services, as well as to develop new
            services and offerings, content, products, features, tools, and/or
            links.{" "}
          </p>
          <p>
            As a result, any data that is collected, which you voluntarily
            provide to us, may require you to provide information for
            verification and/or eligibility purposes, such as company/individual
            name, address, telephone number, email, or any other data to take
            full advantage of certain areas of this Site. We reserve the right
            to use other information collected, including, without limitation,
            for any lawful business purpose, Site development, demographic data,
            i.e., an internet protocol address, the devices you use, such as the
            operating system versions, unique device identifiers, hardware
            model, and mobile information if you choose to use a mobile device
            to access this Site, navigation paths, search queries, and only
            publicly available data via public APIs via opt-in social networks
            and content Sites, including the use of cookies and other similar
            technologies. The server logs may utilize additional methods of
            collecting data through your and party’s various online submissions,
            i.e., online sign-ups, ad purchases, classified listings, calendar
            usage, blogs, comments, events/event registrations, group sign-ups,
            contests, awards, and posting information, service usage, where/when
            you visit online, and what choices you made while on the Site.{" "}
          </p>
          <p>
            No Cap may allow you to connect and share your comments,
            experiences, content, information, and/or profiles from the Site to
            social networking platforms, i.e. Facebook, Twitter, Instagram,
            Google, and the like, to name a few. You understand, agree, and
            accept that No Cap is not and will not be responsible for any of
            your information that you choose to use, provide, and/or share to
            any third-party, nor makes any representation nor offers any
            warranties on behalf of any third parties’ policies and practices.{" "}
          </p>
          <h2>SERVICE ACCESS</h2>
          <p>This Site:</p>
          <ul>
            <li>
              may disseminate promotional materials, newsletters, surveys,
              and/or offers,
            </li>
            <li>
              develops, promotes, recommends, and communicates products and
              services for public/private viewing/education/consumption, which
              are submitted by you, other businesses, suppliers, sponsors,
              and/or advertisers that may include, but is not limited to,
              photos, videos, reviews, posts, bookmarks, friends, lists, links,
              and the like,
            </li>
            <li>
              identifies, creates, tracks, respond, and customizes, based on the
              user’s/visitor’s needs of the Site,
            </li>
            <li>
              shares public information divulged on the Site with companies,
              outside organizations, subsidiaries, affiliates, partners, and/or
              individuals who visit this Site,
            </li>
            <li>
              does not control, represent, nor warranty the privacy policies and
              your privacy settings on third-party Sites, including social
              networks, other companies nationwide nor internationally,
            </li>
            <li>
              allows access to our database by third parties that services to
              us, such as IT services/experts, customer service, email delivery
              services, Google Analytics, or technical maintenance, based on our
              instruction and in compliance with the Policy, as well as security
              measures,
            </li>
            <li>
              may share publicly available data (not personally identifiable
              information) to our advertising customers, online education,
              corporate sponsors, social media platforms, or connected Sites to
              serve your ads to meet and/or exceed your needs and/or interests,
            </li>
            <li>
              requires third parties to adhere to privacy policies and does not
              authorize third parties to use any information except for the
              express purpose for which it is provided. However, No Cap is not
              responsible for any actions or policies of third parties,
              including, without limitation, any of our affiliates or any third
              party operating any Site to which this Site contains a link. Any
              links on the Site and/or any products nor services on the site
              does not imply any endorsement of the linked Site by No Cap or by
              our affiliates.
            </li>
            <li>
              retains ad tracking data for the period necessary permitted by
              law, in adherence to business and legal guidelines,
            </li>
            <li>
              may review, refine, upgrade, our security technology to exercise
              reasonable and feasible preventative measures against lost or
              unauthorized access to your information and on our Site,
            </li>
            <li>
              may allow you to edit, cancel, review, modify, and/or delete
              information, products, and/or services you submit on the Site,
            </li>
            <li>protects our rights and the rights of other users,</li>
            <li>
              only shares personal information to meet any applicable laws,
              regulations, legal processes, enforceable government requests,
              detection/prevention of fraud, security/safety issues of users or
              the public as required/permitted by law
            </li>
          </ul>
          <p>
            No Cap reserves the right to maintain backup archives of any/all
            data collected to maintain, improve, modify our services, and/or
            guarantee quality of service, for internal business purposes, such
            as data analyses, geo-locators/GPS location, audits, your operating
            system, any application versions, types of devices used, browser
            type/language, and developing new contests/services/promotional
            campaigns. Upon creating an account with No Cap, we reserve the
            right to store, collect, process, deliver, monitor, analyze, and/or
            email you transactional, administrative, service, and/or pertinent
            messages. We reserve the right to use, transfer, sell, and share
            aggregated, limited anonymous data of our online users/visitors for
            any business purpose, i.e., analyzing trends about the general use
            of our services, promotions, and in seeking compatible advertisers
            and partners. We reserve the right to buy or sell various assets. In
            the event any/all portions of the assets owned or controlled by No
            Cap, its parent, subsidiary/ies, or affiliate entity/ies are
            involved in any merger, acquisition, or other transaction involving
            the sale, assignment, joint venture, transfer, or acquisition by
            another company, the information from and/or about our Service users
            could be included in the transferred assets. Should such an event
            occur, we will use reasonable means to notify you via email, Site
            posting, and/or formal notice.{" "}
          </p>
          <p>
            All information is maintained in the United States and is subject to
            U.S. laws. Any information submitted on this Site, you understand,
            agree, and accept the transfer and storage of data and records in
            the United States and other countries providing varying levels of
            data security. In the event that any portion of the Site’s database
            is compromised, personal or otherwise, due to any unauthorized
            third-party’s ability to access such database, you understand,
            agree, and accept that No Cap, its parent, partner/s,
            subsidiary/ies, nor its affiliates will not be responsible for
            any/all damages caused directly or indirectly to you. Should cause
            of legal action be taken against you for (i) violation of this
            Privacy Policy and terms of use, (ii) interference with a party’s
            rights or property, and (iii) illegal conduct on the Site, you
            understand, agree, and accept that No Cap reserves the right to
            identify, share, release, and/or disclose any communications,
            electronic or otherwise, content, or data, at our sole discretion.{" "}
          </p>
          <h2>COOKIE POLICY</h2>
          <p>This Cookie Notice is part of the Privacy Statement.</p>
          <p>
            No Cap Vote’s cookies collects automated information when visiting{" "}
            <a href="www.nocapvote.com">
              <u>www.nocapvote.com</u>
            </a>{" "}
            (the “site”) to enhance and improve your web experience, allowing us
            to identify your performance on the site from other users, as well
            as assisting us to tailor our site to interactively meet your
            interests.{" "}
          </p>
          <p>What are cookies?</p>
          <p>
            Cookies are small text files and numbers that a website may send to
            a visitor’s computer or mobile device by a server for this Website,
            which assists the Website to (i) analyze data about web traffic,
            (ii) recognize your next visit on the Website, (iii) allow web
            applications to recognize your preferences, likes or dislikes, (iv)
            identify what pages are being used, (v) identify the visitor’s
            browser, (vi) allow you to log into secure areas of our website, and
            (vii) use a shopping card or make use of e-billing services. Cookies
            does not give us access to your computer or any information about
            you, other than the information you share with us. By modifying your
            web browser setting, you may restrict the use and disable cookies,
            and, simultaneously, may result an in inferior web experience. If
            you decline any cookies, you may not be able to take full advantage
            of this Website and may impact the functionality of the site. If you
            do not decline any cookies, the following will be placed on your
            device:{" "}
          </p>
          <h3>Google Analytics Tracking</h3>
          <p>
            To receive more comprehensive information on how to accept or
            decline cookies and Google Analytics, please visit{" "}
            <a href="http://www.aboutcookies.org/">
              <u>www.aboutcookies.org</u>
            </a>{" "}
            or{" "}
            <a href="http://www.google.com/analytics/learn/privacy.html">
              <u>http://www.google.com/analytics/learn/privacy.html</u>
            </a>
            .
          </p>
          <h3>Third-Party Cookies</h3>
          <p>
            This site engages with third-party cookies to create a profile of
            your interests based on the sites you visit within varying social
            networks, such as Twitter, LinkedIn, Facebook, and other external
            networks and platforms through enabling the social buttons. We do
            not control the dissemination of these cookies. Social buttons are
            for users to bookmark web pages. By placing third-party plug-ins and
            widgets on our Website, the third parties may place “session,”
            “first-party,” “third-party,” or “persistent” cookies on your
            browser. This site utilizes strictly necessary cookies allows you to
            navigate the site and use its features.{" "}
          </p>
          <p>
            Please visit the appropriate forums regarding policies for more
            information.
          </p>
          <h3>Tracking</h3>
          <p>
            Additionally, for statistical purposes, to count the number of
            visitors returning to our site, to compile reports about how
            visitors use our site, collect information to quantify and examine
            the performance of our advertisers, track page usage, and Internet
            banner advertisements, we engage with third-party tracking and
            advertising providers to act on our behalf to track and analyze your
            visit on our Website through the use of pixel tags and other similar
            platforms. When you return to the site, your browser lets the site
            read the tags. Pixel tags do not place any information on your
            device; it is used for purposes of monitoring the effectiveness of
            your visit on the site.
          </p>
          <p>
            At our discretion, we may modify or amend this Cookie Notice from
            time to time and shall be effective upon the date of modification.
          </p>
          <p>
            By using this site, you agree to our use of cookies, as described in
            this notice domestically, regionally, and even globally.
          </p>
          <h2>MINORS/CHILDREN</h2>
          <p>
            This Site is not directed to individuals under the age of 18.
            Children and/or minors under the age of 18 are not permitted to use
            this Site. Any viewing of this Site from minors under 18 years of
            age must get permission from the parent(s) or legal guardian(s)
            before making proceeding to the Site. Any/all information collected
            from under the age of 18 will be immediately deleted. Any
            information disclosed in publicly accessible sections of the Site
            may be utilized by other parties as described.
          </p>
          <p>
            Any questions or further assistance, please do not hesitate to
            contact us at{" "}
            <a href="mailto:info@nocapvote.com">
              <u>info@nocapvote.com</u>
            </a>
            .
          </p>
          <h2>CANCELLATION</h2>
          <p>
            Upon request to cancel your account within thirty (30) days before
            the next billing cycle, No Cap will no longer use your information
            to further service your needs.
          </p>
          <h2>CONTACT NO CAP</h2>
          <p>
            If you have any questions, or need further assistance, please do not
            hesitate to contact us at{" "}
            <a href="mailto:info@nocapvote.com">
              <u>info@nocapvote.com</u>
            </a>
            .
          </p>
        </div>
        <BackTop />
      </div>
    );
  }
}

export default PrivacyPolicyPage;
