import React from "react";
import {
  Collapse,
  List,
  Typography,
  Table,
  Empty
  //  Card, Space
} from "antd";
// import Comments from "../../comments/Comments";
// const { Text, Title } = Typography;
const moment = require('moment');
const { Panel } = Collapse;

const styles = {
  scrollContainer: {
    overflow: "auto",
    padding: "4px 16px",
    maxHeight: "350px",
  },
  cardBodyComments: {
    maxHeight: "230px",
    overflow: "auto",
    marginTop: "5px",
  },
  fixedDiv: {
    position: "sticky",
    width: "100%",
    height: "100px",
  },
};

const BioMain = (props) => {
  const personalData = [{ title: "Name", description: props.details.bio.name }];

  const personal = props.details.bio.personal;
  console.log(personal)
  console.log(props.details)

  if (personal.gender) {
    personalData.push({ title: "Gender", description: personal.gender });
  }
  if (personal.birthdate) {
    console.log(moment(personal.birthdate).format('YYYY-MM-DD'))
    personalData.push({
      title: "Birthdate",
      description: moment(personal.birthdate).format('YYYY-MM-DD'),
    });
  }
  if (personal.homeCity) {
    personalData.push({ title: "Home City", description: personal.homeCity });
  }
  // if (personal.family) {
  //   personalData.push({ title: "Family", description: personal.family });
  // }

  if (personal.religion) {
    personalData.push({
      title: "Religion",
      description: personal.religion,
    });
  }
  if (personal.education) {
    personalData.push({
      title: "Education",
      description: personal.education,
    });
  }
  console.log(personalData)

  const professionalExperiences = [];

  if(props.details.professionalExperiences !== undefined) {
    props.details.professionalExperiences.forEach((professionalExperience)=> {
      professionalExperiences.push({
        title: professionalExperience.title,
        startDate: moment(professionalExperience.startDate).format("YYYY-MM-DD"),
        endDate: moment(professionalExperience.endDate).format("YYYY-MM-DD")
      })
    })
  }

  const columnsProfessionalExperience = [
    { title: "Title", dataIndex: "title", key: "title", width: 100 },
    { title: "Start Date", dataIndex: "startDate", key: "startDate", width: 100 },
    { title: "End Date", dataIndex: "endDate", key: "endDate", width: 100 },
  ];

  let committees = [];

  if(props.details.committees !== null && props.details.committees !== undefined) {
    props.details.committees.forEach((committee) => {
      committees.push({
        date: moment(committee.date).format('YYYY-MM-DD'),
        description: committee.description
      });
    });
  }

  const columnsCommittee = [
    { title: "Committee Date", dataIndex: "date", key: "date", width: 100 },
    { title: "Description", dataIndex: "description", key: "description", width: 100 }
  ];


  const spouses = [];

  if(props.details.bio.personal.family !== undefined && props.details.bio.personal.family.spouses !== undefined) {
    props.details.bio.personal.family.spouses.forEach((spouse) => {
      spouses.push({
        Name: spouse.Name,
        childrens: spouse.childrens
      })
    })
  }

  const columnsSpouses = [
    { title: "Name", dataIndex: "Name", key: "Name", width: 100 },
    { title: "No of Children", dataIndex: "childrens", key: "childrens", width: 100 }
  ];

  if(props.details.professionalExperiences !== null) {
    console.log(props.details.professionalExperiences)
  }




  console.log(spouses);

  

  console.log(committees)

  return (
    <Collapse defaultActiveKey={["personal"]} accordion>
      
      {(professionalExperiences !== null)? (
        <Panel header="Professional Experience" key="professional">
          <div style={styles.scrollContainer}>
            
              <div>
                {professionalExperiences.length !== 0 ? (
                  <div>
                    <Table
                      columns={columnsProfessionalExperience}
                      dataSource={professionalExperiences}
                      pagination={{ pageSize: 8 }}
                    />
                  </div>
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={"Candidate has not yet provided information."}
                  />
                )}{" "}
              </div>
              {/* <List.Item style={{ whiteSpace: "pre-line" }}>
                {props.details.professionalExperiences}
              </List.Item> */}
              {/* <List.Item>
                <Col xs={{ span: 20, order: 1 }} xl={{ span: 24 }}>
                  {commentRender("professional")}
                </Col>
              </List.Item> */}

          </div>
        </Panel>
      ) : (
        <Panel header="Professional Experience" key="professional">
          <div style={styles.scrollContainer}>
            <List>
              {/* <List.Item>
                <Col xs={{ span: 20, order: 1 }} xl={{ span: 24 }}>
                  {commentRender("professional")}
                </Col>
              </List.Item> */}
            </List>
          </div>
        </Panel>
      )}
      {props.details.committees ? (
        <Panel header="Committee Experience" key="committee">
          <div style={styles.scrollContainer}>
          <div>
                {committees.length !== 0 ? (
                  <div>
                    <Table
                      columns={columnsCommittee}
                      dataSource={committees}
                      pagination={{ pageSize: 8 }}
                    />
                  </div>
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={"Candidate has not yet provided information."}
                  />
                )}{" "}
              </div>
          </div>
        </Panel>
      ) : (
        <Panel header="Committee Experience" key="committee">
          <div style={styles.scrollContainer}>
            <List>
              {/* <List.Item>
                <Col xs={{ span: 20, order: 1 }} xl={{ span: 24 }}>
                  {commentRender("committee")}
                </Col>
              </List.Item> */}
            </List>
          </div>
        </Panel>
      )}

      {/* <Panel header="Personal Information" key="personal">
        <div style={styles.scrollContainer}>
          <List
            dataSource={personalData}
            renderItem={(item) => (
              <List.Item style={{ whiteSpace: "pre-line" }}>
                <Typography.Text strong>{item.title} : </Typography.Text>
                {JSON.stringify(item.description)}
              </List.Item>
            )}
          ></List>
        </div>
      </Panel> */}

      <Panel header="Personal Information" key="personal">
        <div style={styles.scrollContainer}>
          <List>
            <List.Item style={{ whiteSpace: "pre-line" }}>
              <Typography.Text strong>Name : </Typography.Text>
              {props.details.bio.name}
            </List.Item>
          
          <List.Item style={{ whiteSpace: "pre-line" }}>
          {personal.birthdate? 
          (<div>
            <Typography.Text strong>Birthdate : </Typography.Text>
            {moment(personal.birthdate).format('YYYY-MM-DD')}
           </div>
          ):(null)}
          </List.Item>
          {/* <List.Item>
          <Typography.Text strong>Gender : </Typography.Text>
          {personal.gender? 
          (<div>
            
            {personal.gender}
           </div>
          ):(null)}
          </List.Item> */}

          <List.Item style={{ whiteSpace: "pre-line" }}>
          {personal.homeCity?
          (<div>
            <Typography.Text strong>Home city : </Typography.Text>
              {personal.homeCity}
          </div>
          ):(null)}
          </List.Item>
        
          <List.Item>

          {personal.education? 
          
          (<div>
            {personal.education.college? 
              ( 
                <div>
                <div style={{display:"flex"}}>
                <Typography.Text strong>College : </Typography.Text>
                <dd>{personal.education.college.join(",")}</dd>
                </div>

                <div>
                <Typography.Text strong>Highschool : </Typography.Text>
                {personal.education.highschool}
                </div>
                </div>
            ): null 

            }
           </div>
          ):(null)}
          </List.Item>

          <List.Item>
          {(personal.religion)?
          (Array.isArray(personal.religion)?
            (<div style={{display:"flex"}}>
              <Typography.Text strong>Religion : </Typography.Text>
              <dd>{personal.religion.join(",")}</dd> 
            </div>
          
          ): (
            <div style={{display:"flex"}}>
              <Typography.Text strong>Religion : </Typography.Text>
              <p>{personal.religion}</p> 
            </div>
          )):
           null
          }
          </List.Item>

          <List.Item>
            {personal.family? (
              <div>
                <Typography.Text strong>Father : </Typography.Text>
                {personal.family.father}

                <br/>

                <Typography.Text strong>Mother : </Typography.Text>
                {personal.family.mother}

                <div>
                {(spouses !== undefined && spouses.length !== 0) ? (
                  <div>
                    <Table
                      columns={columnsSpouses}
                      dataSource={spouses}
                      pagination={{ pageSize: 8 }}
                    />
                  </div>
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={"Candidate has not yet provided information."}
                  />
                )}{" "}
              </div>


              </div>

            ):null}
          </List.Item>




          

          </List>
        </div>
      </Panel>

      {props.details.bio.other.experience ? (
        <Panel header="Others" key="other">
          <div style={styles.scrollContainer}>
            <List>
              <List.Item style={{ whiteSpace: "pre-line" }}>
                {props.details.bio.other.experience}
              </List.Item>
              {/* <List.Item>
                <Col xs={{ span: 20, order: 1 }} xl={{ span: 24 }}>
                  {commentRender("other")}
                </Col>
              </List.Item> */}
            </List>
          </div>
        </Panel>
      ) : (
        <Panel header="Others" key="other">
          <div style={styles.scrollContainer}>
            <List>
              {/* <List.Item>
                <Col xs={{ span: 20, order: 1 }} xl={{ span: 24 }}>
                  {commentRender("other")}
                </Col>
              </List.Item> */}
            </List>
          </div>
        </Panel>
      )}
    </Collapse>
  );
};
export default BioMain;
