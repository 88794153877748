// Libraries
import React from "react";
import ReactQuill from "react-quill";

// Stylesheet
import "react-quill/dist/quill.snow.css";

const QuillEditor = (props) => {
  // Quill Form UI Options
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: [] }], // text direction
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ["link", "image", "video"],
    [{ align: [] }],
    ["clean"], // remove formatting button
  ];

  const modules = {
    toolbar: toolbarOptions,
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: true,
    },
  };
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "align",
  ];

  return (
    <>
      <ReactQuill
        theme="snow"
        value={props.value}
        onChange={(html) => props.onChange(html)}
        modules={modules}
        formats={formats}
        placeholder={"Write a post"}
        name="body"
        style={{ marginBottom: "2em" }}
      />
      {/* {errors.body && errorField} */}
    </>
  );
};

export default QuillEditor;
