import React, { useState } from "react";
import { Form, Input, Button } from "antd";

const SignupForm = (props) => {
  const [hidePassword, setHidePassword] = useState(true);

  return (
    <Form onFinish={props.handleFormSubmit} className="login-form">
      <Form.Item
        style={{ textAlign: "left" }}
        name="email"
        rules={[{ required: true, type: "email" }]}
      >
        <Input
          placeholder="Email"
          size="large"
          name="email"
          disabled={props.loading}
        />
      </Form.Item>

      <Form.Item
        style={{ textAlign: "left" }}
        name="password"
        rules={[{ type: "string", required: true, min: 8 }]}
      >
        <Input
          size="large"
          type={hidePassword ? "password" : "text"}
          name="password"
          placeholder="Insert new password"
          disabled={props.loading}
          suffix={
            <span
              onClick={() => setHidePassword(!hidePassword)}
              className="show-password"
            >
              {hidePassword ? "SHOW" : "HIDE"}
            </span>
          }
        />
      </Form.Item>
      <div style={{ height: "46px" }} />
      <Form.Item>
        <Button
          loading={props.loading}
          style={{ width: "100%", margin: 0, padding: 0 }}
          className="btn-bordered"
          htmlType="submit"
          type="text"
        >
          SIGN UP
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SignupForm;
