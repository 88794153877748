import React from "react";
import { Modal } from "antd";
import CropSingleImage from "./CropSingleImage";

function ImageUploadeModal(props) {
  return (
    <div>
      <Modal
        visible={props.visibility}
        title="Upload your Image"
        closable={true}
        onCancel={props.toggleVisibility}
        width="650px"
        footer={["", ""]}
      >
        <div>
          <CropSingleImage
            handleUpload={props.uploadImage}
            typeKey={props.typeKey}
            imageClass={props.imageClass}
            recommendedPhrase={props.recommendedPhrase}
            cropRatio={props.cropRatio}
            loading={props.loading}
          />
        </div>
      </Modal>
    </div>
  );
}

export default ImageUploadeModal;
