import React from "react";
import EmailLoginForm from "./EmailLoginForm";
import { Layout, Divider, Button } from "antd";

const LoginPage = (props) => {
  return (
    <Layout
      style={{ margin: "77px auto", textAlign: "center", maxWidth: "500px" }}
    >
      <Layout.Content>
        <div className="inner">
          <h1 className="blue">LOG IN</h1>
          <EmailLoginForm auth={props.auth} search={props.location.search} />
          <Divider>OR</Divider>
          <Button
            style={{ width: "100%", marginLeft: "0px" }}
            htmlType="submit"
            onClick={props.auth.loginWithFacebook}
            className="btn-solid"
            type="text"
          >
            LOGIN WITH FACEBOOK
          </Button>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default LoginPage;
