import React, { createContext, useState } from "react";
import { Spin } from "antd";

export const LoadingContext = createContext({});

export const LoadingProvider = (props) => {
  const [isLoading, setGlobalLoading] = useState(false);

  const data = { isLoading, setGlobalLoading };

  return (
    <LoadingContext.Provider value={data}>
      {isLoading && (
        <div className="loader-container">
          <Spin size="large" />
        </div>
      )}
      {props.children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
