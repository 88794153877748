import React, { createContext, useState } from "react";

export const CandidateContext = createContext({});

export const CandidateProvider = (props) => {
  const [candidateTags, setCandidateTags] = useState([]);

  const data = { candidateTags, setCandidateTags };

  return (
    <CandidateContext.Provider value={data}>
      {props.children}
    </CandidateContext.Provider>
  );
};

export default CandidateProvider;
