import React, { useState, useEffect } from "react";
import config from "../../config";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import SingleImageUploader from "../shared/imageUploaders/singleImage/SingleImageUploader";
import MultiImageUploader from "../shared/imageUploaders/multiImage/MultiImageUploader";
import moment from "moment";
import {
  Form,
  Select,
  Button,
  Row,
  Col,
  DatePicker,
  Input,
  message,
  Popconfirm,
} from "antd";
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  YoutubeOutlined,
  HomeOutlined,
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

let addressAutoComplete;
const google = (window.google = window.google ? window.google : {});
function handleScriptLoad(setInput, setLat, setLng, setAddress, addressId) {
  addressAutoComplete = new google.maps.places.Autocomplete(addressId, {
    types: ["address"],
    componentRestrictions: { country: "us" },
  });
  console.log("addressAutoComplete", addressAutoComplete);
  addressAutoComplete.addListener("place_changed", () =>
    handleAddressPlaceSelect(setInput, setLat, setLng, setAddress)
  );
}

async function handleAddressPlaceSelect(setInput, setLat, setLng, setAddress) {
  const addressObject = addressAutoComplete.getPlace();
  setInput(addressObject.formatted_address);
  setAddress(addressObject.formatted_address);
  setLat(addressObject.geometry.location.lat());
  setLng(addressObject.geometry.location.lng());
  console.log(addressObject);
}

const EditEventPostForm = (props) => {
  // Check if the form has been edited
  const [touched, setTouched] = useState(false);

  // For Address Input
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [address, setAddress] = useState("");
  const [input, setInput] = useState("");

  // Multi image uploader states
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [activeFileList, setActiveFileList] = useState([]);

  // Single banner image uploader state
  const [bannerUrl, setBannerUrl] = useState("");

  const [form] = Form.useForm();
  const id = props.match.params.id;
  const history = useHistory();

  useEffect(() => {
    handleScriptLoad(
      setInput,
      setLat,
      setLng,
      setAddress,
      document.getElementById("place-autocomplete")
    );

    loadEventData();
  }, []); // eslint-disable-line

  const loadEventData = async () => {
    if (!id) return;
    try {
      const response = await axios.get(`${config.EVENTS}/events/${id}`);
      const post = response.data;
      console.log("post", post);
      form.setFieldsValue({ event: post.name });
      if (Object.keys(post.location).length !== 0) {
        setInput(post.location.formatted_address);
        setAddress(post.location.formatted_address);
        setLat(post.location.geo.coordinates[1]);
        setLng(post.location.geo.coordinates[0]);
      }
      if (post.descriptors.tags.length !== 0) {
        form.setFieldsValue({ type: post.descriptors.tags });
      }

      form.setFieldsValue({
        date: [moment(post.dates.start), moment(post.dates.end)],
      });

      if (post.contact.email) {
        form.setFieldsValue({
          email: post.contact.email,
        });
      }

      if (post.contact.phone) {
        form.setFieldsValue({
          phone: post.contact.phone,
        });
      }

      if (post.descriptors.website) {
        form.setFieldsValue({
          website: post.descriptors.website,
        });
      }

      if (post.descriptors.social.facebook) {
        form.setFieldsValue({
          facebook: post.descriptors.social.facebook,
        });
      }

      if (post.descriptors.social.instagram) {
        form.setFieldsValue({
          instagram: post.descriptors.social.instagram,
        });
      }

      if (post.descriptors.social.twitter) {
        form.setFieldsValue({
          twitter: post.descriptors.social.twitter,
        });
      }

      if (post.descriptors.social.youtube) {
        form.setFieldsValue({
          youtube: post.descriptors.social.youtube,
        });
      }

      if (post.descriptors.description) {
        form.setFieldsValue({
          description: post.descriptors.description,
        });
      }
      let images = [];
      let uid = 0;

      if (Object.keys(post.images).length !== 0) {
        if (post.images.profile) {
          setBannerUrl(post.images.profile);
        }
        if (post.images.gallery) {
          post.images.gallery.forEach((img, index) => {
            console.log("index", index);

            images.push({
              uid: uid++,
              name: `img ${index}`,
              status: "done",
              url: img.uploadedUrl,
            });
          });
        }
        setFileList(images);
        setActiveFileList(post.images.gallery);
      }
    } catch (err) {
      console.log(err);
      message.error("There was an error connecting to the network!");
    }
  };

  const handleSubmit = (values) => {
    let event = {
      name: values.event,
      dates: {
        start: values.date[0].toDate(),
        end: values.date[1].toDate(),
      },
      location: {
        formatted_address: "",
      },
      descriptors: {
        website: "",
        description: "",
        tags: [],
        social: {
          facebook: "",
          instagram: "",
          twitter: "",
          youtbue: "",
        },
      },
      contact: {
        phone: "",
        email: "",
      },
      images: {},
    };
    if (address) {
      event.location = {
        geo: {
          type: "Point",
          coordinates: [lng, lat],
        },
        formatted_address: address,
      };
    }
    if (values.website) event.descriptors.website = values.website;
    if (values.description) event.descriptors.description = values.description;
    if (values.type) event.descriptors.tags = values.type;
    if (values.facebook) event.descriptors.social.facebook = values.facebook;
    if (values.instagram) event.descriptors.social.instagram = values.instagram;
    if (values.twitter) event.descriptors.social.twitter = values.twitter;
    if (values.youtube) event.descriptors.social.youtube = values.youtube;
    if (values.phone) event.contact.phone = values.phone;
    if (values.email) event.contact.email = values.email;
    if (activeFileList && activeFileList.length > 0) {
      event.images.gallery = activeFileList;
    }
    if (bannerUrl) {
      event.images.profile = bannerUrl;
    }
    return saveEvent(event);
  };

  const saveEvent = async (event) => {
    try {
      const id = props.match.params.id;
      const json = JSON.stringify(event);
      const response = await axios.patch(
        `${config.EVENTS}/events/${id}`,
        {},
        {
          cors: true,
          headers: {
            doc: encodeURI(json),
            token: localStorage.getItem("access_token"),
          },
        }
      );
      console.log("🔽 response: ", response);
      message.success("Event successfully edited");
      let path = `/calendar/${id}`;
      history.push(path);
      setTouched(false);
    } catch (err) {
      message.error("Event not posted");
      console.log(err, "saveevent");
    }
  };
  const setFieldTouched = () => {
    setTouched(true);
  };

  const confirmCancel = () => {
    let path = `/calendar`;
    history.push(path);
  };

  return (
    <div className="eventPostForm">
      <h1 className="page-heading">EDIT EVENT</h1>
      <br />
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Row gutter={12}>
          <Col xs={24} md={12}>
            <Form.Item
              label="Event Title"
              name="event"
              rules={[{ required: true }]}
            >
              <Input
                onChange={setFieldTouched}
                placeholder="Enter title"
                onPressEnter={(e) =>
                  e.keyCode === 13 ? e.preventDefault() : ""
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Location">
              <Input
                suffix={<EnvironmentOutlined />}
                id="place-autocomplete"
                type="text"
                placeholder="Enter Location"
                onChange={(e) => {
                  setInput(e.target.value);
                  setFieldTouched();
                }}
                value={input}
                onPressEnter={(e) =>
                  e.keyCode === 13 ? e.preventDefault() : ""
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} md={10}>
            <Form.Item label="Types" name="type">
              <Select
                onChange={setFieldTouched}
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Add a tag and press Enter"
                name="type"
                allowClear={true}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Date and Time"
              name="date"
              rules={[{ required: true }]}
            >
              <RangePicker
                use12Hours
                style={{ width: "25.9em" }}
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                onChange={setFieldTouched}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="phone">
          <Input
            suffix={<PhoneOutlined />}
            placeholder="Enter contact phone number"
            onChange={setFieldTouched}
            onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "Please enter valid email address.",
            },
          ]}
        >
          <Input
            suffix={<MailOutlined />}
            placeholder="Enter email address"
            onChange={setFieldTouched}
            onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
          />
        </Form.Item>
        <Form.Item
          name="website"
          rules={[
            {
              type: "url",
              message:
                "Please enter valid Website URL (e.g. https://www.nocapvote.com)",
            },
          ]}
        >
          <Input
            placeholder="Website"
            suffix={<HomeOutlined />}
            onChange={setFieldTouched}
            onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
          />
        </Form.Item>
        <Form.Item
          name="facebook"
          rules={[
            {
              type: "url",
              message:
                "Please enter valid Website URL (e.g. https://www.nocapvote.com)",
            },
          ]}
        >
          <Input
            placeholder="Facebook"
            suffix={<FacebookOutlined />}
            onChange={setFieldTouched}
            onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
          />
        </Form.Item>
        <Form.Item
          name="instagram"
          rules={[
            {
              type: "url",
              message:
                "Please enter valid Website URL (e.g. https://www.nocapvote.com)",
            },
          ]}
        >
          <Input
            placeholder="instagram"
            suffix={<InstagramOutlined />}
            onChange={setFieldTouched}
            onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
          />
        </Form.Item>
        <Form.Item
          name="twitter"
          rules={[
            {
              type: "url",
              message:
                "Please enter valid Website URL (e.g. https://www.nocapvote.com)",
            },
          ]}
        >
          <Input
            placeholder="Twitter"
            suffix={<TwitterOutlined />}
            onChange={setFieldTouched}
            onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
          />
        </Form.Item>
        <Form.Item
          name="youtube"
          rules={[
            {
              type: "url",
              message:
                "Please enter valid Website URL (e.g. https://www.nocapvote.com)",
            },
          ]}
        >
          <Input
            placeholder="Youtube"
            suffix={<YoutubeOutlined />}
            onChange={setFieldTouched}
            onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
          />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea
            style={{ height: "8em" }}
            rows={4}
            onChange={setFieldTouched}
            name="description"
            placeholder="Enter the detail of the event"
          />
        </Form.Item>
        <Form.Item label="Banner">
          <div
            style={{
              border: "dashed 1px lightgrey",
            }}
          >
            <SingleImageUploader
              handleImageUpload={(url) => setBannerUrl(url)}
              imagePreviewUrl={bannerUrl}
              typeKey={"calendar"}
            />
          </div>
        </Form.Item>
        <Form.Item label="Gallery">
          <MultiImageUploader
            setPreviewImage={setPreviewImage}
            setTouched={setTouched}
            setPreviewVisible={setPreviewVisible}
            setPreviewTitle={setPreviewTitle}
            setFileList={setFileList}
            setActiveFileList={setActiveFileList}
            activeFileList={activeFileList}
            previewVisible={previewVisible}
            previewTitle={previewTitle}
            previewImage={previewImage}
            fileList={fileList}
            endpoint="calendar"
          />
        </Form.Item>
        <div className="eventActions">
          {touched ? (
            <Popconfirm
              title="You have unsaved changes, are you sure you want to leave?"
              onConfirm={confirmCancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className="btn-bordered"
                style={{ margin: "0px 5px", width: "85px" }}
                type="text"
              >
                Cancel
              </Button>
            </Popconfirm>
          ) : (
            <Link to="/calendar" className="action">
              <Button
                className="btn-bordered"
                style={{ margin: "0px 5px", width: "85px" }}
                type="text"
              >
                Cancel
              </Button>
            </Link>
          )}
          <Button
            className="btn-solid"
            htmlType="submit"
            style={{ margin: "0px 5px", width: "85px" }}
            type="text"
          >
            Update
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditEventPostForm;
