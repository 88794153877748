import React from "react";
import { Form, Input, Col, Button,  DatePicker,} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const Committee = ({ formValues }) => {
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
  return (
    <Col span={24}>
      <Form.List name="committees">
        {(fields, { add, remove }) => {
          const committees = formValues?.committees || [];
          const enableBtn =
            committees.length > 0
              ? committees.every(
                (v) =>
                  v?.committeeDate && v?.description
              )
              : true;

          return (
            <Form.Item label="Committees (Please enter most recent to oldest)">
              {fields.map((field, index) => (
                <div key={"Committee" + index}>
                <Form.Item label="Date" name= {[field.name, "committeeDate"]}>
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="select a date (dd/mm/yyyy)"
                    format={dateFormatList}
                    popupStyle={{ color: "black" }}

                  />
                </Form.Item>

                  <Form.Item
                    label="Description"
                    name={[field.name, "description"]}
                    style={{ marginBottom: 16 }}
                  >
                    <Input placeholder="description" />
                  </Form.Item>



                  {fields.length > 1 ? (
                    <Form.Item style={{ marginBottom: 16 }}>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Form.Item>
                  ) : null}
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  style={{
                    width: "100%",
                  }}
                  disabled={!enableBtn}
                >
                  <PlusOutlined /> Add field
                </Button>
              </Form.Item>
            </Form.Item>
          );
        }}
      </Form.List>
    </Col>
  );
};

export default Committee;
