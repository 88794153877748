import React, { useState, Fragment } from "react";
import { Avatar, Card, Rate, Button, Space, message, Typography } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import DefaultImage from "../../css/images/default_image.jpg";
const { Meta } = Card;
const { Text } = Typography;

const CandidatePreview = (props) => {
  //Candidate Id
  const { id } = useParams();

  // Rating
  let ratingList = JSON.parse(localStorage.getItem("ratingList"))
    ? JSON.parse(localStorage.getItem("ratingList"))
    : { candidates: {}, services: {} };

  let initialRating = 0;
  let rated = false;

  if (ratingList.candidates && ratingList.candidates[id]) {
    initialRating = ratingList.candidates[id];
    rated = true;
  }

  const [rating, setRating] = useState(initialRating);
  const [average, setAverage] = useState(
    parseInt(props.details.bio.rating.stars)
  );
  const [total, setTotal] = useState(parseInt(props.details.bio.rating.total));
  const [rateStatus, setRateStatus] = useState(rated);
  const [userRatingList, setUserRatingList] = useState(ratingList);

  const handleRating = async (value) => {
    if (!localStorage.getItem("userid")) {
      message.info(`Log in is required in order to rate the candidate.`);
    } else {
      message.loading("Action in progress..");
      let newAverage = 0;
      let newTotal = total;
      let newRatingList = userRatingList;

      // First time rate the candidate
      if (!rateStatus) {
        newTotal = total + 1;
        newAverage = (average + value) / newTotal;
        setRateStatus(true);
        setTotal(newTotal);
        newRatingList.candidates[id] = value;
      } else {
        // Re-rate the candidate
        newAverage = (average * total - rating + value) / total;
        newRatingList.candidates[id] = value;
      }
      setUserRatingList(newRatingList);
      setRating(value);
      setAverage(newAverage);
      // Update user rating list array
      let userDoc = JSON.stringify({
        ratingList: userRatingList,
      });
      // Update candidate rating list array
      let candidateDoc = JSON.stringify({
        "bio.rating": {
          total: newTotal,
          stars: newAverage,
        },
      });
      await Promise.all([
        axios.patch(
          `${config.USERS}/users`,
          {},
          {
            headers: {
              token: localStorage.getItem("access_token"),
              doc: encodeURIComponent(userDoc),
            },
          }
        ),
        axios.patch(
          `${config.CANDIDATES}/candidates/update`,
          {},
          {
            headers: {
              candidate_id: id,
              token: localStorage.getItem("access_token"),
              doc: encodeURIComponent(candidateDoc),
            },
          }
        ),
      ]);
      localStorage.setItem("ratingList", JSON.stringify(newRatingList));
      message.success(
        `You gave ${props.details.bio.name} a rating of ${value}`
      );
    }
  };

  // Voting
  let votingList = localStorage.getItem("votingList");
  votingList = votingList ? JSON.parse(votingList) : [];

  const [inVotingList, addToVotingList] = useState(
    votingList.includes(id) ? true : false
  );
  const [votingButton, setVotingButton] = useState(
    votingList.includes(id)
      ? "Remove from My List"
      : "Add to My List"
  );

  const handleVotingButton = () => {
    if (!localStorage.getItem("userid")) {
      message.info(`Please log in to add to your voting list!`);
    } else {
      message.loading("Action in progress..");
      if (!inVotingList) {
        addToVotingList(true);
        votingList.push(id);
        localStorage.setItem("votingList", JSON.stringify(votingList));
        const doc = JSON.stringify({
          votingList: votingList,
        });
        return axios
          .patch(
            `${config.USERS}/users`,
            {},
            {
              headers: {
                token: localStorage.getItem("access_token"),
                doc: encodeURIComponent(doc),
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              message.success(
                `${props.details.bio.name} has been added to your voting list!`
              );
              setVotingButton("Remove from Voting List");
            } else {
              message.error(
                "Something went wrong, make sure you are connected to the internet."
              );
            }
          });
      } else {
        addToVotingList(false);
        votingList = votingList.filter((candidateId) => candidateId !== id);
        localStorage.setItem("votingList", JSON.stringify(votingList));
        const doc = JSON.stringify({
          votingList: votingList,
        });
        return axios
          .patch(
            `${config.USERS}/users`,
            {},
            {
              headers: {
                token: localStorage.getItem("access_token"),
                doc: encodeURIComponent(doc),
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              message.info(
                `${props.details.bio.name} has been removed from your voting list.`
              );
              setVotingButton("Add to My Voting List");
            } else {
              message.error(
                "Something went wrong, make sure you are connected to the internet."
              );
            }
          });
      }
    }
  };
  let cityState = "";
  if (props.details.bio.contact.address !== undefined) {
    let officeContact = props.details.bio.contact.address.split(",");
    let contactLength = officeContact.length;
    let city = officeContact[contactLength - 3];
    cityState =
      contactLength > 1
        ? `${city ? city + ", " : ""} ${officeContact[contactLength - 2]}`
        : "";
  }

  return (
    <div style={{ height: "90%" }} key={props.key}>
      <Space direction="vertical" align="center">
        <Avatar
          className={props.details.bio.profileImage ? "" : "default-image"}
          size={props.avatarSize || 200}
          src={props.details.bio.profileImage || DefaultImage}
        />
        {props.details.archived === true && (
          <div>
            <p
              style={{ border: "2px solid red", color: "red", padding: "3px" }}
            >
              {" "}
              Archived{" "}
            </p>
          </div>
        )}
        <Meta title={props.details.bio.name} />
        {
          props.buttonVisibility ? (
            <>
              <Button
                onClick={handleVotingButton}
                style={{ borderColor: "#bfbfbf", width: "96%" }}
                className="btn-bordered"
                type="text"
              >
                {votingButton}
              </Button>
              <Space>
                <Rate
                  style={{ color: "black" }}
                  value={average}
                  character={<CheckCircleFilled />}
                  onChange={(value) => handleRating(value)}
                />
                <Text type="secondary">({`${total}`})</Text>
              </Space>
              {rateStatus ? (
                <Fragment>
                  <Text type="secondary">{`- You rated: ${rating} -`}</Text>
                </Fragment>
              ) : null}
            </>
          ) : (
            ""
          )
          // (
          //   <Rate
          //     style={{ color: "black", lineHeight: "0px" }}
          //     disabled
          //     character={<CheckCircleFilled />}
          //     defaultValue={average}
          //   />
          // )
        }
        <div className="office-ellipsis">
          {/* {props.details.bio.office.incumbent &&
          props.details.bio.office.incumbent.title ? (
            <Text>{`${props.details.bio.office.incumbent.title} (Incumbent) `}</Text>
          ) : (
            <Text>{`${props.details.bio.office.ballot.title} (Ballot)`}</Text>
          )} */}
          {props.details.officeSelections? 
            (
              <Text>{`${props.details.officeSelections[0].title}`}</Text>
              
            ):(null)
          }
        </div>

        <div>
          <Text type="secondary">{props.details.bio.office.party}</Text>
        </div>

        <div>
          {props.details.bio.contact.address !== "" &&
          !props.buttonVisibility ? (
            <Text style={{ fontSize: "12px" }}>{cityState}</Text>
          ) : (
            ""
          )}
        </div>
      </Space>
    </div>
  );
};

export default CandidatePreview;
