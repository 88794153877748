import { useState } from "react";
import axios from "axios";

export function useNewsData(url) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  async function get() {
    try {
      setLoading(true);
      const result = await axios(url);
      setData(result.data);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }

  return { data, error, loading, get };
}

export default useNewsData;
