import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import { Layout, message } from "antd";
import JSONmanifest from "./nocap.json";
import axios from "axios";
import Auth from "./components/shared/auth/Auth";
import Home from "./components/pages/Home";
import AccountChange from "./components/pages/AccountChange";
import LoginPage from "./components/shared/auth/LoginPage";
import SignupPage from "./components/shared/auth/SignupPage";
import Callback from "./components/shared/auth/Callback";
import FBcallback from "./components/shared/auth/FBcallback";
import ConfirmEmailPage from "./components/shared/auth/ConfirmEmailPage";
import UserProfileStart from "./components/pages/UserProfileStart";
import TopNav from "./components/navigation/TopNavBar.js";
import Map from "./components/pages/Map";
import News from "./components/pages/News";
import NewsPostPage from "./components/pages/NewsPostPage";
import EditNewsPostPage from "./components/pages/EditNewsPostPage";
import NewNewsPost from "./components/news/NewNewsPost";
import Events from "./components/pages/Events";
import EventDetailsPage from "./components/pages/EventDetailsPage";
import EditEventPostForm from "./components/forms/EditEventPostForm";
import NewEventPostForm from "./components/forms/NewEventPostForm";
import ElectionsForm from "./components/forms/ElectionsForm";
import Footer from "./components/Footer";
import PrivacyPolicyPage from "./components/pages/PrivacyPolicyPage";
import TermsConditionsPage from "./components/pages/TermsConditionsPage";
import config from "./config";
import UserSignupForm from "./components/pages/UserSignupFormServe";
import CandidatePage from "./components/pages/Candidate";
import CandidateConsolePage from "./components/pages/CandidateConsolePage";
import SearchPage from "./components/search/SearchPage";
import ServiceDetailsPage from "./components/services/ServiceDetailsPage";
import MyVotingList from "./components/pages/MyVotingList";
import ProfilePage from "./components/pages/ProfilePage";
import Queues from "./components/pages/Queues";
import CandidateProvider from "./providers/CandidateContext";
import SignUpProvider from "./providers/SignupContext";
import LoadingProvider from "./providers/LoadingContext";
import NewsProvider from "./providers/NewsContext";

const auth = new Auth();

const DEFAULT_COORDS = { lng: -118.243683, lat: 34.052235 };

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topNavLinks: [],
      accountTypes: [],
      searchKeyword: "",
      // Default service location - Los Angeles
      coordinates: DEFAULT_COORDS,
      zipCode: "90012",
      formattedAddress: "",
      keywords: "",
      showContact: false,
      marketServices: [],
      locatingMarket: false,
      loading: false,
      // Default candidate location
      userLocation: {
        city: "Washington",
        state: "District of Columbia",
        country: "United States",
        lat: 38.9034,
        lng: -76.9882,
      },
      divisions: ["ocd-division/country:us"],
      locationLoading: true,
    };
  }

  componentDidMount() {
    try {
      this.getGeolocation();
    } catch (err) {
      message.error("Something is wrong with your Location.", err);
    }

    this.getManifest();
    this.checkSession();
  }

  getManifest = () => {
    let manifest = JSONmanifest;
    this.setState({
      topNavLinks: manifest.pages,
      accountTypes: manifest.accountTypes,
    });
  };

  checkSession = () => {
    let checkSession = auth.isSessionValid();
    if (checkSession) {
      this.props.history.push("/login");
      message.error("You session has expired please log-in to continue!");
    }
  };

  fetchServicesByLocation = async (lat, lng, params) => {
    try {
      this.setState({ locatingMarket: true });
      let queryParameters = `&lng=${lng}&lat=${lat}`;
      if (params) {
        queryParameters = queryParameters + params;
      }
      const response = await axios.get(
        `${config.SERVICES}/services/search?${queryParameters}`
      );
      const responseServices = response.data;
      this.setState({
        marketServices: responseServices.services,
        locatingMarket: false,
      });
    } catch (err) {
      this.setState({ error: err, locatingMarket: false });
      message.error("Something is wrong with your location");
    }
  };

  getGeolocation = async () => {
    try {
      // const response = await axios.get(`${config.USERS}/users/location`);
      // let currentUserLocation = {
      //   city: "Secaucus",
      //   state: "New Jersey",
      //   country: "United States",
      //   lat: 40.7876,
      //   lng: -74.06,
      // };
      let divisions = ["ocd-division/country:us"];
      // if (response.data.location) {
      //   if (response.data.location.city) {
      //     currentUserLocation.city = response.data.location.city.names.en;
      //   }
      //   if (response.data.location.subdivisions) {
      //     currentUserLocation.state =
      //       response.data.location.subdivisions[0].names.en;
      //   }
      //   if (response.data.location.country) {
      //     currentUserLocation.country = response.data.location.country.names.en;
      //     if (response.data.location.country.names.en === "United States") {
      //       const getDivisions = await axios.get(
      //         `https://civicinfo.googleapis.com/civicinfo/v2/representatives?address=${currentUserLocation.city},${currentUserLocation.state}United%20States&includeOffices=false&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
      //       );
      //       divisions = Object.keys(getDivisions.data.divisions);
      //     }
      //   }
      //   if (response.data.location.location) {
      //     currentUserLocation.lat = response.data.location.location.latitude;
      //     currentUserLocation.lng = response.data.location.location.longitude;
      //   }
      // } else {
      //   currentUserLocation = this.state.userLocation;
      // }

      this.setState({
        userLocation: {
          city: "Secaucus",
          state: "New Jersey",
          country: "United States",
          lat: 40.7876,
          lng: -74.06,
        },
        divisions: ["ocd-division/country:us"],
        locationLoading: false,
      });

      return divisions;
    } catch (err) {
      console.log(err);
    }
  };

  handleFBregistration = async () => {
    let authResult = await auth.FBhandleAuthentication();
    let userinfo = authResult.idTokenPayload;
    userinfo.email = "sample@gmail.com";
    const response = await axios.get(`${config.USERS}/users`, {
      cors: true,
      responseType: "json", // default
      headers: {
        token: authResult.accessToken,
      },
    });
    const user = response.data;
    if (!user) {
      return axios
        .post(
          `${config.USERS}/users`,
          {},
          {
            cors: true,
            timeout: 15000,
            responseType: "json", // default
            headers: {
              doc: encodeURIComponent(`{"email": "${userinfo.email}",
              "name": "${userinfo.name}",
              "picture": "${userinfo.picture}",
              "sub": "${userinfo.sub}",
              "token": "${authResult.accessToken}"
            }`),
            },
          }
        )
        .then((response) => {
          let userBody = JSON.parse(response.data.body);
          console.log(userBody);
          localStorage.setItem("userid", userBody.result.insertedIds["0"]);
          localStorage.setItem("votingList", JSON.stringify([]));
          localStorage.setItem(
            "ratingList",
            JSON.stringify({
              candidates: {},
              services: {},
            })
          );
          localStorage.setItem("user", JSON.stringify(user));
          this.props.history.push("/profile-start");
        })
        .catch((err) => {
          console.log("signup error", err);
          if (err.description) {
            message.warning(err.description, 3);
          }
        });
    } else {
      localStorage.setItem("userid", user._id);
      localStorage.setItem("votingList", JSON.stringify(user.votingList));
      localStorage.setItem("ratingList", JSON.stringify(user.ratingList));
      localStorage.setItem("role", user.role);
      localStorage.setItem("user", JSON.stringify(user));
      this.props.history.push("/");
    }
  };

  handleAuthentication = async () => {
    const authResult = await auth.handleAuthentication();
    const userinfo = authResult.idTokenPayload;
    if (userinfo["email_verified"] !== true) {
      auth.logout();
      this.props.history.push("/confirm");
      console.log("EMAIL NOT VERIFIED");
      return;
    }
    this.setState({ loading: true });
    const response = await axios.get(`${config.USERS}/users`, {
      cors: true,
      responseType: "json", // default
      headers: {
        token: authResult.accessToken,
      },
    });
    const user = response.data;
    console.log(user);
    if (user) {
      localStorage.setItem("userid", user._id);
      localStorage.setItem("votingList", JSON.stringify(user.votingList));
      localStorage.setItem("ratingList", JSON.stringify(user.ratingList));
      localStorage.setItem("role", user.role);
      localStorage.setItem("user", JSON.stringify(user));
      this.setState({ loading: false });
      if (user.role === "first-auth") {
        this.props.history.push("/profile-start");
      } else if (user.role === "advertiser") {
        if (user.onBoarding) {
          localStorage.setItem("onboard", JSON.stringify(user.onBoarding));
          if (user.onBoarding.completed) {
            this.props.history.push("/profile/advertiser");
          } else {
            this.props.history.push("/signup/advertiser");
          }
        } else {
          this.props.history.push("/");
        }
      } else {
        this.props.history.push("/");
      }
    }
  };

  handleLogout = () => {
    auth.logout();
    this.props.history.push("/");
  };

  changeShowContact = () => {
    this.setState({ showContact: !this.state.showContact });
  };

  render() {
    return (
      <CandidateProvider>
      <NewsProvider>
        <SignUpProvider>
          <div className="App">
            <Layout>
              <LoadingProvider>
                {!this.state.loading ? (
                  <TopNav
                    history={this.props.history}
                    auth={auth}
                    logout={this.handleLogout}
                  />
                ) : null}
                <Layout.Content
                  className="layout-content"
                  // style={{ margin: "2em 1em", minHeight: "calc(100vh - 40vh)" }}
                >
                  <Switch>
                    {!this.state.locationLoading ? (
                      <Route
                        exact
                        path="/"
                        render={(props) => (
                          <Home
                            {...props}
                            promptSubscribeModal={this.promptSubscribeModal}
                            formattedAddress={this.state.formattedAddress}
                            handleNewPlace={this.handleNewPlace}
                            handleKeywordChange={this.handleKeywordChange}
                            coordinates={this.state.coordinates}
                            userLocation={this.state.userLocation}
                            divisions={this.state.divisions}
                          />
                        )}
                      />
                    ) : null}
                    {/* USER ROUTES  --- Start */}
                    <Route
                      path="/profile-start"
                      render={(props) => (
                        <UserProfileStart
                          accountTypes={this.state.accountTypes}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path="/signup/:type"
                      render={(props) => <UserSignupForm {...props} />}
                    />
                    <Route
                      exact
                      path="/signup"
                      render={(props) => <SignupPage auth={auth} {...props} />}
                    />
                    <Route
                      exact
                      path="/login"
                      render={(props) => <LoginPage auth={auth} {...props} />}
                    />
                    <Route
                      exact
                      path="/callback"
                      render={(props) => (
                        <Callback
                          {...props}
                          handleAuthentication={this.handleAuthentication}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/fbCallback"
                      render={(props) => (
                        <FBcallback
                          {...props}
                          handleFBregistration={this.handleFBregistration}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/confirm"
                      render={(props) => <ConfirmEmailPage {...props} />}
                    />
                    {/* USER ROUTES  --- End */}

                    {/* ELECTIONS */}
                    <Route
                      exact
                      path="/elections/new"
                      component={ElectionsForm}
                    />
                    <Route
                      exact
                      path="/elections/edit/:id"
                      component={ElectionsForm}
                    />
                    {/* ELECTIONS  --- END */}

                    {/* MAP */}
                    <Route
                      path="/map"
                      render={(props) => (
                        <Map
                          {...props}
                          coordinates={this.state.coordinates}
                          formattedAddress={this.state.formattedAddress}
                          keywords={this.state.keywords}
                          marketServices={this.state.marketServices}
                          fetchServices={this.fetchServicesByLocation}
                          locatingMarket={this.state.locatingMarket}
                        />
                      )}
                    />
                    {/* News */}
                    <Route
                      exact
                      path="/news"
                      render={(props) => <News {...props} />}
                    />
                    <Route exact path="/news/new" component={NewNewsPost} />
                    <Route exact path="/news/:id" component={NewsPostPage} />
                    <Route
                      exact
                      path="/news/:id/edit"
                      component={EditNewsPostPage}
                    />

                    {/* EVENTS  --- start */}
                    <Route
                      exact
                      path="/calendar"
                      render={(props) => (
                        <Events history={this.props.history} />
                      )}
                    />
                    <Route
                      exact
                      path="/calendar/new"
                      component={NewEventPostForm}
                    />
                    <Route
                      exact
                      path="/calendar/:id/edit"
                      component={EditEventPostForm}
                    />
                    <Route path="/calendar/:id" component={EventDetailsPage} />
                    {/* EVENTS  --- end */}

                    {/* CANDIDATE */}
                    <Route
                      path="/candidate/:id"
                      render={(props) => <CandidatePage {...props} />}
                    />

                    <Route
                      path="/candidate-console"
                      render={(props) => <CandidateConsolePage {...props} />}
                    />

                    {/* SEARCH RESULTS */}
                    <Route
                      exact
                      path="/search"
                      render={(props) => <SearchPage />}
                    />
                    <Route
                      exact
                      path="/services/:id"
                      render={(props) => <ServiceDetailsPage {...props} />}
                    />

                    {/* MY VOTING LIST*/}
                    <Route
                      exact
                      path="/my-voting-list"
                      render={(props) => <MyVotingList />}
                    />

                    {/* PROFILE */}
                    <Route
                      path="/change-type"
                      render={(props) => <AccountChange {...props} />}
                    />
                    <Route
                      path="/profile/:type"
                      render={(props) => <ProfilePage {...props} />}
                    />

                    {/* Queues */}
                    <Route path="/queues" render={(props) => <Queues />} />

                    {/* Footer  --- Start */}
                    <Route
                      exact
                      path="/terms"
                      component={TermsConditionsPage}
                    />
                    <Route
                      exact
                      path="/privacy"
                      component={PrivacyPolicyPage}
                    />
                    {/* Footer  --- End */}
                  </Switch>
                </Layout.Content>
                <Footer
                  footerNavLinks={this.state.topNavLinks}
                  showContact={this.state.showContact}
                  changeShowContact={this.changeShowContact}
                />
              </LoadingProvider>
            </Layout>
          </div>
        </SignUpProvider>
        </NewsProvider>
      </CandidateProvider>
    );
  }
}

export default withRouter(App);
