import React from "react";
import { Row, Col, Card } from "antd";
import { capitalizeFirstLetter } from "../../helpers";

const UserProfileStart = (props) => {
  let accountProps = props.accountTypes ? props.accountTypes : [];
  let accountSelection = accountProps.map((item, i) => {
    return (
      <a key={i} href={`/signup/${item.title}`}>
        <Col span={12}>
          <Card
            style={{ width: "230px", height: "315px" }}
            className="black-border-hover"
          >
            <img
              src={item.icon}
              alt={item.title}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "50%",
                paddingBottom: "1em",
              }}
            />
            <h2 style={{ textAlign: "center" }}>
              {capitalizeFirstLetter(item.title)}
            </h2>
            <div>{item.description}</div>
          </Card>
        </Col>
      </a>
    );
  });

  return (
    <div>
      <Row
        gutter={[16, 16]}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col span={24}>
          <h2 style={{ marginTop: "20px", textAlign: "center" }}>
            Select Your Account Type
          </h2>
        </Col>
        {accountSelection}
      </Row>
    </div>
  );
};

export default UserProfileStart;
