// Libraries
import React, { useContext } from "react";

// Ant Design Components
import { Avatar, Card, Tag, Typography } from "antd";

// Helpers & Config
import { CandidateContext } from "../../providers/CandidateContext";

const CandidateTag = (props) => {
  const { Text } = Typography;

  const { candidateTags, setCandidateTags } = useContext(CandidateContext);

  const candidateTitleByOffice = (office) => {
    if (office.incumbent.title) {
      return office.incumbent.title;
    }
    if (office.ballot.title) {
      return office.ballot.title;
    }
  };

  const handleRemoveCandidateTag = (candidateId) => {
    const filteredCandidateTags = candidateTags.filter(
      (c) => c._id !== candidateId
    );

    setCandidateTags(filteredCandidateTags);
  };

  return (
    <Tag
      closable
      onClose={() => handleRemoveCandidateTag(props.details._id)}
      className="candidate-tag"
    >
      <Avatar size={55} src={props.details.bio.profileImage} />
      <div className="candidate-bio">
        <Card.Meta
          title={props.details.bio.name}
          style={{ display: "flex", flexDirection: "column" }}
        />
        <Text>{candidateTitleByOffice(props.details.bio.office)}</Text>
      </div>
    </Tag>
  );
};

export default CandidateTag;
