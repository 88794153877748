import React, { useState } from "react";
import { Comment, Avatar, Button, Divider } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";

const styles = {
  parentDiv: {
    display: "flex",
  },
  divider: {
    width: "60px",
    height: "auto",
    display: "inline-block",
  },
};

const SingleCommentComponent = (props) => {
  const [upVotes, setUpVotes] = useState(props.comment.up);
  const [downVotes, setDownVotes] = useState(props.comment.down);

  const onUpVote = (e) => {
    console.log("UpVote");
    setUpVotes((upVotes) => upVotes + 1);
  };

  const onDownVote = (e) => {
    console.log();
    setDownVotes((downVotes) => downVotes - 1);
  };
  return (
    <div style={styles.parentDiv}>
      <div>
        <Button
          onClick={onUpVote}
          type="text"
          style={{
            backgroundColor: "#1f1f1f",
            color: "grey",
            height: "5px",
            fontSize: "14px",
          }}
          icon={
            <UpOutlined
              style={{ color: "white", backgroundColor: "#1f1f1f" }}
            />
          }
        >
          {" "}
          {upVotes}{" "}
        </Button>
        <br />
        <Button
          onClick={onDownVote}
          type="text"
          style={{
            backgroundColor: "#1f1f1f",
            color: "grey",
            height: "5px",
            fontSize: "14px",
          }}
          icon={
            <DownOutlined
              style={{ color: "white", backgroundColor: "#1f1f1f" }}
            />
          }
        >
          {" "}
          {downVotes}{" "}
        </Button>
      </div>

      <Comment
        author={
          <div style={styles.parentDiv}>
            <div style={{ color: "grey", fontSize: "11px" }}>
              <i>{props.comment.userId}</i>
            </div>
            <Divider style={styles.divider} />
            <div style={{ color: "grey", fontSize: "11px" }}>
              <i>{props.comment.date}</i>
            </div>
          </div>
        }
        avatar={
          <Avatar
            src={
              "https://upload.wikimedia.org/wikipedia/commons/d/d6/Kamala_Harris_official_photo.jpg"
            }
            alt="image"
          />
        }
        content={<p style={{ color: "white" }}>{props.comment.comment}</p>}
      ></Comment>
    </div>
  );
};

export default SingleCommentComponent;
