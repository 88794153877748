import React from "react";
import { Layout, Button } from "antd";
import { useHistory } from "react-router-dom";

const DraftMessage = () => {
  const history = useHistory();
  const backToHome = () => {
    let path = `/`;
    history.push(path);
  };
  return (
    <Layout>
      <Layout.Content>
        <div className="ConfirmEmailPage big-bottom-padding">
          <h1>Under Review </h1>
          <p className="lead">
            We are reviewing your candidate sign up request. <br />
            If it's been more than <b>48 hours</b> and you haven't heard back
            from us, <br />
            feel free to send us an email at nocapsvote@gmail.com.
          </p>
          <Button
            className="btn-bordered"
            onClick={backToHome}
            style={{ margin: "0px 5px", width: "200px" }}
            type="text"
          >
            GO TO HOME
          </Button>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default DraftMessage;
