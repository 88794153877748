import React, { useState, Fragment } from "react";
import { Form, Input, message, Button } from "antd";

const EmailLoginForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (values) => {
    try {
      setLoading(true);
      let response = await props.auth.login(values.email, values.password);
      setLoading(false);
      return response;
    } catch (err) {
      setLoading(false);
      let body;
      if (err.description) body = err.description;
      message.warning(body || null, 3);
    }
  };

  const changePassword = async () => {
    try {
      const response = await props.auth.changePassword(email);
      message.success(response);
    } catch (err) {
      message.warning(
        "There was a problem with your new password request. Did you input the correct email?"
      );
    }
  };

  return (
    <Fragment>
      <Form onFinish={handleLogin} className="login-form">
        <Form.Item
          style={{ textAlign: "left" }}
          name="email"
          rules={[{ required: true, type: "email" }]}
        >
          <Input
            placeholder="Email"
            name="email"
            size="large"
            disabled={loading}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          style={{ textAlign: "left" }}
          name="password"
          rules={[{ type: "string", required: true, min: 6 }]}
        >
          <Input
            size="large"
            type={hidePassword ? "password" : "text"}
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Insert password"
            disabled={loading}
            value={password}
            suffix={
              <span
                onClick={() => setHidePassword(!hidePassword)}
                className="show-password"
              >
                {hidePassword ? "SHOW" : "HIDE"}
              </span>
            }
          />
        </Form.Item>

        <Button
          className="btn-transparent"
          style={{ fontWeight: 400, color: "rgba(0,0,0,0.3)" }}
          onClick={changePassword}
        >
          forgot your password?
        </Button>

        <div style={{ height: "16px" }} />
        <Form.Item>
          <Button
            style={{ width: "100%", margin: 0, padding: 0 }}
            className="btn-bordered"
            htmlType="submit"
            type="text"
            loading={loading}
          >
            LOGIN
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default EmailLoginForm;
