import React, { useState } from "react";
import { Comment, Avatar, Button, Input, message, Divider } from "antd";
import axios from "axios";
import DefaultImage from "../../css/images/default_image.jpg";

const { TextArea } = Input;

const styles = {
  parentDiv: {
    display: "flex",
  },
  divider: {
    height: "auto",
    display: "inline-block",
  },
  replyDivider: {
    height: "auto",
    display: "inline-block",
  },
};

const SingleQuestionAnswer = (props) => {
  const [ReplyValue, setReplyValue] = useState("");
  const [OpenReply, setOpenReply] = useState(false);

  const handleChange = (e) => {
    setReplyValue(e.currentTarget.value);
  };

  const openReply = () => {
    setOpenReply(!OpenReply);
  };

  const onSubmit = (e) => {
    //should push the reply in the answer array of the specific question using the responseTo variable
    e.preventDefault();

    const data = {
      user: "testabcd",
      candidate_id: props.candidateId,
      questionIndex: props.questionId, //provides the question id
      answer: ReplyValue,
      date: "11/08/2020", // Update it to a getter method for today's date
    };

    // props.refreshFunction(variables, "reply", data.questionIndex);
    // setOpenReply(!OpenReply);

    return axios
      .post(
        "https://ne9c9azsf1.execute-api.us-east-1.amazonaws.com/dev/candidates/questions/answers/insert",
        data,
        {
          headers: {
            candidate_id: props.candidateId,
            question_index: props.questionId,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          message.success("Saved!");
          setReplyValue("");
          props.refreshFunction(data, "reply", data.questionIndex);
          setOpenReply(!OpenReply);
        } else {
          message.error(
            "Something went wrong, make sure you are connected to the internet."
          );
        }
      });
  };

  const actions = [
    <span onClick={openReply} key="comment-basic-reply-to">
      Reply to{" "}
    </span>,
  ];

  return (
    <div>
      {props.type === "question" && (
        <Comment
          actions={actions}
          author={
            <div style={styles.parentDiv}>
              <div style={{ fontSize: "12px" }}>
                <i>{props.QA.user}</i>
              </div>
              <Divider style={styles.divider} />
              <div style={{ fontSize: "12px" }}>
                <i>{props.QA.date}</i>
              </div>
            </div>
          }
          avatar={<Avatar src={props.QA.avatar || DefaultImage} alt="image" />}
          content={
            //render a question here
            <p>{props.content}</p>
          }
        ></Comment>
      )}

      {props.type === "reply" && (
        <Comment
          author={
            <div style={styles.parentDiv}>
              <div style={{ fontSize: "12px" }}>
                <i>testUser</i>
              </div>
              <Divider style={styles.replyDivider} />
              <div style={{ fontSize: "12px" }}>
                <i>20/08/2020</i>
              </div>
            </div>
          }
          avatar={<Avatar src={props.QA?.avatar || DefaultImage} alt="image" />}
          content={
            //render a question here
            <p>{props.content}</p>
          }
        ></Comment>
      )}

      {OpenReply && (
        <form style={{ display: "flex" }} onSubmit={onSubmit}>
          <TextArea
            style={{ width: "80", borderRadius: "5px" }}
            onChange={handleChange}
            value={ReplyValue}
            placeholder="write a reply"
            autoSize
          />
          <Button
            style={{
              margin: "0px 5px",
            }}
            className="btn-bordered"
            onClick={onSubmit}
          >
            Answer
          </Button>
          <br />
        </form>
      )}
    </div>
  );
};

export default SingleQuestionAnswer;
