import React, { useState } from "react";
import { Button, Input, message } from "antd";
import axios from "axios";
import SingleCommentComponent from "./SingleCommentComponent";
import config from "../../config";
import { useParams } from "react-router-dom";
const { TextArea } = Input;

const Comments = (props) => {
  const { id } = useParams();
  const [comment, setComment] = useState("");

  const handleChange = (e) => {
    setComment(e.currentTarget.value);
  };

  //Handles the submission of the form by sending data to backend
  const onSubmit = (e) => {
    e.preventDefault();

    // Hard coded comment data structure
    // Todo: Need to control only login user can comment
    const data = {
      comment: comment,
      userId: "testUser",
      status: "pending",
      date: "26/08/2020",
      candidateId: id,
      section: props.renderKey,
      up: 0,
      down: 0,
    };

    return axios
      .post(
        `${config.CANDIDATES}/candidates/comments/insert`,
        {},
        {
          headers: {
            candidate_id: id,
            token: localStorage.getItem("access_token"),
            section: props.renderKey,
            doc: encodeURIComponent(JSON.stringify({ questions: data })),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          message.success("Saved");
          setComment("");
          let updatedCommentList = [...props.commentList];
          updatedCommentList[props.currentAdvocacyKey].comments.unshift(data);
          props.refreshCommentFunction(updatedCommentList);
        } else {
          message.error(
            "Something went wrong, make sure you are connected to the internet."
          );
          console.log(response.data.headers);
        }
      });
  };
  return (
    <div>
      <form style={{ display: "flex" }} onSubmit={onSubmit}>
        <TextArea
          style={{ width: "180", borderRadius: "5px", height: "30px" }}
          onChange={handleChange}
          value={comment}
          placeholder="write a comment"
          autoSize
        />
        <Button
          style={{
            marginLeft: "5px",
            backgroundColor: "#595959",
          }}
          className="btn-solid"
          onClick={onSubmit}
          type="text"
        >
          Submit
        </Button>
      </form>
      <br />
      <div
        style={{
          maxHeight: 150,
          overflow: "auto",
          paddingLeft: "10px",
        }}
      >
        {props.commentList[props.currentAdvocacyKey].comments.map(
          (comment, index) => {
            return (
              <React.Fragment key={index}>
                <SingleCommentComponent
                  comment={comment}
                  userId={"testUser"}
                  candidateId={id}
                />
              </React.Fragment>
            );
          }
        )}
      </div>
    </div>
  );
};

export default Comments;
