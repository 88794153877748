// Libraries
import React, { useContext, useEffect, useState } from "react";
import { Tag } from "antd";
import axios from "axios";

// Components
import CandidatesSection from "../search/CandidatesSection";
import { NewsContext } from "../../providers/NewsContext";
import { CandidateContext } from "../../providers/CandidateContext";
import FactCheckTagList from "./FactCheckTagList";
import config from "../../config";

// Ant Design Components
import { Button, Form, Input } from "antd";

const FactCheck = ({
  label,
  loading
}) => {
  const { factCheckTags, setFactCheckTags } = useContext(NewsContext);
  const { candidateTags, setCandidateTags } = useContext(CandidateContext);
  const [factCheckLink, setFactCheckLink] = useState(null);
  const [factCheckImageLink, setFactCheckImageLink] = useState(null);

  useEffect(() => {
    console.log(factCheckTags);
    console.log(candidateTags);
  }, [factCheckTags]); // eslint-disable-line

  // async function getElectionsData() {
  //   if (candidateTags?.length > 0) {
  //     let headers = {
  //       ids: JSON.stringify(candidateTags),
  //       token: localStorage.getItem("access_token"),
  //     };
  //     const { data: candidateResultData } = await axios.get(
  //       `${config.CANDIDATES}/candidates/find-by-ids`,
  //       { headers }
  //     );
  //
  //     setCandidateTags(candidateResultData);
  //   }
  // }

  function removeFactCheck(link) {
    // const newTags = candidateTags;
    setFactCheckTags((prev) => prev.filter((v) => v.link !== link));
  }

  function handleSettingTag() {
    let tag = {link: factCheckLink, imageLink:factCheckImageLink}
    setFactCheckTags([...factCheckTags, tag])
    setFactCheckLink("");
    setFactCheckImageLink("");


    console.log(tag)
  }

  const handleFactCheckOnChange = (e) => {
    e.preventDefault();
    setFactCheckLink(e.target.value);
  }

  return (
    <>
      <Form.Item name="factCheck">

        {factCheckTags.length > 0  &&
          factCheckTags.map((factCheck) => (
            <Tag
              key={factCheck.link}
              closable
              onClose={() => removeFactCheck(factCheck.link)}
              style={{ marginBottom: 8 }}
            >
              {factCheck?.link}
            </Tag>
          ))}

          <Form.Item name="FactCheck" label="FactCheck">
            <Input placeholder="news link" onChange={(e)=> setFactCheckLink(e.target.value)} value={factCheckLink} />
            <br/>
            <br/>
            <Input placeholder = "picture link" onChange= {(e)=> setFactCheckImageLink(e.target.value)} value= {factCheckImageLink} />
          </Form.Item>


          {(factCheckLink != null && factCheckImageLink != null)?
            ( <Button
              className="btn-bordered"
              style={{ margin: "1em 0" }}
              onClick={()=>
                handleSettingTag()
              }
            >
              Add
            </Button>
            ):null

          }
      </Form.Item>
    </>
  );
};

export default FactCheck;
