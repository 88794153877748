const prod = {
  // Auth 0 Configuration
  AUTH_CONFIG: {
    domain: "login.nocapvote.com",
    originalDomain: "dev-10ykgk8d.us.auth0.com",
    auth0Tenant: "dev-10ykgk8d",
    clientId: "aqO7OUzJv5LN2CpqkqCDc50RELJbiuLg",
    callbackUrl: "https://www.nocapvote.com/callback",
    fbCallbackUrl: "https://www.nocapvote.com/fbCallback",
    dbConnectionName: "Username-Password-Auth-Prod",
    scope: "openid profile email",
  },

  // Users
  USERS: "https://tm25233yc3.execute-api.us-east-1.amazonaws.com/prod",

  // Ads
  ADS: "https://fknvs2camj.execute-api.us-east-1.amazonaws.com/prod",

  // News
  BLOGS: "https://579xyyj56b.execute-api.us-east-1.amazonaws.com/prod",

  // Candidates
  CANDIDATES: "https://r0rqowlbtc.execute-api.us-east-1.amazonaws.com/prod",

  //Queues
  QUEUES: "https://f7dc7km1al.execute-api.us-east-1.amazonaws.com/prod",

  // EMAIL
  EMAIL: "https://li6njeuj74.execute-api.us-east-1.amazonaws.com/prod",

  // Events
  EVENTS: "https://irz76cs2c1.execute-api.us-east-1.amazonaws.com/prod",

  // Profile Picture Uploader
  IMAGES_UPLOAD: "https://ov4r4fe7cj.execute-api.us-east-1.amazonaws.com/prod",
  IMAGE_HOST: "https://s3.amazonaws.com/www.nocapvote.com",

  //Services
  SERVICES: "https://v8uw276l6k.execute-api.us-east-1.amazonaws.com/prod",

  // Google Place APIs
  GOOGLE_API_KEY: "AIzaSyCQOQNlLCsR4NzTciZgLeFPGmLG6smYUEk", 

  // Location
  LOCATION: "https://0jks8mmci8.execute-api.us-east-1.amazonaws.com/prod",

  // Elections
  ELECTIONS:
    "https://9lmybbkpn3.execute-api.us-east-1.amazonaws.com/prod/elections",

  METROADRATE: 0.035,
  ADRATE: 0.02,
  TAX: 0.9,
};

const test = {
  // Auth 0 Configuration
  AUTH_CONFIG: {
    domain: "dev-10ykgk8d.us.auth0.com",
    clientId: "AIq93l7GGDobYw8a5pZrk3qLXnfR3ypg",
    callbackUrl:
      "http://dev.nocap.com.s3-website-us-east-1.amazonaws.com/callback",
    fbCallbackUrl:
      "http://dev.nocap.com.s3-website-us-east-1.amazonaws.com/fbCallback",
    dbConnectionName: "Username-Password-Authentication",
    scope: "openid profile email",
  },

  // Users
  USERS: "https://59765p9url.execute-api.us-east-1.amazonaws.com/dev",

  // Ads
  ADS: "https://iv9q45b3u4.execute-api.us-east-1.amazonaws.com/dev",

  // News
  BLOGS: "https://64lkszhy7i.execute-api.us-east-1.amazonaws.com/dev",

  // Candidates
  CANDIDATES: "https://ne9c9azsf1.execute-api.us-east-1.amazonaws.com/dev",

  //Queues
  QUEUES: "https://7ro08l8y89.execute-api.us-east-1.amazonaws.com/dev",

  // EMAIL
  EMAIL: "https://9klu2jahz1.execute-api.us-east-1.amazonaws.com/dev",

  // Events
  EVENTS: "https://spkfys5al4.execute-api.us-east-1.amazonaws.com/dev",

  // Profile Picture Uploader
  IMAGES_UPLOAD: "https://undkaoyft2.execute-api.us-east-1.amazonaws.com/dev",
  IMAGE_HOST: "https://s3.amazonaws.com/dev.nocap.com",

  //Services
  SERVICES: "https://znr4gby0wi.execute-api.us-east-1.amazonaws.com/dev",

  // Google Place APIs
  GOOGLE_API_KEY: "AIzaSyCQOQNlLCsR4NzTciZgLeFPGmLG6smYUEk",

  // Location
  LOCATION: "https://1ir663m0v7.execute-api.us-east-1.amazonaws.com/dev",

  METROADRATE: 0.035,
  ADRATE: 0.02,
  TAX: 0.9,
};

const dev = {
  // Auth 0 Configuration
  AUTH_CONFIG: {
    domain: "login.nocapvote.com",
    originalDomain: "dev-10ykgk8d.us.auth0.com",
    auth0Tenant: "dev-10ykgk8d",
    clientId: "aqO7OUzJv5LN2CpqkqCDc50RELJbiuLg",
    callbackUrl: "http://localhost:3000/callback",
    fbCallbackUrl: "http://localhost:3000/fbCallback",
    dbConnectionName: "Username-Password-Auth-Prod",
    scope: "openid profile email",
  },

  // Users
  USERS: "https://tm25233yc3.execute-api.us-east-1.amazonaws.com/prod",

  // Ads
  ADS: "https://fknvs2camj.execute-api.us-east-1.amazonaws.com/prod",

  // News
  BLOGS: "https://579xyyj56b.execute-api.us-east-1.amazonaws.com/prod",

  // Candidates
  CANDIDATES: "https://r0rqowlbtc.execute-api.us-east-1.amazonaws.com/prod",

  //Queues
  QUEUES: "https://f7dc7km1al.execute-api.us-east-1.amazonaws.com/prod",

  // EMAIL
  EMAIL: "https://li6njeuj74.execute-api.us-east-1.amazonaws.com/prod",

  // Events
  EVENTS: "https://irz76cs2c1.execute-api.us-east-1.amazonaws.com/prod",

  // Profile Picture Uploader
  IMAGES_UPLOAD: "https://ov4r4fe7cj.execute-api.us-east-1.amazonaws.com/prod",
  IMAGE_HOST: "https://s3.amazonaws.com/www.nocapvote.com",

  //Services
  SERVICES: "https://v8uw276l6k.execute-api.us-east-1.amazonaws.com/prod",

  // Google Place APIs
  GOOGLE_API_KEY: "AIzaSyCQOQNlLCsR4NzTciZgLeFPGmLG6smYUEk",

  // Location
  LOCATION: "https://0jks8mmci8.execute-api.us-east-1.amazonaws.com/prod",

  // Elections
  ELECTIONS:
    "https://9lmybbkpn3.execute-api.us-east-1.amazonaws.com/prod/elections",

  METROADRATE: 0.035,
  ADRATE: 0.02,
  TAX: 0.9,
};

let config;
switch (process.env.REACT_APP_STAGE) {
  case "prod":
    config = prod;
    break;
  case "test":
    config = test;
    break;
  default:
    config = dev;
}

// Stage independent config values
export const WINDOW_URL = new URL(window.location.origin);

export default config;
