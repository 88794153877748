import React, { useState } from "react";
import { Form, Input, Col, Select, Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import NoCapManifest from "../../../../nocap.json";

let politicalIndex = 0;

const PoliticalParty = (props) => {
  const [party, setParty] = useState("");
  const [partyList, setPartyList] = useState(
    NoCapManifest.candidateInfoForm.politicalParties
  );

  const politicalPartiesSelectOptions = partyList.map((party, i) => {
    return (
      <Select.Option key={"party" + i} value={party}>
        {party}
      </Select.Option>
    );
  });

  const addParty = (e) => {
    e.preventDefault();
    setParty("");
    setPartyList([...partyList, party || `New Party ${politicalIndex++}`]);
  };

  return (
    <Col span={24}>
      <Form.Item
        label="Political Party"
        name="politicalParty"
        rules={[
          {
            required: true,
            message: "Please select your political party",
          },
        ]}
      >
        <Select
          mode="tags"
          allowClear
          maxTagCount={2}
          showSearch
          placeholder="select political party"
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                <Input
                  style={{ flex: "auto" }}
                  value={party}
                  onChange={(e) => setParty(e.target.value)}
                />
                <a
                  style={{
                    flex: "none",
                    padding: "8px",
                    display: "block",
                    cursor: "pointer",
                    color: "#8c8c8c",
                  }}
                  href="/"
                  onClick={addParty}
                >
                  <PlusOutlined /> Add
                </a>
              </div>
            </div>
          )}
        >
          {politicalPartiesSelectOptions}
        </Select>
      </Form.Item>
    </Col>
  );
};

export default PoliticalParty;
