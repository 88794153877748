import React, {useState} from "react";
import { Form, Input, Col, Button, Select, Divider } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const Vote = ({ formValues }) => {
  const [vote, setVote] = useState("");
  const [voteList, setVoteList] = useState(['yes','no'])


  const voteSelectionOptions = voteList.map((vote,i) => {
    return (
      <Select.Option key={"vote" + i} value={vote} >
        {vote}
      </Select.Option>
    )
  })
  return (
    <Col span={24}>
      <Form.List name="legislations">
        {(fields, { add, remove }) => {
          const legislations = formValues?.legislations || [];
          const enableBtn =
            legislations.length > 0
              ? legislations.every(
                (v) => v?.bill && v?.name && v?.summary && v?.vote
              )
              : true;

          return (
            <Form.Item label="Vote (Please enter most recent to oldest)">
              {fields.map((field, index) => (
                <div key={"legislation" + index}>
                  <Form.Item
                    name={[field.name, "bill"]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input placeholder="bill number" />
                  </Form.Item>
                  <br />

                  <Form.Item
                    name={[field.name, "billName"]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input placeholder="bill name" />
                  </Form.Item>
                  <br />

                  <Form.Item
                    name={[field.name, "sponsor"]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input placeholder="sponsor" />
                  </Form.Item>
                  <br />

                  <Form.Item
                    name={[field.name, "summary"]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 3 }}
                      allowClear
                      placeholder="bill overview"
                    />
                  </Form.Item>
                  <br/>

                  <Form.Item
                    name={[field.name, "vote"]}
                    style={{ marginBotton: "0px" }}
                  >
                    <Select
                      placeholder = "select vote"
                    >
                      {voteSelectionOptions}
                    </Select>
                  </Form.Item>

                  {fields.length > 1 ? (
                    <Form.Item style={{ marginBottom: "0px" }}>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Form.Item>
                  ) : null}
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  style={{
                    width: "100%",
                  }}
                  disabled={!enableBtn}
                >
                  <PlusOutlined /> Add field
                </Button>
              </Form.Item>
            </Form.Item>
          );
        }}
      </Form.List>
    </Col>
  );
};

export default Vote;
