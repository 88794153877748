import React from "react";
import SingleQuestionAnswer from "./SingleQuestionAnswer";

const AnswerComment = (props) => {
  return (
    <div>
      {props.QAlist &&
        props.QAlist.map(
          (question, index) =>
            // put a condition to render the questions after identifying it as a question
            question.answers &&
            question.answers.map((answer, key) => (
              <React.Fragment key={key}>
                {answer.questionIndex === props.parentQuestionId && (
                  <div style={{ width: "80%", marginLeft: "40px" }} key={key}>
                    <SingleQuestionAnswer
                      type="reply"
                      content={answer.answer}
                      candidateId={props.candidate_id}
                      refreshFunction={props.refreshFunction}
                    />
                  </div>
                )}
              </React.Fragment>
            ))
        )}
    </div>
  );
};

export default AnswerComment;
