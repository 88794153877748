import React, { useEffect, useState, useContext } from "react";
import { Form, Input, DatePicker, message, Select } from "antd";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

import CreateForm from "./CreateForm";
import LocationInput from "./LocationInput";
import config from "../../config";
import CandidateSearchAndTags from "../candidate/CandidateSearchAndTags";
import { CandidateContext } from "../../providers/CandidateContext";

function ElectionsForm() {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  // Ant Design Title Form Field
  const [form] = Form.useForm();

  // Elections Id
  const [formLabel, setFormLabel] = useState("Create Election");
  const [loading, setLoading] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [candidateData, setCandidateData] = useState({ candidates: [] });
  const [searchCandidateLoading, setSearchCandidateLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    type: "",
    date: "",
    city: "",
    state: "",
    candidates: [],
  });
  const { candidateTags, setCandidateTags } = useContext(CandidateContext);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getElectionsData(id);
    }
  }, []); // eslint-disable-line

  async function getElectionsData(id) {
    const { data } = await axios.get(`${config.ELECTIONS}/getOne/${id}`);
    await setCandidateTags(data.candidates);

    const formValues = {
      name: data.name || "",
      city: data.city || "",
      electionType: data.electionType || "",
      state: data.state || "",
      date: [moment(data.startDate), moment(data.endDate)],
    };
    form.setFieldsValue(formValues);
    setInitialValues(formValues);
    setDisableSubmitBtn(true);

    setFormLabel("Update Election");
  }

  async function onFinish(value) {
    setLoading(true);
    let reducedTags = candidateTags.map((tag) => {
      return tag._id;
    });
    const data = {
      ...value,
      candidates: reducedTags,
      startDate: moment(value.date[0]).startOf("day"),
      endDate: moment(value.date[1]).endOf("day"),
    };
    delete data["date"];
    delete data["type"];

    try {
      let url = `${config.ELECTIONS}/new`;
      if (id) {
        url = `${config.ELECTIONS}/update/${id}`;
        await axios.patch(url, data, {
          headers: {
            token: localStorage.getItem("access_token"),
          },
        });
      } else {
        await axios.post(url, data, {
          headers: {
            token: localStorage.getItem("access_token"),
          },
        });
      }
      setLoading(false);
      setCandidateTags([]);
      message.success("Election is added successfully!");
      message.warning("Redirecting...", 1);
      history.push("/");
    } catch (err) {
      message.error("Something is wrong!");
      setLoading(false);
      console.log(err);
    }
  }

  const handleCandidateSearch = async (search) => {
    try {
      setSearchCandidateLoading(true);
      const result = await axios.get(
        `${config.CANDIDATES}/candidates/search?search-text=${search}&limit=100`
      );

      setCandidateData(result.data);
      setSearchCandidateLoading(false);
    } catch (e) {
      message.error("Error searching.");
      setSearchCandidateLoading(false);
      console.log(e);
    }
  };

  const handleClearCandidateSearch = () => {
    setCandidateData({ candidates: [] });
  };

  const electionTypes = [
    "Local judicial",
    "Local legislative",
    "Local executives",
    "School boards",
    "State judicial",
    "Statewide",
    "State executive",
    "State House",
    "State Senate",
    "Lieutenant Governor",
    "Governor",
    "Federal judicial",
    "U.S. House",
    "U.S. Senate",
    "Presidential Office",
  ];

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFieldsChange={() => {
        const changed = {
          name: form.getFieldsValue().name,
          city: form.getFieldsValue().city,
          electionType: form.getFieldsValue().electionType,
          state: form.getFieldsValue().state,
          date: form.getFieldsValue().date,
        };
        setDisableSubmitBtn(
          JSON.stringify(changed) === JSON.stringify(initialValues)
        );
      }}
      onFinish={onFinish}
    >
      <CreateForm
        submitLabel={formLabel}
        loading={loading}
        disabledBtn={disableSubmitBtn}
      >
        <Form.Item
          label="Election Title"
          name="name"
          rules={[{ required: true }]}
        >
          <Input
            placeholder="Enter title"
            onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
          />
        </Form.Item>

        <Form.Item
          name="electionType"
          label="Election Type"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select Election type"
            autoClearSearchValue={false}
          >
            {electionTypes.map((val) => (
              <Option key={val} value={val}>
                {val}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Date" name="date" rules={[{ required: true }]}>
          <RangePicker
            style={{ width: "100%" }}
            use12Hours
            format="YYYY-MM-DD"
          />
        </Form.Item>

        <Form.Item label="City" name="city" rules={[{ required: true }]}>
          <LocationInput
            id="city"
            cityOnly
            value={form.getFieldValue("state")}
          />
        </Form.Item>

        <Form.Item label="State" name="state" rules={[{ required: true }]}>
          <LocationInput
            id="state"
            stateOnly
            value={form.getFieldValue("city")}
          />
        </Form.Item>

        <CandidateSearchAndTags
          candidateData={candidateData}
          onSearch={(value) => handleCandidateSearch(value)}
          onClick={handleClearCandidateSearch}
          label="Tag Candidates"
          loading={searchCandidateLoading}
        />
      </CreateForm>
    </Form>
  );
}

export default ElectionsForm;
