import axios from "axios";
import config from "../config";

export function useGuideData() {
  async function updateUserGuide(step) {
    const userData = JSON.parse(localStorage.getItem("user"));
    const hasGuideData = userData?.onBoarding?.guide;
    const guide = hasGuideData ? [...hasGuideData, step] : [step];
    const doc = {
      onBoarding: {
        ...userData?.onBoarding,
        guide,
      },
    };

    console.log("doc", doc);
    // Add step if consider done
    await axios
      .patch(
        `${config.USERS}/users`,
        {},
        {
          headers: {
            token: localStorage.getItem("access_token"),
            doc: encodeURIComponent(JSON.stringify(doc)),
          },
        }
      )
      .then(({ data }) => {
        if (userData.role !== "user") {
          localStorage.setItem("user", JSON.stringify(data.value));
        }
      });
  }

  const runJoyRideStep = (step) => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      return userData?.onBoarding?.guide?.includes(step);
    }

    return true;
  };

  return {
    updateUserGuide,
    runJoyRideStep,
  };
}

export default useGuideData;
