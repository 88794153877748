import React from "react";
import { Spin } from "antd";
const Loading = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <small>Loading ... </small>
      <Spin />
    </div>
  );
};

export default Loading;
