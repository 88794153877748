import React from "react";
import { Card, Modal, Row, Col, Divider, Tag } from "antd";

class ProductGridItem extends React.Component {
  state = {
    modalVisible: false,
    width: 0,
    height: 0,
  };
  componentDidMount() {
    console.log(this.props.product, "products mount");
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  setModalVisible = (bool) => {
    console.log("set Modal visible: ", bool);
    this.setState({ modalVisible: bool });
  };
  handleProductClick = (e) => {
    console.log(this.state.width);
    this.setModalVisible(true);
  };
  render() {
    const product = this.props.product;
    const logoUrl = "https://s3.amazonaws.com/dev.nocap.com/uploads/logo.png";

    return (
      <React.Fragment>
        <Card
          className="ProductGridItem"
          onClick={this.handleProductClick}
          style={{ width: 200 }}
          cover={<img height="176" src={logoUrl} alt={product.name} />}
        >
          <div className="product-name">
            <strong>{product.name}</strong>
            {/* <div>
              {product.ingredients.thc
                ? `THC: ${product.ingredients.thc}`
                : null}
              {product.ingredients.cbd
                ? `CBD: ${product.ingredients.cbd}`
                : null}
              {product.ingredients.terpenes
                ? `TERPS: ${product.ingredients.terpenes}`
                : null}
            </div> */}
          </div>
        </Card>
        <Modal
          id="product-modal"
          visible={this.state.modalVisible}
          centered
          className="ProductDetailsModal"
          footer={null}
          width={""}
          // style={{
          //   maxHeight: "900px",
          //   maxWidth: "1200px"
          // }}
          maskClosable={true}
          onCancel={() => this.setModalVisible(false)}
        >
          <div className="product-details">
            <div className="title">
              <h2>{product.name}</h2>
              <div>
                <Tag className="capitalized">{product.category}</Tag>
                {product.type
                  ? product.type.map((t, i) => (
                      <Tag key={i} className="capitalized">
                        {t}
                      </Tag>
                    ))
                  : null}
              </div>
            </div>
            <Row type="flex" align="top">
              <Col md={10}>
                <div className="inner">
                  <div className="image-wrapper">
                    <img
                      style={{ maxWidth: "100%" }}
                      src={logoUrl}
                      alt="product"
                    />
                  </div>
                </div>
              </Col>
              <Col md={14}>
                <div className="inner">
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{ __html: product.description }}
                  />
                </div>

                {product.ingredients && (
                  <div className="inner ingredients">
                    {Object.keys(product.ingredients).map((key) =>
                      product.ingredients[key] > 0 ? (
                        <div key={key} className="ingredient">
                          <span className="label">{key}:</span>
                          <span className="percentage">
                            {product.ingredients[key]}%
                          </span>
                        </div>
                      ) : null
                    )}
                  </div>
                )}
                <div style={{ padding: "0 24px" }}>
                  <Divider />
                </div>

                <div className="inner">
                  <div className="product-pricerates">
                    {Object.keys(product.priceRates).map((key) => (
                      <div key={key} className="pricerate">
                        <div className="price">
                          ${product.priceRates[key].price}{" "}
                        </div>
                        <div className="amount">
                          {product.priceRates[key].amount.toLowerCase()}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ProductGridItem;
