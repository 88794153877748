import React from "react";
import CandidatePreview from "../CandidatePreview";
import { Button, Space, Typography } from "antd";
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  YoutubeOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { objectIsEmpty } from "../../../helpers";
import AdHelper from "../../shared/ad/AdHelper";

const { Text } = Typography;

const BioLeft = (props) => {
  const { bio, source } = props.details;
  const { social } = bio;

  let cityState = "";
  if (bio.contact.address !== undefined) {
    let officeContact = bio.contact.address.split(",");
    let contactLength = officeContact.length;
    let city = officeContact[contactLength - 3];

    cityState =
      contactLength > 1
        ? `${city ? city + ", " : ""} ${officeContact[contactLength - 2]}`
        : "";
  }

  let isLoggedIn = JSON.parse(localStorage.getItem("user"));

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{ textAlign: "center", width: "100%" }}
      align="center"
    >
      <CandidatePreview details={props.details} buttonVisibility={true} />
      {!objectIsEmpty(social) ? (
        <>
          {isLoggedIn && (
            <Text strong underline>
              General
            </Text>
          )}
          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "center" }}
          >
            {social.website ? (
              <Button
                style={{ borderColor: "#bfbfbf" }}
                className="btn-bordered"
                size="small"
                href={social.website}
                icon={<HomeOutlined />}
              />
            ) : null}
            {social.facebook && source === "nocap-auto-loader" ? (
              <Button
                style={{ borderColor: "#bfbfbf" }}
                className="btn-bordered"
                size="small"
                href={`https://wwww.facebook.com/${social.facebook}`}
                icon={<FacebookOutlined />}
              />
            ) : null}
            {social.facebook && source !== "nocap-auto-loader" ? (
              <Button
                style={{ borderColor: "#bfbfbf" }}
                size="small"
                className="btn-bordered"
                href={`https://wwww.facebook.com`}
                icon={<FacebookOutlined />}
              />
            ) : null}
            {social.instagram && source === "nocap-auto-loader" ? (
              <Button
                style={{ borderColor: "#bfbfbf" }}
                size="small"
                className="btn-bordered"
                href={`https://www.instagram.com/`}
                icon={<InstagramOutlined />}
              />
            ) : null}
            {social.instagram && source !== "nocap-auto-loader" ? (
              <Button
                style={{ borderColor: "#bfbfbf" }}
                size="small"
                className="btn-bordered"
                href={`https://www.instagram.com/`}
                icon={<InstagramOutlined />}
              />
            ) : null}
            {social.twitter && source === "nocap-auto-loader" ? (
              <Button
                style={{ borderColor: "#bfbfbf" }}
                size="small"
                className="btn-bordered"
                href={`https://twitter.com/${social.twitter}`}
                icon={<TwitterOutlined />}
              />
            ) : null}
            {social.twitter && source !== "nocap-auto-loader" ? (
              <Button
                style={{ borderColor: "#bfbfbf" }}
                size="small"
                className="btn-bordered"
                href={social.twitter}
                icon={<TwitterOutlined />}
              />
            ) : null}
            {social.youtube && source === "nocap-auto-loader" ? (
              <Button
                style={{ borderColor: "#bfbfbf" }}
                size="small"
                className="btn-bordered"
                href={`https://www.youtube.com/${social.youtube}`}
                icon={<YoutubeOutlined />}
              />
            ) : null}
            {social.youtube && source !== "nocap-auto-loader" ? (
              <Button
                style={{ borderColor: "#bfbfbf" }}
                size="small"
                className="btn-bordered"
                href={social.youtube}
                icon={<YoutubeOutlined />}
              />
            ) : null}
          </Space>
        </>
      ) : null}
      {bio.contact.address ||
      bio.contact.phone ||
      bio.contact.email ||
      bio.contact.fax ? (
        <Space direction="vertical">
          {isLoggedIn && (
            <Text strong underline>
              Office Contacts
            </Text>
          )}
          {bio.contact.address ? (
            <>
              <Text>Address</Text>
              <Text type="secondary">{cityState}</Text>
            </>
          ) : null}
          {bio.contact.phone ? (
            <>
              <Text>Phone</Text>
              <Text type="secondary">{bio.contact.phone}</Text>
            </>
          ) : null}
          {bio.contact.email ? (
            <>
              <Text>Email</Text>
              <div
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "250px",
                }}
              >
                <Text type="secondary">{bio.contact.email}</Text>
              </div>
            </>
          ) : null}
          {bio.contact.fax ? (
            <>
              <Text>Fax</Text>
              <Text type="secondary">{bio.contact.fax}</Text>
            </>
          ) : null}
        </Space>
      ) : null}
      <AdHelper page="news-post" position="left" />
    </Space>
  );
};

export default BioLeft;
