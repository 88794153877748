import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Grid, message, Spin, Empty, Card, Space, Input, Row, Col } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import Slider from "react-slick";

import CandidatePreview from "./CandidatePreview";
import config from "../../config";
import AdInRow from "../ad/AdInRow";
import { sliderSettings } from "../../utils/sliderSettings";

const gridStyle = {
  width: "100%",
  height: 450,
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
let addressAutoComplete;

function handleScriptLoad(setInput, setDivisions, addressId) {
  addressAutoComplete = new window.google.maps.places.Autocomplete(addressId, {
    types: ["address"],
    componentRestrictions: { country: "us" },
  });
  addressAutoComplete.addListener("place_changed", () =>
    handleAddressPlaceSelect(setInput, setDivisions)
  );
}

async function handleAddressPlaceSelect(setInput, setDivisions) {
  const addressObject = addressAutoComplete.getPlace();
  setInput(addressObject.formatted_address);
  const divisions = await axios.get(
    `https://civicinfo.googleapis.com/civicinfo/v2/representatives?address=${addressObject.formatted_address}&includeOffices=false&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
  );
  setDivisions(Object.keys(divisions.data.divisions));
}

const { useBreakpoint } = Grid;

const CandidatesList = (props) => {
  // For Address Input
  const [input, setInput] = useState("");
  const [divisions, setDivisions] = useState(props.divisions);

  const screens = useBreakpoint();
  const isMobile = screens.xs;

  // Candidate datas
  function FetchInfo(url) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    async function get() {
      try {
        setLoading(true);
        let headers = {
          divisions: JSON.stringify(divisions),
        };
        const result = await axios.get(url, { headers });
        setData(result.data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }
    useEffect(() => {
      get();
    }, [divisions]); // eslint-disable-line
    return [data, error, loading];
  }

  const [data, error, loading] = FetchInfo(
    `${config.CANDIDATES}/candidates/find-by-divisions`
  );

  useEffect(() => {
    handleScriptLoad(
      setInput,
      setDivisions,
      document.getElementById("place-autocomplete")
    );
  }, []);

  if (error)
    return message.error(
      "Oops ... Something went wrong, we could not load the information for this search"
    );

  let result = null;
  if (loading) {
    result = (
      <Space direction="vertical" align="center" style={{ width: "100%" }}>
        <Spin style={{ padding: "10px" }} />
      </Space>
    );
  } else if (!loading && data && data.length > 0) {
    const settings = {
      ...sliderSettings,
    };

    result = isMobile ? (
      <Slider {...settings} fade>
        {data.map((candidate, index) => (
          <Card bordered={false} className="CandidateList">
            <Link
              key={`candidate ${index + candidate["_id"]}`}
              to={{
                pathname: `/candidate/${candidate["_id"]}`,
                state: {
                  data: candidate,
                },
              }}
            >
              <Card.Grid
                style={{
                  ...gridStyle,
                }}
              >
                <CandidatePreview
                  key={index + candidate._id}
                  details={candidate}
                  buttonVisibility={false}
                />
              </Card.Grid>
            </Link>
          </Card>
        ))}
      </Slider>
    ) : (
      <Row justify="start">
        {data.map((candidate, index) => (
          <AdInRow count={index + 1}>
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Card bordered={false} className="CandidateList">
                <Link
                  key={`candidate ${index + candidate["_id"]}`}
                  to={{
                    pathname: `/candidate/${candidate["_id"]}`,
                    state: {
                      data: candidate,
                    },
                  }}
                >
                  <Card.Grid style={gridStyle}>
                    <CandidatePreview
                      details={candidate}
                      buttonVisibility={false}
                    />
                  </Card.Grid>
                </Link>
              </Card>
            </Col>
          </AdInRow>
        ))}
      </Row>
    );
  } else {
    result = (
      <Space direction="vertical" align="center" style={{ width: "100%" }}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Space>
    );
  }
  return (
    <>
      <Row justify="center">
        <Col
          className="find-candidates-title-container"
          xs={24}
          sm={16}
          md={12}
          lg={12}
          xl={12}
        >
          <h3>Find Your Candidates</h3>

          <Input
            suffix={<EnvironmentOutlined />}
            id="place-autocomplete"
            type="text"
            placeholder="enter address"
            onChange={(e) => {
              setInput(e.target.value);
            }}
            value={input}
            onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
          />
        </Col>
      </Row>
      {result}
    </>
  );
};

export default CandidatesList;
