import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment";
import { injectStripe } from "react-stripe-elements";
import config from "../../../config";
import Spinner from "../../Loading";
import { SignUpContext } from "../../../providers/SignupContext";

import { Row, Col, Button, message, Form, Input, Divider, Modal } from "antd";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from "react-stripe-elements";

const OnBoardingCheckout = (props) => {
  const { profileData } = useContext(SignUpContext);

  const [state, setState] = useState({
    amount: props.cost,
    name: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).nickname
      : "",
    email: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).email
      : "",
    showModal: false,
    checkBox: false,
    paymentLoading: false,
    promoValue: "",
    promoCodes: [],
    discountSet: false,
    costWithDiscount: 0,
    completed: false,
    bannerCheck: false,
    modalVisible: false,
  });

  useEffect(() => {
    console.log("STRIPE PROPS", props.stepStatus.bannerData);
    if (props.stepStatus.bannerData.banner) {
      bannerChecker();
    }
  }, []); // eslint-disable-line

  const bannerChecker = async () => {
    try {
      let bannerData = props.stepStatus.bannerData;
      // console.log
      // ev.preventDefault()
      // TO DO: check for banner overlap here!
      if (bannerData.banner) {
        // try{
        let doc = {
          type: bannerData.type,
          start_date: bannerData.bannerStartDate,
          placeId: bannerData.location.places_component.place_id,
          runTime: bannerData.runTime,
          dataType: "overlap",
        };

        const response = await axios.get(`${config.LOCATION}/checkdate`, {
          headers: {
            doc: JSON.stringify(doc),
          },
        });
        if (response.status === 200) {
          setState({ modalVisible: true });
          return response.data;
        }
      } else {
        return false;
      }
    } catch (err) {
      console.log("ERROR rechecking date", err);
    }
  };

  const submitStripe = async (name, email) => {
    try {
      setState({ paymentLoading: true });
      const plan =
        profileData.membership.name === "Premium" ? "premium" : "sponsor";
      localStorage.setItem("plan", plan);
      message.success("Purchase Complete");
      // TO DO: SAVE ADD HERE
      setState({ paymentLoading: false, completed: true });
    } catch (err) {
      console.log(err, "stripe charge handle error");
      message.error(
        "There was an error completing payment, please check your details"
      );
      setState({ paymentLoading: false });
    }
  };

  // const onChange = (e) => {
  //   console.log(e.target.name, "+", e.target.value);
  //   const target = e.target;
  //   const name = target.name;
  //   setState({
  //     [name]: target.value,
  //   });
  // };

  const removeOverlapBanner = async () => {
    await props.removeBannerData();
    setState({ modalVisible: false });
    message.success("Banner removed, please continue with payment");
  };

  const goBackToBanner = async () => {
    await props.removeBannerData();
    setState({ modalVisible: false });
  };

  const placeholder = () => {
    return {
      style: {
        base: {
          "::placeholder": {
            color: "#BFBFBF",
            fontWeight: 300,
          },
        },
      },
    };
  };

  const billDay = moment().format("Do");

  return (
    <div className="stripe-form">
      <Modal
        visible={state.modalVisible}
        title="Banner Date Conflict"
        closable={false}
        width="650px"
        footer={[
          <Button
            key="back"
            style={{ borderRadius: ".5em" }}
            onClick={removeOverlapBanner}
          >
            Remove Banner
          </Button>,
          <Button
            key="submit"
            className="btn-blue"
            style={{ borderRadius: ".5em" }}
            onClick={goBackToBanner}
          >
            Try Again
          </Button>,
        ]}
      >
        Oops, looks like someone booked the same banner dates while you were
        finishing up, would you like to try again or remove your banner?
      </Modal>

      <Form
        layout="vertical"
        className="stripe-card-form"
        onSubmit={props.submitStripe}
      >
        <Form.Item style={{ margin: 0, padding: 0 }} label="First and Last" />
        <Input
          className="stripe-input"
          placeholder="First and Last name"
          value={props.name}
          onChange={props.handleStandardInputChange}
          required={true}
          style={{ width: "100%" }}
          name="stripe_name"
        ></Input>
        <Form.Item style={{ margin: 0, padding: 0 }} label="Email" />
        <Input
          className="stripe-input"
          placeholder="Billing Email"
          value={props.email}
          onChange={props.handleStandardInputChange}
          required={true}
          style={{ width: "100%" }}
          name="stripe_email"
        ></Input>
        <Form.Item style={{ margin: 0, padding: 0 }} label="Card Number" />
        <CardNumberElement {...placeholder()} />
        <Row gutter={12}>
          <Col xs={24} md={9}>
            <Form.Item style={{ margin: 0, padding: 0 }} label="Expiry Date" />
            <CardExpiryElement {...placeholder()} />
          </Col>

          <Col xs={24} md={6}>
            <div style={{ display: "auto" }}>
              <Form.Item style={{ margin: 0, padding: 0 }} label="CVC" />
              <CardCVCElement {...placeholder()} />
            </div>
          </Col>
        </Row>
        5% (Five Percent) of your purchase will be donated to the{" "}
        <strong>Angels' Habitat Foundation</strong>, a 501(c)(3) nonprofit
        organization, helping animals who need home relocation, medical
        attention, and ocean pollution cleanup.
        <br />
        <br />
        You will be billed the {billDay} of every month.
        <br />
        <br />
        <strong>For Cancellation: </strong>
        No Cap Vote will email customers for a Verification of cancellation of
        subscription.
        <br />
        <br />
        {state.paymentLoading ? (
          <Spinner size={59} />
        ) : state.completed || props.paymentComplete ? (
          "Your Purchase is complete please click Done to continue"
        ) : (
          <Button
            style={{ marginTop: "1em" }}
            onClick={submitStripe}
            type="submit"
            className="btn-bordered"
          >
            Pay Now
          </Button>
        )}
      </Form>

      <Divider />
    </div>
  );
};

export default injectStripe(OnBoardingCheckout);
