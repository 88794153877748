import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Avatar,
  // Rate, Space, Typography
} from "antd";
// import { CheckCircleFilled } from "@ant-design/icons";

export default (props) => {
  const { _id, organization_name, location, images } = props.service;

  return (
    <Card hoverable bordered={false} className="DirectoryGridItem">
      <Link
        to={{
          pathname: `/services/${_id}`,
          state: {
            data: props.service,
          },
        }}
      >
        <div className="wrapper">
          <Avatar size={200} src={images.logo} />
        </div>

        <div className="card-body">
          <div style={{ textAlign: "center" }}>
            <h4 style={{ margin: "0", height: "18px" }}>{organization_name}</h4>
            {/* TEMPORARY REMOVE - EXCEL B179
             <Space>
              <Rate
                style={{ color: "black" }}
                value={rating?.stars}
                character={<CheckCircleFilled />}
                disabled
              />
              <Typography.Text type="secondary">
                ({`${rating?.total}`})
              </Typography.Text>
            </Space> */}
            <div className="location text-muted">
              {location[0]?.formatted_address || ""}
            </div>
          </div>
        </div>
      </Link>
    </Card>
  );
};
