// Libraries
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// ComponentWillMount and ComponentWillReceiveProps UI Warnings are related to react-stack-grid npm packages

// Components
import AdHelper from "../shared/ad/AdHelper";
import Careers from "../shared/careers/CareerTab";
import CategoryTags from "../news/CategoryTags";
import Loading from "../Loading";
import NewsCard from "../news/NewsCard";
import AdInRow from "../ad/AdInRow";

// Ant Design Components
import { Empty, message, BackTop, Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";

// Helpers & Config
import NoCapManifest from "../../nocap.json";
import config from "../../config";
import useNewsData from "../../hooks/useNewsData";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NewsPage = (props) => {
  const [currentCategory, setCurrentCategory] = useState("");

  // Retrieve window and news data/states using custom hook

  const { get, data, error, loading } = useNewsData(
    `${config.BLOGS}/blogs/all`
  );

  useEffect(() => {
    get();
    // eslint-disable-next-line
  }, []);

  if (error)
    return message.error(
      "Oops ... Something went wrong, we could not load the information"
    );

  let result = null;
  if (loading) {
    result = <Loading />;
  } else if (data && data.length > 0) {
    let newsCount = data.length;
    let careerPosts = [];

    const user = JSON.parse(localStorage.getItem("user")) || {};

    let blogItems = data.map((post, idx) => {
      if (post.category) {
        return (
          <AdInRow count={idx} key={post["_id"]}>
            <Col
              sm={24}
              md={12}
              lg={8}
              xl={6}
              style={{
                padding: "0 1em",
                marginBottom: "2em",
              }}
            >
              <NewsCard
                post={post}
                pathname={`/news/${post["_id"]}`}
                getData={get}
              />
            </Col>
          </AdInRow>
        );
      } else {
        return "";
      }
    });

    result = (
      <>
        <div className="news-cta-container">
          <CategoryTags
            categories={NoCapManifest.news.newsCategories}
            value={currentCategory}
            onChange={setCurrentCategory}
          />
          {/* Admin condition: only show btn if admin */}
          {user.role === "admin" && (
            <Link to="/news/new" className="news-cta--btn-container">
              <Button
                className="btn-bordered"
                icon={<PlusOutlined />}
                size="large"
              >
                Add News Post
              </Button>
            </Link>
          )}
          {/* Admin condition ends */}
        </div>

        {newsCount !== 0 ? (
          <Row className="news-row">{blogItems}</Row>
        ) : currentCategory === "Careers" ? (
          <Careers careerPosts={careerPosts} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}

        <BackTop />
      </>
    );
  } else {
    result = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  return (
    <div>
      <div className="news-ads-container">
        <AdHelper page="news" />
      </div>
      {result}
      <br />
      <div className="news-ads-container">
        <AdHelper page="news" />
      </div>
    </div>
  );
};

export default NewsPage;
