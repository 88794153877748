import React from "react";
import { Button } from "antd";

const NewsCoverLayout = (props) => {
  return (
    <>
      <div className="image-wrapper">{props.$imagePreview}</div>
      <div className="file-upload-container">
        {props.imagePreviewUrl ? (
          <>
            <Button
              onClick={props.toggleVisibility}
              type="text"
              className={"file-upload"}
            >
              <span style={{ textDecoration: "underline" }}>
                Change Cover Image
              </span>
            </Button>
            <Button
              onClick={props.handleRemoveImage}
              type="text"
              className={"file-upload"}
            >
              <span style={{ textDecoration: "underline" }}>Remove</span>
            </Button>
          </>
        ) : (
          <Button
            onClick={props.toggleVisibility}
            type="text"
            style={{ margin: "1em 0" }}
            className={"btn-bordered"}
          >
            Upload Cover Image
          </Button>
        )}
      </div>
    </>
  );
};

export default NewsCoverLayout;
