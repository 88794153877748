import React from "react";
import { Menu, Checkbox } from "antd";
import NoCapManifest from "../../../nocap.json";
import { capitalizeFirstLetter } from "../../../helpers";

const CheckboxDropdown = (props) => (
  <Menu>
    {Object.keys(NoCapManifest.services).map((service, index) => {
      let categoryName =
        capitalizeFirstLetter(service.replace("Categories", "")) + "s";
      return (
        <Menu.SubMenu title={categoryName} key={index}>
          <Menu.Item
            disabled={true}
            className="checkbox-menu-item"
            style={{ border: "1px solid #333" }}
          >
            <span>selectll</span>
            <Checkbox
              name={`${service} - selectll`}
              onChange={props.handleCheckboxChange}
              // checked={props.activeItems.includes("selectll")}
              style={{ borderColor: "#555251" }}
            />
          </Menu.Item>
          {NoCapManifest.services[service].map((sub, index) => {
            return (
              <Menu.Item
                disabled={true}
                className="checkbox-menu-item"
                key={sub + index}
                style={{ border: "1px solid #333" }}
              >
                <span>{sub.split(" - ")[1]}</span>
                <Checkbox
                  name={sub}
                  onChange={props.handleCheckboxChange}
                  checked={props.activeItems.includes(sub)}
                  style={{ borderColor: "#555251" }}
                />
              </Menu.Item>
            );
          })}
        </Menu.SubMenu>
      );
    })}
  </Menu>
);

export default CheckboxDropdown;
