import React, { useEffect, useState } from "react";
import {
  BackTop,
  message,
  Spin,
  Empty,
  Card,
  Space,
  Layout,
  Button,
  Row,
  Col,
} from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import CandidatePreview from "../candidate/CandidatePreview";
import AdHelper from "../shared/ad/AdHelper";

const gridStyle = {
  width: 250,
  height: 450,
  margin: "0 10px",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const MyVotingList = (props) => {
  const [votingList, setVotingList] = useState(
    localStorage.getItem("votingList")
      ? JSON.parse(localStorage.getItem("votingList"))
      : []
  );
  if (!localStorage.getItem("userid")) {
    return (
      <Layout>
        <Layout.Content>
          <div className="ConfirmEmailPage big-bottom-padding">
            <h1>My Voting List</h1>
            <p className="lead">
              {" "}
              - Please log in or signup to see your voting list -
            </p>
          </div>
        </Layout.Content>
      </Layout>
    );
  } else {
    const removeCandidate = (e, candidate) => {
      let list = votingList.filter(
        (candidateId) => candidateId !== candidate._id
      );
      localStorage.setItem("votingList", JSON.stringify(list));
      const doc = JSON.stringify({
        votingList: list,
      });
      return axios
        .patch(
          `${config.USERS}/users`,
          {},
          {
            headers: {
              token: localStorage.getItem("access_token"),
              doc: encodeURIComponent(doc),
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            message.info(
              `${candidate.bio.name} has been removed from your voting list.`
            );
            return setVotingList(list);
          } else {
            message.error(
              "Something went wrong, make sure you are connected to the internet."
            );
          }
        });
    };
    function FetchInfo(url) {
      const [data, setData] = useState(null);
      const [error, setError] = useState(null);
      const [loading, setLoading] = useState(true);
      console.log("JSON.stringify(votingList)", JSON.stringify(votingList));
      async function get() {
        try {
          setLoading(true);
          if (votingList.length !== 0) {
            let headers = {
              ids: JSON.stringify(votingList),
              token: localStorage.getItem("access_token"),
            };
            const result = await axios.get(url, { headers });
            setData(result.data);
          } else {
            setData([]);
          }
        } catch (e) {
          setError(e);
        } finally {
          setLoading(false);
        }
      }
      useEffect(() => {
        get();
      }, [votingList]); // eslint-disable-line
      return [data, error, loading];
    }

    const [data, error, loading] = FetchInfo(
      `${config.CANDIDATES}/candidates/find-by-ids`
    );

    if (error)
      return message.error(
        "Oops ... Something went wrong, we could not load the information for this search"
      );

    let result = null;
    if (loading) {
      result = (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <small>Loading ... </small>
          <Spin />
        </div>
      );
    } else if (data && data.length > 0) {
      result = (
        <Space direction="vertical" align="center" style={{ width: "100%" }}>
          <h1>My Voting List</h1>
          <Card bordered={false}>
            {data.map((candidate) => (
              <Space
                direction="vertical"
                align="center"
                className="candidate-list-container"
              >
                <Link
                  to={{
                    pathname: `/candidate/${candidate["_id"]}`,
                    state: {
                      data: candidate,
                    },
                  }}
                >
                  <Card.Grid style={gridStyle}>
                    <CandidatePreview
                      details={candidate}
                      buttonVisibility={false}
                    />
                  </Card.Grid>
                </Link>
                <Button
                  onClick={(e) => removeCandidate(e, candidate)}
                  style={{ borderColor: "#bfbfbf", marginBottom: "10px" }}
                  className="btn-bordered"
                  type="text"
                >
                  Remove
                </Button>
              </Space>
            ))}
          </Card>
        </Space>
      );
    } else {
      result = (
        <Space direction="vertical" align="center" style={{ width: "100%" }}>
          <h1>My Voting List</h1>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Space>
      );
    }
    return (
      <div>
        <AdHelper page="my-voting-list" />
        <Row>
          <Col xs={24} md={4}>
            <AdHelper page="news-post" position="left" />
          </Col>
          <Col xs={24} md={20}>
            {result}
          </Col>
        </Row>
        <BackTop />
      </div>
    );
  }
};

export default MyVotingList;
