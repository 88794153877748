import React from "react";
import { Card } from "antd";

import FeatureServiceCard from "../services/FeatureServiceCard";

const ServicesSection = (props) => {
  let serviceMap = {
    Coalition: [],
    Organization: [],
    Movement: [],
    Services: [],
  };
  if (props.data.services) {
    props.data.services &&
      props.data.services.filter((s) => {
        if (s._id.length <= 3) {
          s.services.forEach((service) => {
            serviceMap[service.descriptors.categories[0].split(" - ")[0]].push(
              service
            );
          });
        } else {
          serviceMap.Services.push(s);
        }
        return serviceMap;
      });
  }

  return (
    <>
      {serviceMap.Coalition.length !== 0 ? (
        <Card title="Coalitions" bordered={false}>
          <div className="bizcard-home-container">
            {serviceMap.Coalition.map((service, index) => {
              return <FeatureServiceCard service={service} key={service._id} />;
            })}
          </div>
        </Card>
      ) : null}
      {serviceMap.Organization.length !== 0 ? (
        <Card title="Organizations" bordered={false}>
          <div className="bizcard-home-container">
            {serviceMap.Organization.map((service, index) => {
              return <FeatureServiceCard service={service} key={service._id} />;
            })}
          </div>
        </Card>
      ) : null}
      {serviceMap.Movement.length !== 0 ? (
        <Card title="Movements" bordered={false}>
          <div className="bizcard-home-container">
            {serviceMap.Movement.map((service, index) => {
              return <FeatureServiceCard service={service} key={service._id} />;
            })}
          </div>
        </Card>
      ) : null}
      {serviceMap.Services.length !== 0 ? (
        <Card title="Services" bordered={false}>
          {serviceMap.Services.map((subCategory, index) => {
            return (
              <Card
                key={subCategory._id}
                title={subCategory._id}
                style={{ width: "100%", border: "none" }}
              >
                <div className="bizcard-home-container">
                  {subCategory.services.map((service) => {
                    return (
                      <FeatureServiceCard service={service} key={service._id} />
                    );
                  })}
                </div>
              </Card>
            );
          })}
        </Card>
      ) : null}
    </>
  );
};

export default ServicesSection;
