import React from "react";
import { Form, Input } from "antd";
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  YoutubeOutlined,
  HomeOutlined,
  AudioOutlined,
} from "@ant-design/icons";

const SocialMedia = () => (
  <Form.Item label="Social Media">
    <Form.Item name="website">
      <Input
        placeholder="enter valid website URL"
        suffix={<HomeOutlined />}
        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
      />
    </Form.Item>
    <Form.Item name="facebook">
      <Input
        placeholder="enter valid Facebook URL"
        suffix={<FacebookOutlined />}
        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
      />
    </Form.Item>
    <Form.Item name="instagram">
      <Input
        placeholder="enter valid Instagram URL"
        suffix={<InstagramOutlined />}
        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
      />
    </Form.Item>
    <Form.Item name="twitter">
      <Input
        placeholder="enter valid Twitter URL"
        suffix={<TwitterOutlined />}
        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
      />
    </Form.Item>
    <Form.Item name="youtube">
      <Input
        placeholder="enter valid YouTube URL"
        suffix={<YoutubeOutlined />}
        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
      />
    </Form.Item>
    <Form.Item name="podcasts">
      <Input
        placeholder="enter valid podcast URL"
        suffix={<AudioOutlined />}
        onPressEnter={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
      />
    </Form.Item>
  </Form.Item>
);
export default SocialMedia;
