import React, { Component } from "react";
import CareerItemModal from "./CareerItemModal";
import CareerItem from "./CareerItem";

export class CareerPage extends Component {
  state = {
    showItemModal: false,
    loading: false,
    modalDescription: {
      _id: "",
      title: "",
      company: "",
      summary: "",
      companySum: "",
      rate: "",
      email: "",
      phone: "",
      contract_type: "",
      location: "",
      description: "",
      postions: [],
      duties: [],
      skills: [],
      nice: [],
      created_at: new Date(),
      logo: "",
      start_date: new Date(),
    },
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  showItemModal = (item) => {
    console.log("item", item);
    this.setState({
      modalDescription: item,
      showItemModal: !this.state.showItemModal,
    });
  };

  render() {
    return (
      <div className="constrain">
        <CareerItemModal
          showItemModal={this.showItemModal}
          show={this.state.showItemModal}
          modalDescription={this.state.modalDescription}
        />
        <div className="inner" style={{ marginTop: "3em" }}>
          <div className="site-card-border-less-wrapper">
            <div style={{ display: "flex" }}>
              <CareerItem
                careerItems={this.props.careerPosts}
                showItemModal={this.showItemModal}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CareerPage;
