import React, { useState } from "react";
import axios from "axios";
import { Layout, Divider, message, Button } from "antd";
import { NavLink, Link } from "react-router-dom";

import SignupForm from "./SignupForm";
import config from "../../../config";

const SignupPage = (props) => {
  const [loading, setLoading] = useState(false);

  const addUser = async (config, values) => {
    let options = {
      cors: true,
      timeout: 15000,
      responseType: "json", // default
      headers: {
        doc: encodeURIComponent(
          `{"email": "${values.email}","authorized": "true"}`
        ),
      },
    };
    await axios
      .post(`${config.USERS}/users`, {}, options)
      .then(function ({ status }) {
        if (status === 200) {
          setLoading(false);
          message.success("Success!");
          props.history.push("/confirm");
        }
      })
      .catch(function (error) {
        console.log(error);
        message.warning(error, 3);
      });
  };


  const handleFormSubmit = async (values) => {
    try {
      setLoading(!loading);
      let result = await props.auth.signup(values.email, values.password);
      if (result) {
        await addUser(config, values);
      }
    } catch (error) {
      if (error.description) {

        message.warning(`This account has been created. Please Login to access your account`);
        setLoading(false);
        console.log(error.description)
      }
    }
  };

  return (
    <Layout
      style={{ margin: "77px auto", textAlign: "center", maxWidth: "500px" }}
    >
      <Layout.Content>
        <div className="inner">
          <h1 className="blue">SIGN UP</h1>
          <SignupForm
            auth={props.auth}
            history={props.history}
            handleFormSubmit={handleFormSubmit}
            loading={loading}
          />
          <Divider>OR</Divider>
          <Button
            style={{ width: "100%" }}
            htmlType="submit"
            onClick={props.auth.loginWithFacebook}
            className="btn-solid"
            type="text"
            disabled={loading}
          >
            LOGIN WITH FACEBOOK
          </Button>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default SignupPage;
