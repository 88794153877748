import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import Loading from "../Loading";
import Candidate from "../candidate/Candidate";
import config from "../../config";

const CandidatePage = (props) => {
  const { id } = useParams();

  function FetchInfo(url) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [blogData, setBlogData] = useState(null);

    async function get() {
      try {
        setLoading(true);
        const result = await axios(url);
        const blogResult = await axios(
          `${config.BLOGS}/blogs/findBlogsByCandidate/${id}`,
          {
            method: "get",
          }
        );
        setData(result.data);
        setBlogData(blogResult.data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }
    useEffect(() => {
      get();
    }, []); // eslint-disable-line
    return [data, blogData, error, loading];
  }
  const [data, blogData, error, loading] = FetchInfo(
    `${config.CANDIDATES}/candidates/findOne/${id}`
  );

  if (error)
    return message.error(
      "Oops ... Something went wrong, we could not load the information for this candidate"
    );
  let result = null;
  if (loading) {
    result = <Loading />;
  } else if (data && data !== null) {
    result = <Candidate data={data} blogData={blogData} />;
  }
  return <>{result}</>;
};

export default CandidatePage;
